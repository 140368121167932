import type { MessageDto } from '@/domain/data/MessageDto';

import { useTheError } from '@/composables/framework/useTheError';
import { useAppInsights } from '@/composables/insights/useAppInsights';
import { config } from '@/config/config';
import { i18n } from '@/plugins/i18n';
import { AuthError } from '@azure/msal-browser';
import { useNetwork } from '@vueuse/core';

const { isOnline } = useNetwork();
const { enabled } = useTheError();

export function useError() {
  const { t } = i18n.global;

  const handleCatchLocalError = function (error: unknown) {
    if (error instanceof AuthError && error.errorCode === 'app_offline') {
      return;
    }
    console.error('Error', error);

    if (config.company.insightsInsturmetationKey.length > 0) {
      useAppInsights().trackException(error);
    }

    const message = t('errorUnexpected');
    if (error instanceof Error) {
      message.concat('\n').concat(error.message);
    }
    enabled(true, message, 'unknown');
  };

  const handleCatchServerError = function (errors: Array<MessageDto>) {
    if (isOnline.value && errors.length > 0) {
      console.error('Error', errors);

      const errMsg = errors.map((error) => `error_${error.code}`).join('\n');

      errors.forEach((error) => {
        const errMessage = error.text ?? error.technicalReason;
        const er = new Error(errMessage);
        console.log('Error', error);
        useAppInsights().trackException(er);
      });
      enabled(true, errMsg, errors[0].code);
    }
  };

  return {
    handleCatchLocalError,
    handleCatchServerError,
  };
}

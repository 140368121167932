<template>
  <div v-if="offer" class="md:px-[80px] max-md:px-16 mt-12 mb-32 min-w-fit">
    <OfferInfo />

    <div class="text-l pt-8 font-bold">{{ t('offers.deliveryDetails') }}</div>

    <div class="flex max-md:flex-col gap-16 py-16">
      <AgFormGroup :label="t('offers.deliveryTerm')" class="basis-1/2" required>
        <AgSearchSelect
          :disabled="disabledDeliveryTerm"
          :options="deliveryTermsOptions"
          v-model="newDeliveryTerm"
          :validation-state="validationPaymentDelivery.deliveryTerm.state"
          :error-message="validationPaymentDelivery.deliveryTerm.msg"
        />
      </AgFormGroup>
      <AgFormGroup :label="t('offers.deliveryMethod')" class="basis-1/2" required>
        <AgSearchSelect
          :options="deliveryMethodOptions"
          v-model="newDeliveryMethod"
          :validation-state="validationPaymentDelivery.deliveryMethod.state"
          :errorMessage="validationPaymentDelivery.deliveryMethod.msg"
        />
      </AgFormGroup>
    </div>

    <div class="text-l pt-24 font-bold border-t-neutral-200 border-t-2">{{ t('offers.dueDate') }}</div>
    <div class="flex max-md:flex-col gap-16 py-16">
      <AgFormGroup :label="t('offers.dueDate')" class="basis-full md:basis-1/2 md:pr-8" required>
        <AgFormInput
          type="date"
          v-model="dueDate"
          :validation-state="validationDueDate.dueDate.state"
          :min="getMinDueDate()"
          :errorMessage="validationDueDate.dueDate.msg"
        />
      </AgFormGroup>
      <div class="basis-1/2">&nbsp;</div>
    </div>

    <div class="text-l pt-24 font-bold border-t-neutral-200 border-t-2">{{ t('offers.paymentDetails') }}</div>
    <div class="flex max-md:flex-col gap-16 py-16">
      <AgFormGroup :label="t('offers.paymentTerm')" class="basis-1/2" required>
        <AgSearchSelect
          :options="paymentTermsOptions"
          v-model="newPaymentTerm"
          :validation-state="validationPaymentDelivery.paymentTerm.state"
          :errorMessage="validationPaymentDelivery.paymentTerm.msg"
        />
      </AgFormGroup>
      <AgFormGroup :label="t('offers.paymenthMethod')" class="basis-1/2" required>
        <AgSearchSelect
          :options="paymentMethodOptions"
          v-model="newPaymentMethod"
          :validation-state="validationPaymentDelivery.paymentMethod.state"
          :errorMessage="validationPaymentDelivery.paymentMethod.msg"
        />
      </AgFormGroup>
    </div>

    <div v-show="offer.paymentMethod?.ibanRequired" class="flex py-8">
      <AgFormGroup label="IBAN" class="basis-full md:basis-1/2 md:pr-8" required>
        <AgFormInput
          v-model="offer.customer.iban"
          :validation-state="validation.iban.state"
          :errorMessage="validation.iban.msg"
        />
      </AgFormGroup>
    </div>
  </div>
</template>

<script setup lang="ts">
  import type { AgSearchSelectOption } from '@/components/library/search-select/AgSearchSelectOption';
  import type { OfferDeliveryMethodDto } from '@/domain/offerData/OfferDeliveryMethodDto';
  import type { OfferDeliveryTermDto } from '@/domain/offerData/OfferDeliveryTermDto';
  import type { OfferPaymentMethodDto } from '@/domain/offerData/OfferPaymentMethodDto';
  import type { OfferPaymentTermDto } from '@/domain/offerData/OfferPaymentTermDto';

  import AgFormGroup from '@/components/library/form-group/AgFormGroup.vue';
  import AgFormInput from '@/components/library/form-input/AgFormInput.vue';
  import AgSearchSelect from '@/components/library/search-select/AgSearchSelect.vue';
  import OfferInfo from '@/modules/offers/components/offer-wizard/steps/OfferInfo.vue';

  import { useMasterData } from '@/composables/data/useMasterData';
  import { useTheFooter } from '@/composables/framework/useTheFooter';
  import { useTheHeader } from '@/composables/framework/useTheHeader';
  import { useTranslatedText } from '@/composables/useTransalteText';
  import { config } from '@/config/config';
  import {
    isDueDateValid,
    isIbanValid,
    isPaymentDeliveryValid,
    validateDueDate,
    validateForm,
    validatePaymentDelivery,
  } from '@/modules/offers/components/offer-wizard/steps/offer-data/OfferDataValidation';
  import { useOfferWizardStore } from '@/modules/offers/stores/useOfferWizardStore';
  import { i18n } from '@/plugins/i18n';
  import { storeToRefs } from 'pinia';
  import { computed, onBeforeUpdate, onMounted, ref } from 'vue';

  const { t } = i18n.global;

  const { getTranslatedText } = useTranslatedText();

  const { tables } = useMasterData();

  const offerStore = useOfferWizardStore();
  const { offer } = storeToRefs(offerStore);

  const theHeader = useTheHeader();
  theHeader.enabled(true);
  theHeader.visible(true);
  theHeader.title('offers.offer', 'offers.offerDetails');

  const theFooter = useTheFooter();
  theFooter.enabled(false);
  theFooter.visible(false);

  const deliveryTerms = ref<Array<OfferDeliveryTermDto>>([]);
  const deliveryMethods = ref<Array<OfferDeliveryMethodDto>>([]);
  const paymentTerms = ref<Array<OfferPaymentTermDto>>([]);
  const paymentMethods = ref<Array<OfferPaymentMethodDto>>([]);

  const emit = defineEmits(['update:modelValue']);

  const showPopper = ref<boolean>(false);

  const getMinDueDate = function () {
    const minDate = new Date().toISOString().split('T')[0];
    return minDate;
  };

  const loadOfferData = async function () {
    deliveryTerms.value = await tables.deliveryTerms.toArray();
    deliveryMethods.value = await tables.deliveryMethods.toArray();
    paymentTerms.value = await tables.paymentTerms.toArray();
    paymentMethods.value = await tables.paymentMethods.toArray();
  };

  const deliveryTermsOptions = computed((): Array<AgSearchSelectOption> => {
    return deliveryTerms.value.map((dt) => {
      return {
        value: dt.id,
        label: getTranslatedText(dt.title),
        searchableString: getTranslatedText(dt.title),
      };
    });
  });

  const disabledDeliveryTerm = computed(() => {
    if (offer.value && offer.value.deliveryTerm && config.company.disabledDeliveryTerm) {
      return true;
    }
    return false;
  });

  const newDeliveryTerm = computed({
    get: () => {
      if (offer.value && offer.value.deliveryTerm) {
        return offer.value.deliveryTerm.id;
      }
      return null;
    },
    set: (deliveryTermId) => {
      if (deliveryTermId && offer.value) {
        const deliveryTerm = deliveryTerms.value.find((dt) => dt.id === deliveryTermId);
        if (deliveryTerm) {
          offer.value.deliveryTerm = deliveryTerm;
        }
      }
    },
  });

  const deliveryMethodOptions = computed((): Array<AgSearchSelectOption> => {
    return deliveryMethods.value.map((dt) => {
      return {
        value: dt.id,
        label: getTranslatedText(dt.title),
        searchableString: getTranslatedText(dt.title),
      };
    });
  });

  const newDeliveryMethod = computed({
    get: () => {
      if (offer.value && offer.value.deliveryMethod) {
        return offer.value.deliveryMethod.id;
      }
      return null;
    },
    set: (deliveryMethodId) => {
      if (deliveryMethodId && offer.value) {
        const deliveryMethod = deliveryMethods.value.find((dm) => dm.id === deliveryMethodId);
        if (deliveryMethod) {
          offer.value.deliveryMethod = deliveryMethod;
        }
      }
    },
  });

  const paymentTermsOptions = computed((): Array<AgSearchSelectOption> => {
    return paymentTerms.value.map((pt) => {
      return {
        value: pt.id,
        label: getTranslatedText(pt.title),
        searchableString: getTranslatedText(pt.title),
      };
    });
  });

  const newPaymentTerm = computed({
    get: () => {
      if (offer.value && offer.value.paymentTerm) {
        return offer.value.paymentTerm.id;
      }
      return null;
    },
    set: (paymentTermId) => {
      if (paymentTermId && offer.value) {
        const paymentTerm = paymentTerms.value.find((pt) => pt.id === paymentTermId);
        if (paymentTerm) {
          offer.value.paymentTerm = paymentTerm;
        }
      }
    },
  });

  const paymentMethodOptions = computed((): Array<AgSearchSelectOption> => {
    return paymentMethods.value.map((pm) => {
      return {
        value: pm.id,
        label: getTranslatedText(pm.title),
        searchableString: getTranslatedText(pm.title),
      };
    });
  });

  const newPaymentMethod = computed({
    get: () => {
      if (offer.value && offer.value.paymentMethod) {
        return offer.value.paymentMethod.id;
      }
      return null;
    },
    set: (paymentMethodId) => {
      if (paymentMethodId && offer.value) {
        const paymentMethod = paymentMethods.value.find((pm) => pm.id === paymentMethodId);
        if (paymentMethod) {
          offer.value.paymentMethod = paymentMethod;
        }
      }
    },
  });

  const dueDate = computed({
    get: () => {
      if (offer.value && offer.value.offerDueDate) {
        const date = new Date(offer.value.offerDueDate * 1000);
        return date.toISOString().split('T')[0];
      }
      return null;
    },
    set: (newDate) => {
      if (newDate && offer.value) {
        const date = new Date(newDate);
        offer.value.offerDueDate = Math.floor(date.getTime() / 1000);
      }
    },
  });

  const validation = computed(() => {
    validateAll();
    return validateForm(offer.value);
  });

  const validationDueDate = computed(() => {
    validateAll();
    return validateDueDate(offer.value?.offerDueDate);
  });

  const validationPaymentDelivery = computed(() => {
    validateAll();
    return validatePaymentDelivery(
      offer.value,
      deliveryTerms.value,
      deliveryMethods.value,
      paymentTerms.value,
      paymentMethods.value
    );
  });

  const validateAll = function () {
    if (
      !offer.value ||
      !isPaymentDeliveryValid(
        offer.value,
        deliveryTerms.value,
        deliveryMethods.value,
        paymentTerms.value,
        paymentMethods.value
      )
    ) {
      emit('update:modelValue', {
        id: 3,
        error: false,
      });
    } else if (!offer.value?.offerDueDate || !isDueDateValid(offer.value?.offerDueDate)) {
      emit('update:modelValue', {
        id: 3,
        error: false,
      });
    } else if (offer.value?.paymentMethod?.ibanRequired) {
      emit('update:modelValue', {
        id: 3,
        error: isIbanValid(offer.value),
      });
    } else {
      emit('update:modelValue', {
        id: 3,
        error: true,
      });
    }
  };

  onMounted(async () => {
    validateAll();
    await loadOfferData();
  });

  onBeforeUpdate(() => {
    if (
      !isPaymentDeliveryValid(
        offer.value,
        deliveryTerms.value,
        deliveryMethods.value,
        paymentTerms.value,
        paymentMethods.value
      ) ||
      isIbanValid(offer.value)
    ) {
      showPopper.value = true;
    }
  });
</script>

<style scoped lang="scss">
  /* Move icon to the left */
  input[type='date']::-webkit-calendar-picker-indicator {
    margin-right: 20px;
  }
</style>
@/domain/InvoiceNumberDto

<template>
  <AgModal v-if="internalValue" fit-width fit-height>
    <template #header>
      <div class="text-2xl font-bold pb-12">{{ t('offers.daysOfRest') }}</div>
    </template>
    <template #content>
      <div class="pb-8">
        <AgTimeSet class="py-12" v-model:from="from" v-model:to="to" @remove-event="removeEvent" variant="black" />
      </div>
    </template>
    <!-- BUTTON -->
    <template #footer>
      <AgButton variant="secondary" @click="cancel"> {{ t('offers.remove') }}</AgButton>
      <div class="flex gap-16">
        <AgButton variant="secondary" @click="removeEvent">{{ t('offers.reset') }}</AgButton>
        <AgButton variant="primary" @click="closeModal">{{ t('offers.apply') }}</AgButton>
      </div>
    </template>
  </AgModal>
</template>

<script setup lang="ts">
  import type { DayOfRestDto } from '@/domain/masterData/DayOfRestDto';

  import AgButton from '@/components/library/button/AgButton.vue';
  import AgModal from '@/components/library/modal/AgModal.vue';
  import AgTimeSet from '@/components/library/time-set/AgTimeSet.vue';

  import { useOfferWizardStore } from '@/modules/offers/stores/useOfferWizardStore';
  import { i18n } from '@/plugins/i18n';
  import { storeToRefs } from 'pinia';
  import { computed } from 'vue';

  const { t } = i18n.global;

  const store = useOfferWizardStore();
  const { offer } = storeToRefs(store);

  interface Props {
    modelValue?: boolean;
    day: number;
  }
  const props = withDefaults(defineProps<Props>(), {
    modelValue: false,
    day: -1,
  });

  const emit = defineEmits(['update:modelValue']);

  const internalValue = computed({
    get: () => {
      return props.modelValue;
    },
    set: (newValue) => {
      emit('update:modelValue', newValue);
    },
  });

  const closeModal = function () {
    internalValue.value = false;
  };

  const cancel = function () {
    removeEvent();
    closeModal();
  };

  const removeEvent = function () {
    const address = offer.value?.deliveryAddressEqualToBilling
      ? offer.value.invoiceAddress
      : offer.value?.deliveryAddress;
    if (props.day > -1 && offer.value && address) {
      const index = daysOfRest.value.findIndex((d) => d.day === props.day);
      if (index > -1) {
        daysOfRest.value.splice(index, 1);
        address.daysOfRest.splice(index, 1);
      }
    }
  };

  const daysOfRest = computed(() => {
    const daysRest: Array<DayOfRestDto> = [];
    const address = offer.value?.deliveryAddressEqualToBilling
      ? offer.value.invoiceAddress
      : offer.value?.deliveryAddress;

    if (address) {
      const tt = address.daysOfRest.length > 0 ? address.daysOfRest : [];
      daysRest.push(...tt);
    }
    return daysRest;
  });

  const from = computed({
    get: () => daysOfRest.value.find((d) => d.day === props.day)?.timeStart || '00:00',
    set: (newTimeStart) => {
      if (newTimeStart) {
        setStartTime(newTimeStart);
      }
    },
  });

  const setStartTime = function (startTime: string) {
    if (offer.value) {
      const address = offer.value?.deliveryAddressEqualToBilling
        ? offer.value.invoiceAddress
        : offer.value?.deliveryAddress;
      if (address) {
        const dayOfRest = daysOfRest.value.find((d) => d.day === props.day);
        if (dayOfRest) {
          dayOfRest.timeStart = startTime;
        } else {
          address.daysOfRest.push({
            id: null,
            day: props.day,
            timeStart: startTime,
            timeEnd: '00:00',
          });
        }
      }
    }
  };

  const to = computed({
    get: () => daysOfRest.value.find((d) => d.day === props.day)?.timeEnd || '00:00',
    set: (newTimeEnd) => {
      if (newTimeEnd) {
        setEndTime(newTimeEnd);
      }
    },
  });

  const setEndTime = function (endTime: string) {
    if (offer.value) {
      const address = offer.value?.deliveryAddressEqualToBilling
        ? offer.value.invoiceAddress
        : offer.value?.deliveryAddress;
      if (address) {
        const dayOfRest = daysOfRest.value.find((d) => d.day === props.day);
        if (dayOfRest) {
          dayOfRest.timeEnd = endTime;
        } else {
          address.daysOfRest.push({
            id: null,
            day: props.day,
            timeStart: '00:00',
            timeEnd: endTime,
          });
        }
      }
    }
  };
</script>

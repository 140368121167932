import authAdminRequired from '@/middleware/authAdminRequired';
import type { RouteRecordRaw } from 'vue-router';
import CollaboratorDetailsPage from './pages/CollaboratorDetailsPage.vue';
import NewCollaboratorPage from './pages/NewCollaboratorPage.vue';

export const routes: Array<RouteRecordRaw> = [
  {
    path: ':collaboratorId/',
    name: '/collaborator',
    component: CollaboratorDetailsPage,
    meta: { layout: 'AdminLayout' },
    beforeEnter: authAdminRequired,
  },
  {
    path: '/new-collaborator',
    name: '/new-collaborator',
    component: NewCollaboratorPage,
    meta: { layout: 'AdminLayout' },
    beforeEnter: authAdminRequired,
  },
];

export default routes;

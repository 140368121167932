<template>
  <div>
    <div>
      <!-- LOADING BOX -->
      <div v-if="isLoading" class="absolute inset-x-1/2 inset-y-1/3 w-fit h-fit">
        <IcLoading />
      </div>
      <!-- USER BOX -->
      <div v-else-if="collaborator" class="md:mx-[80px] max-md:mx-16 min-w-fit pt-8 max-h-[calc(100dvh-160px)]">
        <div class="flex items-center space-x-4">
          <AgButton variant="ghost" @click="saveCollaborator()">
            <template #icon>
              <IcSave />
            </template>
            {{ t('admin.save') }}
          </AgButton>
          <AgButton variant="ghost" @click="deleteCollaborator()">
            <template #icon>
              <IcDelete />
            </template>
            {{ t('admin.delete') }}
          </AgButton>
        </div>

        <div class="flex justify-between">
          <div class="userbox max-w-256">
            <div class="infoboxTitle">{{ t('admin.name').toUpperCase() }}</div>
            <AgFormInput v-model="collaborator.name" class="name" />
          </div>
          <div class="userbox">
            <div class="infoboxTitle">{{ t('admin.active').toUpperCase() }}</div>
            <div>
              <component :is="activeIcon" class="h-24 w-24 fill-success text-infoRed" />
            </div>
          </div>
        </div>

        <!-- Email Edit Section, TO DO -->
        <div class="flex">
          <div class="userbox">
            <div class="infoboxTitle">{{ t('admin.email').toUpperCase() }}</div>
            <AgFormInput
              v-model="collaborator.email"
              class="w-1/2"
              :validation-state="!emailError"
              :error-message="emailErrorText"
            />
          </div>
        </div>

        <!-- User Code -->
        <div class="flex">
          <div class="userbox">
            <div class="infoboxTitle">{{ t('admin.code').toUpperCase() }}</div>
            <div class="name">{{ collaborator.code }}</div>
          </div>
          <div class="userbox">
            <!-- Activate User Button -->
            <AgButton
              :disabled="collaborator?.active"
              class="w-1/4"
              variant="primary"
              rounded
              @click="showActivateModal = !showActivateModal"
            >
              {{ t('admin.activate').toUpperCase() }}
            </AgButton>
          </div>
        </div>

        <div class="max-w-384">
          <span class="infoboxTitle">{{ t('admin.workingForAgent').toUpperCase() }}</span>
          <AgSearchSelect :options="userOptions" v-model="collaborator.workingForAgentId" />
        </div>

        <div class="flex">
          <div class="userbox">
            <div class="infoboxTitle">{{ t('admin.password').toUpperCase() }}</div>
            <div class="flex items-center">
              <component :is="passwordIcon" @click="togglePasswordVisibility" class="cursor-pointer ml-2" />
              <div class="name">{{ passwordVisible ? collaborator.endOfPassword : '****' }}</div>
            </div>
          </div>
          <div class="userbox">
            <AgButton
              :disabled="!collaborator?.active"
              class="w-1/4"
              @click="showActivateModal = !showActivateModal"
              rounded
            >
              {{ t('admin.deactivate').toUpperCase() }}
            </AgButton>
          </div>
        </div>
      </div>
    </div>
    <UserActivationModal
      :userId="collaborator?.id"
      :actived="collaborator?.active ?? false"
      v-model="showActivateModal"
      @update:user="reloadCollaborators"
    />
  </div>
</template>

<script setup lang="ts">
  import type { AuthUserDto } from '@/admin/domain/AuthUserDto';
  import type { AgSearchSelectOption } from '@/components/library/search-select/AgSearchSelectOption';

  import UserActivationModal from '@/admin/modules/collaborators/components/UserActivationModal.vue';
  import IcCheck from '@/components/icons/IcCheck.vue';
  import IcClose from '@/components/icons/IcClose.vue';
  import IcDelete from '@/components/icons/IcDelete.vue';
  import IcHide from '@/components/icons/IcHide.vue';
  import IcLoading from '@/components/icons/IcLoading.vue';
  import IcSave from '@/components/icons/IcSave.vue';
  import IcShow from '@/components/icons/IcShow.vue';
  import AgButton from '@/components/library/button/AgButton.vue';
  import AgFormInput from '@/components/library/form-input/AgFormInput.vue';
  import AgSearchSelect from '@/components/library/search-select/AgSearchSelect.vue';

  import { useCollaborators } from '@/admin/modules/collaborators/composables/useCollaborators';
  import { useTheHeader } from '@/composables/framework/useTheHeader';
  import { i18n } from '@/plugins/i18n';
  import { computed, onMounted, ref } from 'vue';
  import { useRoute, useRouter } from 'vue-router';

  const { t } = i18n.global;
  const { loadUsers, loadData, deleteData, updateData } = useCollaborators();
  const router = useRouter();
  const route = useRoute();
  const theHeader = useTheHeader();

  theHeader.enabled(true);
  theHeader.visible(true);

  const collaborator = ref<AuthUserDto>();
  const passwordVisible = ref<boolean>(false);
  const showActivateModal = ref<boolean>(false);
  const collaborators = ref<Array<AuthUserDto>>([]);
  const userOptions = ref<Array<AgSearchSelectOption>>([]);
  const isLoading = ref<boolean>(true);
  const emailError = ref<boolean>(false);
  const emailErrorText = ref<string>('');
  const users = ref<Array<AuthUserDto>>([]);

  const emailRegexp = new RegExp(
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  );

  const activeIcon = computed(() => {
    return collaborator.value?.active ? IcCheck : IcClose;
  });

  const togglePasswordVisibility = () => {
    passwordVisible.value = !passwordVisible.value;
  };

  const passwordIcon = computed(() => {
    return passwordVisible.value ? IcHide : IcShow;
  });

  const saveCollaborator = async () => {
    if (!collaborator.value?.email || collaborator.value?.email.length === 0) {
      emailError.value = true;
      emailErrorText.value = t('admin.emailRequired');
    } else if (!emailRegexp.test(collaborator.value?.email ?? '')) {
      emailError.value = true;
      emailErrorText.value = t('admin.invalidEmail');
    }

    const emailAlreadyExists =
      users.value.some((user) => user.email === collaborator.value?.email && user.id !== collaborator.value.id) ||
      collaborators.value.some(
        (collab) => collab.email === collaborator.value?.email && collab.id !== collaborator.value.id
      );

    if (emailAlreadyExists) {
      emailError.value = true;
      emailErrorText.value = t('admin.emailAlreadyExists');
    }
    if (emailError.value) {
      return;
    }
    isLoading.value = true;
    await updateData(collaborator.value);
    try {
      await router.push({
        path: '/admin/collaborators',
      });
    } catch (error) {
      console.error('Failed to navigate to collaborators:', error);
    }
    isLoading.value = false;
  };

  const deleteCollaborator = async function () {
    if (collaborator.value) {
      isLoading.value = true;
      await deleteData(collaborator.value.id);
      try {
        await router.push({
          path: '/admin/collaborators',
        });
      } catch (error) {
        console.error('Failed to navigate to collaborators:', error);
      }
      isLoading.value = false;
    }
  };

  const reloadCollaborators = async function () {
    isLoading.value = true;
    const collaboratorId = parseInt(route.params.collaboratorId.toString());
    collaborators.value = await loadData().then((users) => {
      isLoading.value = false;
      return users;
    });
    collaborator.value = collaborators.value.find((collab) => collab.id === collaboratorId);
  };

  onMounted(async () => {
    try {
      await reloadCollaborators();
      users.value = await loadUsers();
      userOptions.value = users.value.map((user) => ({
        value: user.id,
        id: user.id,
        searchableString: `${user.name} / ${user.email}`,
        label: `${user.name} / ${user.email}`,
      }));
    } catch (error) {
      console.log('Some error during the loading of the collaborator: ', error);
    }
  });
</script>

<style scoped lang="scss">
  .infoboxTitle {
    @apply text-primary-light;
  }

  .name {
    @apply text-xl font-bold;
  }

  .userbox {
    @apply w-1/2 justify-center my-12;
  }
</style>

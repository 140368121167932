<template>
  <div v-if="isLoading" class="absolute inset-x-1/2 inset-y-1/3 w-fit h-fit">
    <IcLoading />
  </div>
  <div v-else-if="currentBusinessCategory" class="md:mx-[80px]">
    <div class="flex items-center space-x-4">
      <AgButton variant="ghost" @click="saveBusinessCategory">
        <template #icon>
          <IcSave />
        </template>
        {{ t('admin.save') }}
      </AgButton>
      <AgButton variant="ghost" @click="deleteBusinessCategory(currentBusinessCategory.id)">
        <template #icon>
          <IcDelete />
        </template>
        {{ t('admin.delete') }}
      </AgButton>
    </div>

    <div class="flex">
      <div class="infoBox">
        <div class="infoboxTitle">{{ t('admin.code').toUpperCase() }}</div>
        <div class="name">{{ currentBusinessCategory.code }}</div>
      </div>
    </div>

    <div class="flex">
      <div class="table mt-16">
        <span class="text-primary-light">{{ t('admin.languageDescription').toUpperCase() }}</span>
        <div v-for="selectedLang in Object.values(config.company.availableLanguages)" :key="selectedLang">
          <div class="tableColumn">
            <AgFormInput :placeholder="selectedLang" disabled />
            <AgFormInput v-model="currentBusinessCategory.title[selectedLang]" />
            <IcClose class="cursor-pointer" @click="deleteTranslation(selectedLang)"></IcClose>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div v-else>
    <AgAlert variant="info">{{ t('admin.notFound') }}</AgAlert>
  </div>
</template>

<script setup lang="ts">
  import type { BusinessCategoryDto } from '@/admin/domain/BusinessCategoryDto';
  import type { AgFormSelectOption } from '@/components/library/form-select/AgFormSelectOption';

  import IcClose from '@/components/icons/IcClose.vue';
  import IcDelete from '@/components/icons/IcDelete.vue';
  import IcLoading from '@/components/icons/IcLoading.vue';
  import IcSave from '@/components/icons/IcSave.vue';
  import AgAlert from '@/components/library/alert/AgAlert.vue';
  import AgButton from '@/components/library/button/AgButton.vue';
  import AgFormInput from '@/components/library/form-input/AgFormInput.vue';

  import { useBusinnessCategories } from '@/admin/modules/businessCategories/composables/useBusinnessCategories';
  import { useTheHeader } from '@/composables/framework/useTheHeader';
  import { config } from '@/config/config';
  import { i18n } from '@/plugins/i18n';
  import { onMounted, ref } from 'vue';
  import { useRoute, useRouter } from 'vue-router';

  const { t } = i18n.global;
  const route = useRoute();
  const router = useRouter();
  const { loadData, deleteData, updateData } = useBusinnessCategories();
  const theUsedHeader = useTheHeader();

  theUsedHeader.enabled(true);
  theUsedHeader.visible(true);
  theUsedHeader.title('admin.businessCategoryDetails');

  const businessCategories = ref<Array<BusinessCategoryDto>>([]);
  const isLoading = ref<boolean>(true);
  const currentBusinessCategory = ref<BusinessCategoryDto>();
  const languageSelectOptions = ref<Array<AgFormSelectOption>>([]);
  const predefinedChecked = ref<boolean>(false);

  const setCurrentItem = () => {
    currentBusinessCategory.value = businessCategories.value.find(
      (category) => category.id.toString() === route.params.categoryId
    );
  };

  const saveBusinessCategory = async () => {
    predefinedChecked.value = false;

    isLoading.value = true;
    await updateData(currentBusinessCategory.value);
    try {
      await router.push({
        path: '/admin/business-categories',
      });
    } catch (error) {
      console.error('Failed to navigate to business categories:', error);
    }
    isLoading.value = false;
  };

  const deleteBusinessCategory = async function (categoryId: number) {
    isLoading.value = true;
    await deleteData(categoryId);
    try {
      await router.push({
        path: '/admin/business-categories',
      });
    } catch (error) {
      console.error('Failed to navigate to business categories:', error);
    }
    isLoading.value = false;
  };

  const deleteTranslation = function (language: string) {
    if (!currentBusinessCategory.value) {
      return;
    }
    const titleKeys = Object.keys(currentBusinessCategory.value.title);
    const indexToRemove = titleKeys.findIndex((keyLang) => keyLang === language);

    if (indexToRemove !== -1) {
      delete currentBusinessCategory.value.title[language];
    }
  };

  const update = async function () {
    loadData()
      .then((categories) => {
        businessCategories.value = categories ?? [];
        if (categories) {
          setCurrentItem();
        }
        isLoading.value = false;

        const options: Array<AgFormSelectOption> = [];
        Object.values(config.company.availableLanguages).forEach((lang) => {
          options.push({
            key: lang,
            label: lang,
          });
        });
        languageSelectOptions.value = options;
      })
      .catch((error) => {
        console.log('Error loading business categories: ', error);
        isLoading.value = false;
      });
  };

  onMounted(async () => update());
</script>

<style scoped lang="scss">
  .infoboxTitle {
    @apply text-primary-light;
  }

  .name {
    @apply text-xl font-bold flex items-center;
  }

  .infoBox {
    @apply w-1/2 justify-center my-12;
  }

  .table {
    @apply grid grid-cols-1 py-16 space-x-0;
  }

  .tableColumn {
    @apply flex items-center min-w-0 py-4 gap-4;
  }
</style>

import authAdminRequired from '@/middleware/authAdminRequired';
import type { RouteRecordRaw } from 'vue-router';
import BusinessCategoryDetailPage from './pages/BusinessCategoryDetailPage.vue';
import NewBusinessCategoryPage from './pages/NewBusinessCategoryPage.vue';

export const routes: Array<RouteRecordRaw> = [
  {
    path: ':categoryId/',
    name: '/business-category',
    component: BusinessCategoryDetailPage,
    meta: { layout: 'AdminLayout' },
    beforeEnter: authAdminRequired,
  },
  {
    path: '/new-business-category',
    name: '/new-business-category',
    component: NewBusinessCategoryPage,
    meta: { layout: 'AdminLayout' },
    beforeEnter: authAdminRequired,
  },
];

export default routes;

<template>
  <div v-if="offer" class="md:px-[80px] max-md:px-16 mt-12 mb-32 overflow-x-auto">
    <OfferInfo />

    <div v-if="items.length > 0" class="min-w-fit py-12">
      <!-- ARTICLES -->
      <div v-for="(item, index) in items" :key="item.article.id">
        <div class="flex-col border-b-2 border-y-neutral-200 w-full py-12" :class="{ 'border-t-2': index === 0 }">
          <div
            class="flex gap-12 text-s-13 font-light w-fit text-neutral-550 my-4"
            v-if="item.article.articleGroup || item.article.articleType"
          >
            <div v-if="item.article.articleGroup">
              {{ getTranslatedText(item.article.articleGroup) }}
            </div>
            <div v-if="item.article.articleType">
              {{ getTranslatedText(item.article.articleType) }}
            </div>
          </div>
          <div class="flex flex-row items-center gap-4">
            <span class="font-bold text-l">{{ getTranslatedText(item.article.title) }}</span>
            <div v-if="item.note">
              <Popper class="agPopper" arrow @click.stop>
                <AgButton variant="ghost">
                  <template #icon>
                    <IcInfo class="text-link" />
                  </template>
                </AgButton>
                <template #content>
                  <div class="p-8 text-s-20 whitespace-pre-wrap max-w-[400px] max-h-[400px] break-all">
                    {{ item.note }}
                  </div>
                </template>
              </Popper>
            </div>
          </div>
          <div>
            <span class="text-neutral-550 font-light">{{ t('offers.articleCode') }}:&nbsp;</span>
            <span>{{ item.article.code }}</span>
          </div>
          <div class="font-bold mt-12">
            <span class="font-bold">{{ t('offers.packaging') }}:&nbsp;</span>
            {{ item.packaging.unit.title[locale] }}
          </div>

          <div class="flex items-start">
            <div class="flex flex-col basis-6/12">
              <div v-if="item.quantity && item.quantity > 0">
                <span class="justify-end text-neutral-550 font-light">{{ t('offers.quantity') }}:&nbsp;</span>
                <span class="font-bold">{{ item.quantity }}&nbsp;</span>
                <span> (x {{ item.packaging.size }} = {{ (item.packaging.size * item.quantity).toFixed(2) }}) </span>
              </div>
              <div v-if="config.company.enableFreeQuantity && item.freeQuantity && item.freeQuantity > 0">
                <span class="justify-end text-neutral-550 font-light">{{ t('offers.freeQta') }}:&nbsp;</span>
                <span class="font-bold">{{ item.freeQuantity }}</span>
                <span>
                  (x {{ item.packaging.size }} = {{ (item.packaging.size * item.freeQuantity).toFixed(2) }})
                </span>
              </div>
              <div v-if="item.article.dimensions && item.article.dimensions.length > 0" class="flex flex-row">
                <span class="text-neutral-550 font-light">{{ t('offers.optionals') }}:&nbsp;</span>
                <span>{{ getSortDimensions(item, locale) }}</span>
              </div>
              <div
                v-if="
                  item.article.articleTypeCode === config.company.productionArticleTypeCode &&
                  item.article.productionDescription
                "
                class="break-all"
              >
                <span class="font-light text-neutral-550 break-all"> {{ t('offers.productDescription') }}:&nbsp; </span>
                {{ item.article.productionDescription.substring(0, 600) }}
                {{ item.article.productionDescription.length > 600 ? '...' : '' }}
              </div>
            </div>

            <div class="basis-5/12 flex flex-col ml-24 items-end">
              <div>
                <span class="font-light text-neutral-550"> {{ t('orders.unitPrice') }}:&nbsp;</span>
                {{ formatNumberToEuro(tradeAgreementPrice(item)) }}
              </div>
              <div v-if="item.quantity && item.quantity > 0">
                ({{ tradeAgreementPrice(item) }}*{{ item.packaging.size }}*{{ item.quantity }})&nbsp;=&nbsp;
                <span>{{ formatNumberToEuro(getFinalPrice(item)) }}</span>
              </div>
              <div v-if="getDiscountsString(item)" class="pl-4 text-neutral-550 font-light">
                <span v-if="getDiscountedAmount(item) > 0">
                  - {{ formatNumberToEuro(getDiscountedAmount(item)) }}&nbsp;
                </span>
                <span>({{ getDiscountsString(item) }})</span>
              </div>
              <span class="font-bold text-l">
                {{ formatNumberToEuro(getFinalDiscountedPrice(item)) }}
              </span>
              <div
                v-if="item.quantity && item.quantity > 0 && item.tradeAgreement.isPromotional"
                class="flex flex-row w-full justify-end"
              >
                <span class="text-infoRed font-bold">{{ t('offers.promotion') }}</span>
              </div>
              <span
                class="text-link font-bold"
                v-if="item.article.articleTypeCode === config.company.productionArticleTypeCode"
              >
                {{ t('offers.production') }}
              </span>
            </div>
            <!-- COLUMN DELETED -->
            <div class="flex basis-1/12 items-stretch justify-end">
              <AgButton variant="ghost" @click.stop="openPopupEditArticle(item)" class="pr-12">
                <template #icon>
                  <IcEdit class="h-24 w-24 hover:text-link" />
                </template>
              </AgButton>
              <AgButton variant="ghost" @click.stop="removeArticle(item)">
                <template #icon>
                  <IcDelete class="h-24 w-24 hover:text-link" />
                </template>
              </AgButton>
            </div>
          </div>
        </div>
      </div>
      <OfferWizardShoppingCartSummary editDiscounts v-model:openPopupPositionsDiscount="showModalDiscount" />
    </div>
    <div v-else class="h-[calc(100dvh-300px)] flex flex-col justify-center items-center">
      <LogoCart variant="gray" />
      <div class="flex flex-col items-center">
        <span class="mt-24 font-bold text-xl">{{ t('offers.emptyShoppingCart') }}</span>
        <span class="mt-8 max-w-[364px] text-center text-s-20">{{ t('offers.addArticles') }}</span>
      </div>
    </div>

    <div class="flex justify-end w-full">
      <div class="pb-96 fixed bottom-0">
        <AgButton effect="hover" class="min-h-48 min-w-48 rounded-full" @click.stop="openPopupArticle">
          {{ t('offers.addArticle') }}
          <template #icon><IcAdd /></template>
        </AgButton>
      </div>
    </div>

    <OfferWizardPopupArticle v-model="showModal" />
    <OfferWizardPopupDiscount v-model="showModalDiscount" />
    <OfferWizardPopupEditArticle v-model="showModalEdit" :item="itemSelected" />
  </div>
</template>

<script setup lang="ts">
  import type { OfferItemDto } from '@/domain/offerData/OfferItemDto';

  import IcAdd from '@/components/icons/IcAdd.vue';
  import IcDelete from '@/components/icons/IcDelete.vue';
  import IcEdit from '@/components/icons/IcEdit.vue';
  import IcInfo from '@/components/icons/IcInfo.vue';
  import AgButton from '@/components/library/button/AgButton.vue';
  import LogoCart from '@/components/logo/LogoCart.vue';
  import OfferInfo from '@/modules/offers/components/offer-wizard/steps/OfferInfo.vue';
  import OfferWizardPopupArticle from '@/modules/offers/components/offer-wizard/steps/shopping-cart/OfferWizardPopupArticle.vue';
  import OfferWizardPopupDiscount from '@/modules/offers/components/offer-wizard/steps/shopping-cart/OfferWizardPopupDiscount.vue';
  import OfferWizardPopupEditArticle from '@/modules/offers/components/offer-wizard/steps/shopping-cart/OfferWizardPopupEditArticle.vue';
  import OfferWizardShoppingCartSummary from '@/modules/offers/components/offer-wizard/steps/shopping-cart/shopping-cart-summary/OfferWizardShoppingCartSummary.vue';
  import Popper from 'vue3-popper';

  import { useTheFooter } from '@/composables/framework/useTheFooter';
  import { useTheHeader } from '@/composables/framework/useTheHeader';
  import { useNumberFormatting } from '@/composables/useNumberFormatting';
  import { useTranslatedText } from '@/composables/useTransalteText';
  import { config } from '@/config/config';
  import { useOfferWizardStore } from '@/modules/offers/stores/useOfferWizardStore';
  import { i18n } from '@/plugins/i18n';
  import { storeToRefs } from 'pinia';
  import { computed, onMounted, ref, watch } from 'vue';

  const { locale, t } = i18n.global;

  const { getTranslatedText } = useTranslatedText();
  const { formatNumberToEuro } = useNumberFormatting();
  const { formatNumberToPercentage } = useNumberFormatting();

  const offerStore = useOfferWizardStore();
  const { offer } = storeToRefs(offerStore);

  const theHeader = useTheHeader();
  theHeader.enabled(true);
  theHeader.visible(true);
  theHeader.title('offers.offer', 'offers.shoppingCart');

  const theFooter = useTheFooter();
  theFooter.enabled(false);
  theFooter.visible(false);

  const emit = defineEmits(['update:modelValue']);

  const showModal = ref<boolean>(false);
  const showModalEdit = ref<boolean>(false);
  const showModalDiscount = ref<boolean>(false);
  const itemSelected = ref<OfferItemDto>();

  const items = computed((): Array<OfferItemDto> => {
    if (!offer.value) {
      return [];
    }
    return offer.value.items;
  });

  const tradeAgreementPrice = function (item: OfferItemDto) {
    if (item && item.quantity && item.quantity > 0) {
      if (item.isTradeAgreementPriceEdited) {
        return item.tradeAgreement.editedPrice ?? 0;
      }
      return item.tradeAgreement.price;
    }
    return 0;
  };

  const openPopupArticle = () => (showModal.value = true);

  const openPopupEditArticle = (article: OfferItemDto) => {
    itemSelected.value = article;
    showModalEdit.value = true;
  };

  const getSortDimensions = function (item: OfferItemDto, locale: string) {
    const dimensions: Array<string> = [];

    if (item.article.dimensions && item.article.dimensions.length > 0) {
      const sortDim = item.article.dimensions.sort((a, b) => {
        if (!a.title[locale]) {
          console.warn('Warning: founded a dimension without title in locale: ' + locale);
          return 1;
        }
        if (!b.title[locale]) {
          console.warn('Warning: founded a dimension without title in locale: ' + locale);
          return -1;
        }
        return a.title[locale].localeCompare(b.title[locale]);
      });
      dimensions.push(...sortDim.map((d) => d.value.title[locale]));
    }
    return dimensions.join(', ');
  };

  const removeArticle = function (item: OfferItemDto) {
    if (!offer.value) {
      return;
    }
    const index = offer.value.items.findIndex((x) => x.article === item.article && x.packaging === item.packaging);
    offer.value.items.splice(index, 1);
  };

  const getFinalPrice = function (item: OfferItemDto) {
    if (item.quantity && item.quantity > 0) {
      return tradeAgreementPrice(item) * item.quantity * item.packaging.size;
    }
    return 0;
  };

  const getFinalDiscountedPrice = function (item: OfferItemDto) {
    let price = tradeAgreementPrice(item);
    for (let i = 1; i <= config.company.maxItemDiscounts; i++) {
      if ((item as never)[`discount${i}`]) {
        price = price * (1 - (item as never)[`discount${i}`] / 100);
      }
    }
    if (item.quantity && item.quantity > 0) {
      return price * item.quantity * item.packaging.size;
    }
    return 0;
  };

  const getDiscountedAmount = function (item: OfferItemDto) {
    return getFinalPrice(item) - getFinalDiscountedPrice(item);
  };

  const getDiscountsString = function (item: OfferItemDto) {
    const discounts: Array<string> = [];
    if (item.quantity && item.quantity > 0) {
      type ObjectKey = keyof typeof item & number;
      for (let i = 1; i <= config.company.maxItemDiscounts; i++) {
        const key = `discount${i}` as ObjectKey;
        if (!item[key]) {
          continue;
        }
        discounts.push(formatNumberToPercentage(item[key]));
      }
    }
    return discounts.join(', ');
  };

  const updateValidation = function (length: number) {
    if (length === 0) {
      emit('update:modelValue', {
        id: 2,
        error: false,
      });
    } else {
      emit('update:modelValue', {
        id: 2,
        error: true,
      });
    }
  };

  watch(items.value, (newValue) => updateValidation(newValue.length), {
    immediate: true,
  });

  onMounted(() => updateValidation(offer.value?.items.length ?? 0));
</script>
<style scoped lang="scss">
  .agPopper {
    --popper-theme-background-color: #006fff;
    --popper-theme-background-color-hover: #006fff;
    --popper-theme-text-color: #ffffff;
    --popper-theme-border-width: 0px;
    --popper-theme-border-style: solid;
    --popper-theme-border-radius: 6px;
    --popper-theme-box-shadow: 0 6px 30px -6px rgba(0, 0, 0, 0.25);
  }
</style>

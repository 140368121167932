<template>
  <div v-if="isLoading" class="absolute inset-x-1/2 inset-y-1/3 w-fit h-fit">
    <IcLoading />
  </div>
  <div v-else class="md:mx-[80px]">
    <div class="flex items-center space-x-4">
      <AgButton variant="ghost" @click="saveDownload">
        <template #icon>
          <IcSave />
        </template>
        {{ t('admin.save') }}
      </AgButton>
    </div>

    <div class="infoBox">
      <span class="infoboxTitle">{{ t('admin.title').toUpperCase() }}</span>
      <AgFormInput
        v-model="download.title"
        class="w-256"
        :validation-state="!titleError"
        :error-message="titleErrorText"
        @input="resetValidation"
      />
    </div>

    <div class="infoBox max-w-384">
      <span class="infoboxTitle">{{ t('admin.category').toUpperCase() }}</span>
      <AgSearchSelect :options="categoryOptions" v-model="download.category.id" />
    </div>

    <div class="infoBox max-w-384">
      <span class="infoboxTitle">{{ t('admin.language').toUpperCase() }}</span>
      <AgFormSelect :options="languageSelectOptions" v-model="download.language" />
    </div>

    <div class="infoBox max-w-384">
      <span class="infoboxTitle">{{ t('admin.permission').toUpperCase() }}</span>
      <AgSearchSelect :options="permissionGroupOptions" v-model="download.permissionGroupId" />
    </div>

    <div class="infoBox max-w-384">
      <span class="infoboxTitle">{{ t('admin.article').toUpperCase() }}</span>
      <AgSearchSelect
        :options="articleOptions"
        v-model="download.articleId"
        :disabled="download.articleGroupId !== null && download.articleGroupId !== -1"
      />
    </div>

    <div class="infoBox max-w-384">
      <span class="infoboxTitle">{{ t('admin.articleGroup').toUpperCase() }}</span>
      <AgSearchSelect
        :options="articleGroupOptions"
        v-model="download.articleGroupId"
        :disabled="download.articleId !== null && download.articleId !== -1"
      />
    </div>

    <div class="infoBox">
      <span class="infoboxTitle">{{ t('admin.fileName').toUpperCase() }}</span>
      <div class="flex items-center gap-4">
        <AgFormInput
          v-model="download.fileName"
          class="w-256"
          :validation-state="!fileNameError"
          :error-message="fileNameErrorText"
          @input="resetValidation"
        />
        <IcShow v-if="fileInput && fileInput.value" class="h-24 w-24 cursor-pointer text-infoBlue" @click="openFile" />
      </div>
    </div>

    <div>
      <div class="infoboxTitle">{{ t('admin.upload').toUpperCase() }}</div>
      <input ref="fileInput" type="file" class="hidden" accept="application/pdf" @change="handleFileChange" />
      <AgButton @click="openFileDialog" variant="ghost">
        <template #icon>
          <IcDownload class="h-24 w-24 text-infoBlue" />
        </template>
      </AgButton>
      <AgAlert v-if="downloadError" variant="danger">{{ t('admin.downloadRequired') }}</AgAlert>
    </div>
  </div>
</template>

<script setup lang="ts">
  import type { DownloadDto } from '@/admin/domain/DownloadDto';
  import type { AgFormSelectOption } from '@/components/library/form-select/AgFormSelectOption';
  import type { AgSearchSelectOption } from '@/components/library/search-select/AgSearchSelectOption';

  import IcDownload from '@/components/icons/IcDownload.vue';
  import IcLoading from '@/components/icons/IcLoading.vue';
  import IcSave from '@/components/icons/IcSave.vue';
  import IcShow from '@/components/icons/IcShow.vue';
  import AgButton from '@/components/library/button/AgButton.vue';
  import AgFormInput from '@/components/library/form-input/AgFormInput.vue';
  import AgFormSelect from '@/components/library/form-select/AgFormSelect.vue';
  import AgSearchSelect from '@/components/library/search-select/AgSearchSelect.vue';

  import { useDownloads } from '@/admin/modules/downloads/composables/useDownloads';
  import AgAlert from '@/components/library/alert/AgAlert.vue';
  import { useTheHeader } from '@/composables/framework/useTheHeader';
  import { useTranslatedText } from '@/composables/useTransalteText';
  import { config } from '@/config/config';
  import { i18n } from '@/plugins/i18n';
  import { onMounted, ref } from 'vue';
  import { useRouter } from 'vue-router';

  const { t, locale } = i18n.global;
  const { createData, getDownloadCategoryList, getPermissionGroups, getArticles, getArticleGroups } = useDownloads();
  const { getTranslatedText } = useTranslatedText();
  const router = useRouter();
  const theHeader = useTheHeader();

  theHeader.enabled(true);
  theHeader.visible(true);

  const download = ref<DownloadDto>({
    id: 0,
    title: '',
    fileName: '',
    language: '',
    category: {
      id: 0,
      title: {},
      code: '',
      downloadsNumber: 0,
    },
    articleGroupId: null,
    articleId: null,
    permissionGroupId: null,
  });
  const isLoading = ref<boolean>(false);
  const languageSelectOptions = ref<Array<AgFormSelectOption>>([]);
  const categoryOptions = ref<Array<AgSearchSelectOption>>([]);
  const fileInput = ref<HTMLInputElement | null>(null);
  const permissionGroupOptions = ref<Array<AgSearchSelectOption>>([]);
  const articleOptions = ref<Array<AgSearchSelectOption>>([]);
  const articleGroupOptions = ref<Array<AgSearchSelectOption>>([]);

  const titleError = ref<boolean>(false);
  const titleErrorText = ref<string>('');
  const downloadError = ref<boolean>(false);
  const fileNameError = ref<boolean>(false);
  const fileNameErrorText = ref<string>('');

  const openFileDialog = () => {
    resetValidation();
    fileInput.value?.click();
  };

  const handleFileChange = (event: Event) => {
    const target = event.target as HTMLInputElement;
    const files = target.files;

    if (files && files.length > 0 && download.value) {
      const file = files[0];
      download.value.fileName = file.name;
    }
  };

  const resetValidation = function () {
    titleError.value = false;
    titleErrorText.value = '';
    downloadError.value = false;
    fileNameError.value = false;
    fileNameErrorText.value = '';
  };

  const saveDownload = async () => {
    resetValidation();
    if (!download.value.title || download.value.title.length === 0) {
      titleError.value = true;
      titleErrorText.value = t('admin.titleRequired');
    }
    if (!download.value.fileName || download.value.fileName.length === 0) {
      fileNameError.value = true;
      fileNameErrorText.value = t('admin.fileNameRequired');
    }
    if (!download.value.fileName.includes('.pdf')) {
      fileNameError.value = true;
      fileNameErrorText.value = t('admin.fileNotSupported');
    }

    if (!fileInput.value || !fileInput.value.files || !fileInput.value.files[0]) {
      downloadError.value = true;
    }

    if (download.value && !titleError.value && !fileNameError.value && !downloadError.value) {
      await createData(download.value, fileInput.value?.files?.[0] ?? null);
      try {
        await router.push({
          path: '/admin/downloads',
        });
      } catch (error) {
        console.error('Failed to navigate to downloads:', error);
      }
    }
  };

  const openFile = function () {
    if (fileInput.value && fileInput.value.files && fileInput.value.files[0]) {
      const fileURL = URL.createObjectURL(fileInput.value.files[0]);
      window.open(fileURL, '_blank');
    }
  };

  const update = async function () {
    const options: Array<AgFormSelectOption> = [];
    Object.values(config.company.availableLanguages).forEach((lang) => {
      options.push({
        key: lang,
        label: lang,
      });
    });

    languageSelectOptions.value = options;
  };

  onMounted(async () => {
    update();

    const categories = await getDownloadCategoryList();

    categories.forEach((category) => {
      categoryOptions.value.push({
        label: getTranslatedText(category.title),
        value: category.id,
        searchableString: getTranslatedText(category.title),
      });
    });

    const permissionGroupsData = await getPermissionGroups();

    permissionGroupOptions.value = permissionGroupsData.map((group) => ({
      label: group.code,
      value: group.id,
      searchableString: group.code,
    }));
    permissionGroupOptions.value.unshift({ label: 'admin.noChoice', value: -1, searchableString: 'admin.noChoice' });

    const articlesData = await getArticles();

    articleOptions.value = articlesData.map((article) => ({
      label: `${article.code} / ${getTranslatedText(article.title)}`,
      value: article.id,
      searchableString: `${article.code} / ${getTranslatedText(article.title)}`,
    }));
    articleOptions.value.unshift({ label: 'admin.noChoice', value: -1, searchableString: 'admin.noChoice' });

    const articleGroupsData = await getArticleGroups();

    articleGroupOptions.value = articleGroupsData.map((group) => ({
      label: `${group.code} / ${getTranslatedText(group.title)}`,
      value: group.id,
      searchableString: `${group.code} / ${getTranslatedText(group.title)}`,
    }));
    articleGroupOptions.value.unshift({ label: 'admin.noChoice', value: -1, searchableString: 'admin.noChoice' });

    if (categories && categories.length > 0) {
      download.value.category.id = categories[0].id;
    }
    download.value.language = locale.value;
  });
</script>

<style scoped lang="scss">
  .infoboxTitle {
    @apply text-primary-light;
  }
  .infoBox {
    @apply my-12;
  }

  .table {
    @apply grid grid-cols-1 py-16 space-x-0;
  }

  .tableColumn {
    @apply flex items-center min-w-0 py-4 gap-4;
  }

  .content {
    margin-top: 16px;
  }

  .contentDownloads {
    margin-top: 16px;
  }
</style>

<template>
  <div>
    <!-- SUBHEADER SEARCH AREA -->
    <TheSubheader>
      <OpenItemsFilter v-model:selectDue="selectDue" v-model:inputSearch="inputSearch" />
    </TheSubheader>

    <div class="mb-32 mt-12 md:mx-[80px] max-md:mx-16">
      <div v-if="isLoading" class="absolute inset-x-1/2 inset-y-1/3 w-fit h-fit">
        <IcLoading />
      </div>
      <!-- PAGINATED CONTENT -->
      <div v-else class="no-scrollbar overflow-x-auto">
        <div class="my-12 min-w-fit">
          <div v-if="openItems.content && openItems.content.length > 0" class="my-16 flex items-center justify-between">
            <AgPaginatorInfo :current-page="currentPage" :page-size="pageSize" :number-items="totalItems" />

            <div
              v-if="isAuthenticated && isOnline && emailTo"
              class="flex cursor-pointer items-center hover:text-neutral-550"
              @click="openPopupEmail"
            >
              {{ t('openItems.sendByMail') }}&nbsp;<IcMailClose class="h-24 w-24" />
            </div>
            <div v-else class="flex items-center text-neutral-500">
              {{ t('openItems.sendByMail') }}&nbsp;<IcMailClose class="h-24 w-24" />
            </div>
          </div>

          <!-- PAGINATOR -->
          <AgPaginator
            v-if="openItems.content && openItems.content.length > 0"
            v-model="currentPage"
            :total-pages="totalPages"
            class="my-24"
          />
          <!-- TABLE ALL OPEN ITEMS -->
          <div>
            <OpenItemsTable :openItems="openItems.content" v-model="sorting" />
          </div>
        </div>

        <!-- SEND EMAIL POPUP -->
        <OpenItemsPopupEmail
          v-model:modal="showPopupEmail"
          v-model:email="emailTo"
          :which-email="WhichEmail.OUTSTANDING_DEBTS"
          :filter="filter"
          :sorting="sorting"
          :customer-language="locale"
        />
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
  import TheSubheader from '@/components/framework/the-subheader/TheSubheader.vue';
  import IcLoading from '@/components/icons/IcLoading.vue';
  import IcMailClose from '@/components/icons/IcMailClose.vue';
  import AgPaginator from '@/components/library/paginator/AgPaginator.vue';
  import AgPaginatorInfo from '@/components/library/paginator/info/AgPaginatorInfo.vue';
  import OpenItemsFilter from '@/modules/open-items/components/OpenItemsFilter.vue';
  import OpenItemsPopupEmail from '@/modules/open-items/components/OpenItemsPopupEmail.vue';
  import OpenItemsTable from '@/modules/open-items/components/OpenItemsTable.vue';

  import { useAuthentication } from '@/composables/auth/useAuthentication';
  import { useTheFooter } from '@/composables/framework/useTheFooter';
  import { useTheHeader } from '@/composables/framework/useTheHeader';
  import { WhichEmail } from '@/domain/enumeration/WhichEmail';
  import { useOpenItemStore } from '@/modules/open-items/store/useOpenItemStore';
  import { i18n } from '@/plugins/i18n';
  import { Filter } from '@/util/Filter';
  import { OutstandingFilter } from '@/util/filters/OutstandingFilter';
  import { Order } from '@/util/Order';
  import { PageRequest } from '@/util/Paging';
  import { Sorting } from '@/util/Sorting';
  import { useNetwork } from '@vueuse/core';
  import { storeToRefs } from 'pinia';
  import { computed, ref, watch } from 'vue';

  const { t, locale } = i18n.global;

  const store = useOpenItemStore();
  const { openItems } = storeToRefs(store);
  const { loadData } = store;

  const { accountName, isAuthenticated } = useAuthentication();
  const { isOnline } = useNetwork();

  const theHeader = useTheHeader();
  theHeader.enabled(true);
  theHeader.visible(true);
  theHeader.title('openItems.openItems');

  const theFooter = useTheFooter();
  theFooter.enabled(true);
  theFooter.visible(true);

  const emailTo = ref<string>(accountName.value);
  const currentPage = ref<number>(1);
  const pageSize = ref<number>(10);
  const sorting = ref<Sorting>(new Sorting('invoiceDate', Order.ASC));
  const selectDue = ref<number>(0);
  const inputSearch = ref<string>('');
  const isLoading = ref<boolean>(true);

  const showPopupEmail = ref<boolean>(false);
  const openPopupEmail = () => (showPopupEmail.value = true);

  const filter = computed(() => new Filter(inputSearch.value, selectDue.value));

  const totalPages = computed(() => {
    return openItems.value.totalPages;
  });

  const totalItems = computed(() => {
    return openItems.value.totalElements;
  });

  watch(
    [selectDue, inputSearch, sorting, currentPage],
    () => {
      loadData(
        new PageRequest(pageSize.value, currentPage.value),
        new OutstandingFilter(inputSearch.value, selectDue.value),
        sorting.value
      )
        .then(() => (isLoading.value = false))
        .catch((error) => console.log(error));
    },
    { immediate: true }
  );
</script>

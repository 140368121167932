<template>
  <div>
    <div class="overflow-y-auto">
      <!-- LOADING BOX -->
      <div v-if="isLoading" class="absolute inset-x-1/2 inset-y-1/3 w-fit h-fit">
        <IcLoading />
      </div>
      <!-- USER BOX -->
      <div v-else-if="user" class="md:mx-[80px] max-md:mx-16 min-w-fit pt-8 max-h-[calc(100dvh-160px)]">
        <div class="flex">
          <div class="userbox">
            <div class="infoboxTitle">{{ t('admin.name').toUpperCase() }}</div>
            <div class="name">{{ user.name }}</div>
          </div>
          <div class="userbox">
            <div class="infoboxTitle">{{ t('admin.active').toUpperCase() }}</div>
            <div>
              <component :is="activeIcon" class="h-24 w-24 fill-success text-infoRed" />
            </div>
          </div>
        </div>

        <!-- Email Edit Section, TO DO -->
        <div class="flex">
          <div class="userbox">
            <div class="infoboxTitle">{{ t('admin.email').toUpperCase() }}</div>
            <AgFormInput
              v-model="user.email"
              class="w-1/2"
              :validation-state="isValidEmailCheck"
              :error-message="emailErrorText"
            />
          </div>
        </div>

        <!-- User Code -->
        <div class="flex">
          <div class="userbox">
            <div class="infoboxTitle">{{ t('admin.code').toUpperCase() }}</div>
            <div class="name">{{ user.code }}</div>
          </div>
          <div class="userbox">
            <!-- Activate User Button -->
            <AgButton
              :disabled="user?.active"
              class="w-1/4"
              variant="primary"
              rounded
              @click="showActivateModal = !showActivateModal"
            >
              {{ t('admin.activate').toUpperCase() }}
            </AgButton>
          </div>
        </div>

        <div class="flex">
          <div class="userbox">
            <div class="infoboxTitle">{{ t('admin.password').toUpperCase() }}</div>
            <div class="flex items-center">
              <component :is="passwordIcon" @click="togglePasswordVisibility" class="cursor-pointer ml-2" />
              <div class="name">{{ passwordVisible ? user.endOfPassword : '****' }}</div>
            </div>
          </div>
          <div class="userbox">
            <AgButton :disabled="!user?.active" class="w-1/4" @click="showActivateModal = !showActivateModal" rounded>
              {{ t('admin.deactivate').toUpperCase() }}
            </AgButton>
          </div>
        </div>
      </div>
    </div>
    <UserActivationModal
      :userId="user?.id"
      :actived="user?.active ?? false"
      v-model="showActivateModal"
      @update:user="reloadUsers"
    />
  </div>
</template>

<script setup lang="ts">
  import type { AuthUserDto } from '@/admin/domain/AuthUserDto';

  import UserActivationModal from '@/admin/modules/users/components/UserActivationModal.vue';
  import useUsers from '@/admin/modules/users/composables/useUsers';
  import IcCheck from '@/components/icons/IcCheck.vue';
  import IcClose from '@/components/icons/IcClose.vue';
  import IcHide from '@/components/icons/IcHide.vue';
  import IcLoading from '@/components/icons/IcLoading.vue';
  import IcShow from '@/components/icons/IcShow.vue';
  import AgButton from '@/components/library/button/AgButton.vue';
  import AgFormInput from '@/components/library/form-input/AgFormInput.vue';

  import { useTheHeader } from '@/composables/framework/useTheHeader';
  import { i18n } from '@/plugins/i18n';
  import { computed, onMounted, ref } from 'vue';
  import { useRoute } from 'vue-router';

  const { t } = i18n.global;
  const { loadUsers } = useUsers();
  const route = useRoute();

  const theHeader = useTheHeader();
  theHeader.enabled(true);
  theHeader.visible(true);

  const userId = ref<number>();
  const user = ref<AuthUserDto | null>(null);
  const passwordVisible = ref<boolean>(false);
  const showActivateModal = ref<boolean>(false);
  const usersList = ref<Array<AuthUserDto>>([]);
  const isLoading = ref<boolean>(true);
  const emailErrorText = ref<string>('');

  const emailRegexp = new RegExp(
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  );

  const activeIcon = computed(() => {
    return user.value?.active ? IcCheck : IcClose;
  });

  const isValidEmailCheck = computed(() => {
    const result = emailRegexp.test(user.value?.email.toString() ?? '') ?? false;
    if (!result) {
      emailErrorText.value = t('admin.invalidEmail');
    }
    const emailAlreadyExists = usersList.value.some((u) => u.email === user.value?.email && u.id !== user.value?.id);
    if (emailAlreadyExists) {
      emailErrorText.value = t('admin.emailAlreadyExists');
    }
    return result && !emailAlreadyExists;
  });

  const togglePasswordVisibility = () => {
    passwordVisible.value = !passwordVisible.value;
  };

  const passwordIcon = computed(() => {
    return passwordVisible.value ? IcHide : IcShow;
  });

  const reloadUsers = async function () {
    isLoading.value = true;
    usersList.value = await loadUsers().then((users) => {
      isLoading.value = false;
      return users;
    });
    user.value = usersList.value.find((user) => user.id === userId.value) || null;
  };

  onMounted(async () => {
    try {
      userId.value = parseInt(route.params.userId.toString());
      await reloadUsers();
    } catch (error) {
      console.log('Some error during the loading of the user: ', error);
      userId.value = -1;
    }
  });
</script>

<style scoped lang="scss">
  .infoboxTitle {
    @apply text-primary-light;
  }

  .name {
    @apply text-xl font-bold;
  }

  .userbox {
    @apply w-1/2 justify-center my-12;
  }
</style>

<template>
  <AgModal v-if="internalValue" fit-width fit-height>
    <template #header>
      <div class="text-2xl font-bold pb-12">{{ t('orders.daysOfRest') }}</div>
    </template>
    <template #content>
      <div class="max-md:p-16 items-stretch">
        <div class="pb-8">
          <AgTimeSet class="py-12" v-model:from="from" v-model:to="to" @remove-event="removeEvent" variant="black" />
        </div>
      </div>
    </template>
    <template #footer>
      <AgButton variant="secondary" @click="cancel">{{ t('orders.remove') }}</AgButton>
      <div class="flex justify-end gap-16">
        <AgButton variant="secondary" @click="removeEvent">{{ t('orders.reset') }}</AgButton>
        <AgButton variant="primary" @click.stop="closeModal">{{ t('orders.apply') }}</AgButton>
      </div>
    </template>
  </AgModal>
</template>

<script setup lang="ts">
  import type { DayOfRestDto } from '@/domain/masterData/DayOfRestDto';

  import AgButton from '@/components/library/button/AgButton.vue';
  import AgModal from '@/components/library/modal/AgModal.vue';
  import AgTimeSet from '@/components/library/time-set/AgTimeSet.vue';

  import { useOrderWizardStore } from '@/modules/orders/stores/useOrderWizardStore';
  import { i18n } from '@/plugins/i18n';
  import { storeToRefs } from 'pinia';
  import { computed } from 'vue';

  const { t } = i18n.global;

  const store = useOrderWizardStore();
  const { order } = storeToRefs(store);

  interface Props {
    modelValue?: boolean;
    day: number;
  }
  const props = withDefaults(defineProps<Props>(), {
    modelValue: false,
    day: -1,
  });

  const emit = defineEmits(['update:modelValue']);

  const internalValue = computed({
    get: () => props.modelValue,
    set: (newValue) => emit('update:modelValue', newValue),
  });

  const closeModal = function () {
    internalValue.value = false;
  };

  const cancel = function () {
    removeEvent();
    closeModal();
  };

  const removeEvent = function () {
    const address = order.value?.deliveryAddressEqualToBilling
      ? order.value.invoiceAddress
      : order.value?.deliveryAddress;
    if (props.day > -1 && order.value && address) {
      const index = daysOfRest.value.findIndex((d) => d.day === props.day);
      if (index > -1) {
        daysOfRest.value.splice(index, 1);
        address.daysOfRest.splice(index, 1);
      }
    }
  };

  const daysOfRest = computed(() => {
    const daysRest: Array<DayOfRestDto> = [];
    const address = order.value?.deliveryAddressEqualToBilling
      ? order.value.invoiceAddress
      : order.value?.deliveryAddress;
    if (address) {
      const tt = address.daysOfRest.length > 0 ? address.daysOfRest : [];
      daysRest.push(...tt);
    }
    return daysRest;
  });

  const from = computed({
    get: () => daysOfRest.value.find((d) => d.day === props.day)?.timeStart || '00:00',
    set: (newTimeStart) => {
      if (newTimeStart) {
        setStartTime(newTimeStart);
      }
    },
  });

  const setStartTime = function (startTime: string) {
    if (order.value) {
      const address = order.value?.deliveryAddressEqualToBilling
        ? order.value.invoiceAddress
        : order.value?.deliveryAddress;
      if (address) {
        const dayOfRest = daysOfRest.value.find((d) => d.day === props.day);
        if (dayOfRest) {
          dayOfRest.timeStart = startTime;
        } else {
          address.daysOfRest.push({
            id: null,
            day: props.day,
            timeStart: startTime,
            timeEnd: '00:00',
          });
        }
      }
    }
  };

  const to = computed({
    get: () => daysOfRest.value.find((d) => d.day === props.day)?.timeEnd || '00:00',
    set: (newTimeEnd) => {
      if (newTimeEnd) {
        setEndTime(newTimeEnd);
      }
    },
  });

  const setEndTime = function (endTime: string) {
    if (order.value) {
      const address = order.value?.deliveryAddressEqualToBilling
        ? order.value.invoiceAddress
        : order.value?.deliveryAddress;
      const dayOfRest = daysOfRest.value.find((d) => d.day === props.day);
      if (dayOfRest) {
        dayOfRest.timeEnd = endTime;
      } else if (address) {
        address.daysOfRest.push({
          id: null,
          day: props.day,
          timeStart: '00:00',
          timeEnd: endTime,
        });
      }
    }
  };
</script>

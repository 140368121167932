import type { IntervalKey } from '@/domain/internal/IntervalKey';
import { useOffersStore } from '@/modules/offers/stores/useOffersStore';
import { useOrdersStore } from '@/modules/orders/stores/useOrdersStore';
import { defineStore } from 'pinia';
import { ref } from 'vue';

export const useDataTimestampStore = defineStore('data-timestamp', () => {
  let intervalLastDataSync: number | null = null;

  const lastMasterDataSync = ref<IntervalKey | null>(null);
  const lastOrdersDataSync = ref<IntervalKey | null>(null);
  const lastOffersDataSync = ref<IntervalKey | null>(null);

  const lastMasterDataTimestamp = ref<number>(0);
  const lastOrdersTimestamp = ref<number>(0);
  const lastOffersTimestamp = ref<number>(0);

  const orderStore = useOrdersStore();
  const offerStore = useOffersStore();

  // Master data sync timestamp
  function refreshMasterDataTimestamp(masterTimestamp = 0) {
    console.log('*** REFRESH MASTER DATA TIMESTAMP ***', new Date().toISOString(), masterTimestamp);
    lastMasterDataTimestamp.value = masterTimestamp;
    lastMasterDataSync.value = fromNow(masterTimestamp);
  }

  // Orders data sync timestamp
  function refreshOrdersDataTimestamp(ordersTimestamp = 0) {
    console.log('*** REFRESH ORDERS DATA TIMESTAMP ***', new Date().toISOString(), ordersTimestamp);
    lastOrdersTimestamp.value = ordersTimestamp;
    lastOrdersDataSync.value = fromNow(ordersTimestamp);

    orderStore.refreshOrdersData().then();
  }

  // Offers data sync timestamp
  function refreshOffersDataTimestamp(offersTimestamp = 0) {
    console.log('*** REFRESH OFFERS DATA TIMESTAMP ***', new Date().toISOString(), offersTimestamp);
    lastOffersTimestamp.value = offersTimestamp;
    lastOffersDataSync.value = fromNow(offersTimestamp);

    offerStore.refreshOffersData().then();
  }

  function updateLastDataSnc() {
    console.log('*** REFRESH ALL DATA TIMESTAMP ***', new Date().toISOString());
    lastMasterDataSync.value = fromNow(lastMasterDataTimestamp.value);
    lastOrdersDataSync.value = fromNow(lastOrdersTimestamp.value);
    lastOffersDataSync.value = fromNow(lastOffersTimestamp.value);
  }

  const setIntervalLastDataSync = function () {
    if (intervalLastDataSync === null) {
      console.log('Scheduled update last data Sync', new Date().toISOString());
      intervalLastDataSync = window.setInterval(() => updateLastDataSnc(), 5 * 60 * 1000); // Every 5 minutes
    }
  };

  const clearLastDataSync = function () {
    if (intervalLastDataSync !== null) {
      console.log('!!!Clear update last data sync called!!!', new Date().toISOString());
      clearInterval(intervalLastDataSync);
      intervalLastDataSync = null;
    }
  };

  const keys: { [key: string]: string } = {
    ['year']: 'year',
    ['month']: 'month',
    ['week']: 'week',
    ['day']: 'day',
    ['hour']: 'hour',
    ['minute']: 'minute',
    ['second']: 'second',
    ['now']: 'now',
  };

  function getKey(interval: number, key: string) {
    return interval > 1 ? key.concat('s') : key;
  }

  const fromNow = function (timestamp: number) {
    const past = timestamp;
    const now = Date.now();

    const seconds = Math.floor((now - past) / 1000);
    if (seconds < 0) {
      throw new TypeError('Please put date in past');
    }
    // YEARS
    let interval = Math.floor(seconds / 31536000);
    if (interval > 0) {
      return {
        interval,
        key: getKey(interval, keys['year']),
      } as IntervalKey;
    }
    // MONTHS
    interval = Math.floor(seconds / 2592000);
    if (interval > 0) {
      return {
        interval,
        key: getKey(interval, keys['month']),
      } as IntervalKey;
    }
    // WEEKS
    interval = Math.floor(seconds / (86400 * 7));
    if (interval > 0) {
      return {
        interval,
        key: getKey(interval, keys['week']),
      } as IntervalKey;
    }
    // DAYS
    interval = Math.floor(seconds / 86400);
    if (interval > 0) {
      return {
        interval,
        key: getKey(interval, keys['day']),
      } as IntervalKey;
    }
    // Hours
    interval = Math.floor(seconds / 3600);
    if (interval > 0) {
      return {
        interval,
        key: getKey(interval, keys['hour']),
      } as IntervalKey;
    }
    // MINUTES
    interval = Math.floor(seconds / 60);
    if (interval > 0) {
      return {
        interval,
        key: getKey(interval, keys['minute']),
      } as IntervalKey;
    }
    // NOW
    interval = Math.floor(seconds);
    if (interval === 0) {
      return {
        interval,
        key: keys['now'],
      } as IntervalKey;
    }
    // SECONDS
    return {
      interval,
      key: keys['second'],
    } as IntervalKey;
  };

  return {
    setIntervalLastDataSync,
    clearLastDataSync,
    refreshMasterDataTimestamp,
    refreshOrdersDataTimestamp,
    refreshOffersDataTimestamp,
    lastMasterDataSync,
    lastOrdersDataSync,
    lastOffersDataSync,
  };
});

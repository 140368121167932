<template>
  <AgModal v-if="internalValue">
    <template #header>
      <div class="flex justify-between items-center">
        <div class="text-2xl font-bold">
          {{ t('offers.addArticle') }}
        </div>
        <AgButton v-if="!config.company.slimShoppingCart" variant="ghost" class="text-link" @click.stop="emptyAll">
          {{ t('offers.empty') }}
          <template #icon><IcDelete /></template>
        </AgButton>
      </div>

      <!-- SEARCH -->
      <div class="flex items-center justify-between pt-8 pb-16 gap-12">
        <AgFormGroup v-if="!config.company.slimShoppingCart" :label="t('offers.articleType')" class="w-full">
          <AgSearchSelect
            :options="articleTypesOptions"
            :placeholder="t('offers.search')"
            v-model="articleTypeId"
            @update:model-value="filterForArticleType"
          />
        </AgFormGroup>
        <AgFormGroup v-if="!config.company.slimShoppingCart" :label="t('offers.search')" class="w-full">
          <AgSearchSelect :options="articleOptions" :placeholder="t('offers.search')" v-model="articleId" />
        </AgFormGroup>
        <AgFormGroup v-else :label="t('offers.search')" class="w-full">
          <AgSearchInput v-model="articleOpenFilter" />
        </AgFormGroup>
      </div>
    </template>

    <template #content>
      <!-- ARTICLE ROWS -->
      <div v-if="Object.values(selectedArticles).length > 0 && !config.company.slimShoppingCart">
        <div class="flex flex-col">
          <div v-for="key in Object.keys(selectedArticles)" :key="key" class="px-4 my-8">
            <div v-if="selectedArticles[key].size > 0" class="elementNumber">
              <span class="text-neutral-550">{{ key }}&nbsp;/&nbsp;</span>
              <span class="font-bold">{{ t('offers.elementNumber') }} {{ selectedArticles[key].size }}</span>
            </div>
            <!-- ARTICLES -->
            <div>
              <div v-for="article in selectedArticles[key].values()" :key="article.id" class="row">
                <OfferArticleTableRow
                  class="w-full"
                  v-model="articlesPackaging"
                  :article="article"
                  :customerId="offer?.customer.id ?? -1"
                  :agentId="offer?.agentId ?? -1"
                  searchText=""
                  @remove-article="removeArticle(article, key)"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div v-else-if="Object.values(sortedArticles).length > 0 && config.company.slimShoppingCart">
        <div class="flex flex-col">
          <div v-for="key in Object.keys(sortedArticles)" :key="key" class="px-4 my-8">
            <div v-for="keyType in Object.keys(sortedArticles[key])" :key="keyType">
              <!-- ARTICLES -->
              <AgCollapsible v-if="sortedArticles[key][keyType]" class="border-b border-neutral-250" showIcon>
                <template #header>
                  <div :class="getMatch(key, keyType)" class="flex py-8 gap-8">
                    <span class="elementNumber text-neutral-550">
                      {{ keyType }}
                    </span>
                    <div>
                      <span>{{ key }}&nbsp;</span>
                      <span class="text-neutral-550">({{ sortedArticles[key][keyType].size }})</span>
                    </div>
                  </div>
                </template>
                <template #default>
                  <div v-for="article in sortedArticles[key][keyType].values()" :key="article.id" class="row">
                    <OfferArticleTableRow
                      class="w-full"
                      v-model="articlesPackaging"
                      :article="article"
                      :customerId="offer?.customer.id ?? -1"
                      :agentId="offer?.agentId ?? -1"
                      :searchText="articleOpenFilter"
                      @remove-article="removeArticle(article, key)"
                    />
                  </div>
                </template>
              </AgCollapsible>
            </div>
          </div>
        </div>
      </div>
      <div v-else-if="!config.company.slimShoppingCart" class="overflow-y-auto flex justify-center items-center h-full">
        <LogoCart variant="gray" />
      </div>
    </template>
    <template #footer>
      <AgButton variant="secondary" @click="closeModal">{{ t('offers.cancel') }}</AgButton>
      <div class="flex gap-16">
        <AgButton variant="secondary" @click="resetArticles">{{ t('offers.reset') }}</AgButton>
        <AgButton variant="primary" @click="addArticleToOffer" :disabled="isSaveButtonDisabled">
          {{ t('offers.apply') }}
        </AgButton>
      </div>
    </template>
  </AgModal>
</template>

<script setup lang="ts">
  import type { AgSearchSelectOption } from '@/components/library/search-select/AgSearchSelectOption';
  import type { DimensionItemDto } from '@/domain/DimensionItemDto';
  import type { ArticlePackaging } from '@/domain/internal/ArticlePackaging';
  import type { ShoppingCartArticleDto } from '@/domain/masterData/ShoppingCartArticleDto';

  import IcDelete from '@/components/icons/IcDelete.vue';
  import AgButton from '@/components/library/button/AgButton.vue';
  import AgFormGroup from '@/components/library/form-group/AgFormGroup.vue';
  import AgModal from '@/components/library/modal/AgModal.vue';
  import AgSearchSelect from '@/components/library/search-select/AgSearchSelect.vue';
  import LogoCart from '@/components/logo/LogoCart.vue';

  import AgCollapsible from '@/components/library/collapsible/AgCollapsible.vue';
  import AgSearchInput from '@/components/library/search-input/AgSearchInput.vue';
  import { useMasterData } from '@/composables/data/useMasterData';
  import { useCommon } from '@/composables/useCommon';
  import { useTranslatedText } from '@/composables/useTransalteText';
  import { config } from '@/config/config';
  import { CustomerType } from '@/domain/enumeration/CustomerType';
  import { useOfferFactory } from '@/modules/offers/composables/useOfferFactory';
  import { useOfferWizardStore } from '@/modules/offers/stores/useOfferWizardStore';
  import { i18n } from '@/plugins/i18n';
  import { Utilities } from '@/util/Utilities';
  import { storeToRefs } from 'pinia';
  import { computed, ref, watch } from 'vue';
  import OfferArticleTableRow from './article-table-row/OfferArticleTableRow.vue';

  const { t } = i18n.global;

  const { getTranslatedText } = useTranslatedText();
  const { isFullReloading } = useMasterData();

  const { getTradeAgreementPriceAndDisconts, getArticleCustomerTax } = useOfferFactory();
  const { escapedRegExpTwo, escapedRegExpThree } = Utilities();
  const store = useOfferWizardStore();
  const { offer } = storeToRefs(store);

  const common = useCommon();

  const articles = ref<Array<ShoppingCartArticleDto>>([]);
  const filteredArticles = ref<Array<ShoppingCartArticleDto>>([]);
  const articleId = ref<number>();
  const articleTypeId = ref<number>(-1);

  const selectedArticles = ref<{ [key: string]: Set<ShoppingCartArticleDto> }>({});
  const selectedSlimArticles = ref<{ [key: string]: { [key: string]: Set<ShoppingCartArticleDto> } }>({});
  const articlesPackaging = ref<ArticlePackaging>({});
  const articleOpenFilter = ref<string>('');

  interface Props {
    modelValue: boolean;
  }
  const props = withDefaults(defineProps<Props>(), {
    modelValue: false,
  });

  const emit = defineEmits(['update:modelValue']);

  const internalValue = computed({
    get: () => props.modelValue,
    set: (newValue) => emit('update:modelValue', newValue),
  });

  const sortedArticles = computed((): { [key: string]: { [key: string]: Set<ShoppingCartArticleDto> } } => {
    const keys = Object.keys(selectedSlimArticles.value);

    const articlesWithKeys = keys.flatMap((key1) => {
      return Object.keys(selectedSlimArticles.value[key1]).map((key2) => ({
        key1,
        key2,
        articles: selectedSlimArticles.value[key1][key2],
      }));
    });

    articlesWithKeys.sort((a, b) => {
      const compareKey2 = a.key2.localeCompare(b.key2);
      if (compareKey2 !== 0) {
        return compareKey2;
      }
      return a.key1.localeCompare(b.key1);
    });

    const sortedObject: { [key: string]: { [key: string]: Set<ShoppingCartArticleDto> } } = {};
    articlesWithKeys.forEach(({ key1, key2, articles }) => {
      if (!sortedObject[key1]) {
        sortedObject[key1] = {};
      }
      sortedObject[key1][key2] = articles;
    });

    return sortedObject;
  });

  const closeModal = function () {
    internalValue.value = false;
    selectedArticles.value = {};
    selectedSlimArticles.value = {};
    articleId.value = undefined;
    articleOpenFilter.value = '';
    articleTypeId.value = -1;
  };

  const addArticleToOffer = async function () {
    if (!offer.value) {
      return;
    }

    let articlesToInsert: ShoppingCartArticleDto[] = [];
    if (config.company.slimShoppingCart) {
      articlesToInsert = Object.values(selectedSlimArticles.value)
        .map((value) => Object.values(value))
        .flat()
        .map((value) => Array.from(value))
        .flat();
    } else {
      articlesToInsert = Object.values(selectedArticles.value)
        .map((value) => Array.from(value))
        .flat();
    }

    const formArticle: ArticlePackaging = articlesPackaging.value;

    if (articlesToInsert.length === 0) {
      return;
    }

    for (const article of articlesToInsert) {
      for (const packaging of article.packagings) {
        const quantity = formArticle[article.id][packaging.id].quantity ?? 0;
        const freeQuantity = formArticle[article.id][packaging.id].freeQuantity ?? 0;
        const productionDescription = formArticle[article.id][packaging.id].productionDescription ?? '';
        const dimensions = formArticle[article.id][packaging.id].dimensions ?? [];

        if (quantity || freeQuantity) {
          const articleFound =
            config.company.productionArticle && config.company.productionArticleTypeCode === article.articleType?.code
              ? offerProductionArticle(article.id, packaging.id, productionDescription, dimensions)
              : offerContainArticle(article.id, packaging.id, dimensions);

          let newQuantity = quantity;
          if (articleFound) {
            newQuantity += articleFound.quantity ?? 0;
          }
          // Add first dimension value config id if present
          const dimValConfId = dimensions && dimensions.length > 0 ? dimensions[0].value.id : undefined;
          // GET Trade Agreement and discounts
          const taDiscountLists = await getTradeAgreementPriceAndDisconts(
            offer.value,
            article.id,
            article.priceGroupIds,
            newQuantity,
            dimValConfId
          );
          const tradeAgreement = taDiscountLists.tradeAgreement;
          const discounts = taDiscountLists.discounts;
          const price = taDiscountLists.price;

          const isPromotional = tradeAgreement && tradeAgreement.customerType === CustomerType.PROMOTION ? true : false;

          if (articleFound) {
            const qt = articleFound.quantity ?? 0;
            const fqt = articleFound.freeQuantity ?? 0;

            articleFound.quantity = qt + quantity;
            articleFound.freeQuantity = fqt + freeQuantity;

            articleFound.article.productionDescription = productionDescription;
            articleFound.article.dimensions = dimensions;
            articleFound.article.contingentGroupId = article.contingentGroupId;

            articleFound.unitPrice = getUnitPrice(price, discounts);
            articleFound.finalPrice = getFinalPrice(price, discounts, packaging.size, qt);
            articleFound.tradeAgreement = {
              id: tradeAgreement?.id ?? -1,
              price: price,
              isPromotional: isPromotional,
              editedPrice: undefined,
            };
            if (discounts.length > 0) {
              type objectKey = keyof typeof articleFound;
              for (let i = 0; i < discounts.length; i++) {
                const key = `discount${i + 1}` as objectKey;
                setObjProperty(articleFound, key, discounts[i]);
              }
            } else {
              type objectKey = keyof typeof articleFound;
              for (let i = 0; i < config.company.maxItemDiscounts; i++) {
                const key = `discount${i + 1}` as objectKey;
                if (articleFound[key]) {
                  delete articleFound[key];
                }
              }
            }
          } else {
            offer.value.items.push({
              note: '',
              article: {
                id: article.id,
                code: article.code,
                type: article.type,
                unit: article.unit,
                externalCode: article.externalCode,
                productionDescription: productionDescription,
                contingentGroupId: article.contingentGroupId,
                dimensions: dimensions,
                priceGroupIds: article.priceGroupIds,

                sizeForNumber: article.sizeForNumber,
                sizeForWeight: article.sizeForWeight,

                title: article.title,

                articleGroupId: article.articleGroup?.id,
                articleGroup: article.articleGroup?.title,

                articleType: article.articleType?.title,
                articleTypeCode: article.articleType?.code,
              },
              packaging,
              tradeAgreement: {
                id: tradeAgreement?.id ?? -1,
                price: price,
                isPromotional: isPromotional,
                editedPrice: undefined,
              },
              quantity: quantity || 0,
              freeQuantity: freeQuantity || 0,
              finalPrice: getFinalPrice(price, discounts, packaging.size, quantity),
              unitPrice: getUnitPrice(price, discounts),
              isSomeDiscountEdited: false,
              isTradeAgreementPriceEdited: false,
              tax: await getArticleCustomerTax(offer.value.customer.customerTaxCode, article.id),
              ...Object.fromEntries(discounts.map((value, i) => [`discount${i + 1}`, value])),
            });
          }
        }
      }
    }
    closeModal();
  };

  const getUnitPrice = function (price: number, discounts: number[]) {
    let discountedPrice = price;
    if (discounts.length > 0) {
      for (let i = 0; i < discounts.length; i++) {
        const discount = discounts[i];
        if (discount > 0) {
          discountedPrice = discountedPrice * (1 - discount / 100);
        }
      }
    }
    return discountedPrice;
  };

  const getFinalPrice = function (price: number, discounts: number[], packagingSize: number, quantity: number) {
    const unitPrice = getUnitPrice(price, discounts);
    const finalPrice = unitPrice * packagingSize * quantity;

    return finalPrice;
  };

  const setObjProperty = function <T, K extends keyof T>(obj: T, key: K, value: T[K]) {
    obj[key] = value;
  };

  const offerContainArticle = (articleId: number, packagingId: number, dimensions: Array<DimensionItemDto>) => {
    if (offer.value) {
      const item = offer.value.items.find(
        (item) =>
          item.article.id === articleId &&
          item.packaging.id === packagingId &&
          compareArrayDimensions(item.article.dimensions, dimensions)
      );
      return item;
    }
    return undefined;
  };

  const offerProductionArticle = (
    articleId: number,
    packagingId: number,
    description: string,
    dimensions: Array<DimensionItemDto>
  ) => {
    if (offer.value) {
      const item = offer.value.items.find(
        (item) =>
          item.article.id === articleId &&
          item.packaging.id === packagingId &&
          item.article.productionDescription === description &&
          compareArrayDimensions(item.article.dimensions, dimensions)
      );
      return item;
    }
    return undefined;
  };

  const compareArrayDimensions = (a?: Array<DimensionItemDto>, b?: Array<DimensionItemDto>) => {
    return a && b && a.length === b.length && a.every((adim) => b.some((bdim) => adim.value.id === bdim.value.id));
  };

  const groupingArticles = function () {
    const article = articles.value.find((article) => article.id === articleId.value);

    if (article) {
      const group = article.articleGroup ? getTranslatedText(article.articleGroup.title) : 'Other';
      if (selectedArticles.value[group]) {
        selectedArticles.value[group].add(article);
      } else {
        selectedArticles.value[group] = new Set();
        selectedArticles.value[group].add(article);
      }
      articleId.value = undefined;
    }
  };

  const articleOptions = computed((): Array<AgSearchSelectOption> => {
    return filteredArticles.value.map((a) => {
      const label: string[] = [a.code];

      const articleGroup = getTranslatedText(a.articleGroup?.title);
      const articleType = getTranslatedText(a.articleType?.title);
      if (articleGroup.length > 0 && articleType.length > 0) {
        label.push(`${articleGroup} (${articleType})`);
      } else if (articleGroup.length > 0) {
        label.push(articleGroup);
      } else if (articleType.length > 0) {
        label.push(articleType);
      }
      const title = getTranslatedText(a.title);
      if (title.length > 0) {
        label.push(title);
      }
      return {
        value: a.id,
        label: label.join(' - '),
        searchableString: a.code + (articleType ?? '') + title + a.searchName,
        disabled: !a.code,
      };
    });
  });

  const articleTypesOptions = computed((): Array<AgSearchSelectOption> => {
    const opts: Array<AgSearchSelectOption> = [];
    articles.value.map((a) => {
      if (!opts.some((o) => o.value === a.articleType?.id) && a.articleType) {
        opts.push({
          value: a.articleType.id,
          label: getTranslatedText(a.articleType.title),
          searchableString: a.articleType.code + getTranslatedText(a.articleType?.title),
        });
      }
    });

    opts.sort((a, b) => a.label.localeCompare(b.label));

    opts.push({
      value: -1,
      label: t('offers.all'),
      searchableString: t('offers.all'),
    });
    return opts;
  });

  const filterForArticleType = function () {
    if (articleTypeId.value === -1) {
      filteredArticles.value = articles.value;
    } else {
      filteredArticles.value = articles.value.filter((a) => a.articleType?.id === articleTypeId.value);
    }
    console.log(filteredArticles.value);
  };

  const loadArticles = async function () {
    articles.value = [];
    if (offer.value) {
      const customerId = offer.value.customer.id;
      const priceListId = offer.value.customer.priceListId;

      articles.value = await common.getArticlesForCustomer(customerId, priceListId);
      filteredArticles.value = articles.value;
    }
  };

  const removeArticle = function (article: ShoppingCartArticleDto, group: string) {
    if (selectedArticles.value[group]) {
      selectedArticles.value[group].delete(article);
      if (selectedArticles.value[group].size === 0) {
        delete selectedArticles.value[group];
      }
      for (const packaging of article.packagings) {
        articlesPackaging.value[article.id][packaging.id] = {
          requestedPartialDelivery: undefined,
          productionDescription: undefined,
          dimensions: undefined,
          quantity: null,
          freeQuantity: null,
        };
      }
    }
  };

  const resetArticles = function () {
    if (!config.company.slimShoppingCart) {
      Object.keys(selectedArticles.value).forEach((key) => {
        selectedArticles.value[key].forEach((article) => {
          for (const packaging of article.packagings) {
            articlesPackaging.value[article.id][packaging.id] = {
              requestedPartialDelivery: false,
              productionDescription: undefined,
              dimensions: undefined,
              quantity: null,
              freeQuantity: null,
            };
          }
        });
      });
    } else {
      Object.keys(selectedSlimArticles.value).forEach((key) => {
        Object.keys(selectedSlimArticles.value[key]).forEach((keyType) => {
          selectedSlimArticles.value[key][keyType].forEach((article) => {
            for (const packaging of article.packagings) {
              articlesPackaging.value[article.id][packaging.id] = {
                requestedPartialDelivery: false,
                productionDescription: undefined,
                dimensions: undefined,
                quantity: null,
                freeQuantity: null,
              };
            }
          });
        });
      });
    }
  };

  const emptyAll = function () {
    selectedArticles.value = {};
  };

  const isSaveButtonDisabled = computed(() => {
    if (config.company.slimShoppingCart) {
      return false;
    }
    let returnValue = false;
    if (Object.values(selectedArticles.value).length > 0) {
      for (const key in selectedArticles.value) {
        selectedArticles.value[key].forEach((article) => {
          let internalReturn = true;
          for (const packaging of article.packagings) {
            const formArticle = articlesPackaging.value[article.id][packaging.id];
            if (
              internalReturn &&
              ((formArticle.quantity && formArticle.quantity > 0) ||
                (formArticle.freeQuantity && formArticle.freeQuantity > 0))
            ) {
              internalReturn = false;
            }
          }
          returnValue = internalReturn || returnValue;
        });
      }
    } else {
      returnValue = true;
    }
    return returnValue;
  });

  const getMatch = function (ref?: string, ref2?: string) {
    if (ref && articleOpenFilter.value.length > 1) {
      const regexp = escapedRegExpThree(articleOpenFilter.value);
      if (regexp.test(ref) || (ref2 && regexp.test(ref2))) {
        return 'bg-yellow px-4 w-fit';
      }
    }
    return '';
  };

  const filterArticles = function () {
    const filterText = articleOpenFilter.value.trim();
    const typeId = articleTypeId.value;
    const hasFilterText = filterText.length > 1;
    const hasTypeId = typeId !== -1;

    if ((hasFilterText || hasTypeId) && articles.value.length > 0) {
      const regexp = hasFilterText ? escapedRegExpTwo(filterText) : null;

      const articlesFiltered = articles.value.reduce(
        (acc: { [key: string]: { [key: string]: Set<ShoppingCartArticleDto> } }, article) => {
          const matchesFilterText = hasFilterText
            ? regexp!.test(
                [
                  article.code,
                  article.externalCode || '',
                  article.title ? getTranslatedText(article.title) : '',
                  article.searchName,
                  article.articleGroup ? getTranslatedText(article.articleGroup.title) : '',
                  article.articleType ? getTranslatedText(article.articleType.title) : '',
                ].join('')
              )
            : true;

          const matchesTypeId = hasTypeId ? article.articleType?.id === typeId : true;

          if (matchesFilterText && matchesTypeId) {
            const group = article.articleGroup ? getTranslatedText(article.articleGroup.title) : 'Other';
            const type = article.articleType ? getTranslatedText(article.articleType.title) : 'Other';
            if (!acc[group]) {
              acc[group] = {};
              if (!acc[group][type]) {
                acc[group][type] = new Set();
              }
            }
            acc[group][type].add(article);
          }
          return acc;
        },
        {}
      );
      selectedSlimArticles.value = articlesFiltered;
    } else {
      selectedSlimArticles.value = articles.value.reduce(
        (acc: { [key: string]: { [key: string]: Set<ShoppingCartArticleDto> } }, article) => {
          const group = article.articleGroup ? getTranslatedText(article.articleGroup.title) : 'Other';
          const type = article.articleType ? getTranslatedText(article.articleType.title) : 'Other';
          if (acc[group]) {
            if (acc[group][type]) {
              acc[group][type].add(article);
            } else {
              acc[group][type] = new Set();
              acc[group][type].add(article);
            }
          } else {
            acc[group] = {};
            acc[group][type] = new Set();
            acc[group][type].add(article);
          }
          return acc;
        },
        {}
      );
    }
  };

  watch(articleOpenFilter, filterArticles, { immediate: true });

  watch(
    isFullReloading,
    async (fullReload) => {
      if (!fullReload) {
        await loadArticles();
      }
    },
    { immediate: true }
  );

  watch(
    articleId,
    (newArticleId) => {
      if (newArticleId) {
        groupingArticles();
      }
    },
    { immediate: true }
  );

  watch(
    internalValue,
    (value) => {
      if (value && articles.value) {
        const artPackaging: ArticlePackaging = {};
        for (const article of articles.value) {
          artPackaging[article.id] = {};
          for (const packaging of article.packagings) {
            artPackaging[article.id][packaging.id] = {
              productionDescription: undefined,
              dimensions: undefined,
              quantity: null,
              freeQuantity: null,
            };
          }
        }
        if (config.company.slimShoppingCart) {
          selectedSlimArticles.value = articles.value.reduce(
            (acc: { [key: string]: { [key: string]: Set<ShoppingCartArticleDto> } }, article) => {
              const group = article.articleGroup ? getTranslatedText(article.articleGroup.title) : 'Other';
              const type = article.articleType ? getTranslatedText(article.articleType.title) : 'Other';
              if (acc[group]) {
                if (acc[group][type]) {
                  acc[group][type].add(article);
                } else {
                  acc[group][type] = new Set();
                  acc[group][type].add(article);
                }
              } else {
                acc[group] = {};
                acc[group][type] = new Set();
                acc[group][type].add(article);
              }
              return acc;
            },
            {}
          );
        }
        articlesPackaging.value = artPackaging;
      }
    },
    { immediate: true }
  );
</script>

<style scoped lang="scss">
  .row {
    @apply flex justify-between items-center;
  }

  .elementNumber {
    @apply flex items-center justify-center rounded-full p-4 w-fit shrink-0 grow-0 border bg-neutral-200 border-neutral-300 px-12 text-s mb-4;
  }
</style>

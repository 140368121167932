import DeliveryTermDetailPage from '@/admin/modules/deliveryTerms/pages/DeliveryTermDetailPage.vue';
import NewDeliveryTermPage from '@/admin/modules/deliveryTerms/pages/NewDeliveryTermPage.vue';
import authRequired from '@/middleware/authRequired';
import type { RouteRecordRaw } from 'vue-router';
export const routes: Array<RouteRecordRaw> = [
  {
    path: ':codeId/',
    name: '/delivery-term',
    component: DeliveryTermDetailPage,
    meta: { layout: 'AdminLayout' },
    beforeEnter: authRequired,
  },
  {
    path: '/new-delivery-term',
    name: '/new-delivery-term',
    component: NewDeliveryTermPage,
    meta: { layout: 'AdminLayout' },
    beforeEnter: authRequired,
  },
];

export default routes;

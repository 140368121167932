import { useLocalDatabase } from '@/composables/data/local-database/useLocalDatabase';
import type { AccountInfo, SilentRequest } from '@azure/msal-browser';
import {
  AuthError,
  BrowserAuthError,
  BrowserCacheLocation,
  ClientAuthError,
  LogLevel,
  PublicClientApplication,
} from '@azure/msal-browser';
import { ref } from 'vue';

const offlineData = useLocalDatabase();

const account = ref<AccountInfo>();
const msalInstance = ref<PublicClientApplication>();
const currentOptions = ref<MsalOptions>({
  clientId: '',
  loginAuthority: '',
  passwordRestAuthority: '',
  knownAuthority: '',
  accessTokenScopes: [],
  redirectUri: '',
  redirectLogoutUri: '',
});

export interface MsalOptions {
  clientId: string;
  loginAuthority: string;
  passwordRestAuthority: string;
  knownAuthority: string;
  accessTokenScopes: Array<string>;
  redirectUri: string;
  redirectLogoutUri: string;
}

export function useMsal() {
  const initialize = function (options: MsalOptions) {
    currentOptions.value = options;

    const msalConfig = {
      auth: {
        clientId: options.clientId,
        authority: options.loginAuthority,
        knownAuthorities: [options.knownAuthority],
        redirectUri: options.redirectUri,
        postLogoutRedirectUri: options.redirectLogoutUri,
      },
      system: {
        loggerOptions: {
          loggerCallback: (level: LogLevel, message: string, containsPii: boolean) => {
            if (containsPii) {
              return;
            }
            switch (level) {
              case LogLevel.Error:
                console.error(message);
                return;
              case LogLevel.Info:
                console.info(message);
                return;
              case LogLevel.Verbose:
                console.debug(message);
                return;
              case LogLevel.Warning:
                console.warn(message);
                break;
              default:
                return;
            }
          },
          logLevel: LogLevel.Warning,
        },
      },
      cache: {
        cacheLocation: BrowserCacheLocation.SessionStorage,
        storeAuthStateInCookie: false,
      },
    };
    msalInstance.value = new PublicClientApplication(msalConfig);
    msalInstance.value
      .initialize()
      .then(async () => await handleResponse())
      .catch((error) => console.error('Error initializing MSAL', error));
  };

  const handleResponse = async function () {
    if (!msalInstance.value) {
      return;
    }
    // ADD EVENT CALL BACK
    msalInstance.value.addEventCallback(setAccount);

    await msalInstance.value.handleRedirectPromise().catch(async (error) => {
      if (error.errorMessage.indexOf('AADB2C90118') > -1) {
        await resetPassword();
      }
    });
  };

  const setAccount = function () {
    account.value = undefined;
    if (!msalInstance.value) {
      return;
    }
    const currentAccounts = msalInstance.value.getAllAccounts();
    if (currentAccounts.length > 0) {
      account.value = currentAccounts[0];
    }
  };

  const signIn = async function () {
    if (!msalInstance.value) {
      return;
    }
    try {
      if (account.value) {
        // Logged in but access token missing
        await msalInstance.value.acquireTokenRedirect({
          authority: currentOptions.value.loginAuthority,
          account: account.value,
          scopes: currentOptions.value.accessTokenScopes,
        });
      } else {
        // Not logged in
        await msalInstance.value.loginRedirect({
          authority: currentOptions.value.loginAuthority,
          account: account.value,
          scopes: currentOptions.value.accessTokenScopes,
        });
      }
    } catch (error) {
      console.error('Error', error);
    }
  };

  const resetPassword = async function () {
    try {
      await msalInstance.value?.loginRedirect({
        authority: currentOptions.value.passwordRestAuthority,
        account: account.value,
        scopes: currentOptions.value.accessTokenScopes,
      });
    } catch (passwordResetError) {
      console.error('Password reset error:', passwordResetError);
    }
  };

  const signOut = async function () {
    if (!msalInstance.value) {
      return;
    }
    offlineData.clearAll();
    await msalInstance.value.logoutRedirect();
  };

  const acquireToken = async function () {
    if (!msalInstance.value) {
      return;
    }
    const request: SilentRequest = {
      authority: currentOptions.value.loginAuthority,
      account: account.value,
      scopes: currentOptions.value.accessTokenScopes,
      forceRefresh: true,
    };

    try {
      const response = await msalInstance.value.acquireTokenSilent(request);
      return response.accessToken;
    } catch (error) {
      if (error instanceof BrowserAuthError || error instanceof ClientAuthError) {
        console.warn('Error', error);
        if (
          error.errorCode === 'post_request_failed' ||
          error.errorCode === 'no_network_connectivity' ||
          error.errorCode === 'endpoints_resolution_error'
        ) {
          const offlineError = new AuthError('App is Offline');
          offlineError.errorCode = 'app_offline';
          throw offlineError;
        }
      }
      console.warn('Error: Session is expired --> logout:', error);
      throw error;
    }
  };

  return {
    initialize,
    signIn,
    signOut,
    acquireToken,
    account,
  };
}

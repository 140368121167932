<template>
  <div>
    <!-- SUBHEADER SEARCH AREA -->
    <TheSubheader>
      <ProcessedDataFilter
        v-model:agentId="agentId"
        v-model:customerId="selectedCustomerId"
        v-model:articleCode="selectedArticleCode"
        v-model:month="selectedMonth"
        v-model:year="selectedYear"
        v-model:selectState="selectState"
        v-model:inputSearch="inputSearch"
        @newSearch="newSearch"
      />
    </TheSubheader>

    <div class="mb-32 mt-12 md:mx-[80px] max-md:mx-16">
      <div v-if="isLoading" class="absolute inset-x-1/2 inset-y-1/3 w-fit h-fit">
        <IcLoading />
      </div>
      <!-- PAGINATED CONTENT -->
      <div v-else>
        <div v-if="processedData.content && processedData.content.length > 0">
          <AgPaginatorInfo
            :current-page="currentPage"
            :page-size="pageSize"
            :number-items="processedData.totalElements"
          />

          <!-- PAGINATOR -->
          <AgPaginator
            v-if="processedData.content && processedData.content.length > 0"
            v-model="currentPage"
            :total-pages="processedData.totalPages"
            class="my-24"
          />
          <!-- TABLE PROCESSED DATA -->
          <ProcessedDataHeader :processedData="processedData.content" v-model="sorting" />
        </div>
        <div v-else>
          <AgAlert variant="info">
            {{ t('processedData.notFound') }}
          </AgAlert>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
  import TheSubheader from '@/components/framework/the-subheader/TheSubheader.vue';
  import IcLoading from '@/components/icons/IcLoading.vue';
  import AgAlert from '@/components/library/alert/AgAlert.vue';
  import AgPaginator from '@/components/library/paginator/AgPaginator.vue';
  import AgPaginatorInfo from '@/components/library/paginator/info/AgPaginatorInfo.vue';
  import ProcessedDataFilter from '@/modules/processed-data/components/ProcessedDataFilter.vue';
  import ProcessedDataHeader from '@/modules/processed-data/components/ProcessedDataHeader.vue';

  import { useTheFooter } from '@/composables/framework/useTheFooter';
  import { useTheHeader } from '@/composables/framework/useTheHeader';
  import { useHierarchy } from '@/composables/useHierarchy';
  import { config } from '@/config/config';
  import { useProcessedDataStore } from '@/modules/processed-data/store/useProcessedDataStore';
  import { i18n } from '@/plugins/i18n';
  import { PDataFilter } from '@/util/filters/PDataFilter';
  import { Order } from '@/util/Order';
  import { PageRequest } from '@/util/Paging';
  import { Sorting } from '@/util/Sorting';
  import { useError } from '@/util/useError';
  import { storeToRefs } from 'pinia';
  import { onBeforeMount, onMounted, ref, watch } from 'vue';

  const { t } = i18n.global;

  const theHeader = useTheHeader();
  theHeader.enabled(true);
  theHeader.visible(true);
  theHeader.title('processedData.processedData');

  const theFooter = useTheFooter();
  theFooter.enabled(true);
  theFooter.visible(true);

  const currentPage = ref<number>(1);
  const pageSize = ref<number>(10);

  const sorting = ref<Sorting>(new Sorting('orderOfferDate', Order.ASC));

  const selectState = ref<number>(1);
  const inputSearch = ref<string>('');
  const isLoading = ref<boolean>(false);

  const agentId = ref<number>();

  const selectedCustomerId = ref<number>(0);
  const selectedArticleCode = ref<string>('');
  const selectedMonth = ref<number>(0);
  const selectedYear = ref<number>(0);

  const store = useProcessedDataStore();

  const { processedData } = storeToRefs(store);
  const { loadData } = store;

  const { handleCatchLocalError } = useError();
  const { getAllHierarchy } = useHierarchy();

  const newSearch = function () {
    const filter = new PDataFilter(
      inputSearch.value,
      selectedArticleCode.value,
      selectState.value,
      selectedCustomerId.value,
      selectedMonth.value,
      selectedYear.value,
      agentId.value
    );
    loadData(new PageRequest(pageSize.value, currentPage.value), filter, sorting.value)
      .then(() => (isLoading.value = false))
      .catch((error) => handleCatchLocalError(error));
  };

  watch(
    [
      selectState,
      inputSearch,
      sorting,
      currentPage,
      agentId,
      selectedCustomerId,
      selectedArticleCode,
      selectedMonth,
      selectedYear,
    ],
    () => newSearch(),
    { immediate: false, deep: true }
  );

  onBeforeMount(() => {
    if (config.company.defaultProcessedDataFilterState != null && config.company.defaultProcessedDataFilterState != 1) {
      selectState.value = config.company.defaultProcessedDataFilterState;
      console.log('selectState.value', selectState.value);
    }

    if (config.company.enableCurrentYearProcessedDataFilter) {
      selectedYear.value = new Date().getFullYear();
    }
  });

  onMounted(async () => {
    const hierarchyList = await getAllHierarchy();
    if (hierarchyList.length > 0 && hierarchyList[0].children.length > 0) {
      agentId.value = hierarchyList[0].authUser.id;
    } else {
      isLoading.value = true;
      newSearch();
    }
  });
</script>

import authAdminRequired from '@/middleware/authAdminRequired';
import type { RouteRecordRaw } from 'vue-router';
import CampaignDetailPage from './pages/CampaignDetailPage.vue';
import NewCampaignPage from './pages/NewCampaignPage.vue';

export const routes: Array<RouteRecordRaw> = [
  {
    path: '/:campaignId/',
    name: 'campaign',
    component: CampaignDetailPage,
    meta: { layout: 'AdminLayout' },
    beforeEnter: authAdminRequired,
  },
  {
    path: '/new-campaign',
    name: '/new-campaign',
    component: NewCampaignPage,
    meta: { layout: 'AdminLayout' },
    beforeEnter: authAdminRequired,
  },
];

export default routes;

<template>
  <div v-if="!isSumRow && agentId">
    <StatisticByMonthRow :statistic="props.statistic" :agentId="props.agentId" />
  </div>

  <div v-else-if="!isSumRow && !agentId">
    <AgCollapsible showIcon class="border-b border-neutral-500">
      <template #header>
        <StatisticByMonthRow :statistic="props.statistic" :agentId="props.agentId" />
      </template>

      <template #default>
        <AgBox variant="accent" :showBorder="false" class="border-t border-x border-neutral-500">
          <!-- INTERNAL TABLE -->
          <StatisticByMonthSubTableRow
            :yearOne="props.yearOne"
            :yearTwo="props.yearTwo"
            :startMonth="props.statistic.month"
            :endMonth="props.endMonth"
            :inputSearch="props.inputSearch"
            :agentId="props.agentId"
          />
        </AgBox>
      </template>
    </AgCollapsible>
  </div>
  <div v-else-if="isSumRow">
    <div class="tableHeaderRow border-b border-neutral-300 bg-neutral-100" :class="{ 'pl-8 pr-24': !props.agentId }">
      <span class="flex items-center border-r border-neutral-500">
        {{ t('statistics.sum') }}
      </span>

      <div class="tableRowEntry border-r border-neutral-500">
        <span class="right" :class="numberClass(props.statistic.totalQuantityPeriodTwo)">
          {{ props.statistic.totalQuantityPeriodTwo }}
        </span>
        <span class="right" :class="numberClass(props.statistic.totalAmountPeriodTwo)">
          {{ formatNumberToEuro(props.statistic.totalAmountPeriodTwo) }}
        </span>
        <span class="right" :class="numberClass(props.statistic.customerCodesPeriodTwo.length)">
          {{ props.statistic.customerCodesPeriodTwo.length }}*
        </span>
      </div>

      <div class="tableRowEntry border-r border-neutral-500">
        <span class="right" :class="numberClass(props.statistic.totalQuantityPeriodOne)">
          {{ props.statistic.totalQuantityPeriodOne }}
        </span>
        <span class="right" :class="numberClass(props.statistic.totalAmountPeriodOne)">
          {{ formatNumberToEuro(props.statistic.totalAmountPeriodOne) }}
        </span>
        <span class="right" :class="numberClass(props.statistic.customerCodesPeriodOne.length)">
          {{ props.statistic.customerCodesPeriodOne.length }}*
        </span>
      </div>

      <div class="tableRowEntry">
        <span class="right" :class="numberClass(totalQuantity)">{{ totalQuantity }}</span>
        <span class="right" :class="numberClass(totalAmount)">{{ formatNumberToEuro(totalAmount) }}</span>
        <span class="right" :class="numberClass(revenuePercentage)">
          {{ formatNumberToPercentage(revenuePercentage) }}
        </span>
        <span class="right" :class="numberClass(totalCustomers)">{{ totalCustomers }}*</span>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
  import type { StatisticByMonthDto } from '@/domain/StatisticByMonthDto';

  import AgBox from '@/components/library/box/AgBox.vue';
  import AgCollapsible from '@/components/library/collapsible/AgCollapsible.vue';
  import StatisticByMonthRow from '@/modules/statistics/components/by-month/StatisticByMonthRow.vue';
  import StatisticByMonthSubTableRow from '@/modules/statistics/components/by-month/sub-table/StatisticByMonthSubTableRow.vue';

  import { useNumberFormatting } from '@/composables/useNumberFormatting';
  import { i18n } from '@/plugins/i18n';
  import { computed } from 'vue';

  const { t } = i18n.global;

  const { formatNumberToPercentage, formatNumberToEuro } = useNumberFormatting();

  interface Props {
    statistic: StatisticByMonthDto;
    agentId: number | undefined;
    yearOne?: number;
    yearTwo?: number;
    endMonth?: number;
    inputSearch?: string;
    isSumRow?: boolean;
  }
  const props = defineProps<Props>();

  const numberClass = function (number: number): string {
    return number < 0 ? 'text-infoRed' : '';
  };

  const totalQuantity = computed(
    () => Math.abs(props.statistic.totalQuantityPeriodTwo) - Math.abs(props.statistic.totalQuantityPeriodOne)
  );

  const totalAmount = computed(
    () => Math.abs(props.statistic.totalAmountPeriodTwo) - Math.abs(props.statistic.totalAmountPeriodOne)
  );

  const totalCustomers = computed(
    () =>
      Math.abs(props.statistic.customerCodesPeriodTwo.length) - Math.abs(props.statistic.customerCodesPeriodOne.length)
  );

  const revenuePercentage = computed(() => {
    if (props.statistic.totalAmountPeriodTwo === 0 && props.statistic.totalAmountPeriodOne === 0) {
      return 0;
    } else if (props.statistic.totalAmountPeriodOne > 0) {
      const totalAmountPeriodTwo = Math.abs(props.statistic.totalAmountPeriodTwo);
      const totalAmountPeriodOne = Math.abs(props.statistic.totalAmountPeriodOne);

      const totalAmount = totalAmountPeriodTwo - totalAmountPeriodOne;
      const revenue = (totalAmount * 100) / totalAmountPeriodOne;

      return revenue;
    }
    return 100;
  });
</script>

<style scoped lang="scss">
  .tableHeaderRow {
    @apply grid grid-cols-[100px_minmax(283px,333px)_minmax(283px,333px)_minmax(333px,1fr)] min-h-48;
  }

  .tableRowEntry {
    @apply flex justify-between items-center gap-4;
  }

  .right {
    @apply flex justify-end items-center w-full px-4;
  }
</style>

<template>
  <div class="tableHeader border-y border-neutral-300">
    <div class="flex items-center border-r border-neutral-500" @click.stop="updateSorting(ColumnName.ARTICLE_NAME)">
      <span class="px-4">{{ t('statistics.articleName') }}</span>
      <span v-if="sorting.col === ColumnName.ARTICLE_NAME">
        <IcChevronDown v-if="sorting.order === Order.ASC" class="h-12 w-12" />
        <IcChevronUp v-if="sorting.order === Order.DESC" class="h-12 w-12" />
      </span>
      <span v-else class="h-12 w-12">&nbsp;</span>
    </div>

    <div class="tableHeaderEntry border-r border-neutral-500">
      <div class="right" @click.stop="updateSorting(ColumnName.QUANTITY_TWO)">
        <span class="px-4">{{ t('statistics.quantity') }}</span>
        <span v-if="sorting.col === ColumnName.QUANTITY_TWO">
          <IcChevronDown v-if="sorting.order === Order.ASC" class="h-12 w-12" />
          <IcChevronUp v-if="sorting.order === Order.DESC" class="h-12 w-12" />
        </span>
        <span v-else class="h-12 w-12">&nbsp;</span>
      </div>

      <div class="right" @click.stop.prevent="updateSorting(ColumnName.AMOUNT_TWO)">
        <span class="px-4">{{ t('statistics.amount') }}</span>
        <span v-if="sorting.col === ColumnName.AMOUNT_TWO">
          <IcChevronDown v-if="sorting.order === Order.ASC" class="h-12 w-12" />
          <IcChevronUp v-if="sorting.order === Order.DESC" class="h-12 w-12" />
        </span>
        <span v-else class="h-12 w-12">&nbsp;</span>
      </div>

      <div class="right cursor-default">
        <span class="px-4">{{ t('statistics.customers') }}</span>
      </div>
    </div>

    <div class="tableHeaderEntry border-r border-neutral-500">
      <div class="right" @click.stop="updateSorting(ColumnName.QUANTITY_ONE)">
        <span class="px-4">{{ t('statistics.quantity') }}</span>
        <span v-if="sorting.col === ColumnName.QUANTITY_ONE">
          <IcChevronDown v-if="sorting.order === Order.ASC" class="h-12 w-12" />
          <IcChevronUp v-if="sorting.order === Order.DESC" class="h-12 w-12" />
        </span>
        <span v-else class="h-12 w-12">&nbsp;</span>
      </div>

      <div class="right" @click.stop.prevent="updateSorting(ColumnName.AMOUNT_ONE)">
        <span class="px-4">{{ t('statistics.amount') }}</span>
        <span v-if="sorting.col === ColumnName.AMOUNT_ONE">
          <IcChevronDown v-if="sorting.order === Order.ASC" class="h-12 w-12" />
          <IcChevronUp v-if="sorting.order === Order.DESC" class="h-12 w-12" />
        </span>
        <span v-else class="h-12 w-12">&nbsp;</span>
      </div>

      <div class="right cursor-default">
        <span class="px-4">{{ t('statistics.customers') }}</span>
      </div>
    </div>

    <div class="tableHeaderEntry">
      <div class="right" @click.stop="updateSorting(ColumnName.QUANTITY_DIFFERENCE)">
        <span class="px-4">{{ t('statistics.quantity') }}</span>
        <span v-if="sorting.col === ColumnName.QUANTITY_DIFFERENCE">
          <IcChevronDown v-if="sorting.order === Order.ASC" class="h-12 w-12" />
          <IcChevronUp v-if="sorting.order === Order.DESC" class="h-12 w-12" />
        </span>
        <span v-else class="h-12 w-12">&nbsp;</span>
      </div>

      <div class="right" @click.stop="updateSorting(ColumnName.AMOUNT_DIFFERENCE)">
        <span class="px-4">{{ t('statistics.amount') }}</span>
        <span v-if="sorting.col === ColumnName.AMOUNT_DIFFERENCE">
          <IcChevronDown v-if="sorting.order === Order.ASC" class="h-12 w-12" />
          <IcChevronUp v-if="sorting.order === Order.DESC" class="h-12 w-12" />
        </span>
        <span v-else class="h-12 w-12">&nbsp;</span>
      </div>

      <div class="right" @click.stop="updateSorting(ColumnName.PERCENTAGE)">
        <span class="px-4">{{ t('statistics.amount') }}&nbsp;%</span>
        <span v-if="sorting.col === ColumnName.PERCENTAGE">
          <IcChevronDown v-if="sorting.order === Order.ASC" class="h-12 w-12" />
          <IcChevronUp v-if="sorting.order === Order.DESC" class="h-12 w-12" />
        </span>
        <span v-else class="h-12 w-12">&nbsp;</span>
      </div>

      <div class="right cursor-default">
        <span class="px-4">{{ t('statistics.customers') }}</span>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
  import IcChevronDown from '@/components/icons/IcChevronDown.vue';
  import IcChevronUp from '@/components/icons/IcChevronUp.vue';
  import { i18n } from '@/plugins/i18n';

  import { Order } from '@/util/Order';
  import { Sorting } from '@/util/Sorting';
  import { ref } from 'vue';

  const { t } = i18n.global;

  const emit = defineEmits(['update:sorting']);

  const sorting = ref<Sorting>(new Sorting('articlename', Order.ASC));

  enum ColumnName {
    ARTICLE_NAME = 'articlename',

    QUANTITY_ONE = 'quantity1',
    AMOUNT_ONE = 'amount1',

    QUANTITY_TWO = 'quantity2',
    AMOUNT_TWO = 'amount2',

    QUANTITY_DIFFERENCE = 'quantity_difference',
    AMOUNT_DIFFERENCE = 'amount_difference',
    PERCENTAGE = 'percentage',
  }

  const updateSorting = (column: ColumnName) => {
    sorting.value.col = column;
    if (sorting.value.order === Order.ASC) {
      sorting.value.order = Order.DESC;
    } else {
      sorting.value.order = Order.ASC;
    }
    emit('update:sorting', sorting.value);
  };
</script>

<style scoped lang="scss">
  .tableHeader {
    @apply grid grid-cols-[minmax(233px,433px)_300px_300px_350px] min-h-48;
    @apply text-neutral-550 cursor-pointer;
  }

  .tableHeaderEntry {
    @apply flex justify-between items-center px-12;
  }

  .right {
    @apply flex justify-end items-center w-full;
  }
</style>

import authAdminRequired from '@/middleware/authAdminRequired';
import type { RouteRecordRaw } from 'vue-router';
import CategoryDetailPage from './pages/CategoryDetailPage.vue';
import NewDownloadCategory from './pages/NewDownloadCategory.vue';

export const routes: Array<RouteRecordRaw> = [
  {
    path: '/:categoryId/',
    name: 'download-categories',
    component: CategoryDetailPage,
    meta: { layout: 'AdminLayout' },
    beforeEnter: authAdminRequired,
  },
  {
    path: '/new-download-category',
    name: '/new-download-category',
    component: NewDownloadCategory,
    meta: { layout: 'AdminLayout' },
    beforeEnter: authAdminRequired,
  },
];

export default routes;

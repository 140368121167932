import type { ArticleDto } from '@/admin/domain/ArticleDto';
import type { ArticleGroupDto } from '@/admin/domain/ArticleGroupDto';
import type { ContainerDto } from '@/admin/domain/data/ContainerDto';
import type { DownloadCategoryDto } from '@/admin/domain/DownloadCategoryDto';
import type { DownloadDto } from '@/admin/domain/DownloadDto';
import type { PermissionGroupDto } from '@/admin/domain/PermissionGroupDto';
import { useApi } from '@/composables/api/useApi';
import { config } from '@/config/config';
import { useError } from '@/util/useError';

export function useDownloads() {
  const api = useApi();
  const { handleCatchServerError, handleCatchLocalError } = useError();

  const getDownloadCategoryList = async function () {
    let downloadCategories: Array<DownloadCategoryDto> = [];
    try {
      const requestUri = '/adminapi/downloadCategories';

      const container = await api.fetch<ContainerDto>(requestUri, 'GET', undefined, true);
      if (container.errors && container.errors.length > 0) {
        handleCatchServerError(container.errors);
        return [];
      }
      if (container.data.downloadCategories) {
        downloadCategories = container.data.downloadCategories;
      }
      return downloadCategories;
    } catch (error) {
      handleCatchLocalError(error);
      return [];
    }
  };

  const loadData = async function () {
    let downloads: Array<DownloadDto> = [];
    try {
      const requestUri = '/adminapi/download';

      const container = await api.fetch<ContainerDto>(requestUri, 'GET', undefined, true);
      if (container.errors && container.errors.length > 0) {
        handleCatchServerError(container.errors);
        return [];
      }
      if (container.data.downloads) {
        downloads = container.data.downloads;
      }
      return downloads;
    } catch (error) {
      handleCatchLocalError(error);
      return [];
    }
  };

  async function loadFile(fileName: string): Promise<Blob | undefined> {
    try {
      const url = `${config.admin.url}/adminapi/download/file/download/${fileName}`;
      const blob = await api.download(url);

      if (blob instanceof Blob) {
        return blob;
      }
      return undefined;
    } catch (e) {
      handleCatchLocalError(e);
    }
  }

  const createData = async function (download: DownloadDto, file: File | null) {
    if (!download || !file) {
      return;
    }
    try {
      const requestUri = '/adminapi/download';
      const body = new FormData();

      body.set('fileBody', file); //THESE NAMES ARE IMPORTANT; DON'T CHANGE
      body.set('downloadStringBody', JSON.stringify(download));

      const container = await api.fetch<ContainerDto>(requestUri, 'POST', body, true);

      if (container.errors && container.errors.length > 0) {
        handleCatchServerError(container.errors);
        return;
      }
    } catch (error) {
      handleCatchLocalError(error);
    }
  };

  const deleteData = async function (downloadId: number) {
    try {
      const requestUri = `/adminapi/download/${downloadId}`;

      const container = await api.fetch<ContainerDto>(requestUri, 'DELETE', undefined, true);
      if (container.errors && container.errors.length > 0) {
        handleCatchServerError(container.errors);
        return;
      }
    } catch (error) {
      handleCatchLocalError(error);
    }
  };

  const updateData = async function (download: DownloadDto, file: File | null) {
    if (!download) {
      return;
    }
    try {
      const requestUri = '/adminapi/download';
      const body = new FormData();

      if (file) {
        body.set('fileBody', file); //THESE NAMES ARE IMPORTANT; DON'T CHANGE
      }
      body.set('downloadStringBody', JSON.stringify(download));

      const container = await api.fetch<ContainerDto>(requestUri, 'PATCH', body, true);

      if (container.errors && container.errors.length > 0) {
        handleCatchServerError(container.errors);
        return;
      }
    } catch (error) {
      handleCatchLocalError(error);
    }
  };

  const getPermissionGroups = async function () {
    let groups: Array<PermissionGroupDto> = [];
    try {
      const requestUri = '/adminapi/permissionGroup';

      const container = await api.fetch<ContainerDto>(requestUri, 'GET', undefined, true);
      if (container.errors && container.errors.length > 0) {
        handleCatchServerError(container.errors);
        return [];
      }
      if (container.data.permissionGroups) {
        groups = container.data.permissionGroups;
      }
      return groups;
    } catch (error) {
      handleCatchLocalError(error);
      return [];
    }
  };

  const getArticles = async function () {
    let articles: Array<ArticleDto> = [];
    try {
      const requestUri = '/adminapi/article';

      const container = await api.fetch<ContainerDto>(requestUri, 'GET', undefined, true);
      if (container.errors && container.errors.length > 0) {
        handleCatchServerError(container.errors);
        return [];
      }
      if (container.data.articles) {
        articles = container.data.articles;
      }
      return articles;
    } catch (error) {
      handleCatchLocalError(error);
      return [];
    }
  };

  const getArticleGroups = async function () {
    let groups: Array<ArticleGroupDto> = [];
    try {
      const requestUri = '/adminapi/articleGroup';

      const container = await api.fetch<ContainerDto>(requestUri, 'GET', undefined, true);
      if (container.errors && container.errors.length > 0) {
        handleCatchServerError(container.errors);
        return [];
      }
      if (container.data.articleGroups) {
        groups = container.data.articleGroups;
      }
      return groups;
    } catch (error) {
      handleCatchLocalError(error);
      return [];
    }
  };

  return {
    loadData,
    createData,
    updateData,
    deleteData,
    getDownloadCategoryList,
    loadFile,
    getPermissionGroups,
    getArticles,
    getArticleGroups,
  };
}

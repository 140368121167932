import DeliveryDetailsPage from '@/admin/modules/deliveryMethods/pages/DeliveryDetailsPage.vue';
import NewDeliveryMethodPage from '@/admin/modules/deliveryMethods/pages/NewDeliveryMethodPage.vue';
import authAdminRequired from '@/middleware/authAdminRequired';
import type { RouteRecordRaw } from 'vue-router';

export const routes: Array<RouteRecordRaw> = [
  {
    path: ':codeId/',
    name: '/delivery-method',
    component: DeliveryDetailsPage,
    meta: { layout: 'AdminLayout' },
    beforeEnter: authAdminRequired,
  },
  {
    path: '/new-delivery-method',
    name: '/new-delivery-method',
    component: NewDeliveryMethodPage,
    meta: { layout: 'AdminLayout' },
    beforeEnter: authAdminRequired,
  },
];

export default routes;

<template>
  <div v-for="article in displayedItems" :key="article.id" class="border-b border-neutral-500 px-12 py-8">
    <div class="flex flex-col text-base">
      <!-- TITLE -->
      <div class="flex gap-8 items-center py-4">
        <div class="font-light text-neutral-550">{{ t('articles.title') }}:</div>
        <div class="text-ellipsis" :class="getMatch(getTranslatedText(article.title))">
          {{ getTranslatedText(article.title) }}
        </div>
      </div>

      <div class="flex gap-8 items-center py-4">
        <div class="font-light text-neutral-550">{{ t('articles.minPrice') }}:</div>
        <div class="text-ellipsis">{{ formatNumberToEuro(article.minPrice) }}</div>
      </div>

      <div class="flex items-center gap-24 py-4">
        <div class="flex gap-8 items-center">
          <div class="font-light text-neutral-550">{{ t('articles.articleCode') }}:</div>
          <div :class="getMatch(article.code)">{{ article.code }}</div>
        </div>
        <div class="flex gap-8 items-center">
          <div class="font-light text-neutral-550">{{ t('articles.exCode') }}:</div>
          <div :class="getMatch(article.externalCode)">{{ article.externalCode }}</div>
        </div>
      </div>

      <div v-if="article.packagings">
        <div class="flex items-center gap-8 py-4">
          <div class="font-light text-neutral-550">{{ t('articles.packaging') }}:</div>
          <div class="flex flex-row items-center gap-4">
            <div v-for="packaging in article.packagings" :key="packaging.id">
              <span v-if="article.packagings.length > 1">
                {{ getTranslatedText(packaging.unit?.title) }} ({{ packaging.size }});
              </span>
              <span v-else>{{ getTranslatedText(packaging.unit?.title) }} ({{ packaging.size }})</span>
            </div>
          </div>
        </div>
      </div>
      <div v-else class="flex items-center gap-8 py-4">
        <div class="font-light text-neutral-550">{{ t('articles.packaging') }}:</div>
        <div>-</div>
      </div>

      <div v-if="article.articleDimensionValueConfigIds && article.articleDimensionValueConfigIds.length > 0">
        <div class="flex gap-8 py-4">
          <div class="font-light text-neutral-550">{{ t('articles.dimensions') }}:</div>
          <ArticleDimensions :articleId="article.id" :dimensionIds="article.articleDimensionValueConfigIds" />
        </div>
      </div>
      <div v-else class="flex items-center gap-8 py-4">
        <div class="font-light text-neutral-550">{{ t('articles.dimensions') }}:</div>
        <div>-</div>
      </div>

      <div v-if="article.infoTitle && Object.keys(article.infoTitle).length > 0" class="flex items-center gap-8 py-4">
        <div class="font-light text-neutral-550">{{ t('articles.articleInfo') }}:</div>
        <div class="italic text-infoBlue">{{ getTranslatedText(article.infoTitle) }}</div>
      </div>

      <div v-if="hasEnabledStocks(article.articleType?.code)" class="items-center py-4 flex">
        <span class="font-light text-neutral-550"> {{ t('articles.stocks') }}:&nbsp; </span>
        <div v-if="isOnline">
          <span class="text-link text-s hover:cursor-pointer" @click.stop="openModalStocks(article.code)">
            {{ t('articles.showStockInfo') }}
          </span>
        </div>
        <div v-else>
          <span>{{ t('articles.showStoksOffline') }}</span>
        </div>
      </div>

      <div class="items-center py-4">
        <div v-if="isOnline">
          <span class="font-light text-neutral-550"> {{ t('articles.attachments') }}:&nbsp; </span>
          <ArticleDownload v-if="hasAttachments(article)" :article="article" class="py-8" />
          <span v-else>{{ t('articles.noAttachments') }}</span>
        </div>
        <div v-else class="flex">
          <span class="font-light text-neutral-550"> {{ t('articles.attachments') }}:&nbsp; </span>
          <span>{{ t('articles.showAttachmentsOffline') }}</span>
        </div>
      </div>

      <div class="flex items-center justify-between pt-12 pb-4">
        <div class="flex gap-8 items-center">
          <div class="font-light text-s-13 text-neutral-550 italic">{{ t('articles.type') }}:</div>
          <div
            class="text-ellipsis text-s-13 text-neutral-550 italic"
            :class="getMatch(getTranslatedText(article.articleType?.title))"
          >
            {{ getTranslatedText(article.articleType?.title) }}
          </div>
        </div>
        <div class="italic text-s-13 text-neutral-550" :class="getMatch(article.searchName)">
          {{ article.searchName }}
        </div>
      </div>
    </div>
  </div>
  <div class="m-24">
    <AgPaginatorInfo :current-page="currentPage" :page-size="itemsPerPage" :number-items="props.articles.length" />
    <AgPaginator v-model="currentPage" :total-pages="pageCount" />
  </div>
  <ArticlePopUpStocks v-model="showStocks" :articleCode="selectedArticleStocks" />
</template>

<script setup lang="ts">
  import type { ShoppingCartArticleDto } from '@/domain/masterData/ShoppingCartArticleDto';

  import AgPaginator from '@/components/library/paginator/AgPaginator.vue';
  import AgPaginatorInfo from '@/components/library/paginator/info/AgPaginatorInfo.vue';
  import ArticleDimensions from '@/modules/articles/components/ArticleDimensions.vue';

  import { useNumberFormatting } from '@/composables/useNumberFormatting';
  import { useTranslatedText } from '@/composables/useTransalteText';
  import { config } from '@/config/config';
  import ArticleDownload from '@/modules/articles/components/ArticleDownload.vue';
  import ArticlePopUpStocks from '@/modules/articles/components/ArticlePopUpStocks.vue';
  import { i18n } from '@/plugins/i18n';
  import { Utilities } from '@/util/Utilities';
  import { useNetwork } from '@vueuse/core';
  import { computed, ref } from 'vue';

  const { t } = i18n.global;

  const { escapedRegExpThree } = Utilities();
  const { getTranslatedText } = useTranslatedText();
  const { formatNumberToEuro } = useNumberFormatting();
  const { isOnline } = useNetwork();

  interface Props {
    articles: Array<ShoppingCartArticleDto>;
    filter: string;
  }
  const props = defineProps<Props>();

  const getMatch = function (ref?: string) {
    if (ref && props.filter.length > 2) {
      const regexp = escapedRegExpThree(props.filter);
      if (regexp.test(ref)) {
        return 'bg-yellow px-4 w-fit';
      }
    }
    return '';
  };

  const itemsPerPage = 3;
  const currentPage = ref<number>(1);
  const showStocks = ref<boolean>(false);
  const selectedArticleStocks = ref<string>('');

  const pageCount = computed(() => Math.ceil(props.articles.length / itemsPerPage));

  const displayedItems = computed(() => {
    const startIndex = (currentPage.value - 1) * itemsPerPage;
    const endIndex = Math.min(startIndex + itemsPerPage, props.articles.length);
    const artxpage = props.articles.slice(startIndex, endIndex);

    return artxpage;
  });

  const hasEnabledStocks = function (articleTypeCode: string = '') {
    return (
      config.company.enableStockAvailability &&
      config.company.partialDeliveryArticleTypeCode &&
      config.company.partialDeliveryArticleTypeCode.includes(articleTypeCode)
    );
  };

  const hasAttachments = function (article: ShoppingCartArticleDto) {
    return article.attachmentsNumber || article.articleGroup?.attachmentsNumber;
  };

  const openModalStocks = function (articleCode: string) {
    showStocks.value = true;
    selectedArticleStocks.value = articleCode;
  };
</script>

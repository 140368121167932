<template>
  <div class="m-24 border border-neutral-550">
    <div class="p-12">
      <div class="tableHeader">
        <div>
          <div class="headerColumn" @click.stop.prevent="sortCustomerCode">
            <span class="px-4">{{ t('openItems.customerNumber') }}</span>
            <span v-if="orderCustomerCode === Order.ASC">
              <IcChevronDown class="h-12 w-12" />
            </span>
            <span v-else-if="orderCustomerCode === Order.DESC">
              <IcChevronUp class="h-12 w-12" />
            </span>
            <span v-else class="h-12 w-12">&nbsp;</span>
          </div>
        </div>
        <div>
          <div class="headerColumn" @click.stop.prevent="sortCustomerName">
            <span class="px-4">{{ t('openItems.customerName') }}</span>
            <span v-if="orderCustomerName === Order.ASC">
              <IcChevronDown class="h-12 w-12" />
            </span>
            <span v-else-if="orderCustomerName === Order.DESC">
              <IcChevronUp class="h-12 w-12" />
            </span>
            <span v-else class="h-12 w-12">&nbsp;</span>
          </div>
        </div>
        <div>
          <div class="headerColumn right" @click.stop.prevent="sortInvoiceCount">
            <span class="px-4">{{ t('openItems.numberOfInvoices') }}</span>
            <span v-if="orderInvoiceCount === Order.ASC">
              <IcChevronDown class="h-12 w-12" />
            </span>
            <span v-else-if="orderInvoiceCount === Order.DESC">
              <IcChevronUp class="h-12 w-12" />
            </span>
            <span v-else class="h-12 w-12">&nbsp;</span>
          </div>
        </div>
        <div>
          <div class="headerColumn right" @click.stop.prevent="sortOpenAmountSum">
            <span class="px-4">{{ t('openItems.openAmount') }}</span>
            <span v-if="orderOpenAmountSum === Order.ASC">
              <IcChevronDown class="h-12 w-12" />
            </span>
            <span v-else-if="orderOpenAmountSum === Order.DESC">
              <IcChevronUp class="h-12 w-12" />
            </span>
            <span v-else class="h-12 w-12">&nbsp;</span>
          </div>
        </div>
        <div>
          <div class="headerColumn">&nbsp;</div>
        </div>
      </div>

      <div v-if="shouldRender">
        <div
          v-for="item in customersInfo"
          :key="item.id"
          @click.stop="openCustomerInvoices(item.customerCode)"
          class="table"
        >
          <div class="tableColumn">
            {{ item.customerCode }}
          </div>
          <div class="tableColumn">
            {{ item.customerName }}
          </div>
          <div class="tableColumn right">
            {{ item.invoiceCount }}
          </div>
          <div class="tableColumn right" :class="{ 'text-[#dc3545]': item.openAmountSum < 0 }">
            {{ formatNumberToEuro(item.openAmountSum) }}
          </div>
          <div class="tableColumn right">
            <IcMailClose
              class="cursor-pointer"
              @click.stop="sendEmail(item.customerId, item.customerEmail, item.customerLanguage)"
            />
          </div>
        </div>
      </div>
      <div v-else>
        <IcLoading />
      </div>
    </div>

    <div>
      <CustomerInvoicesTableModal v-if="showModal" v-model:show-modal="showModal" :openItems="customerOpenItems" />
    </div>

    <!-- SEND EMAIL POPUP -->
    <div>
      <OpenItemsPopupEmail
        v-if="showPopupEmail"
        v-model:modal="showPopupEmail"
        v-model:email="emailTo"
        :which-email="WhichEmail.CUSTOMER_OUTSTANDING_DEBTS"
        :customer-id="customerId"
        :customerLanguage="currentCustomerLanguage"
      />
    </div>
  </div>
</template>

<script setup lang="ts">
  import type { OutstandingDebtDto } from '@/domain/OutstandingDebtDto';

  import IcChevronDown from '@/components/icons/IcChevronDown.vue';
  import IcChevronUp from '@/components/icons/IcChevronUp.vue';
  import IcLoading from '@/components/icons/IcLoading.vue';
  import IcMailClose from '@/components/icons/IcMailClose.vue';
  import OpenItemsPopupEmail from '@/modules/open-items/components/OpenItemsPopupEmail.vue';
  import CustomerInvoicesTableModal from '@/modules/open-items/components/by-agent/CustomerInvoicesTableModal.vue';

  import { useMasterData } from '@/composables/data/useMasterData';
  import { useNumberFormatting } from '@/composables/useNumberFormatting';
  import { CustomerAddressType } from '@/domain/enumeration/CustomerAddressType';
  import { WhichEmail } from '@/domain/enumeration/WhichEmail';
  import { useByAgentStore } from '@/modules/open-items/store/useByAgentStore';
  import { i18n } from '@/plugins/i18n';
  import { Order } from '@/util/Order';
  import { Sorting } from '@/util/Sorting';
  import { storeToRefs } from 'pinia';
  import { computed, ref, watch } from 'vue';

  const { t, locale } = i18n.global;

  const masterData = useMasterData();
  const { formatNumberToEuro } = useNumberFormatting();

  interface Props {
    agentId: number;
    modelValue: boolean;
  }
  const props = defineProps<Props>();

  const emit = defineEmits(['update:modelValue']);

  const disabledAutoCollapse = computed({
    get: () => props.modelValue,
    set: (value) => emit('update:modelValue', value),
  });

  const store = useByAgentStore();

  const { openItems } = storeToRefs(store);
  const { getAgentInvoices } = store;

  const shouldRender = ref<boolean>(false);
  const showPopupEmail = ref<boolean>(false);
  const showModal = ref<boolean>(false);

  const customerId = ref<number>(-1);

  const emailTo = ref<string>('');
  const currentCustomerLanguage = ref<string>(locale.value);

  const customerOpenItems = ref<Array<OutstandingDebtDto>>([]);
  const customersInfo = ref<Array<OutstandingDebtDataInfo>>([]);

  const openCustomerInvoices = (customerCode: string) => {
    customerOpenItems.value = getCustomerOpenItems(customerCode);
    showModal.value = true;
  };

  const getCustomerOpenItems = (customerCode = '') =>
    openItems.value.filter((item) => customerCode === item.customerCode);

  interface OutstandingDebtDataInfo {
    id: number;
    invoiceCount: number;
    openAmountSum: number;
    customerId: number;
    customerCode: string;
    customerLanguage: string;
    customerName: string;
    customerEmail: string;
  }

  const getDataInfo = async function () {
    customersInfo.value = [];
    const codes = new Map<string, OutstandingDebtDataInfo>();

    for (const item of openItems.value) {
      if (item.customerCode) {
        if (!codes.has(item.customerCode)) {
          const customer = await masterData.tables.customers.where('code').equals(item.customerCode).first();

          const email = customer?.addresses.find((ad) => ad.type !== CustomerAddressType.DELIVERY)?.email || '';

          const entry: OutstandingDebtDataInfo = {
            id: item.id,
            customerId: customer?.id || -1,
            customerCode: item.customerCode,
            customerLanguage: customer?.language || locale.value,
            customerName: item.customerName || '',
            customerEmail: email,
            invoiceCount: 1,
            openAmountSum: item.openAmount,
          };
          customersInfo.value.push(entry);
          codes.set(item.customerCode, entry);
        } else {
          const entry = codes.get(item.customerCode);
          if (entry) {
            entry.invoiceCount++;
            entry.openAmountSum += item.openAmount;
          }
        }
      }
    }
    shouldRender.value = true;
  };

  const orderCustomerCode = ref<Order | null>(null);
  const sortCustomerCode = () => updateSorting(ColumnName.CUSTOMER_CODE);

  const orderCustomerName = ref<Order | null>(null);
  const sortCustomerName = () => updateSorting(ColumnName.CUSTOMER_NAME);

  const orderInvoiceCount = ref<Order | null>(null);
  const sortInvoiceCount = () => updateSorting(ColumnName.INVOICE_COUNT);

  const orderOpenAmountSum = ref<Order | null>(null);
  const sortOpenAmountSum = () => updateSorting(ColumnName.OPEN_AMOUNT_SUM);

  enum ColumnName {
    CUSTOMER_CODE = 'customerCode',
    CUSTOMER_NAME = 'customerName',
    INVOICE_COUNT = 'invoiceCount',
    OPEN_AMOUNT_SUM = 'openAmountSum',
  }

  const updateSorting = (colum: ColumnName) => {
    if (colum === ColumnName.CUSTOMER_CODE) {
      if (orderCustomerCode.value === Order.ASC) {
        orderCustomerCode.value = Order.DESC;
        customersInfo.value.sort((a, b) =>
          a.customerCode.localeCompare(b.customerCode, undefined, {
            sensitivity: 'base',
          })
        );
      } else {
        orderCustomerCode.value = Order.ASC;
        customersInfo.value.sort((a, b) =>
          b.customerCode.localeCompare(a.customerCode, undefined, {
            sensitivity: 'base',
          })
        );
      }
      orderCustomerName.value = null;
      orderInvoiceCount.value = null;
      orderOpenAmountSum.value = null;
    } else if (colum === ColumnName.CUSTOMER_NAME) {
      if (orderCustomerName.value === Order.ASC) {
        orderCustomerName.value = Order.DESC;
        customersInfo.value.sort((a, b) =>
          a.customerName.localeCompare(b.customerName, undefined, {
            sensitivity: 'base',
          })
        );
      } else {
        orderCustomerName.value = Order.ASC;
        customersInfo.value.sort((a, b) =>
          b.customerName.localeCompare(a.customerName, undefined, {
            sensitivity: 'base',
          })
        );
      }
      orderCustomerCode.value = null;
      orderInvoiceCount.value = null;
      orderOpenAmountSum.value = null;
    } else if (colum === ColumnName.INVOICE_COUNT) {
      if (orderInvoiceCount.value === Order.ASC) {
        orderInvoiceCount.value = Order.DESC;
        customersInfo.value.sort((a, b) => a.invoiceCount - b.invoiceCount);
      } else {
        orderInvoiceCount.value = Order.ASC;
        customersInfo.value.sort((a, b) => b.invoiceCount - a.invoiceCount);
      }
      orderCustomerCode.value = null;
      orderCustomerName.value = null;
      orderOpenAmountSum.value = null;
    } else if (colum === ColumnName.OPEN_AMOUNT_SUM) {
      if (orderOpenAmountSum.value === Order.ASC) {
        orderOpenAmountSum.value = Order.DESC;
        customersInfo.value.sort((a, b) => a.openAmountSum - b.openAmountSum);
      } else {
        orderOpenAmountSum.value = Order.ASC;
        customersInfo.value.sort((a, b) => b.openAmountSum - a.openAmountSum);
      }
      orderCustomerCode.value = null;
      orderCustomerName.value = null;
      orderInvoiceCount.value = null;
    }
  };

  const sendEmail = (id: number, email: string, language: string) => {
    customerId.value = id;
    emailTo.value = email;
    currentCustomerLanguage.value = language ?? locale.value;
    showPopupEmail.value = true;
  };

  watch(
    () => props.agentId,
    () => {
      const sorting = new Sorting(ColumnName.CUSTOMER_NAME, Order.ASC);
      getAgentInvoices(props.agentId, sorting)
        .then(async () => await getDataInfo())
        .catch((error) => console.log(error));
    },
    { immediate: true }
  );

  watch([showModal, showPopupEmail], () => (disabledAutoCollapse.value = showModal.value || showPopupEmail.value), {
    immediate: true,
  });
</script>

<style scoped lang="scss">
  .tableHeader {
    @apply grid grid-cols-[160px_minmax(200px,1fr)_170px_200px_50px] min-h-32;
    @apply font-light text-neutral-550;
  }

  .headerColumn {
    @apply flex items-center gap-4 hover:cursor-pointer;
  }

  .table {
    @apply grid grid-cols-[160px_minmax(200px,1fr)_170px_200px_50px] border-t border-neutral-500 py-8;
  }

  .table:hover {
    @apply bg-neutral-200;
  }

  .tableColumn {
    @apply flex items-center;
  }

  .right {
    @apply justify-end;
  }

  .v-enter-active,
  .v-leave-active {
    transition: opacity 0.5s ease;
  }

  .v-enter-from,
  .v-leave-to {
    opacity: 0;
  }
</style>

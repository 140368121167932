import type { CampaignDto } from '@/admin/domain/CampaignDto';
import type { ContainerDto } from '@/admin/domain/data/ContainerDto';
import { useApi } from '@/composables/api/useApi';
import { useError } from '@/util/useError';

export function useCampaigns() {
  const api = useApi();
  const { handleCatchServerError, handleCatchLocalError } = useError();

  const loadData = async function () {
    let campaigns: Array<CampaignDto> = [];
    try {
      const requestUri = '/adminapi/campaign';

      const container = await api.fetch<ContainerDto>(requestUri, 'GET', undefined, true);
      if (container.errors && container.errors.length > 0) {
        handleCatchServerError(container.errors);
        return [];
      }
      if (container.data.campaigns) {
        campaigns = container.data.campaigns;
      }
      return campaigns;
    } catch (error) {
      handleCatchLocalError(error);
      return [];
    }
  };

  const createData = async function (campaign?: CampaignDto) {
    if (!campaign) {
      return;
    }
    try {
      const requestUri = '/adminapi/campaign';

      const container = await api.fetch<ContainerDto>(requestUri, 'POST', campaign, true);
      if (container.errors && container.errors.length > 0) {
        handleCatchServerError(container.errors);
      }
    } catch (error) {
      handleCatchLocalError(error);
    }
  };

  const updateData = async function (campaign?: CampaignDto) {
    if (!campaign) {
      return;
    }
    try {
      const requestUri = '/adminapi/campaign';

      const container = await api.fetch<ContainerDto>(requestUri, 'PATCH', campaign, true);
      if (container.errors && container.errors.length > 0) {
        handleCatchServerError(container.errors);
      }
    } catch (error) {
      handleCatchLocalError(error);
    }
  };

  const deleteData = async function (id: number) {
    try {
      const requestUri = `/adminapi/campaign/${id}`;

      const container = await api.fetch<ContainerDto>(requestUri, 'DELETE', undefined, true);
      if (container.errors && container.errors.length > 0) {
        handleCatchServerError(container.errors);
      }
    } catch (error) {
      handleCatchLocalError(error);
    }
  };

  return {
    loadData,
    createData,
    updateData,
    deleteData,
  };
}

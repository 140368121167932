<template>
  <AgModal v-if="internalValue" fit-height fit-width :has-header="false">
    <template #content>
      <div class="overflow-x-auto min-h-[250px]">
        <h1 class="text-xl font-bold pl-24 break-all p-12">{{ t(message) }}</h1>
      </div>
    </template>
    <template #footer>
      <AgButton @click="userLogout" v-if="props.code === 'FORBIDDEN'">LOGOUT</AgButton>
      <AgButton @click="closeModal" v-else>OK</AgButton>
    </template>
  </AgModal>
</template>

<script setup lang="ts">
  import AgButton from '@/components/library/button/AgButton.vue';
  import AgModal from '@/components/library/modal/AgModal.vue';

  import { useAuthentication } from '@/composables/auth/useAuthentication';
  import { useMasterData } from '@/composables/data/useMasterData';
  import { useOfferData } from '@/composables/data/useOfferData';
  import { useOrderData } from '@/composables/data/useOrderData';
  import { config } from '@/config/config';
  import { i18n } from '@/plugins/i18n';
  import { useDataTimestampStore } from '@/store/useDataTimestampStore';
  import { computed } from 'vue';

  const { t } = i18n.global;
  
  const { logout } = useAuthentication();

  const masterData = useMasterData();
  const orderData = useOrderData();
  const offerData = useOfferData();

  const store = useDataTimestampStore();

  interface Props {
    message: string;
    code: string | number | undefined;
    modelValue?: boolean;
  }
  const props = withDefaults(defineProps<Props>(), { message: '', code: undefined });

  const emit = defineEmits(['update:modelValue']);

  const internalValue = computed({
    get: () => props.modelValue,
    set: (newValue) => emit('update:modelValue', newValue),
  });

  const closeModal = function () {
    internalValue.value = false;
  };

  const userLogout = function () {
    masterData.clearSyncMasterData();
    if (config.company.modules.ORDER) {
      orderData.clearSyncOrders();
    }
    if (config.company.modules.OFFER) {
      offerData.clearSyncOffers();
    }
    store.clearLastDataSync();

    logout();
  };
</script>

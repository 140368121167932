import authRequired from '@/middleware/authRequired';
import type { RouteRecordRaw } from 'vue-router';
import OfferDetailPage from './pages/OfferDetailPage.vue';
import OrderDetailsPage from './pages/OrderDetailsPage.vue';
export const routes: Array<RouteRecordRaw> = [
  {
    path: ':gid/',
    name: '/order',
    component: OrderDetailsPage,
    meta: { layout: 'AdminLayout' },
    beforeEnter: authRequired,
  },
  {
    path: ':gid/',
    name: '/offer',
    component: OfferDetailPage,
    meta: { layout: 'AdminLayout' },
    beforeEnter: authRequired,
  },
];

export default routes;

<template>
  <div v-if="isLoading" class="absolute inset-x-1/2 inset-y-1/3 w-fit h-fit">
    <IcLoading />
  </div>
  <div v-else-if="activity" class="md:mx-[80px]">
    <div class="flex items-center space-x-4">
      <AgButton variant="ghost" @click="saveActivity">
        <template #icon>
          <IcSave />
        </template>
        {{ t('admin.save') }}
      </AgButton>
      <AgButton variant="ghost" @click="deleteCurrentActivity">
        <template #icon>
          <IcDelete />
        </template>
        {{ t('admin.delete') }}
      </AgButton>
    </div>

    <div class="flex gap-12 items-center py-12">
      <div class="font-bold text-primary">{{ t('admin.name').toUpperCase() }}</div>
      <AgFormInput
        v-model="activity.name"
        :validation-state="!nameError"
        :error-message="nameErrorText"
        @input="resetValidation"
      />
    </div>
    <div class="flex gap-12 items-center py-12">
      <div class="font-bold text-primary">{{ t('admin.campaign').toUpperCase() }}</div>
      <AgSearchSelect
        :options="campaignsOptions"
        v-model="activity.campaign.id"
        @update:model-value="resetValidation"
      />
    </div>
    <div class="flex gap-12 items-center py-12">
      <div class="font-bold text-primary">{{ t('admin.dueDate').toUpperCase() }}</div>
      <AgFormInput type="date" v-model="date" :min="getMinDate()" @update:model-value="resetValidation" />
    </div>
    <div class="flex justify-end gap-12 items-center py-12">
      <div
        class="flex items-center"
        :class="{ 'text-primary cursor-pointer': activity.agents?.length > 0 }"
        @click="goToCustomerSelection"
      >
        <span class="font-bold">
          {{ t('admin.selectCustomers').toUpperCase() }}
        </span>
        <AgButton variant="ghost" @click="goToCustomerSelection" :disabled="activity.agents?.length === 0">
          <template #icon>
            <IcChevronForward />
          </template>
        </AgButton>
      </div>
    </div>

    <div class="flex justify-between gap-x-32 h-[calc(100dvh-370px)] py-12">
      <div class="w-full">
        <span class="text-xl font-bold text-primary">{{ t('admin.insert').toUpperCase() }}</span>
        <AgBox class="border-0 p-0 h-[calc(100dvh-420px)]" variant="strong-accent">
          <AgSearchInput v-model="insertFilter" class="w-full" />
          <div class="w-full overflow-y-auto h-[calc(100dvh-500px)]">
            <div v-for="(agent, index) in filteredInsertAgents" :key="agent.id">
              <div
                class="px-12 py-8 border-neutral-550 cursor-pointer hover:bg-neutral-500 italic"
                :class="{ 'border-t': index !== 0 }"
                @click="select(agent.id)"
              >
                <span class="font-bold">
                  {{ agent.name }}
                </span>
                <span> &nbsp;({{ agent.email }}) </span>
              </div>
            </div>
          </div>
        </AgBox>
      </div>

      <div class="w-full">
        <span class="text-xl font-bold text-primary">{{ t('admin.remove').toUpperCase() }}</span>
        <AgBox class="w-full h-[calc(100dvh-420px)] border-0 p-0" variant="strong-accent">
          <AgSearchInput v-model="removeFilter" class="w-full" />
          <div class="w-full overflow-y-auto h-[calc(100dvh-500px)]">
            <div v-for="(agent, index) in filteredRemoveAgents" :key="agent.agent.id">
              <div
                class="px-12 py-8 border-neutral-550 cursor-pointer hover:bg-neutral-500 italic"
                :class="{ 'border-t': index !== 0 }"
                @click="unselect(agent.agent.id)"
              >
                <span class="font-bold">
                  {{ agent.agent.name }}
                </span>
                <span> &nbsp;({{ agent.agent.email }}) </span>
              </div>
            </div>
          </div>
        </AgBox>
      </div>
    </div>
  </div>
  <div v-else>
    <AgAlert variant="info"> {{ t('admin.activityCategoryNotFound') }} </AgAlert>
  </div>
</template>

<script setup lang="ts">
  import type { ActivityDto } from '@/admin/domain/ActivityDto';
  import type { AuthUserDto } from '@/admin/domain/AuthUserDto';
  import type { AgSearchSelectOption } from '@/components/library/search-select/AgSearchSelectOption';

  import IcChevronForward from '@/components/icons/IcChevronForward.vue';
  import IcDelete from '@/components/icons/IcDelete.vue';
  import IcLoading from '@/components/icons/IcLoading.vue';
  import IcSave from '@/components/icons/IcSave.vue';
  import AgAlert from '@/components/library/alert/AgAlert.vue';
  import AgBox from '@/components/library/box/AgBox.vue';
  import AgButton from '@/components/library/button/AgButton.vue';
  import AgFormInput from '@/components/library/form-input/AgFormInput.vue';
  import AgSearchInput from '@/components/library/search-input/AgSearchInput.vue';
  import AgSearchSelect from '@/components/library/search-select/AgSearchSelect.vue';

  import { useActivities } from '@/admin/modules/activities/composables/useActivities';
  import { useTheHeader } from '@/composables/framework/useTheHeader';
  import { i18n } from '@/plugins/i18n';
  import { computed, onMounted, ref } from 'vue';
  import { useRoute, useRouter } from 'vue-router';

  const { t } = i18n.global;
  const route = useRoute();
  const router = useRouter();
  const { loadData, updateData, deleteData, loadCampaigns, loadAgents } = useActivities();
  const theHeader = useTheHeader();

  theHeader.enabled(true);
  theHeader.visible(true);
  theHeader.title('admin.activityDetail');

  const activity = ref<ActivityDto | null>(null);
  const campaignsOptions = ref<Array<AgSearchSelectOption>>([]);
  const isLoading = ref<boolean>(true);
  const agents = ref<Array<AuthUserDto>>([]);

  const insertFilter = ref<string>('');
  const removeFilter = ref<string>('');

  const nameError = ref<boolean>(false);
  const nameErrorText = ref<string>('');

  const resetValidation = () => {
    nameError.value = false;
    nameErrorText.value = '';
  };

  const activityId = computed(() => {
    const camId = route.params.activityId;
    if (Array.isArray(camId)) {
      return parseInt(camId[0]);
    }
    return parseInt(camId);
  });

  const date = computed({
    get: () => {
      if (activity.value?.dueDate) {
        const date = new Date(activity.value.dueDate * 1000);
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const day = String(date.getDate()).padStart(2, '0');
        return `${year}-${month}-${day}`;
      }
      return null;
    },
    set: (newDate) => {
      if (newDate && activity.value) {
        const date = new Date(newDate);
        activity.value.dueDate = Math.floor(date.getTime() / 1000);
      }
    },
  });

  const filteredInsertAgents = computed(() => {
    const assignedAgents = activity.value?.agents.map((agent) => agent.agent.id) ?? [];
    if (insertFilter.value === '') {
      return agents.value.filter((agent) => !assignedAgents.includes(agent.id));
    }
    return agents.value.filter((agent) => {
      return (
        (agent.name.toLowerCase().includes(insertFilter.value.toLowerCase()) ||
          agent.email.toLowerCase().includes(insertFilter.value.toLowerCase())) &&
        !assignedAgents.includes(agent.id)
      );
    });
  });

  const filteredRemoveAgents = computed(() => {
    if (removeFilter.value === '') {
      return activity.value?.agents ?? [];
    }
    return activity.value?.agents.filter((agent) => {
      return (
        agent.agent.name.toLowerCase().includes(removeFilter.value.toLowerCase()) ||
        agent.agent.email.toLowerCase().includes(removeFilter.value.toLowerCase())
      );
    });
  });

  const getMinDate = function () {
    const today = new Date();
    const year = today.getFullYear();
    const month = String(today.getMonth() + 1).padStart(2, '0');
    const day = String(today.getDate()).padStart(2, '0');
    return `${year}-${month}-${day}`;
  };

  const select = (id: number) => {
    insertFilter.value = '';
    const agent = agents.value.find((agent) => agent.id === id);
    if (agent) {
      if (!activity.value?.agents.some((agent) => agent.agent.id === id)) {
        activity.value?.agents.push({ agent: agent, customers: undefined });
      }
      agents.value = agents.value.filter((agent) => agent.id !== id);
    }
  };

  const unselect = (id: number) => {
    removeFilter.value = '';
    const agent = activity.value?.agents.find((agent) => agent.agent.id === id);
    if (agent && activity.value) {
      if (!agents.value.some((agent) => agent.id === id)) {
        agents.value.push(agent.agent);
      }
      activity.value.agents = activity.value.agents.filter((agent) => agent.agent.id !== id);
    }
  };

  const saveActivity = async () => {
    resetValidation();
    if (!activity.value?.name) {
      nameError.value = true;
      nameErrorText.value = t('admin.nameRequired');
      return;
    }
    if (activity.value) {
      await updateData(activity.value);
      try {
        await router.push({
          path: '/admin/activities',
        });
      } catch (error) {
        console.error('Failed to navigate to activities:', error);
      }
    }
  };

  const deleteCurrentActivity = async () => {
    if (activity.value) {
      await deleteData(activity.value.id);
      try {
        await router.push({
          path: '/admin/activities',
        });
      } catch (error) {
        console.error('Failed to navigate to activities:', error);
      }
    }
  };

  const update = async function () {
    loadData().then((activities) => {
      activity.value = activities.find((a) => a.id === activityId.value) || null;
    });
  };

  const goToCustomerSelection = async function () {
    if (!activity.value?.agents || activity.value.agents.length === 0) {
      return;
    }
    if (activity.value) {
      await updateData(activity.value);
    }
    await router.push({
      name: 'activity/customers',
      params: { activityId: activityId.value },
    });
  };

  onMounted(async () => {
    isLoading.value = true;
    await update();
    agents.value = await loadAgents();
    const campaigns = await loadCampaigns();
    campaignsOptions.value = campaigns.map((c) => ({
      label: c.name,
      searchableString: c.name,
      value: c.id,
    }));
    isLoading.value = false;
  });
</script>

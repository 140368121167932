<template>
  <div>
    <div class="tableHeader">
      <div class="headerColumn">{{ t('admin.name').toUpperCase() }}</div>
      <div class="headerColumn">{{ t('admin.campaign').toUpperCase() }}</div>
      <div class="headerColumn">{{ t('admin.dueDate').toUpperCase() }}</div>
      <div class="headerColumn">{{ t('admin.completed').toUpperCase() }}</div>
      <div class="headerColumn"></div>
    </div>
    <div v-for="activity in props.activities" :key="activity.id" class="tableRow" @click="viewActivity(activity.id)">
      <div class="tableColumn truncate">{{ activity.name }}</div>
      <div class="tableColumn">{{ activity.campaign.name }}</div>
      <div class="tableColumn">{{ formatDate(activity.dueDate) }}</div>
      <div class="tableColumn">
        <component :is="activity.completed ? IcCheck : IcClose" class="fill-success text-infoRed"> </component>
      </div>
      <div class="tableColumn justify-end w-fit"><IcChevronForward /></div>
    </div>
  </div>
</template>

<script setup lang="ts">
  import type { ActivityDto } from '@/admin/domain/ActivityDto';
  import IcCheck from '@/components/icons/IcCheck.vue';
  import IcChevronForward from '@/components/icons/IcChevronForward.vue';
  import IcClose from '@/components/icons/IcClose.vue';
  import { useDateFormatting } from '@/composables/useDateFormatting';
  import { i18n } from '@/plugins/i18n';
  import { useRouter } from 'vue-router';

  const { formatDate } = useDateFormatting();

  interface Props {
    activities: Array<ActivityDto>;
  }

  const props = defineProps<Props>();

  const router = useRouter();
  const { t } = i18n.global;

  const viewActivity = async function (activityId: number) {
    await router.push({
      name: 'activity',
      params: { activityId: activityId },
    });
  };
</script>

<style scoped>
  .tableHeader {
    @apply grid grid-cols-[1fr_1fr_1fr_1fr_30px] gap-12 py-8 font-light text-neutral-550;
  }

  .headerColumn {
    @apply flex items-center;
    color: #04a040;
    font-weight: bold;
  }

  .tableRow {
    @apply grid grid-cols-[1fr_1fr_1fr_1fr_30px] gap-12 border-t border-neutral-300 py-8 hover:bg-neutral-200 hover:cursor-pointer;
  }

  .tableColumn {
    @apply flex items-center;
  }
</style>

import authAdminRequired from '@/middleware/authAdminRequired';
import type { RouteRecordRaw } from 'vue-router';
import NewPaymentMethodPage from './pages/NewPaymentMethodPage.vue';
import PaymentMethodDetailsPage from './pages/PaymentMethodDetailsPage.vue';

export const routes: Array<RouteRecordRaw> = [
  {
    path: ':methodId/',
    name: '/payment-method',
    component: PaymentMethodDetailsPage,
    meta: { layout: 'AdminLayout' },
    beforeEnter: authAdminRequired,
  },
  {
    path: '/new-payment-method',
    name: '/new-payment-method',
    component: NewPaymentMethodPage,
    meta: { layout: 'AdminLayout' },
    beforeEnter: authAdminRequired,
  },
];

export default routes;

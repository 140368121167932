import type { ContainerDto } from '@/admin/domain/data/ContainerDto';
import type { DeliveryMethodDto } from '@/admin/domain/DeliveryMethodDto';
import { useApi } from '@/composables/api/useApi';
import { useError } from '@/util/useError';

export function useDeliveryMethods() {
  const api = useApi();
  const { handleCatchServerError, handleCatchLocalError } = useError();

  const loadData = async function (): Promise<Array<DeliveryMethodDto>> {
    let deliveryMethods: Array<DeliveryMethodDto> = [];
    try {
      const requestUri = '/adminapi/deliveryMethod';

      const container = await api.fetch<ContainerDto>(requestUri, 'GET', undefined, true);
      if (container.errors && container.errors.length > 0) {
        handleCatchServerError(container.errors);
        return [];
      }
      if (container.data.deliveryMethods) {
        deliveryMethods = container.data.deliveryMethods;
      }
      return deliveryMethods;
    } catch (error) {
      handleCatchLocalError(error);
      return [];
    }
  };

  const createData = async function (method: DeliveryMethodDto | undefined) {
    if (!method) {
      return;
    }
    try {
      const requestUri = '/adminapi/deliveryMethod';
      const container = await api.fetch<ContainerDto>(requestUri, 'POST', method, true);
      if (container.errors && container.errors.length > 0) {
        handleCatchServerError(container.errors);
        return;
      }
    } catch (error) {
      handleCatchLocalError(error);
    }
  };
  const deleteData = async function (methodId: number) {
    try {
      const requestUri = `/adminapi/deliveryMethod/${methodId}`;

      const container = await api.fetch<ContainerDto>(requestUri, 'DELETE', undefined, true);
      if (container.errors && container.errors.length > 0) {
        handleCatchServerError(container.errors);
        return;
      }
    } catch (error) {
      handleCatchLocalError(error);
    }
  };

  const updateData = async function (method: DeliveryMethodDto | undefined) {
    if (!method) {
      return;
    }
    try {
      const requestUri = '/adminapi/deliveryMethod';

      const container = await api.fetch<ContainerDto>(requestUri, 'PATCH', method, true);
      if (container.errors && container.errors.length > 0) {
        handleCatchServerError(container.errors);
        return;
      }
    } catch (error) {
      handleCatchLocalError(error);
    }
  };

  return {
    loadData,
    createData,
    deleteData,
    updateData,
  };
}

<template>
  <div>
    <div v-if="isLoading" class="absolute inset-x-1/2 inset-y-1/3 w-fit h-fit">
      <IcLoading />
    </div>

    <div v-else class="md:mx-[80px]">
      <div class="flex items-center space-x-4">
        <AgButton variant="ghost" @click="savePermissionGroup">
          <template #icon>
            <IcSave />
          </template>
          {{ t('admin.save') }}
        </AgButton>
      </div>

      <div>
        <span class="text-primary font-bold">{{ t('admin.code').toUpperCase() }}</span>
        <AgFormInput
          v-model="permissionGroup.code"
          class="max-w-256"
          :validation-state="!codeError"
          :error-message="codeErrorText"
          @change="resetValidation"
        />
      </div>
      <div class="flex justify-between gap-x-32 h-[calc(100dvh-250px)] py-12">
        <div class="w-full">
          <span class="text-xl font-bold text-primary">{{ t('admin.insert').toUpperCase() }}</span>
          <AgBox class="w-full h-[calc(100dvh-300px)] overflow-y-auto border-0 p-0" variant="strong-accent">
            <AgSearchInput v-model="insertFilter" class="w-full" />
            <div v-for="(agent, index) in filteredInsertAgents" :key="agent.id">
              <div
                class="px-12 py-8 border-neutral-550 cursor-pointer hover:bg-neutral-500 italic"
                :class="{ 'border-t': index !== 0 }"
                @click="select(agent.id)"
              >
                <span class="font-bold">
                  {{ agent.name }}
                </span>
                <span> &nbsp;({{ agent.email }}) </span>
              </div>
            </div>
          </AgBox>
        </div>

        <div class="w-full">
          <span class="text-xl font-bold text-primary">{{ t('admin.remove').toUpperCase() }}</span>
          <AgBox class="w-full h-[calc(100dvh-300px)] overflow-y-auto border-0 p-0" variant="strong-accent">
            <AgSearchInput v-model="removeFilter" class="w-full" />
            <div v-for="(agent, index) in filteredRemoveAgents" :key="agent.id">
              <div
                class="px-12 py-8 border-neutral-550 cursor-pointer hover:bg-neutral-500 italic"
                :class="{ 'border-t': index !== 0 }"
                @click="unselect(agent.id)"
              >
                <span class="font-bold">
                  {{ agent.name }}
                </span>
                <span> &nbsp;({{ agent.email }}) </span>
              </div>
            </div>
          </AgBox>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
  import { useTheHeader } from '@/composables/framework/useTheHeader';
  import { computed, onMounted, ref } from 'vue';
  import { useRouter } from 'vue-router';

  import IcLoading from '@/components/icons/IcLoading.vue';
  import IcSave from '@/components/icons/IcSave.vue';
  import AgButton from '@/components/library/button/AgButton.vue';

  import type { AuthUserDto } from '@/admin/domain/AuthUserDto';
  import type { PermissionGroupDto } from '@/admin/domain/PermissionGroupDto';
  import AgBox from '@/components/library/box/AgBox.vue';
  import AgFormInput from '@/components/library/form-input/AgFormInput.vue';
  import AgSearchInput from '@/components/library/search-input/AgSearchInput.vue';
  import { i18n } from '@/plugins/i18n';
  import { usePermissiongroups } from '../composables/usePermissionGroups';

  const { t } = i18n.global;
  const router = useRouter();

  const theUsedHeader = useTheHeader();
  theUsedHeader.enabled(true);
  theUsedHeader.visible(true);
  theUsedHeader.title('admin.newPermissionGroup');

  const { loadData, loadAgents, createData } = usePermissiongroups();

  const permissinGroups = ref<Array<PermissionGroupDto>>([]);
  const isLoading = ref<boolean>(false);
  const agents = ref<Array<AuthUserDto>>([]);
  const insertFilter = ref<string>('');
  const removeFilter = ref<string>('');
  const codeError = ref<boolean>(false);
  const codeErrorText = ref<string>('');

  const permissionGroup = ref<PermissionGroupDto>({
    id: 0,
    code: '',
    agents: [],
  });

  const filteredInsertAgents = computed(() => {
    if (insertFilter.value === '') {
      return agents.value;
    }
    return agents.value.filter((agent) => {
      return (
        agent.name.toLowerCase().includes(insertFilter.value.toLowerCase()) ||
        agent.email.toLowerCase().includes(insertFilter.value.toLowerCase())
      );
    });
  });

  const filteredRemoveAgents = computed(() => {
    if (removeFilter.value === '') {
      return permissionGroup.value.agents;
    }
    return permissionGroup.value.agents.filter((agent) => {
      return (
        agent.name.toLowerCase().includes(removeFilter.value.toLowerCase()) ||
        agent.email.toLowerCase().includes(removeFilter.value.toLowerCase())
      );
    });
  });

  const resetValidation = function () {
    codeError.value = false;
    codeErrorText.value = '';
  };

  const savePermissionGroup = async () => {
    resetValidation();

    if (permissionGroup.value.code.length === 0) {
      codeError.value = true;
      codeErrorText.value = t('admin.codeRequired');
    }

    const codeAlreadyExists = permissinGroups.value.find(
      (pg) => pg.code === permissionGroup.value.code && pg.id !== permissionGroup.value.id
    );

    if (codeAlreadyExists) {
      codeError.value = true;
      codeErrorText.value = t('admin.codeAlreadyExists');
    }

    if (codeError.value) {
      return;
    }

    isLoading.value = true;
    await createData(permissionGroup.value);
    try {
      await router.push({
        path: '/admin/permissionGroups',
      });
    } catch (error) {
      console.error('Failed to navigate to permisssionGroup:', error);
    }
    isLoading.value = false;
  };

  const select = (id: number) => {
    insertFilter.value = '';
    const agent = agents.value.find((agent) => agent.id === id);
    if (agent) {
      permissionGroup.value?.agents.push(agent);
      agents.value = agents.value.filter((agent) => agent.id !== id);
    }
  };

  const unselect = (id: number) => {
    removeFilter.value = '';
    const agent = permissionGroup.value?.agents.find((agent) => agent.id === id);
    if (agent) {
      agents.value.push(agent);
      permissionGroup.value.agents = permissionGroup.value.agents.filter((agent) => agent.id !== id);
    }
  };

  onMounted(async () => {
    agents.value = await loadAgents();
    permissinGroups.value = await loadData();
  });
</script>

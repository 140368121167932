import type { ActivityDto } from '@/admin/domain/ActivityDto';
import type { AuthUserDto } from '@/admin/domain/AuthUserDto';
import type { CampaignDto } from '@/admin/domain/CampaignDto';
import type { CustomerDto } from '@/admin/domain/CustomerDto';
import type { ContainerDto } from '@/admin/domain/data/ContainerDto';
import { useApi } from '@/composables/api/useApi';
import { useError } from '@/util/useError';

export function useActivities() {
  const api = useApi();
  const { handleCatchServerError, handleCatchLocalError } = useError();

  const loadData = async function () {
    let activities: Array<ActivityDto> = [];
    try {
      const requestUri = '/adminapi/activity';

      const container = await api.fetch<ContainerDto>(requestUri, 'GET', undefined, true);
      if (container.errors && container.errors.length > 0) {
        handleCatchServerError(container.errors);
        return [];
      }
      if (container.data.activities) {
        activities = container.data.activities;
      }
      return activities;
    } catch (error) {
      handleCatchLocalError(error);
      return [];
    }
  };

  const loadCampaigns = async function () {
    let campaigns: Array<CampaignDto> = [];
    try {
      const requestUri = '/adminapi/campaign';

      const container = await api.fetch<ContainerDto>(requestUri, 'GET', undefined, true);
      if (container.errors && container.errors.length > 0) {
        handleCatchServerError(container.errors);
        return [];
      }
      if (container.data.campaigns) {
        campaigns = container.data.campaigns;
      }
      return campaigns;
    } catch (error) {
      handleCatchLocalError(error);
      return [];
    }
  };

  const loadAgents = async function () {
    let agents: Array<AuthUserDto> = [];
    try {
      const requestUri = '/adminapi/users';

      const container = await api.fetch<ContainerDto>(requestUri, 'GET', undefined, true);
      if (container.errors && container.errors.length > 0) {
        handleCatchServerError(container.errors);
        return [];
      }
      if (container.data.authUsers) {
        agents = container.data.authUsers;
      }
      return agents;
    } catch (error) {
      handleCatchLocalError(error);
      return [];
    }
  };

  const loadCustomers = async function () {
    let customers: Array<CustomerDto> = [];
    try {
      const requestUri = '/adminapi/customer';

      const container = await api.fetch<ContainerDto>(requestUri, 'GET', undefined, true);
      if (container.errors && container.errors.length > 0) {
        handleCatchServerError(container.errors);
        return [];
      }
      if (container.data.customers) {
        customers = container.data.customers;
      }
      return customers;
    } catch (error) {
      handleCatchLocalError(error);
      return [];
    }
  };

  const createData = async function (activity?: ActivityDto) {
    if (!activity) {
      return;
    }
    try {
      const requestUri = '/adminapi/activity';

      const container = await api.fetch<ContainerDto>(requestUri, 'POST', activity, true);
      if (container.errors && container.errors.length > 0) {
        handleCatchServerError(container.errors);
      }
    } catch (error) {
      handleCatchLocalError(error);
    }
  };

  const updateData = async function (activity?: ActivityDto) {
    if (!activity) {
      return;
    }
    try {
      const requestUri = '/adminapi/activity';

      const container = await api.fetch<ContainerDto>(requestUri, 'PATCH', activity, true);
      if (container.errors && container.errors.length > 0) {
        handleCatchServerError(container.errors);
      }
    } catch (error) {
      handleCatchLocalError(error);
    }
  };

  const deleteData = async function (id: number) {
    try {
      const requestUri = `/adminapi/activity/${id}`;

      const container = await api.fetch<ContainerDto>(requestUri, 'DELETE', undefined, true);
      if (container.errors && container.errors.length > 0) {
        handleCatchServerError(container.errors);
      }
    } catch (error) {
      handleCatchLocalError(error);
    }
  };

  return {
    loadData,
    createData,
    updateData,
    deleteData,
    loadCampaigns,
    loadAgents,
    loadCustomers,
  };
}

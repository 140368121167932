<template>
  <AgModal v-if="internalValue" fit-width fit-height>
    <template #header>
      <div class="text-2xl font-bold pb-12">{{ t('orders.unloadingTimes') }}</div>
    </template>
    <template #content>
      <AgTimeSet class="py-12" v-model:from="from" v-model:to="to" @remove-event="removeEvent" />
    </template>
    <template #footer>
      <AgButton variant="secondary" @click="cancel"> {{ t('orders.remove') }}</AgButton>
      <div class="flex justify-end gap-16">
        <AgButton variant="secondary" @click="removeEvent">{{ t('orders.reset') }}</AgButton>
        <AgButton variant="primary" @click="closeModal">{{ t('orders.apply') }}</AgButton>
      </div>
    </template>
  </AgModal>
</template>

<script setup lang="ts">
  import type { UnloadingTimeDto } from '@/domain/masterData/UnloadingTimeDto';

  import AgButton from '@/components/library/button/AgButton.vue';
  import AgModal from '@/components/library/modal/AgModal.vue';
  import AgTimeSet from '@/components/library/time-set/AgTimeSet.vue';

  import { useOrderWizardStore } from '@/modules/orders/stores/useOrderWizardStore';
  import { i18n } from '@/plugins/i18n';
  import { storeToRefs } from 'pinia';
  import { computed } from 'vue';

  const { t } = i18n.global;

  const store = useOrderWizardStore();
  const { order } = storeToRefs(store);

  interface Props {
    modelValue?: boolean;
    indexUnloadingTime: number;
  }
  const props = withDefaults(defineProps<Props>(), {
    modelValue: false,
    indexUnloadingTime: -1,
  });

  const emit = defineEmits(['update:modelValue']);

  const internalValue = computed({
    get: () => props.modelValue,
    set: (newValue) => emit('update:modelValue', newValue),
  });

  const closeModal = function () {
    internalValue.value = false;
  };

  const cancel = function () {
    removeEvent();
    closeModal();
  };

  const removeEvent = function () {
    const address = order.value?.deliveryAddressEqualToBilling
      ? order.value.invoiceAddress
      : order.value?.deliveryAddress;
    if (address && props.indexUnloadingTime > -1) {
      unloadingTimes.value.splice(props.indexUnloadingTime, 1);
      address.unloadingTimes.splice(props.indexUnloadingTime, 1);
    }
  };

  const unloadingTimes = computed(() => {
    const unloadingTimes: Array<UnloadingTimeDto> = [];
    const address = order.value?.deliveryAddressEqualToBilling
      ? order.value.invoiceAddress
      : order.value?.deliveryAddress;
    if (address) {
      const tt = address.unloadingTimes.length > 0 ? address.unloadingTimes : [];
      unloadingTimes.push(...tt);
    }
    return unloadingTimes;
  });

  const from = computed({
    get: () => {
      if (props.indexUnloadingTime > -1) {
        return unloadingTimes.value[props.indexUnloadingTime]?.timeStart || '00:00';
      } else {
        return '00:00';
      }
    },
    set: (newTimeStart) => {
      const address = order.value?.deliveryAddressEqualToBilling
        ? order.value.invoiceAddress
        : order.value?.deliveryAddress;
      if (
        newTimeStart &&
        unloadingTimes.value.length > 0 &&
        props.indexUnloadingTime > -1 &&
        unloadingTimes.value[props.indexUnloadingTime]
      ) {
        unloadingTimes.value[props.indexUnloadingTime].timeStart = newTimeStart;
      } else if (address && newTimeStart && props.indexUnloadingTime > -1) {
        address.unloadingTimes.push({
          id: null,
          timeStart: newTimeStart,
          timeEnd: '00:00',
        });
      }
    },
  });

  const to = computed({
    get: () => {
      if (props.indexUnloadingTime > -1) {
        return unloadingTimes.value[props.indexUnloadingTime]?.timeEnd || '00:00';
      } else {
        return '00:00';
      }
    },
    set: (newTimeEnd) => {
      const address = order.value?.deliveryAddressEqualToBilling
        ? order.value.invoiceAddress
        : order.value?.deliveryAddress;
      if (
        newTimeEnd &&
        unloadingTimes.value.length > 0 &&
        props.indexUnloadingTime > -1 &&
        unloadingTimes.value[props.indexUnloadingTime]
      ) {
        unloadingTimes.value[props.indexUnloadingTime].timeEnd = newTimeEnd;
      } else if (address && newTimeEnd && props.indexUnloadingTime > -1) {
        address.unloadingTimes.push({
          id: null,
          timeStart: '00:00',
          timeEnd: newTimeEnd,
        });
      }
    },
  });
</script>

<!-- eslint-disable max-len -->
<template>
  <svg id="ic_hide" xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20">
    <path id="Pfad_11680" data-name="Pfad 11680" d="M0,0H20V20H0ZM0,0H20V20H0ZM0,0H20V20H0ZM0,0H20V20H0Z" fill="none" />
    <path
      id="Pfad_11681"
      data-name="Pfad 11681"
      d="M9.04,5.932A3.661,3.661,0,0,1,12.694,9.6a3.566,3.566,0,0,1-.263,1.341l2.134,2.14A8.661,8.661,0,0,0,17.072,9.6a8.645,8.645,0,0,0-8.04-5.5,8.49,8.49,0,0,0-2.909.513L7.7,6.2A3.538,3.538,0,0,1,9.04,5.932Zm-7.309-2L3.4,5.6l.336.337A8.65,8.65,0,0,0,1,9.6a8.628,8.628,0,0,0,11.241,4.881l.307.308,2.142,2.14L15.618,16,2.659,3ZM5.773,7.984,6.906,9.12a2.073,2.073,0,0,0-.058.476A2.193,2.193,0,0,0,9.04,11.8a2.057,2.057,0,0,0,.475-.059l1.133,1.136a3.615,3.615,0,0,1-1.608.388A3.643,3.643,0,0,1,5.773,7.984Zm3.15-.572,2.3,2.309L11.24,9.6a2.193,2.193,0,0,0-2.193-2.2Z"
      transform="translate(0.965 -0.143)"
      fill="currentColor"
    />
  </svg>
</template>

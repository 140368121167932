import type { ContainerDto } from '@/admin/domain/data/ContainerDto';
import { useApi } from '@/composables/api/useApi';
import type { PaymentTermDto } from '@/domain/masterData/PaymentTermDto';
import { useError } from '@/util/useError';

export function usePaymentTerms() {
  const api = useApi();
  const { handleCatchServerError, handleCatchLocalError } = useError();

  const loadData = async function () {
    let terms: Array<PaymentTermDto> = [];
    try {
      const requestUri = '/adminapi/paymentTerm';

      const container = await api.fetch<ContainerDto>(requestUri, 'GET', undefined, true);
      if (container.errors && container.errors.length > 0) {
        handleCatchServerError(container.errors);
        return [];
      }
      if (container.data.paymentTerms) {
        terms = container.data.paymentTerms;
      }
      return terms;
    } catch (error) {
      handleCatchLocalError(error);
      return [];
    }
  };

  const createData = async function (term: PaymentTermDto | undefined) {
    if (!term) {
      return;
    }
    try {
      const requestUri = '/adminapi/paymentTerm';

      const container = await api.fetch<ContainerDto>(requestUri, 'POST', term, true);
      if (container.errors && container.errors.length > 0) {
        handleCatchServerError(container.errors);
        return;
      }
    } catch (error) {
      handleCatchLocalError(error);
    }
  };

  const deleteData = async function (termId: number) {
    try {
      const requestUri = `/adminapi/paymentTerm/${termId}`;

      const container = await api.fetch<ContainerDto>(requestUri, 'DELETE', undefined, true);
      if (container.errors && container.errors.length > 0) {
        handleCatchServerError(container.errors);
        return;
      }
    } catch (error) {
      handleCatchLocalError(error);
    }
  };

  const updateData = async function (paymentTerm: PaymentTermDto | undefined) {
    if (paymentTerm) {
      try {
        const requestUri = '/adminapi/paymentTerm';

        const container = await api.fetch<ContainerDto>(requestUri, 'PATCH', paymentTerm, true);
        if (container.errors && container.errors.length > 0) {
          handleCatchServerError(container.errors);
        }
      } catch (error) {
        handleCatchLocalError(error);
      }
    }
  };

  return {
    loadData,
    updateData,
    deleteData,
    createData,
  };
}

import type { RouteRecordRaw } from 'vue-router';

import authAdminRequired from '@/middleware/authAdminRequired';
import DownloadDetailPage from './pages/DownloadDetailPage.vue';
import NewDownloadPage from './pages/NewDownloadPage.vue';

export const routes: Array<RouteRecordRaw> = [
  {
    path: '/:downloadId/',
    name: '/download',
    component: DownloadDetailPage,
    meta: { layout: 'AdminLayout' },
    beforeEnter: authAdminRequired,
  },
  {
    path: '/new-download',
    name: '/new-download',
    component: NewDownloadPage,
    meta: { layout: 'AdminLayout' },
    beforeEnter: authAdminRequired,
  },
];

export default routes;

import type { CommunicationDto } from '@/admin/domain/CommunicationDto';
import type { ContainerDto } from '@/admin/domain/data/ContainerDto';
import type { DownloadDto } from '@/admin/domain/DownloadDto';
import type { PermissionGroupDto } from '@/admin/domain/PermissionGroupDto';
import { useApi } from '@/composables/api/useApi';
import { useError } from '@/util/useError';

export function useCommunication() {
  const api = useApi();
  const { handleCatchServerError, handleCatchLocalError } = useError();

  const loadData = async function () {
    let communication: Array<CommunicationDto> = [];
    try {
      const requestUri = '/adminapi/communication';

      const container = await api.fetch<ContainerDto>(requestUri, 'GET', undefined, true);
      if (container.errors && container.errors.length > 0) {
        handleCatchServerError(container.errors);
        return [];
      }

      if (container.data.communications) {
        communication = container.data.communications;
      }
      return communication;
    } catch (error) {
      handleCatchLocalError(error);
      return [];
    }
  };

  const getDownloads = async function () {
    let downloads: Array<DownloadDto> = [];
    try {
      const requestUri = '/adminapi/download';

      const container = await api.fetch<ContainerDto>(requestUri, 'GET', undefined, true);
      if (container.errors && container.errors.length > 0) {
        handleCatchServerError(container.errors);
        return [];
      }
      if (container.data.downloads) {
        downloads = container.data.downloads;
      }
      return downloads;
    } catch (error) {
      handleCatchLocalError(error);
      return [];
    }
  };

  const getPermissionGroups = async function () {
    let groups: Array<PermissionGroupDto> = [];
    try {
      const requestUri = '/adminapi/permissionGroup';

      const container = await api.fetch<ContainerDto>(requestUri, 'GET', undefined, true);
      if (container.errors && container.errors.length > 0) {
        handleCatchServerError(container.errors);
        return [];
      }
      if (container.data.permissionGroups) {
        groups = container.data.permissionGroups;
      }
      return groups;
    } catch (error) {
      handleCatchLocalError(error);
      return [];
    }
  };

  const updateData = async function (communication: CommunicationDto) {
    try {
      const requestUri = '/adminapi/communication';

      const container = await api.fetch<ContainerDto>(requestUri, 'PATCH', communication, true);
      if (container.errors && container.errors.length > 0) {
        handleCatchServerError(container.errors);
        return;
      }
    } catch (error) {
      handleCatchLocalError(error);
    }
  };

  const deleteData = async function (communicationId: number) {
    try {
      const requestUri = `/adminapi/communication/${communicationId}`;

      const container = await api.fetch<ContainerDto>(requestUri, 'DELETE', undefined, true);
      if (container.errors && container.errors.length > 0) {
        handleCatchServerError(container.errors);
        return;
      }
    } catch (error) {
      handleCatchLocalError(error);
    }
  };

  const createData = async function (communication: CommunicationDto) {
    try {
      const requestUri = '/adminapi/communication';

      const container = await api.fetch<ContainerDto>(requestUri, 'POST', communication, true);
      if (container.errors && container.errors.length > 0) {
        handleCatchServerError(container.errors);
        return;
      }
    } catch (error) {
      handleCatchLocalError(error);
    }
  };

  return {
    loadData,
    getDownloads,
    getPermissionGroups,
    updateData,
    deleteData,
    createData,
  };
}

<template>
  <div class="bg-[#EFEFEF] px-32 py-12 text-s font-light">
    <div class="flex gap-4 truncate">
      <span class="text-neutral-500"> {{ t('appVersionSidebar') }}: </span>
      <span class="text-neutral-900">
        {{ config.version }}
      </span>
    </div>
    <div class="flex gap-8 truncate mt-8">
      <a v-for="locale in locales" :key="locale.key" href="" @click.prevent="changeLocale(locale.key)">
        <span :class="getClass(locale.key)" class="hover:font-bold text-neutral-900">{{ locale.label }}</span>
      </a>
    </div>
  </div>
</template>

<script setup lang="ts">
  import { config } from '@/config/config';
  import { i18n } from '@/plugins/i18n';
  import { ref } from 'vue';

  const { t, locale } = i18n.global;

  interface Props {
    appVersion?: string;
    memoryTotalBytes?: number;
    memoryUsedBytes?: number;
    storagePersisted?: boolean;
  }

  const locales = ref<Array<{ key: string; label: string }>>(
    Object.values(config.company.availableLanguages).map((lang) => ({
      key: lang,
      label: lang,
    }))
  );

  const changeLocale = function (selectLocale: string) {
    locale.value = selectLocale;
  };

  const getClass = (lang: string) => (lang === locale.value ? 'font-bold underline' : '');
</script>

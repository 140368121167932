<template>
  <div>
    <TheSubheader class="flex items-center justify-between">
      <Filter v-model:searchText="searchText" />
      <div class="flex items-center justify-end space-x-64 md:mx-[80px] max-md:mx-16">
        <AgButton variant="ghost" iconBeforeText @click="update">
          <template #icon>
            <IcUpdate />
          </template>
          <div class="text-xl">{{ t('admin.update') }}</div>
        </AgButton>

        <AgButton variant="ghost" iconBeforeText @click="newCampaign">
          <template #icon>
            <IcAdd />
          </template>
          <div class="text-xl">{{ t('admin.create') }}</div>
        </AgButton>
      </div>
    </TheSubheader>

    <div class="overflow-y-auto">
      <div v-if="isLoading" class="absolute inset-x-1/2 inset-y-1/3 w-fit h-fit">
        <IcLoading />
      </div>
      <div v-else class="md:mx-[80px] max-md:mx-16 min-w-fit pt-8 max-h-[calc(100dvh-160px)]">
        <div class="m-24">
          <AgPaginatorInfo :current-page="currentPage" :page-size="itemsPerPage" :number-items="campaigns.length" />
          <AgPaginator v-model="currentPage" :total-pages="pageCount" />
        </div>
        <CampaignTable :campaigns="displayedItems" />
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
  import type { CampaignDto } from '@/admin/domain/CampaignDto';

  import CampaignTable from '@/admin/modules/campaigns/components/CampaignTable.vue';
  import Filter from '@/admin/modules/campaigns/components/filter/Filter.vue';
  import TheSubheader from '@/components/framework/the-subheader/TheSubheader.vue';
  import IcAdd from '@/components/icons/IcAdd.vue';
  import IcLoading from '@/components/icons/IcLoading.vue';
  import IcUpdate from '@/components/icons/IcUpdate.vue';
  import AgButton from '@/components/library/button/AgButton.vue';

  import { useCampaigns } from '@/admin/modules/campaigns/composables/useCampaigns';
  import AgPaginator from '@/components/library/paginator/AgPaginator.vue';
  import AgPaginatorInfo from '@/components/library/paginator/info/AgPaginatorInfo.vue';
  import { useTheHeader } from '@/composables/framework/useTheHeader';
  import { i18n } from '@/plugins/i18n';
  import { computed, onMounted, ref } from 'vue';
  import { useRouter } from 'vue-router';

  const { t } = i18n.global;

  const { loadData } = useCampaigns();

  const router = useRouter();

  const theHeader = useTheHeader();
  theHeader.enabled(true);
  theHeader.visible(true);
  theHeader.title('admin.campaigns');

  const campaigns = ref<Array<CampaignDto>>([]);

  const searchText = ref<string>('');
  const isLoading = ref<boolean>(true);
  const currentPage = ref<number>(1);
  const itemsPerPage = 10;

  const filteredCampaigns = computed(() => {
    if (!searchText.value) {
      return campaigns.value;
    }

    return campaigns.value.filter((campaign) => campaign.name.toLowerCase().includes(searchText.value.toLowerCase()));
  });

  const pageCount = computed(() => Math.ceil(filteredCampaigns.value.length / itemsPerPage));

  const displayedItems = computed(() => {
    const startIndex = (currentPage.value - 1) * itemsPerPage;
    const endIndex = Math.min(startIndex + itemsPerPage, filteredCampaigns.value.length);
    const itmxpage = filteredCampaigns.value.slice(startIndex, endIndex);

    return itmxpage;
  });

  const newCampaign = async () => {
    try {
      await router.push({
        name: '/new-campaign',
      });
    } catch (error) {
      console.error('Failed to navigate to campaign:', error);
    }
  };

  const update = async () => {
    isLoading.value = true;
    campaigns.value = await loadData();
    isLoading.value = false;
  };

  onMounted(async () => update());
</script>

<template>
  <div v-if="isLoading" class="absolute inset-x-1/2 inset-y-1/3 w-fit h-fit">
    <IcLoading />
  </div>
  <div v-else-if="currentPaymentTerm" class="md:mx-[80px]">
    <div class="flex items-center space-x-4">
      <AgButton variant="ghost" @click="savePaymentTerm">
        <template #icon>
          <IcSave />
        </template>
        {{ t('admin.save') }}
      </AgButton>
      <AgButton variant="ghost" @click="deletePaymentTerm(currentPaymentTerm.id)">
        <template #icon>
          <IcDelete />
        </template>
        {{ t('admin.delete') }}
      </AgButton>
    </div>

    <div class="flex">
      <div class="infoBox">
        <div class="infoboxTitle">{{ t('admin.code').toUpperCase() }}</div>
        <div class="name">{{ currentPaymentTerm.code }}</div>
      </div>
      <div class="infoBox">
        <span class="infoboxTitle">{{ t('admin.predefined').toUpperCase() }}</span>
        <div>
          <AgButton
            variant="ghost"
            class="h-24 w-24 flex items-center border-2"
            :class="{ 'border-success': !defaultError, 'border-infoRed': defaultError }"
            @click="toggleDefault"
          >
            <template #icon>
              <IcCheck v-if="currentPaymentTerm.default" class="pr-4" />
            </template>
          </AgButton>
          <AgAlert v-if="defaultError" variant="danger" class="w-256">
            {{ t('admin.paymentTermStandardAlreadyChecked') }}
          </AgAlert>
        </div>
      </div>
    </div>

    <div class="flex">
      <div class="table mt-16">
        <span class="text-primary-light">{{ t('admin.languageDescription').toUpperCase() }}</span>
        <div v-for="selectedLang in Object.values(config.company.availableLanguages)" :key="selectedLang">
          <div class="tableColumn">
            <AgFormInput :placeholder="selectedLang" disabled />
            <AgFormInput v-model="currentPaymentTerm.title[selectedLang]" />
            <IcClose class="cursor-pointer" @click="deleteTranslation(selectedLang)"></IcClose>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div v-else>
    <AgAlert variant="info">{{ t('admin.paymentTermnotFound') }}</AgAlert>
  </div>
</template>

<script setup lang="ts">
  import type { PaymentTermDto } from '@/admin/domain/PaymentTermDto';
  import type { AgFormSelectOption } from '@/components/library/form-select/AgFormSelectOption';

  import IcCheck from '@/components/icons/IcCheck.vue';
  import IcClose from '@/components/icons/IcClose.vue';
  import IcDelete from '@/components/icons/IcDelete.vue';
  import IcLoading from '@/components/icons/IcLoading.vue';
  import IcSave from '@/components/icons/IcSave.vue';
  import AgAlert from '@/components/library/alert/AgAlert.vue';
  import AgButton from '@/components/library/button/AgButton.vue';
  import AgFormInput from '@/components/library/form-input/AgFormInput.vue';

  import { usePaymentTerms } from '@/admin/modules/paymentTerms/composables/usePaymentTerms';
  import { useTheHeader } from '@/composables/framework/useTheHeader';
  import { config } from '@/config/config';
  import { i18n } from '@/plugins/i18n';
  import { onMounted, ref } from 'vue';
  import { useRoute, useRouter } from 'vue-router';

  const { t } = i18n.global;
  const route = useRoute();
  const router = useRouter();
  const { loadData, updateData, deleteData } = usePaymentTerms();
  const theUsedHeader = useTheHeader();

  theUsedHeader.enabled(true);
  theUsedHeader.visible(true);
  theUsedHeader.title('admin.paymentTermDetails');

  const paymentTerms = ref<Array<PaymentTermDto>>([]);
  const isLoading = ref<boolean>(true);
  const currentPaymentTerm = ref<PaymentTermDto>();
  const languageSelectOptions = ref<Array<AgFormSelectOption>>([]);
  const defaultError = ref<boolean>(false);

  const setCurrentItem = () => {
    currentPaymentTerm.value = paymentTerms.value.find((term) => term.id.toString() === route.params.termId);
  };

  const savePaymentTerm = async () => {
    defaultError.value = false;

    if (currentPaymentTerm.value?.default) {
      for (let i = 0; i < paymentTerms.value.length; i++) {
        if (paymentTerms.value[i].default && paymentTerms.value[i] != currentPaymentTerm.value) {
          defaultError.value = true;
          return;
        }
      }
    }
    isLoading.value = true;
    await updateData(currentPaymentTerm.value);
    try {
      await router.push({
        path: '/admin/payment-terms',
      });
    } catch (error) {
      console.error('Failed to navigate to payment term:', error);
    }
    isLoading.value = false;
  };

  const deletePaymentTerm = async function (dtId: number) {
    isLoading.value = true;
    await deleteData(dtId);
    try {
      await router.push({
        path: '/admin/payment-terms',
      });
    } catch (error) {
      console.error('Failed to navigate to payment terms:', error);
    }
    isLoading.value = false;
  };

  const deleteTranslation = function (language: string) {
    if (!currentPaymentTerm.value) {
      return;
    }
    const titleKeys = Object.keys(currentPaymentTerm.value.title);
    const indexToRemove = titleKeys.findIndex((keyLang) => keyLang === language);

    if (indexToRemove !== -1) {
      delete currentPaymentTerm.value.title[language];
    }
  };

  const toggleDefault = () => {
    defaultError.value = false;
    if (!currentPaymentTerm.value) {
      return;
    }
    currentPaymentTerm.value.default = !currentPaymentTerm.value?.default;
  };

  const update = async function () {
    loadData()
      .then((methods) => {
        paymentTerms.value = methods ?? [];
        if (methods) {
          setCurrentItem();
        }
        isLoading.value = false;

        const options: Array<AgFormSelectOption> = [];
        Object.values(config.company.availableLanguages).forEach((lang) => {
          options.push({
            key: lang,
            label: lang,
          });
        });
        languageSelectOptions.value = options;
      })
      .catch((error) => {
        console.log('Error loading payment terms: ', error);
        isLoading.value = false;
      });
  };

  onMounted(async () => update());
</script>

<style scoped lang="scss">
  .infoboxTitle {
    @apply text-primary-light;
  }

  .name {
    @apply text-xl font-bold flex items-center;
  }

  .infoBox {
    @apply w-1/2 justify-center my-12;
  }

  .table {
    @apply grid grid-cols-1 py-16 space-x-0;
  }

  .tableColumn {
    @apply flex items-center min-w-0 py-4 gap-4;
  }
</style>

import type { RouteRecordRaw } from 'vue-router';

import authAdminRequired from '@/middleware/authAdminRequired';
import NewPermissionGroupPage from './pages/NewPermissionGroupPage.vue';
import PermissionGroupDetailPage from './pages/PermissionGroupDetailPage.vue';

export const routes: Array<RouteRecordRaw> = [
  {
    path: '/:groupId/',
    name: '/permission-group',
    component: PermissionGroupDetailPage,
    meta: { layout: 'AdminLayout' },
    beforeEnter: authAdminRequired,
  },
  {
    path: '/new-permission-group',
    name: '/new-permission-group',
    component: NewPermissionGroupPage,
    meta: { layout: 'AdminLayout' },
    beforeEnter: authAdminRequired,
  },
];

export default routes;

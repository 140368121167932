<template>
  <div v-if="isLoading" class="absolute inset-x-1/2 inset-y-1/3 w-fit h-fit">
    <IcLoading />
  </div>
  <div v-else-if="activity" class="md:mx-[80px]">
    <div class="flex items-center space-x-4">
      <AgButton variant="ghost" @click="saveActivity">
        <template #icon>
          <IcSave />
        </template>
        {{ t('admin.save') }}
      </AgButton>
      <AgButton variant="ghost" @click="deleteCurrentActivity">
        <template #icon>
          <IcDelete />
        </template>
        {{ t('admin.delete') }}
      </AgButton>
    </div>

    <div class="flex gap-12 items-center py-12">
      <div class="font-bold text-primary">{{ t('admin.name').toUpperCase() }}</div>
      <div class="break-all">{{ activity.name }}</div>
    </div>
    <div class="flex gap-12 items-center py-12">
      <div class="font-bold text-primary">{{ t('admin.campaign').toUpperCase() }}</div>
      {{ activity.campaign.name }}
    </div>
    <div class="flex gap-12 items-center py-12">
      <div class="font-bold text-primary">{{ t('admin.dueDate').toUpperCase() }}</div>
      {{ formatDate(activity.dueDate) }}
    </div>
    <div v-if="selectedAgentId" class="flex gap-12 items-center py-12">
      <span class="text-primary font-bold">{{ t('admin.agent').toUpperCase() }}</span>
      <AgSearchSelect :options="agentOptions" v-model="selectedAgentId" />
    </div>

    <div class="flex gap-24">
      <div class="w-full">
        <span class="text-xl font-bold text-primary">{{ t('admin.insert').toUpperCase() }}</span>
        <AgBox class="border-0 p-0 h-[calc(100dvh-420px)]" variant="strong-accent" key="insert">
          <AgSearchInput v-model="insertFilter" class="w-full" />
          <div class="w-full overflow-y-auto h-[calc(100dvh-500px)]">
            <div v-for="(customer, index) in filteredInsertCustomers" :key="customer.id">
              <div
                class="px-12 py-8 border-neutral-550 cursor-pointer hover:bg-neutral-500 italic"
                :class="{ 'border-t': index !== 0 }"
                @click="select(customer.id)"
              >
                <span class="font-bold">
                  {{ customer.name }}
                </span>
              </div>
            </div>
          </div>
        </AgBox>
      </div>

      <div class="w-full">
        <span class="text-xl font-bold text-primary">{{ t('admin.remove').toUpperCase() }}</span>
        <AgBox class="w-full h-[calc(100dvh-420px)] border-0 p-0" variant="strong-accent" key="remove">
          <AgSearchInput v-model="removeFilter" class="w-full" />
          <div class="w-full overflow-y-auto h-[calc(100dvh-500px)]">
            <div v-for="(customer, index) in filteredRemoveCustomers" :key="customer.id">
              <div
                class="px-12 py-8 border-neutral-550 cursor-pointer hover:bg-neutral-500 italic"
                :class="{ 'border-t': index !== 0 }"
                @click="unselect(customer.id)"
              >
                <span class="font-bold">
                  {{ customer.name }}
                </span>
              </div>
            </div>
          </div>
        </AgBox>
      </div>
    </div>
  </div>
  <div v-else>
    <AgAlert variant="info"> {{ t('admin.activityCategoryNotFound') }} </AgAlert>
  </div>
</template>

<script setup lang="ts">
  import type { ActivityDto } from '@/admin/domain/ActivityDto';
  import type { AuthUserDto } from '@/admin/domain/AuthUserDto';
  import type { CustomerDto } from '@/admin/domain/CustomerDto';
  import type { AgSearchSelectOption } from '@/components/library/search-select/AgSearchSelectOption';

  import IcDelete from '@/components/icons/IcDelete.vue';
  import IcLoading from '@/components/icons/IcLoading.vue';
  import IcSave from '@/components/icons/IcSave.vue';
  import AgAlert from '@/components/library/alert/AgAlert.vue';
  import AgBox from '@/components/library/box/AgBox.vue';
  import AgButton from '@/components/library/button/AgButton.vue';
  import AgSearchInput from '@/components/library/search-input/AgSearchInput.vue';
  import AgSearchSelect from '@/components/library/search-select/AgSearchSelect.vue';

  import { useActivities } from '@/admin/modules/activities/composables/useActivities';
  import { useTheHeader } from '@/composables/framework/useTheHeader';
  import { useDateFormatting } from '@/composables/useDateFormatting';
  import { i18n } from '@/plugins/i18n';
  import { computed, onMounted, ref } from 'vue';
  import { useRoute, useRouter } from 'vue-router';

  const { t } = i18n.global;
  const route = useRoute();
  const router = useRouter();
  const { loadData, updateData, deleteData, loadAgents, loadCustomers } = useActivities();
  const { formatDate } = useDateFormatting();
  const theHeader = useTheHeader();

  theHeader.enabled(true);
  theHeader.visible(true);
  theHeader.title('admin.selectCustomers');

  const activity = ref<ActivityDto | null>(null);
  const agentOptions = ref<Array<AgSearchSelectOption>>([]);
  const isLoading = ref<boolean>(true);
  const agents = ref<Array<AuthUserDto>>([]);
  const readOnlyCustomers = ref<Array<CustomerDto>>([]);
  const customers = ref<Array<CustomerDto>>([]);
  const selectedAgentId = ref<number>();
  const insertFilter = ref<string>('');
  const removeFilter = ref<string>('');

  const filteredCustomers = computed(() => {
    const agentActivity = activity.value?.agents.find((agent) => agent.agent.id === (selectedAgentId.value ?? 0));
    const assignedCustomerIds = agentActivity?.customers?.map((customer) => customer.id) ?? [];
    return readOnlyCustomers.value.filter(
      (c) => c.agentId === (selectedAgentId.value ?? 0) && !assignedCustomerIds.includes(c.id)
    );
  });

  const filteredInsertCustomers = computed(() => {
    if (insertFilter.value === '') {
      return filteredCustomers.value;
    }
    return filteredCustomers.value?.filter((customer) => {
      return customer.name.toLowerCase().includes(insertFilter.value.toLowerCase());
    });
  });

  const filteredRemoveCustomers = computed(() => {
    const agentActivity = activity.value?.agents.find((agent) => agent.agent.id === (selectedAgentId.value ?? 0));
    if (removeFilter.value === '') {
      return agentActivity?.customers ?? [];
    }
    return agentActivity?.customers?.filter((customer) => {
      return customer.name.toLowerCase().includes(removeFilter.value.toLowerCase());
    });
  });

  const activityId = computed(() => {
    const camId = route.params.activityId;
    if (Array.isArray(camId)) {
      return parseInt(camId[0]);
    }
    return parseInt(camId);
  });

  const saveActivity = async () => {
    if (activity.value) {
      await updateData(activity.value);
      try {
        await router.push({
          path: '/admin/activities',
        });
      } catch (error) {
        console.error('Failed to navigate to activities:', error);
      }
    }
  };

  const deleteCurrentActivity = async () => {
    if (activity.value) {
      await deleteData(activity.value.id);
      try {
        await router.push({
          path: '/admin/activities',
        });
      } catch (error) {
        console.error('Failed to navigate to activites:', error);
      }
    }
  };

  const update = async function () {
    const activities = await loadData();

    activity.value = activities.find((a) => a.id === activityId.value) || null;
  };

  const select = (id: number) => {
    insertFilter.value = '';
    const customer = filteredCustomers.value?.find((customer) => customer.id === id);
    if (customer) {
      const agentActivity = activity.value?.agents.find((agent) => agent.agent.id === (selectedAgentId.value ?? 0));
      if (agentActivity) {
        if (!agentActivity.customers) {
          agentActivity.customers = [];
        }
        if (!agentActivity.customers.find((customer) => customer.id === id)) {
          agentActivity.customers.push(customer);
        }
      }
      customers.value = customers.value.filter((customer) => customer.id !== id);
    }
  };

  const unselect = (id: number) => {
    removeFilter.value = '';
    const customer = readOnlyCustomers.value.find((customer) => customer.id === id);
    if (customer && activity.value) {
      filteredCustomers.value?.push(customer);
      const agentActivity = activity.value.agents.find((agent) => agent.agent.id === (selectedAgentId.value ?? 0));
      if (agentActivity) {
        agentActivity.customers = agentActivity?.customers?.filter((customer) => customer.id !== id) ?? [];
      }
    }
  };

  onMounted(async () => {
    isLoading.value = true;
    await update();
    agents.value = await loadAgents();
    customers.value = await loadCustomers();
    readOnlyCustomers.value = customers.value;
    if (activity.value) {
      agentOptions.value = activity.value.agents.map((a) => ({
        label: `${a.agent.name} (${a.agent.email})`,
        searchableString: `${a.agent.name} (${a.agent.email})`,
        value: a.agent.id,
      }));
    }
    selectedAgentId.value = activity.value?.agents[0].agent.id;
    isLoading.value = false;
  });
</script>

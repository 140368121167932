<template>
  <div>
    <div class="overflow-y-auto">
      <TheSubheader class="flex items-center justify-between">
        <Filter v-model:searchText="searchText" />
        <div class="flex items-center justify-end space-x-64 md:mx-[80px] max-md:mx-16">
          <AgButton variant="ghost" iconBeforeText @click="update">
            <template #icon>
              <IcUpdate />
            </template>
            <div class="text-xl">{{ t('admin.update') }}</div>
          </AgButton>

          <AgButton variant="ghost" iconBeforeText @click="newCollaborator">
            <template #icon>
              <IcAdd />
            </template>
            <div class="text-xl">{{ t('admin.create') }}</div>
          </AgButton>
        </div>
      </TheSubheader>
      <div v-if="isLoading" class="absolute inset-x-1/2 inset-y-1/3 w-fit h-fit">
        <IcLoading />
      </div>
      <div v-else class="md:mx-[80px] max-md:mx-16 min-w-fit pt-8 max-h-[calc(100dvh-160px)]">
        <div class="m-24">
          <AgPaginatorInfo :current-page="currentPage" :page-size="itemsPerPage" :number-items="usersList.length" />
          <AgPaginator v-model="currentPage" :total-pages="pageCount" />
        </div>
        <div class="tableHeader">
          <div class="headerColumn">{{ t('admin.name').toUpperCase() }}</div>
          <div class="headerColumn">{{ t('admin.email').toUpperCase() }}</div>
          <div class="headerColumn">{{ t('admin.password').toUpperCase() }}</div>
          <div class="headerColumn">{{ t('admin.active').toUpperCase() }}</div>
          <div class="headerColumn"></div>
        </div>
        <div v-for="collaborator in displayedItems" :key="collaborator.id">
          <div class="table justify-start items-center" @click="viewCollaborator(collaborator.id)">
            <div class="tableColumn">{{ collaborator.name }}</div>
            <div class="tableColumn emailColumn">{{ collaborator.email }}</div>
            <div class="tableColumn">
              <component
                :is="passwordVisible.get(collaborator.id) ? IcHide : IcShow"
                @click.stop="togglePasswordVisibility(collaborator)"
                class="cursor-pointer ml-2"
              />
              <span v-if="passwordVisible.get(collaborator.id)">{{ collaborator.endOfPassword }}</span>
              <span v-else>****</span>
            </div>
            <div class="tableColumn">
              <component :is="collaborator.active ? IcCheck : IcClose" class="fill-success infoRed" />
            </div>
            <div class="flex justify-end">
              <IcChevronForward />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
  import type { AuthUserDto } from '@/admin/domain/AuthUserDto';

  import Filter from '@/admin/modules/collaborators/components/filter/Filter.vue';
  import TheSubheader from '@/components/framework/the-subheader/TheSubheader.vue';
  import IcAdd from '@/components/icons/IcAdd.vue';
  import IcCheck from '@/components/icons/IcCheck.vue';
  import IcClose from '@/components/icons/IcClose.vue';
  import IcHide from '@/components/icons/IcHide.vue';
  import IcLoading from '@/components/icons/IcLoading.vue';
  import IcShow from '@/components/icons/IcShow.vue';
  import IcUpdate from '@/components/icons/IcUpdate.vue';
  import AgButton from '@/components/library/button/AgButton.vue';

  import { useCollaborators } from '@/admin/modules/collaborators/composables/useCollaborators';
  import IcChevronForward from '@/components/icons/IcChevronForward.vue';
  import AgPaginator from '@/components/library/paginator/AgPaginator.vue';
  import AgPaginatorInfo from '@/components/library/paginator/info/AgPaginatorInfo.vue';
  import { useTheHeader } from '@/composables/framework/useTheHeader';
  import { i18n } from '@/plugins/i18n';
  import { computed, onMounted, ref } from 'vue';
  import { useRouter } from 'vue-router';

  const { t } = i18n.global;
  const { loadData } = useCollaborators();
  const router = useRouter();
  const theHeader = useTheHeader();

  theHeader.enabled(true);
  theHeader.visible(true);
  theHeader.title('admin.collaborators');

  const searchText = ref<string>('');
  const passwordVisible = ref<Map<number, boolean>>(new Map());
  const usersList = ref<Array<AuthUserDto>>([]);
  const isLoading = ref<boolean>(true);
  const currentPage = ref<number>(1);
  const itemsPerPage = 10;

  const filteredCollaborators = computed(() => {
    return usersList.value.filter((collaborator) => {
      return (
        collaborator.name.toLowerCase().includes(searchText.value.toLowerCase()) ||
        collaborator.email.toLowerCase().includes(searchText.value.toLowerCase())
      );
    });
  });

  const pageCount = computed(() => Math.ceil(filteredCollaborators.value.length / itemsPerPage));

  const displayedItems = computed(() => {
    const startIndex = (currentPage.value - 1) * itemsPerPage;
    const endIndex = Math.min(startIndex + itemsPerPage, filteredCollaborators.value.length);
    const itmxpage = filteredCollaborators.value.slice(startIndex, endIndex);

    return itmxpage;
  });

  const togglePasswordVisibility = (user: AuthUserDto) => {
    const pwdVisibleElement = passwordVisible.value.get(user.id);
    passwordVisible.value.set(user.id, !pwdVisibleElement);
  };

  const newCollaborator = async () => {
    await router.push({ name: '/new-collaborator' });
  };

  const viewCollaborator = async function (collaboratorId: number) {
    await router.push({
      name: '/collaborator',
      params: { collaboratorId: collaboratorId },
    });
  };

  const update = async () => {
    isLoading.value = true;
    const collaborators = await loadData();
    usersList.value = collaborators;
    isLoading.value = false;
    collaborators.forEach((collab) => {
      passwordVisible.value.set(collab.id, false);
    });
  };

  onMounted(() => update());
</script>

<style scoped lang="scss">
  .tableHeader {
    @apply grid auto-cols-fr grid-flow-col-dense gap-12 py-8 font-light text-neutral-550;
  }

  .headerColumn {
    @apply flex items-center hover:cursor-pointer;
    color: #04a040;
    font-weight: bold;
    word-break: break-word;
    overflow-wrap: break-word;
  }

  .table {
    @apply grid auto-cols-fr grid-flow-col-dense gap-12 border-t border-neutral-500 py-8;
  }

  .table:hover {
    @apply bg-neutral-200 cursor-pointer;
  }

  .tableColumn {
    @apply flex items-center;
    word-break: break-word;
    overflow-wrap: break-word;
  }

  .emailColumn {
    word-break: break-word;
    overflow-wrap: break-word;
  }

  .fill-success {
    color: #04a040; /* Assuming this is your success color */
  }

  .infoRed {
    color: red; /* Adjust this to your desired info red color */
  }

  @media (max-width: 1024px) {
    .tableHeader,
    .table {
      grid-template-columns: repeat(5, 1fr); /* Adjust grid layout for smaller screens */
      gap: 8px; /* Reduce gap for smaller screens */
    }

    .tableColumn {
      min-width: 0; /* Allow columns to shrink */
    }

    .emailColumn {
      min-width: 0; /* Allow email column to shrink */
    }
  }
</style>

import type { AuthUserDto } from '@/admin/domain/AuthUserDto';
import type { ContainerDto } from '@/admin/domain/data/ContainerDto';
import type { PermissionGroupDto } from '@/admin/domain/PermissionGroupDto';
import { useApi } from '@/composables/api/useApi';
import { useError } from '@/util/useError';

export function usePermissiongroups() {
  const api = useApi();
  const { handleCatchServerError, handleCatchLocalError } = useError();

  const loadData = async function () {
    let groups: Array<PermissionGroupDto> = [];
    try {
      const requestUri = '/adminapi/permissionGroup';

      const container = await api.fetch<ContainerDto>(requestUri, 'GET', undefined, true);
      if (container.errors && container.errors.length > 0) {
        handleCatchServerError(container.errors);
        return [];
      }
      if (container.data.permissionGroups) {
        groups = container.data.permissionGroups;
      }
      return groups;
    } catch (error) {
      handleCatchLocalError(error);
      return [];
    }
  };

  const createData = async function (permissionGroup?: PermissionGroupDto) {
    if (!permissionGroup) {
      return;
    }
    try {
      const requestUri = '/adminapi/permissionGroup';

      const container = await api.fetch<ContainerDto>(requestUri, 'POST', permissionGroup, true);
      if (container.errors && container.errors.length > 0) {
        handleCatchServerError(container.errors);
      }
    } catch (error) {
      handleCatchLocalError(error);
    }
  };

  const updateData = async function (permissionGroup?: PermissionGroupDto) {
    if (!permissionGroup) {
      return;
    }
    try {
      const requestUri = '/adminapi/permissionGroup';

      const container = await api.fetch<ContainerDto>(requestUri, 'PATCH', permissionGroup, true);
      if (container.errors && container.errors.length > 0) {
        handleCatchServerError(container.errors);
      }
    } catch (error) {
      handleCatchLocalError(error);
    }
  };

  const deleteData = async function (id: number) {
    try {
      const requestUri = `/adminapi/permissionGroup/${id}`;

      const container = await api.fetch<ContainerDto>(requestUri, 'DELETE', undefined, true);
      if (container.errors && container.errors.length > 0) {
        handleCatchServerError(container.errors);
      }
    } catch (error) {
      handleCatchLocalError(error);
    }
  };

  const loadAgents = async function () {
    let agents: Array<AuthUserDto> = [];
    try {
      const requestUri = '/adminapi/users';

      const container = await api.fetch<ContainerDto>(requestUri, 'GET', undefined, true);
      if (container.errors && container.errors.length > 0) {
        handleCatchServerError(container.errors);
        return [];
      }
      if (container.data.authUsers) {
        agents = container.data.authUsers;
      }
      return agents;
    } catch (error) {
      handleCatchLocalError(error);
      return [];
    }
  };

  return {
    loadData,
    loadAgents,
    updateData,
    deleteData,
    createData,
  };
}

import type { ContainerDto } from '@/admin/domain/data/ContainerDto';
import type { DeliveryTermDto } from '@/admin/domain/DeliveryTermDto';
import { useApi } from '@/composables/api/useApi';
import { useError } from '@/util/useError';

export function useDeliveryTerms() {
  const api = useApi();
  const { handleCatchServerError, handleCatchLocalError } = useError();

  const loadData = async function () {
    let deliveryTerms: Array<DeliveryTermDto> = [];
    try {
      const requestUri = '/adminapi/deliveryTerm';

      const container = await api.fetch<ContainerDto>(requestUri, 'GET', undefined, true);
      if (container.errors && container.errors.length > 0) {
        handleCatchServerError(container.errors);
        return [];
      }
      if (container.data.deliveryTerms) {
        deliveryTerms = container.data.deliveryTerms;
      }
      return deliveryTerms;
    } catch (error) {
      handleCatchLocalError(error);
      return [];
    }
  };

  const createData = async function (term: DeliveryTermDto | undefined) {
    if (!term) {
      return;
    }
    try {
      const requestUri = '/adminapi/deliveryTerm';

      const container = await api.fetch<ContainerDto>(requestUri, 'POST', term, true);

      if (container.errors && container.errors.length > 0) {
        handleCatchServerError(container.errors);
        return;
      }
    } catch (error) {
      handleCatchLocalError(error);
    }
  };

  const deleteData = async function (termId: number) {
    try {
      const requestUri = `/adminapi/deliveryTerm/${termId}`;

      const container = await api.fetch<ContainerDto>(requestUri, 'DELETE', undefined, true);

      if (container.errors && container.errors.length > 0) {
        handleCatchServerError(container.errors);
        return;
      }
    } catch (error) {
      handleCatchLocalError(error);
    }
  };

  const updateData = async function (term: DeliveryTermDto | undefined) {
    if (!term) {
      return;
    }
    try {
      const requestUri = '/adminapi/deliveryTerm';

      const container = await api.fetch<ContainerDto>(requestUri, 'PATCH', term, true);
      if (container.errors && container.errors.length > 0) {
        handleCatchServerError(container.errors);
        return;
      }
    } catch (error) {
      handleCatchLocalError(error);
    }
  };

  return {
    loadData,
    createData,
    deleteData,
    updateData,
  };
}

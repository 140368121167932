<template>
  <div v-if="isLoading" class="absolute inset-x-1/2 inset-y-1/3 w-fit h-fit">
    <IcLoading />
  </div>
  <div v-else-if="activity" class="md:mx-[80px]">
    <div class="flex items-center space-x-4">
      <AgButton variant="ghost" @click="saveActivity">
        <template #icon>
          <IcSave />
        </template>
        {{ t('admin.save') }}
      </AgButton>
    </div>

    <div class="space-y-8">
      <div class="font-bold text-primary">{{ t('admin.name').toUpperCase() }}</div>
      <AgFormTextarea
        v-model="activity.name"
        :validation-state="!nameError"
        :error-message="nameErrorText"
        @update:model-value="resetValidation"
      />
    </div>
    <div class="space-y-8">
      <div class="font-bold text-primary">{{ t('admin.campaign').toUpperCase() }}</div>
      <AgSearchSelect
        :options="campaignsOptions"
        v-model="activity.campaign.id"
        @update:model-value="resetValidation"
      />
    </div>
    <div class="flex gap-12 items-center py-12">
      <div class="font-bold text-primary">{{ t('admin.dueDate').toUpperCase() }}</div>
      <AgFormInput type="date" v-model="date" :min="getMinDate()" @update:model-value="resetValidation" />
    </div>
  </div>
</template>

<script setup lang="ts">
  import type { ActivityDto } from '@/admin/domain/ActivityDto';
  import type { AgSearchSelectOption } from '@/components/library/search-select/AgSearchSelectOption';

  import IcLoading from '@/components/icons/IcLoading.vue';
  import IcSave from '@/components/icons/IcSave.vue';
  import AgButton from '@/components/library/button/AgButton.vue';
  import AgFormInput from '@/components/library/form-input/AgFormInput.vue';
  import AgFormTextarea from '@/components/library/form-textarea/AgFormTextarea.vue';
  import AgSearchSelect from '@/components/library/search-select/AgSearchSelect.vue';

  import { useActivities } from '@/admin/modules/activities/composables/useActivities';
  import { useTheHeader } from '@/composables/framework/useTheHeader';
  import { i18n } from '@/plugins/i18n';
  import { computed, onMounted, ref } from 'vue';
  import { useRouter } from 'vue-router';

  const { t } = i18n.global;
  const router = useRouter();
  const { createData, loadCampaigns } = useActivities();
  const theHeader = useTheHeader();

  theHeader.enabled(true);
  theHeader.visible(true);
  theHeader.title('admin.newActivity');

  const campaignsOptions = ref<Array<AgSearchSelectOption>>([]);
  const activity = ref<ActivityDto>({
    id: 0,
    name: '',
    agents: [],
    campaign: {
      id: 0,
      name: '',
    },
    dueDate: 0,
    completed: false,
  });
  const isLoading = ref<boolean>(false);
  const nameError = ref<boolean>(false);
  const nameErrorText = ref<string>('');

  const resetValidation = () => {
    nameError.value = false;
    nameErrorText.value = '';
  };

  const date = computed({
    get: () => {
      if (activity.value?.dueDate) {
        const date = new Date(activity.value.dueDate * 1000);
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const day = String(date.getDate()).padStart(2, '0');
        return `${year}-${month}-${day}`;
      }
      return getMinDate();
    },
    set: (newDate) => {
      if (newDate && activity.value) {
        const date = new Date(newDate);
        activity.value.dueDate = Math.floor(date.getTime() / 1000);
      }
    },
  });

  const getMinDate = function () {
    const today = new Date();
    const year = today.getFullYear();
    const month = String(today.getMonth() + 1).padStart(2, '0');
    const day = String(today.getDate()).padStart(2, '0');
    return `${year}-${month}-${day}`;
  };

  const saveActivity = async () => {
    resetValidation();
    if (!activity.value.name) {
      nameError.value = true;
      nameErrorText.value = t('admin.nameRequired');
      return;
    }

    if (activity.value) {
      await createData(activity.value);
      try {
        await router.push({
          path: '/admin/activities',
        });
      } catch (error) {
        console.error('Failed to navigate to activities:', error);
      }
    }
  };

  onMounted(async () => {
    const campaigns = await loadCampaigns();
    campaignsOptions.value = campaigns.map((c) => ({
      label: c.name,
      searchableString: c.name,
      value: c.id,
    }));
    activity.value.campaign = campaigns[0];
    activity.value.dueDate = Math.floor(Date.now() / 1000);
  });
</script>

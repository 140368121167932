import type { RouteRecordRaw } from 'vue-router';

import AdminDashboard from '@/admin/pages/admin-dashboard/AdminDashboard.vue';
import authAdminRequired from '@/middleware/authAdminRequired';
import authRequired from '@/middleware/authRequired';
import guestOnly from '@/middleware/guestOnly';
import DashboardPage from '@/pages/DashboardPage.vue';
import Login from '@/pages/LoginPage.vue';
import PageNotFound from '@/pages/_.vue';

export const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    redirect: '/dashboard',
    beforeEnter: authRequired,
  },
  {
    path: '/dashboard',
    component: DashboardPage,
    meta: { layout: 'DefaultLayout' },
    beforeEnter: authRequired,
  },
  {
    path: '/login',
    component: Login,
    meta: { layout: 'FullScreenLayout' },
    beforeEnter: guestOnly,
  },
  {
    path: '/admin',
    component: AdminDashboard,
    meta: { layout: 'AdminLayout' },
    beforeEnter: authAdminRequired,
  },
  {
    path: '/:pathMatch(.*)*',
    component: PageNotFound,
    meta: { layout: 'DefaultLayout' },
    beforeEnter: authRequired,
  },
];

<template>
  <AgModal v-if="internalValue" fit-width fit-height :has-header="false">
    <template #content>
      <div class="md:min-h-[120px]">
        <div v-if="props.actived" class="text-xl font-bold pb-16">
          {{ t('admin.deactivateUser') }}
        </div>
        <div v-else class="text-xl font-bold pb-16">
          {{ t('admin.activateUser') }}
        </div>
        <div v-if="isLoading">
          <IcLoading />
        </div>
      </div>
    </template>

    <template #footer>
      <AgButton variant="secondary" @click="closeModal" :disabled="isLoading">
        {{ t('admin.cancel') }}
      </AgButton>
      <AgButton variant="primary" @click="sendRequest" :disabled="isLoading">
        <span v-if="props.actived">{{ t('admin.deactivate') }}</span>
        <span v-else>{{ t('admin.activate') }}</span>
      </AgButton>
    </template>
  </AgModal>
</template>

<script setup lang="ts">
  import useUsers from '@/admin/modules/users/composables/useUsers';
  import IcLoading from '@/components/icons/IcLoading.vue';
  import AgButton from '@/components/library/button/AgButton.vue';
  import AgModal from '@/components/library/modal/AgModal.vue';

  import { i18n } from '@/plugins/i18n';
  import { computed, ref } from 'vue';

  const { t } = i18n.global;

  interface Props {
    userId: number | undefined;
    actived: boolean;
    modelValue: boolean;
  }
  const props = defineProps<Props>();

  const { activateUser, deactivateUser } = useUsers();

  const isLoading = ref<boolean>(false);

  const emit = defineEmits(['update:modelValue', 'update:user']);

  const internalValue = computed({
    get: () => {
      return props.modelValue;
    },
    set: (newValue) => {
      emit('update:modelValue', newValue);
    },
  });

  const closeModal = function () {
    emit('update:modelValue', false);
  };

  const sendRequest = async function () {
    if (props.actived) {
      await deactivateUserRequest();
    } else {
      await activateUserRequest();
    }
    emit('update:user');
  };

  const deactivateUserRequest = async () => {
    if (props.userId) {
      isLoading.value = true;
      await deactivateUser(props.userId);
      isLoading.value = false;
      closeModal();
    }
  };

  const activateUserRequest = async () => {
    if (props.userId) {
      isLoading.value = true;
      await activateUser(props.userId);
      isLoading.value = false;
      closeModal();
    }
  };
</script>

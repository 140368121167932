import type { CountryDto } from '@/domain/masterData/CountryDto';
import type { OrderAddressDto } from '@/domain/orderData/OrderAddressDto';
import type { OrderCustomerDto } from '@/domain/orderData/OrderCustomerDto';

import { config } from '@/config/config';
import type { OrderPaymentMethodDto } from '@/domain/orderData/OrderPaymentMethodDto';
import { i18n } from '@/plugins/i18n';
import { isValid as isValidIban } from 'iban';

const { t } = i18n.global;

export function required(value: string) {
  const ret = {
    state: false,
    msg: '',
  };
  if (!value) {
    ret.state = false;
    ret.msg = '';
  }
  return true;
}

export interface CustomerErrorObject {
  name: { state: boolean; msg: string };
  surname: { state: boolean; msg: string };
  webSiteUrl: { state: boolean; msg: string };
  fiscalCode: { state: boolean; msg: string };
  vatCode: { state: boolean; msg: string };
  sdiCode: { state: boolean; msg: string };
  language: { state: boolean; msg: string };
  iban: { state: boolean; msg: string };
}

export function initCustomerErrors(): CustomerErrorObject {
  return {
    name: { state: true, msg: '' },
    surname: { state: true, msg: '' },
    webSiteUrl: { state: true, msg: '' },
    fiscalCode: { state: true, msg: '' },
    vatCode: { state: true, msg: '' },
    sdiCode: { state: true, msg: '' },
    language: { state: true, msg: '' },
    iban: { state: true, msg: '' },
  };
}

export interface AddressErrorObject {
  name: { state: boolean; msg: string };
  street: { state: boolean; msg: string };
  city: { state: boolean; msg: string };
  nation: { state: boolean; msg: string };
  zip: { state: boolean; msg: string };
  region: { state: boolean; msg: string };
  phone: { state: boolean; msg: string };
  email: { state: boolean; msg: string };
  pec: { state: boolean; msg: string };
  addressType: { state: boolean; msg: string };
  district: { state: boolean; msg: string };
}

export function initAddressErrors(): AddressErrorObject {
  return {
    name: { state: true, msg: '' },
    street: { state: true, msg: '' },
    city: { state: true, msg: '' },
    nation: { state: true, msg: '' },
    zip: { state: true, msg: '' },
    region: { state: true, msg: '' },
    phone: { state: true, msg: '' },
    email: { state: true, msg: '' },
    pec: { state: true, msg: '' },
    addressType: { state: true, msg: '' },
    district: { state: true, msg: '' },
  };
}

export function validateCustomerForm(
  data: OrderCustomerDto | null | undefined,
  paymentMethod: OrderPaymentMethodDto | null
): CustomerErrorObject {
  const errors: CustomerErrorObject = initCustomerErrors();
  const fCodeRegexp = new RegExp(/^[A-Za-z]{6}[0-9]{2}[A-Za-z]{1}[0-9]{2}[A-Za-z]{1}[0-9]{3}[A-Za-z]{1}$/);
  const urlRegexp = new RegExp(/^(?:https?):\/\/(\w+:?\w*)?(\S+)(:\d+)?(\/|\/([\w#!:.?+=&%!\-/]))?$/);

  if (!data) {
    return errors;
  }
  if (!data.name) {
    errors.name.state = false;
    errors.name.msg = t('orders.nameRequired');
  }
  // IF OrderCustomerDto HAS ID NULL, IT IS A NEW CUSTOMER
  if (!data.id) {
    // NEW CUSTOMER VERIFICATION VAT CODE AND FISCAL CODE

    // VAT CODE VALIDATION
    if (!data.vatCode) {
      errors.vatCode.state = false;
      errors.vatCode.msg = t('orders.vatCodeRequired');
    }
    // FISCAL CODE VALIDATION
    if (data.fiscalCode && !fCodeRegexp.test(data.fiscalCode)) {
      errors.fiscalCode.state = false;
      errors.fiscalCode.msg = t('orders.fiscalCodeNotValid');
    }
  }
  // WEBSITE URL
  if (data.webSiteUrl && !urlRegexp.test(data.webSiteUrl)) {
    errors.webSiteUrl.state = false;
    errors.webSiteUrl.msg = t('orders.websiteNotValid');
  }
  // SDI CODE VERIFICATION
  if (data.sdiCode && data.sdiCode.length != 7) {
    errors.sdiCode.state = false;
    errors.sdiCode.msg = t('orders.sdiNotValid');
  }
  // LANGUAGE VERIFICATION
  const languages = config.company.availableLanguages;
  if (!data.language || (languages && Object.values(languages).findIndex((c) => c === data.language) === -1)) {
    errors.language.state = false;
    errors.language.msg = t('orders.languageRequired');
  }
  if (paymentMethod && paymentMethod.ibanRequired) {
    if (!data.iban) {
      errors.iban.state = false;
      errors.iban.msg = t('orders.ibanRequired');
    }
  }
  if (data.iban && data.iban.length > 0) {
    if (!isValidIban(data.iban)) {
      errors.iban.state = false;
      errors.iban.msg = t('orders.ibanNotValid');
    }
  }
  return errors;
}

export function validatePrivateCustomerForm(
  data: OrderCustomerDto | null | undefined,
  paymentMethod: OrderPaymentMethodDto | null,
  name: string,
  surname: string
): CustomerErrorObject {
  const errors: CustomerErrorObject = initCustomerErrors();
  const fCodeRegexp = new RegExp(/^[A-Za-z]{6}[0-9]{2}[A-Za-z]{1}[0-9]{2}[A-Za-z]{1}[0-9]{3}[A-Za-z]{1}$/);
  const urlRegexp = new RegExp(/^(?:https?):\/\/(\w+:?\w*)?(\S+)(:\d+)?(\/|\/([\w#!:.?+=&%!\-/]))?$/);

  if (!data) {
    return errors;
  }
  if (!name) {
    errors.name.state = false;
    errors.name.msg = t('orders.nameRequired');
  }
  if (!surname) {
    errors.surname.state = false;
    errors.surname.msg = t('orders.surnameRequired');
  }
  // WEBSITE URL
  if (data.webSiteUrl && !urlRegexp.test(data.webSiteUrl)) {
    errors.webSiteUrl.state = false;
    errors.webSiteUrl.msg = t('orders.websiteNotValid');
  }
  // IF OrderCustomerDto HAS ID NULL, IT IS A NEW CUSTOMER
  if (!data.id) {
    // FISCAL CODE VALIDATION FOR PRIVATE CUSTOMER
    if (!data.fiscalCode) {
      errors.fiscalCode.state = false;
      errors.fiscalCode.msg = t('orders.fiscalCodeRequired');
    }
    // FISCAL CODE VALIDATION
    if (data.fiscalCode && !fCodeRegexp.test(data.fiscalCode)) {
      errors.fiscalCode.state = false;
      errors.fiscalCode.msg = t('orders.fiscalCodeNotValid');
    }
  }
  // SDI CODE VERIFICATION
  if (data.sdiCode && data.sdiCode.length != 7) {
    errors.sdiCode.state = false;
    errors.sdiCode.msg = t('orders.sdiNotValid');
  }
  // LANGUAGE VERIFICATION
  const languages = config.company.availableLanguages;
  if (!data.language || (languages && Object.values(languages).findIndex((c) => c === data.language) === -1)) {
    errors.language.state = false;
    errors.language.msg = t('orders.languageRequired');
  }
  if (paymentMethod && paymentMethod.ibanRequired) {
    if (!data.iban) {
      errors.iban.state = false;
      errors.iban.msg = t('orders.ibanRequired');
    }
  }
  if (data.iban && data.iban.length > 0) {
    if (!isValidIban(data.iban)) {
      errors.iban.state = false;
      errors.iban.msg = t('orders.ibanNotValid');
    }
  }
  return errors;
}

export function validateAddressForm(
  data: OrderAddressDto | null | undefined,
  countries: Array<CountryDto>
): AddressErrorObject {
  const errors: AddressErrorObject = initAddressErrors();
  const phoneRegexp = new RegExp(
    /^\+?\d{1,4}?[-.\s]?[/.\s]?\(?\d{1,3}?\)?[-.\s]?[/.\s]?\d{1,4}[-.\s]?\d{1,4}[-.\s]?\d{1,9}$/
  );
  const emailRegexp = new RegExp(
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  );
  const zipRegexp = new RegExp(/^[0-9]+$/);

  if (!data) {
    return errors;
  }
  // STREET VERIFICATION
  if (!data.street) {
    errors.street.state = false;
    errors.street.msg = t('orders.streetRequired');
  }
  // CITY VERIFICATION
  if (!data.city) {
    errors.city.state = false;
    errors.city.msg = t('orders.cityRequired');
  }
  // ZIP VERIFICATION
  if (!data.zip) {
    errors.zip.state = false;
    errors.zip.msg = t('orders.zipRequired');
  } else if (!zipRegexp.test(data.zip)) {
    errors.zip.state = false;
    errors.zip.msg = t('orders.zipNotValid');
  }
  // REGION VERIFICATION
  if (!data.region) {
    errors.region.state = false;
    errors.region.msg = t('orders.regionRequired');
  }
  // EMAIL VERIFICATION
  if (!data.email) {
    errors.email.state = false;
    errors.email.msg = t('orders.emailRequired');
  } else if (!emailRegexp.test(data.email)) {
    errors.email.state = false;
    errors.email.msg = t('orders.emailNotValid');
  }
  // PEC VERIFICATION
  if (data.pec && !emailRegexp.test(data.pec)) {
    errors.pec.state = false;
    errors.pec.msg = t('orders.pecNotValid');
  }
  // PHONE NUMBER VERIFICATION
  if (!data?.phoneNumber) {
    errors.phone.state = false;
    errors.phone.msg = t('orders.phoneRequired');
  } else if (!phoneRegexp.test(data.phoneNumber)) {
    errors.phone.state = false;
    errors.phone.msg = t('orders.phoneNotValid');
  }
  // NATION VERIFICATION
  if (!data.nation || (countries && countries.findIndex((c) => c.code === data.nation) === -1)) {
    errors.nation.state = false;
    errors.nation.msg = t('orders.nationRequired');
  }
  return errors;
}

export function isCustomerValid(
  data: OrderCustomerDto | null | undefined,
  paymentMethod: OrderPaymentMethodDto | null,
  isPrivateCustomer: boolean,
  name: string,
  surname: string
) {
  if (!data) {
    return false;
  }
  let errorsCustomer: CustomerErrorObject = {
    name: { state: true, msg: '' },
    surname: { state: true, msg: '' },
    webSiteUrl: { state: true, msg: '' },
    fiscalCode: { state: true, msg: '' },
    vatCode: { state: true, msg: '' },
    sdiCode: { state: true, msg: '' },
    language: { state: true, msg: '' },
    iban: { state: true, msg: '' },
  };
  if (!isPrivateCustomer) {
    errorsCustomer = validateCustomerForm(data, paymentMethod);
  } else {
    errorsCustomer = validatePrivateCustomerForm(data, paymentMethod, name, surname);
  }

  let ret = true;
  Object.values(errorsCustomer).forEach((value) => {
    if (!value.state) ret = false;
  });
  return ret;
}

export function isAddressValid(data: OrderAddressDto | null | undefined, countries: Array<CountryDto>) {
  if (!data) {
    return false;
  }
  const errorsAddress: AddressErrorObject = validateAddressForm(data, countries);
  let ret = true;
  Object.values(errorsAddress).forEach((value) => {
    if (!value.state) ret = false;
  });
  return ret;
}

<template>
  <div>
    <div class="tableHeader">
      <div class="headerColumn">{{ t('admin.title') }}</div>
      <div class="headerColumn">{{ t('admin.subtitle') }}</div>
      <div class="headerColumn">{{ t('admin.language') }}</div>
      <div class="headerColumn"></div>
    </div>

    <div v-for="communication in props.communications" :key="communication.id">
      <div class="table justify-start items-center" @click="viewCommunication(communication.id)">
        <div class="tableColumn">{{ communication.title }}</div>
        <div class="tableColumn">{{ communication.subtitle }}</div>
        <div class="tableColumn">{{ communication.language }}</div>
        <div class="tableColumn justify-end mr-32"><IcChevronForward /></div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
  import type { CommunicationDto } from '@/admin/domain/CommunicationDto';

  import IcChevronForward from '@/components/icons/IcChevronForward.vue';

  import { i18n } from '@/plugins/i18n';
  import { useRouter } from 'vue-router';

  interface Props {
    communications: Array<CommunicationDto>;
  }

  const props = defineProps<Props>();

  const { t } = i18n.global;

  const router = useRouter();

  const viewCommunication = async (communicationId: number) => {
    try {
      await router.push({
        name: '/communication',
        params: { communicationId: communicationId },
      });
    } catch (error) {
      console.error('Failed to navigate to communication:', error);
    }
  };
</script>

<style scoped>
  .head {
    @apply bg-neutral-100 w-full h-[80px] px-4 flex justify-between items-center;
  }

  .tableHeader {
    @apply grid auto-cols-fr grid-flow-col-dense gap-12 py-8 font-light;
  }

  .headerColumn {
    @apply flex items-center  font-bold text-xl text-success;
  }

  .table:hover {
    @apply bg-neutral-100;
  }

  .tableColumn {
    @apply flex items-center;
  }
  .table {
    @apply grid auto-cols-fr grid-flow-col-dense gap-12 border-t border-neutral-300 py-8 cursor-pointer;
  }
</style>

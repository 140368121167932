<template>
  <div v-if="isLoading" class="absolute inset-x-1/2 inset-y-1/3 w-fit h-fit">
    <IcLoading />
  </div>
  <div v-else-if="campaign" class="md:mx-[80px]">
    <div class="flex items-center space-x-4">
      <AgButton variant="ghost" @click="saveCampaign">
        <template #icon>
          <IcSave />
        </template>
        {{ t('admin.save') }}
      </AgButton>
    </div>

    <div class="space-y-8">
      <div class="font-bold text-primary">{{ t('admin.name').toUpperCase() }}</div>
      <AgFormTextarea
        v-model="campaign.name"
        :validation-state="!nameError"
        :error-message="nameErrorText"
        @update:model-value="resetValidation"
      />
    </div>
  </div>
  <div v-else>
    <AgAlert variant="info"> {{ t('admin.campaignCategoryNotFound') }} </AgAlert>
  </div>
</template>

<script setup lang="ts">
  import type { CampaignDto } from '@/admin/domain/CampaignDto';

  import IcLoading from '@/components/icons/IcLoading.vue';
  import IcSave from '@/components/icons/IcSave.vue';
  import AgAlert from '@/components/library/alert/AgAlert.vue';
  import AgButton from '@/components/library/button/AgButton.vue';
  import AgFormTextarea from '@/components/library/form-textarea/AgFormTextarea.vue';

  import { useCampaigns } from '@/admin/modules/campaigns/composables/useCampaigns';
  import { useTheHeader } from '@/composables/framework/useTheHeader';
  import { i18n } from '@/plugins/i18n';
  import { onMounted, ref } from 'vue';
  import { useRouter } from 'vue-router';

  const { t } = i18n.global;
  const router = useRouter();
  const { loadData, createData } = useCampaigns();
  const theHeader = useTheHeader();

  theHeader.enabled(true);
  theHeader.visible(true);
  theHeader.title('admin.newCampaign');

  const campaigns = ref<Array<CampaignDto>>([]);
  const nameError = ref<boolean>(false);
  const nameErrorText = ref<string>('');
  const isLoading = ref<boolean>(false);
  const campaign = ref<CampaignDto>({
    id: 0,
    name: '',
  });

  const resetValidation = () => {
    nameError.value = false;
    nameErrorText.value = '';
  };

  const saveCampaign = async () => {
    if (!campaign.value.name || campaign.value.name.length === 0) {
      nameError.value = true;
      nameErrorText.value = t('admin.nameRequired');
      return;
    }

    if (campaign.value && !nameError.value) {
      await createData(campaign.value);
      try {
        await router.push({
          path: '/admin/campaigns',
        });
      } catch (error) {
        console.error('Failed to navigate to campaigns:', error);
      }
    }
  };

  onMounted(async () => {
    campaigns.value = await loadData();
  });
</script>

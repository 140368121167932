import type { AuthUserDto } from '@/admin/domain/AuthUserDto';
import type { ContainerDto } from '@/admin/domain/data/ContainerDto';
import { useApi } from '@/composables/api/useApi';
import { useError } from '@/util/useError';

export default function useUsers() {
  const api = useApi();
  const { handleCatchServerError, handleCatchLocalError } = useError();

  const loadUsers = async function (): Promise<Array<AuthUserDto>> {
    let users: Array<AuthUserDto> = [];
    try {
      const requestUri = '/adminapi/users';

      const container = await api.fetch<ContainerDto>(requestUri, 'GET', undefined, true);
      if (container.errors && container.errors.length > 0) {
        handleCatchServerError(container.errors);
        return [];
      }
      if (container.data.authUsers) {
        users = container.data.authUsers as Array<AuthUserDto>;
      }
      return users;
    } catch (error) {
      handleCatchLocalError(error);
    }
    return users;
  };

  async function activateUser(userId: number) {
    try {
      const requestUri = '/adminapi/user';

      const body = new FormData();
      body.set('authUserId', `${userId}`);

      const container = await api.fetch<ContainerDto>(requestUri, 'POST', body, true);
      if (container.errors && container.errors.length > 0) {
        handleCatchServerError(container.errors);
        return;
      }
    } catch (error) {
      handleCatchLocalError(error);
    }
  }

  async function deactivateUser(userId: number) {
    const api = useApi();

    try {
      const requestUri = '/adminapi/user';

      const body = new FormData();
      body.set('authUserId', `${userId}`);

      const container = await api.fetch<ContainerDto>(requestUri, 'DELETE', body, true);
      if (container.errors && container.errors.length > 0) {
        handleCatchServerError(container.errors);
        return;
      }
    } catch (error) {
      handleCatchLocalError(error);
    }
  }

  return {
    activateUser,
    deactivateUser,
    loadUsers,
  };
}

<template>
  <div>
    <div class="tableHeader">
      <div class="headerColumn">{{ t('admin.title').toUpperCase() }}</div>
      <div class="headerColumn">{{ t('admin.category').toUpperCase() }}</div>
      <div class="headerColumn">{{ t('admin.language').toUpperCase() }}</div>
      <div class="headerColumn"></div>
    </div>
    <div v-for="download in props.downloads" :key="download.id" @click="viewDownload(download.id)">
      <div class="table justify-start items-center">
        <div class="tableColumn">{{ download.title }}</div>
        <div class="tableColumn">{{ getTranslatedText(download.category.title) }}</div>
        <div class="tableColumn">{{ download.language }}</div>
        <div class="tableColumn justify-end"><IcChevronForward /></div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
  import type { DownloadDto } from '@/admin/domain/DownloadDto';

  import IcChevronForward from '@/components/icons/IcChevronForward.vue';

  import { useTranslatedText } from '@/composables/useTransalteText';
  import { i18n } from '@/plugins/i18n';
  import { useRouter } from 'vue-router';

  const { getTranslatedText } = useTranslatedText();
  const router = useRouter();
  const { t } = i18n.global;

  interface Props {
    downloads: Array<DownloadDto>;
  }

  const props = defineProps<Props>();

  const viewDownload = async function (downloadId: number) {
    await router.push({
      name: '/download',
      params: { downloadId: downloadId },
    });
  };
</script>

<style scoped>
  .tableHeader {
    @apply grid auto-cols-fr grid-flow-col-dense gap-12 py-8 font-light;
  }
  .headerColumn {
    @apply flex items-center hover:cursor-pointer font-bold text-xl text-success;
  }
  .table:hover {
    @apply bg-neutral-100;
  }
  .tableColumn {
    @apply flex items-center;
  }
  .table {
    @apply grid auto-cols-fr grid-flow-col-dense gap-12 border-t border-neutral-300 py-8 cursor-pointer;
  }
</style>

<template>
  <div>
    <div class="tableHeader">
      <div class="headerColumn">{{ t('admin.code').toUpperCase() }}</div>
      <div class="headerColumn">{{ t('admin.description').toUpperCase() }}</div>
    </div>
    <div
      v-for="category in props.downloadCategories"
      :key="category.id"
      class="tableRow"
      @click="viewCategory(category.id)"
    >
      <div class="tableColumn titleColumn">{{ category.code }}</div>
      <div class="tableColumn descriptionColumn">{{ category.title[locale] }}</div>
      <div class="tableColumn iconColumn"><IcChevronForward /></div>
    </div>
  </div>
</template>

<script setup lang="ts">
  import type { DownloadCategoryDto } from '@/domain/DownloadCategoryDto';

  import IcChevronForward from '@/components/icons/IcChevronForward.vue';

  import { i18n } from '@/plugins/i18n';
  import { useRouter } from 'vue-router';

  interface Props {
    downloadCategories: Array<DownloadCategoryDto>;
  }

  const props = defineProps<Props>();

  const router = useRouter();
  const { t, locale } = i18n.global;

  const viewCategory = async function (categoryId: number) {
    await router.push({
      name: 'download-categories',
      params: { categoryId },
    });
  };
</script>

<style scoped>
  .tableHeader {
    @apply grid grid-cols-[2fr_3fr_auto] gap-12 py-8 font-light text-neutral-550;
  }

  .headerColumn {
    @apply flex items-center;
    color: #04a040;
    font-weight: bold;
  }

  .tableRow {
    @apply grid grid-cols-[2fr_3fr_auto] gap-12 border-t border-neutral-300 py-8 hover:bg-neutral-200 hover:cursor-pointer;
  }

  .tableColumn {
    @apply flex items-center;
  }

  .titleColumn {
    @apply col-start-1 col-end-2;
  }

  .descriptionColumn {
    @apply col-start-2 col-end-3;
  }

  .iconColumn {
    @apply col-start-3 col-end-4 justify-end;
  }
</style>

import type { ContainerDto } from '@/admin/domain/data/ContainerDto';
import { useApi } from '@/composables/api/useApi';
import { config } from '@/config/config';
import type { OfferDto } from '@/domain/offerData/OfferDto';
import type { OrderDto } from '@/domain/orderData/OrderDto';
import { useError } from '@/util/useError';

export function usePhotos() {
  const api = useApi();
  const { handleCatchServerError, handleCatchLocalError } = useError();

  const loadOrders = async function () {
    let orders: Array<OrderDto> = [];
    try {
      const requestUri = '/adminapi/photo/orders';

      const container = await api.fetch<ContainerDto>(requestUri, 'GET', undefined, true);
      if (container.errors && container.errors.length > 0) {
        handleCatchServerError(container.errors);
        return [];
      }
      if (container.data.orders) {
        orders = container.data.orders;
      }
      return orders;
    } catch (error) {
      handleCatchLocalError(error);
      return [];
    }
  };

  const loadOffers = async function () {
    let offers: Array<OfferDto> = [];
    try {
      const requestUri = '/adminapi/photo/offers';

      const container = await api.fetch<ContainerDto>(requestUri, 'GET', undefined, true);
      if (container.errors && container.errors.length > 0) {
        handleCatchServerError(container.errors);
        return [];
      }
      if (container.data.offers) {
        offers = container.data.offers;
      }
      return offers;
    } catch (error) {
      handleCatchLocalError(error);
      return [];
    }
  };

  async function downloadFile(photoName: string): Promise<Blob | undefined> {
    try {
      const url = `${config.admin.url}/adminapi/photo/download/${photoName}`;
      const blob = await api.download(url);

      if (blob instanceof Blob) {
        return blob;
      }
      return undefined;
    } catch (e) {
      handleCatchLocalError(e);
    }
  }

  async function openFile(photoName: string, download = false): Promise<Blob | undefined> {
    try {
      const url = `${config.admin.url}/adminapi/photo/download/${photoName}`;
      const blob = await api.download(url);

      if (blob instanceof Blob) {
        const link = document.createElement('a');
        const downloadUrl = window.URL.createObjectURL(blob);

        link.href = downloadUrl;

        if (download) {
          link.download = photoName;
        }
        link.target = '_blank';
        link.click();
        setTimeout(function () {
          window.URL.revokeObjectURL(downloadUrl);
        }, 333);
        return blob;
      }
      return undefined;
    } catch (e) {
      handleCatchLocalError(e);
    }
  }

  return {
    loadOrders,
    loadOffers,
    openFile,
    downloadFile,
  };
}

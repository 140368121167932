<template>
  <div>
    <div class="tableHeader">
      <div class="headerColumn">{{ t('admin.code') }}</div>
      <div class="headerColumn">{{ t('admin.description') }}</div>
      <div class="headerColumn"></div>
    </div>

    <div v-for="category in props.categories" :key="category.id">
      <div class="table justify-start items-center" @click="viewBusinessCategory(category.id)">
        <div class="tableColumn">{{ category.code }}</div>
        <div class="tableColumn">{{ category.title[locale] }}</div>
        <div class="tableColumn justify-end mr-32"><IcChevronForward /></div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
  import type { BusinessCategoryDto } from '@/admin/domain/BusinessCategoryDto';

  import IcChevronForward from '@/components/icons/IcChevronForward.vue';

  import { i18n } from '@/plugins/i18n';
  import { useRouter } from 'vue-router';

  interface Props {
    categories: Array<BusinessCategoryDto>;
  }

  const props = defineProps<Props>();

  const { t, locale } = i18n.global;

  const router = useRouter();

  const viewBusinessCategory = async (categoryId: number) => {
    try {
      await router.push({
        name: '/business-category',
        params: { categoryId: categoryId },
      });
    } catch (error) {
      console.error('Failed to navigate to business category:', error);
    }
  };
</script>

<style scoped>
  .head {
    @apply bg-neutral-100 w-full h-[80px] px-4 flex justify-between items-center;
  }

  .tableHeader {
    @apply grid auto-cols-fr grid-flow-col-dense gap-12 py-8 font-light;
  }

  .headerColumn {
    @apply flex items-center  font-bold text-xl text-success;
  }

  .table:hover {
    @apply bg-neutral-100;
  }

  .tableColumn {
    @apply flex items-center;
  }
  .table {
    @apply grid auto-cols-fr grid-flow-col-dense gap-12 border-t border-neutral-300 py-8 cursor-pointer;
  }
</style>

<template>
  <div class="tableHeader border-y border-neutral-500" :class="{ 'pl-8 pr-24': !props.agentId }">
    <div class="tableHeaderEntry border-r border-neutral-500">
      <div class="flex items-center" @click.stop="updateSorting(ColumnName.MONTH)">
        <span class="px-4">{{ t('statistics.month') }}</span>
        <span v-if="sorting.col === ColumnName.MONTH">
          <IcChevronDown v-if="sorting.order === Order.ASC" class="h-12 w-12" />
          <IcChevronUp v-if="sorting.order === Order.DESC" class="h-12 w-12" />
        </span>
        <span v-else class="h-12 w-12">&nbsp;</span>
      </div>
    </div>

    <div class="tableHeaderEntry border-r border-neutral-500">
      <div class="right" @click.stop="updateSorting(ColumnName.QUANTITY_TWO)">
        <span class="px-4">{{ t('statistics.quantity') }}</span>
        <span v-if="sorting.col === ColumnName.QUANTITY_TWO">
          <IcChevronDown v-if="sorting.order === Order.ASC" class="h-12 w-12" />
          <IcChevronUp v-if="sorting.order === Order.DESC" class="h-12 w-12" />
        </span>
        <span v-else class="h-12 w-12">&nbsp;</span>
      </div>

      <div class="right" @click.stop="updateSorting(ColumnName.AMOUNT_TWO)">
        <span class="px-4">{{ t('statistics.amount') }}</span>
        <span v-if="sorting.col === ColumnName.AMOUNT_TWO">
          <IcChevronDown v-if="sorting.order === Order.ASC" class="h-12 w-12" />
          <IcChevronUp v-if="sorting.order === Order.DESC" class="h-12 w-12" />
        </span>
        <span v-else class="h-12 w-12">&nbsp;</span>
      </div>

      <div class="right cursor-default">
        <span class="px-4">{{ t('statistics.customers') }}</span>
      </div>
    </div>

    <div class="tableHeaderEntry border-r border-neutral-500">
      <div class="right" @click.stop="updateSorting(ColumnName.QUANTITY_ONE)">
        <span class="px-4">{{ t('statistics.quantity') }}</span>
        <span v-if="sorting.col === ColumnName.QUANTITY_ONE">
          <IcChevronDown v-if="sorting.order === Order.ASC" class="h-12 w-12" />
          <IcChevronUp v-if="sorting.order === Order.DESC" class="h-12 w-12" />
        </span>
        <span v-else class="h-12 w-12">&nbsp;</span>
      </div>

      <div class="right" @click.stop="updateSorting(ColumnName.AMOUNT_ONE)">
        <span class="px-4">{{ t('statistics.amount') }}</span>
        <span v-if="sorting.col === ColumnName.AMOUNT_ONE">
          <IcChevronDown v-if="sorting.order === Order.ASC" class="h-12 w-12" />
          <IcChevronUp v-if="sorting.order === Order.DESC" class="h-12 w-12" />
        </span>
        <span v-else class="h-12 w-12">&nbsp;</span>
      </div>

      <div class="right cursor-default">
        <span class="px-4">{{ t('statistics.customers') }}</span>
      </div>
    </div>

    <div class="tableHeaderEntry">
      <div class="right" @click.stop="updateSorting(ColumnName.QUANTITY_DIFFERENCE)">
        <span class="px-4">{{ t('statistics.quantity') }}</span>
        <span v-if="sorting.col === ColumnName.QUANTITY_DIFFERENCE">
          <IcChevronDown v-if="sorting.order === Order.ASC" class="h-12 w-12" />
          <IcChevronUp v-if="sorting.order === Order.DESC" class="h-12 w-12" />
        </span>
        <span v-else class="h-12 w-12">&nbsp;</span>
      </div>

      <div class="right" @click.stop="updateSorting(ColumnName.AMOUNT_DIFFERENCE)">
        <span class="px-4">{{ t('statistics.amount') }}</span>
        <span v-if="sorting.col === ColumnName.AMOUNT_DIFFERENCE">
          <IcChevronDown v-if="sorting.order === Order.ASC" class="h-12 w-12" />
          <IcChevronUp v-if="sorting.order === Order.DESC" class="h-12 w-12" />
        </span>
        <span v-else class="h-12 w-12">&nbsp;</span>
      </div>

      <div class="right" @click.stop="updateSorting(ColumnName.PERCENTAGE)">
        <span class="px-4">{{ t('statistics.amount') }}&nbsp;%</span>
        <span v-if="sorting.col === ColumnName.PERCENTAGE">
          <IcChevronDown v-if="sorting.order === Order.ASC" class="h-12 w-12" />
          <IcChevronUp v-if="sorting.order === Order.DESC" class="h-12 w-12" />
        </span>
        <span v-else class="h-12 w-12">&nbsp;</span>
      </div>

      <div class="right cursor-default">
        <span class="px-4">{{ t('statistics.customers') }}</span>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
  import type { StatisticByMonthDto } from '@/domain/StatisticByMonthDto';

  import IcChevronDown from '@/components/icons/IcChevronDown.vue';
  import IcChevronUp from '@/components/icons/IcChevronUp.vue';

  import { i18n } from '@/plugins/i18n';
  import { Order } from '@/util/Order';
  import { Sorting } from '@/util/Sorting';
  import { ref, watch } from 'vue';

  const { t } = i18n.global;

  interface Props {
    statistics: Array<StatisticByMonthDto>;
    agentId: number | undefined;
  }
  const props = defineProps<Props>();

  enum ColumnName {
    QUANTITY_ONE = 'quantity1',
    AMOUNT_ONE = 'amount1',

    QUANTITY_TWO = 'quantity2',
    AMOUNT_TWO = 'amount2',

    MONTH = 'month',
    QUANTITY_DIFFERENCE = 'quantity_difference',
    AMOUNT_DIFFERENCE = 'amount_difference',
    PERCENTAGE = 'revenue',
  }
  const sorting = ref<Sorting>(new Sorting(ColumnName.MONTH, Order.ASC));

  const updateSorting = (colum: ColumnName) => {
    if (colum === ColumnName.MONTH) {
      if (sorting.value.order === Order.ASC) {
        props.statistics.sort((a, b) => a.month - b.month);
      } else {
        props.statistics.sort((a, b) => b.month - a.month);
      }
    } else if (colum === ColumnName.QUANTITY_ONE) {
      if (sorting.value.order === Order.ASC) {
        props.statistics.sort((a, b) => a.totalAmountPeriodOne - b.totalAmountPeriodOne);
      } else {
        props.statistics.sort((a, b) => b.totalAmountPeriodOne - a.totalAmountPeriodOne);
      }
    } else if (colum === ColumnName.QUANTITY_TWO) {
      if (sorting.value.order === Order.ASC) {
        props.statistics.sort((a, b) => a.totalAmountPeriodTwo - b.totalAmountPeriodTwo);
      } else {
        props.statistics.sort((a, b) => b.totalAmountPeriodTwo - a.totalAmountPeriodTwo);
      }
    } else if (colum === ColumnName.QUANTITY_DIFFERENCE) {
      if (sorting.value.order === Order.ASC) {
        props.statistics.sort(
          (a, b) => a.totalAmountPeriodOne - a.totalAmountPeriodTwo - (b.totalAmountPeriodOne - b.totalAmountPeriodTwo)
        );
      } else {
        props.statistics.sort(
          (a, b) => b.totalAmountPeriodOne - b.totalAmountPeriodTwo - (a.totalAmountPeriodOne - a.totalAmountPeriodTwo)
        );
      }
    } else if (colum === ColumnName.AMOUNT_ONE) {
      if (sorting.value.order === Order.ASC) {
        props.statistics.sort((a, b) => a.totalAmountPeriodOne - b.totalAmountPeriodOne);
      } else {
        props.statistics.sort((a, b) => b.totalAmountPeriodOne - a.totalAmountPeriodOne);
      }
    } else if (colum === ColumnName.AMOUNT_TWO) {
      if (sorting.value.order === Order.ASC) {
        props.statistics.sort((a, b) => a.totalAmountPeriodTwo - b.totalAmountPeriodTwo);
      } else {
        props.statistics.sort((a, b) => b.totalAmountPeriodTwo - a.totalAmountPeriodTwo);
      }
    } else if (colum === ColumnName.AMOUNT_DIFFERENCE) {
      if (sorting.value.order === Order.ASC) {
        props.statistics.sort(
          (a, b) => a.totalAmountPeriodOne - a.totalAmountPeriodTwo - (b.totalAmountPeriodOne - b.totalAmountPeriodTwo)
        );
      } else {
        props.statistics.sort(
          (a, b) => b.totalAmountPeriodOne - b.totalAmountPeriodTwo - (a.totalAmountPeriodOne - a.totalAmountPeriodTwo)
        );
      }
    } else if (colum === ColumnName.PERCENTAGE) {
      if (sorting.value.order === Order.ASC) {
        props.statistics.sort((a, b) => {
          if (a.totalAmountPeriodOne === 0) {
            return 1;
          }
          if (b.totalAmountPeriodOne === 0) {
            return -1;
          }
          const revenueA = (a.totalAmountPeriodTwo * 100) / a.totalAmountPeriodOne;
          const revenueB = (b.totalAmountPeriodTwo * 100) / b.totalAmountPeriodOne;

          return revenueA - revenueB;
        });
      } else {
        props.statistics.sort((a, b) => {
          if (a.totalAmountPeriodOne === 0) {
            return -1;
          }
          if (b.totalAmountPeriodOne === 0) {
            return 1;
          }
          const revenueA = (a.totalAmountPeriodTwo * 100) / a.totalAmountPeriodOne;
          const revenueB = (b.totalAmountPeriodTwo * 100) / b.totalAmountPeriodOne;

          return revenueB - revenueA;
        });
      }
    }
    sorting.value.col = colum;
    sorting.value.order = sorting.value.order === Order.DESC ? Order.ASC : Order.DESC;
  };

  watch(
    () => props.agentId,
    () => (sorting.value = new Sorting(ColumnName.MONTH, Order.ASC))
  );
</script>

<style scoped lang="scss">
  .tableHeader {
    @apply grid grid-cols-[100px_minmax(283px,333px)_minmax(283px,333px)_minmax(333px,1fr)] min-h-48;
    @apply text-neutral-550 cursor-pointer;
  }

  .tableHeaderEntry {
    @apply flex justify-between items-center gap-4;
  }

  .right {
    @apply flex justify-end items-center w-full px-4;
  }
</style>

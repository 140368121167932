import NewPaymentTermPage from '@/admin/modules/paymentTerms/pages/NewPaymentTermPage.vue';
import PaymentTermDetailPage from '@/admin/modules/paymentTerms/pages/PaymentTermDetailPage.vue';
import authRequired from '@/middleware/authRequired';
import type { RouteRecordRaw } from 'vue-router';
export const routes: Array<RouteRecordRaw> = [
  {
    path: '/detail/:termId',
    name: 'detail',
    component: PaymentTermDetailPage,
    meta: { layout: 'DefaultLayout' },
    beforeEnter: authRequired,
  },
  {
    path: '/new-payment-term',
    name: 'new-payment-term',
    component: NewPaymentTermPage,
    meta: { layout: 'AdminLayout' },
    beforeEnter: authRequired,
  },
];

export default routes;

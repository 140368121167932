<template>
  <div class="px-32 flex text-s font-light">
    <div class="flex flex-col justify-start w-full">
      <div>{{ t('loggedAsSidebar') }}:</div>
      <div class="truncate font-bold">{{ props.username }}</div>
    </div>
    <div class="flex items-center">
      <AgButton variant="ghost" @click="logout">
        <template #icon><IcLogout /></template>
      </AgButton>
      <AgButton variant="ghost" v-if="isAdmin" @click="toAdminSettings">
        <template #icon><IcSettings /></template>
      </AgButton>
    </div>
  </div>
</template>

<script setup lang="ts">
  import IcLogout from '@/components/icons/IcLogout.vue';
  import IcSettings from '@/components/icons/IcSettings.vue';
  import AgButton from '@/components/library/button/AgButton.vue';

  import { useAuthentication } from '@/composables/auth/useAuthentication';
  import { i18n } from '@/plugins/i18n';
  import { computed } from 'vue';
  import { useRoute, useRouter } from 'vue-router';

  const { role } = useAuthentication();

  const router = useRouter();
  const route = useRoute();

  const { t } = i18n.global;

  interface Props {
    username: string;
  }

  const props = withDefaults(defineProps<Props>(), {
    username: '',
  });

  const emit = defineEmits(['logout']);

  const logout = () => emit('logout');

  const isAdmin = computed(() => {
    return role.value === 'Admin' && !route.path.includes('/admin');
  });

  async function toAdminSettings() {
    await router.push({
      path: '/admin',
    });
  }
</script>

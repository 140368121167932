<template>
  <div v-if="order" class="md:mx-[80px] max-md:mx-16 mt-12 mb-48 min-w-fit">
    <OrderInfo />
    <div v-if="isNewCustomer()" class="flex gap-4 items-center">
      <div class="flex gap-4" @click="order.customer.isPrivate = false">
        <input
          type="radio"
          id="company"
          name="company"
          v-model="order.customer.isPrivate"
          :value="false"
          class="hover:cursor-pointer"
        />
        <label for="company" class="font-bold cursor-pointer">
          {{ t('orders.company') }}
        </label>
      </div>

      <div class="flex gap-4" @click="order.customer.isPrivate = true">
        <input
          type="radio"
          id="privateCustomer"
          name="privateCustomer"
          :value="true"
          v-model="order.customer.isPrivate"
          class="hover:cursor-pointer"
        />
        <label for="privateCustomer" class="font-bold cursor-pointer">
          {{ t('orders.isPrivateCustomer') }}
        </label>
      </div>
    </div>

    <div v-if="order.customer.isPrivate" class="flex flex-row gap-12">
      <AgFormGroup class="basis-1/3" :label="t('orders.customerName')" required>
        <AgFormInput
          v-model="customerName"
          :validation-state="validationCustomer.name.state"
          :errorMessage="validationCustomer.name.msg"
          :disabled="isDisable"
          @input="customerName = customerName.toUpperCase()"
        />
      </AgFormGroup>
      <AgFormGroup class="basis-1/3" :label="t('orders.surname')" required>
        <AgFormInput
          v-model="customerSurname"
          :validation-state="validationCustomer.surname.state"
          :errorMessage="validationCustomer.surname.msg"
          :disabled="isDisable"
          @input="customerSurname = customerSurname.toUpperCase()"
        />
      </AgFormGroup>
      <AgFormGroup class="basis-1/3" :label="t('orders.alternativeName')">
        <AgFormInput v-model="order.customer.alternativeName" />
      </AgFormGroup>
    </div>

    <div v-else class="flex flex-row gap-12">
      <AgFormGroup class="basis-1/2" :label="t('orders.customerName')" required>
        <AgFormInput
          v-model="order.customer.name"
          :validation-state="validationCustomer.name.state"
          :errorMessage="validationCustomer.name.msg"
          :disabled="isDisable"
          @input="order.customer.name = order.customer.name.toUpperCase()"
        />
      </AgFormGroup>
      <AgFormGroup class="basis-1/2" :label="t('orders.alternativeName')">
        <AgFormInput
          v-model="order.customer.alternativeName"
          @input="order.customer.alternativeName = (order.customer.alternativeName ?? '').toLocaleUpperCase()"
        />
      </AgFormGroup>
    </div>

    <div class="flex gap-12 max-md:flex-col py-4">
      <!-- CUSTOMER FISCAL CODE -->
      <AgFormGroup
        :label="t('orders.fiscalCode')"
        :class="privateCustomerClasses('basis-1/3')"
        :required="order.customer.isPrivate"
      >
        <AgFormInput
          v-model="order.customer.fiscalCode"
          :validation-state="validationCustomer.fiscalCode.state"
          :errorMessage="validationCustomer.fiscalCode.msg"
          :disabled="isDisable"
          @input="order.customer.fiscalCode = order.customer.fiscalCode.toUpperCase()"
        />
      </AgFormGroup>
      <!-- CUSTOMER VAT CODE -->
      <AgFormGroup v-if="!order.customer.isPrivate" class="basis-1/3" :label="t('orders.vatCode')" required>
        <AgFormInput
          v-model="order.customer.vatCode"
          :validation-state="validationCustomer.vatCode.state"
          :errorMessage="validationCustomer.vatCode.msg"
          :disabled="isDisable"
          @input="order.customer.vatCode = order.customer.vatCode.toUpperCase()"
        />
      </AgFormGroup>
      <!-- CUSTOMER WEBSITE URL -->
      <AgFormGroup v-if="!order.customer.isPrivate" class="basis-1/3" :label="t('orders.website')">
        <AgFormInput
          v-model="order.customer.webSiteUrl"
          :validation-state="validationCustomer.webSiteUrl.state"
          :errorMessage="validationCustomer.webSiteUrl.msg"
        />
      </AgFormGroup>
    </div>

    <div class="flex gap-12 max-md:flex-col py-4">
      <!-- ADDRESS STREET -->
      <AgFormGroup class="basis-1/3" :label="t('orders.street')" required>
        <AgFormInput
          v-model="order.invoiceAddress.street"
          :validation-state="validationAddress.street.state"
          :errorMessage="validationAddress.street.msg"
        />
      </AgFormGroup>
      <!-- ADDRESS CITY -->
      <AgFormGroup class="basis-1/3" :label="t('orders.city')" required>
        <AgFormInput
          v-model="order.invoiceAddress.city"
          :validation-state="validationAddress.city.state"
          :errorMessage="validationAddress.city.msg"
        />
      </AgFormGroup>
      <!-- ADDRESS DISTRICT -->
      <AgFormGroup class="basis-1/3" :label="t('orders.district')">
        <AgFormInput v-model="order.invoiceAddress.district" />
      </AgFormGroup>
    </div>

    <div class="flex max-sm:flex-col gap-12 py-4">
      <!-- COUNTRY -->
      <AgFormGroup class="basis-2/3" :label="t('orders.country')" required>
        <AgSearchSelect
          :options="countryOptions"
          v-model="order.invoiceAddress.nation"
          :validation-state="validationAddress.nation.state"
          :errorMessage="validationAddress.nation.msg"
        />
      </AgFormGroup>
      <!-- ZIP CODE -->
      <AgFormGroup class="basis-1/3" :label="t('orders.zipCode')" required>
        <AgFormInput
          v-model="order.invoiceAddress.zip"
          :validation-state="validationAddress.zip.state"
          :errorMessage="validationAddress.zip.msg"
        />
      </AgFormGroup>
      <!-- ADDRESS REGION -->
      <AgFormGroup class="basis-1/3" :label="t('orders.region')" required>
        <AgFormInput
          v-model="order.invoiceAddress.region"
          :validation-state="validationAddress.region.state"
          :errorMessage="validationAddress.region.msg"
        />
      </AgFormGroup>
    </div>

    <div class="flex gap-12 py-4 max-md:flex-col">
      <!-- PHONE -->
      <AgFormGroup class="basis-1/3" :label="t('orders.phone')" required>
        <AgFormInput
          type="tel"
          v-model="order.invoiceAddress.phoneNumber"
          :validation-state="validationAddress.phone.state"
          :errorMessage="validationAddress.phone.msg"
        />
      </AgFormGroup>
      <!-- MAIL -->
      <AgFormGroup class="basis-1/3" :label="t('orders.mail')" required>
        <AgFormInput
          type="email"
          v-model="order.invoiceAddress.email"
          :validation-state="validationAddress.email.state"
          :errorMessage="validationAddress.email.msg"
        />
      </AgFormGroup>
      <!-- PEC -->
      <AgFormGroup class="basis-1/3" :label="t('orders.pecMail')">
        <AgFormInput
          type="email"
          v-model="order.invoiceAddress.pec"
          :validation-state="validationAddress.pec.state"
          :errorMessage="validationAddress.pec.msg"
        />
      </AgFormGroup>
    </div>

    <div class="flex gap-12 py-4 max-sm:flex-col">
      <!-- CUSTOMER LANGUAGE -->
      <AgFormGroup class="basis-1/2" :label="t('orders.languageCustomer')" required>
        <AgSearchSelect
          v-model="order.customer.language"
          :options="langOptions"
          :validation-state="validationCustomer.language.state"
          :errorMessage="validationCustomer.language.msg"
        />
      </AgFormGroup>
      <!-- BUSINESS CATEGORY -->
      <AgFormGroup class="basis-1/2" :label="t('orders.section')">
        <AgSearchSelect :options="businessCategoryOptions" v-model="selectedBusinessCategory" />
      </AgFormGroup>
    </div>

    <div class="flex gap-12 py-4 max-md:flex-col">
      <!-- CUSTOMER SDI CODE -->
      <AgFormGroup v-if="!order.customer.isPrivate" class="basis-1/2" :label="t('orders.sdiCode')">
        <AgFormInput
          v-model="order.customer.sdiCode"
          :validation-state="validationCustomer.sdiCode.state"
          :errorMessage="validationCustomer.sdiCode.msg"
        />
      </AgFormGroup>
      <!-- CUSTOMER IBAN -->
      <AgFormGroup :class="privateCustomerClasses('basis-1/2')" label="IBAN" :required="isIbanRequired">
        <AgFormInput
          v-model="order.customer.iban"
          :validation-state="validationCustomer.iban.state"
          :errorMessage="validationCustomer.iban.msg"
        />
      </AgFormGroup>
    </div>
  </div>
</template>

<script setup lang="ts">
  import type { AgSearchSelectOption } from '@/components/library/search-select/AgSearchSelectOption';
  import type { CountryDto } from '@/domain/masterData/CountryDto';
  import type { OrderBusinessCategoryDto } from '@/domain/orderData/OrderBusinessCategoryDto';

  import AgFormGroup from '@/components/library/form-group/AgFormGroup.vue';
  import AgFormInput from '@/components/library/form-input/AgFormInput.vue';
  import AgSearchSelect from '@/components/library/search-select/AgSearchSelect.vue';
  import OrderInfo from '@/modules/orders/components/order-wizard/steps/OrderInfo.vue';

  import { useMasterData } from '@/composables/data/useMasterData';
  import { useTheFooter } from '@/composables/framework/useTheFooter';
  import { useTheHeader } from '@/composables/framework/useTheHeader';
  import { useTranslatedText } from '@/composables/useTransalteText';
  import { config } from '@/config/config';
  import {
    isAddressValid,
    isCustomerValid,
    validateAddressForm,
    validateCustomerForm,
    validatePrivateCustomerForm,
  } from '@/modules/orders/components/order-wizard/steps/customer/CustomerValidation';
  import { useOrderWizardStore } from '@/modules/orders/stores/useOrderWizardStore';
  import { i18n } from '@/plugins/i18n';
  import { storeToRefs } from 'pinia';
  import { computed, onBeforeMount, onMounted, ref, watch } from 'vue';

  const { t } = i18n.global;

  const { getTranslatedText } = useTranslatedText();

  const orderStore = useOrderWizardStore();
  const { order } = storeToRefs(orderStore);

  const { tables } = useMasterData();

  const theHeader = useTheHeader();
  theHeader.enabled(true);
  theHeader.visible(true);
  theHeader.title('orders.orders', 'orders.customer');

  const theFooter = useTheFooter();
  theFooter.enabled(false);
  theFooter.visible(false);

  const businessCategories = ref<Array<OrderBusinessCategoryDto>>([]);
  const countries = ref<Array<CountryDto>>([]);

  const emit = defineEmits(['update:modelValue']);

  const isDisable = computed(() => !!order.value?.customer.id);

  const customerName = ref<string>('');
  const customerSurname = ref<string>('');

  const isNewCustomer = function () {
    if (!order.value) {
      return false;
    }
    return order.value.customer.id === null;
  };

  const privateCustomerClasses = function (cls: string) {
    return {
      'w-full': order.value?.customer.isPrivate,
      [cls]: !order.value?.customer.isPrivate,
    };
  };

  const selectedBusinessCategory = computed({
    get: () => {
      const id = order.value?.invoiceAddress.businessCategory?.id;
      if (id && businessCategories.value.some((bc) => bc.id === id)) {
        return id;
      }
      return null;
    },
    set: (businessCategoryId) => {
      const businessCategory = businessCategories.value.find((bc) => bc.id === businessCategoryId);
      if (!businessCategory || !order.value) {
        return;
      }
      order.value.invoiceAddress.businessCategory = businessCategory;
      order.value.invoiceAddress.businessCategoryId = businessCategory.id;
    },
  });

  const isIbanRequired = computed(() => {
    return order.value?.paymentMethod?.ibanRequired ?? false;
  });

  const loadBusinessCategories = async function () {
    businessCategories.value = await tables.businessCategories.toArray();
  };

  const businessCategoryOptions = computed((): Array<AgSearchSelectOption> => {
    const categories = businessCategories.value.map((bc) => {
      return {
        value: bc.id,
        label: getTranslatedText(bc.title).charAt(0).toUpperCase() + getTranslatedText(bc.title).slice(1),
        searchableString: getTranslatedText(bc.title),
      };
    });
    const options: Array<AgSearchSelectOption> = [];
    options.push({ value: -1, label: '', searchableString: '', disabled: true });
    options.push(...categories);

    return options;
  });

  const loadCountries = async function () {
    countries.value = await tables.countries.toArray();
  };

  const countryOptions = computed((): Array<AgSearchSelectOption> => {
    return countries.value.map((c) => {
      return {
        value: c.code,
        label: getTranslatedText(c.title).toUpperCase(),
        searchableString: getTranslatedText(c.title),
      };
    });
  });

  const langOptions = ref<Array<AgSearchSelectOption>>(
    Object.values(config.company.availableLanguages).map((lang) => ({
      value: lang,
      label: t(`orders.language.${lang}`),
      searchableString: t(`orders.language.${lang}`),
    }))
  );

  const validationCustomer = computed(() => {
    validateAll();
    const isPrivate = order.value?.customer.isPrivate ?? false;
    if (isPrivate) {
      return validatePrivateCustomerForm(
        order.value?.customer,
        order.value?.paymentMethod ?? null,
        customerName.value,
        customerSurname.value
      );
    }
    return validateCustomerForm(order.value?.customer, order.value?.paymentMethod ?? null);
  });

  const validationAddress = computed(() => {
    validateAll();
    return validateAddressForm(order.value?.invoiceAddress, countries.value);
  });

  const validateAll = function () {
    emit('update:modelValue', {
      id: 0,
      error:
        isAddressValid(order.value?.invoiceAddress, countries.value) &&
        isCustomerValid(
          order.value?.customer,
          order.value?.paymentMethod ?? null,
          order.value?.customer.isPrivate ?? false,
          customerName.value,
          customerSurname.value
        ),
    });
  };

  watch(
    [customerName, customerSurname],
    () => {
      if (order.value && order.value.customer.isPrivate) {
        if (customerName.value || customerSurname.value) {
          order.value.customer.name = customerName.value + ',' + customerSurname.value;
        }
      }
    },
    { immediate: true }
  );

  onMounted(async () => {
    await loadBusinessCategories();

    if (order.value && order.value.customer.isPrivate) {
      const customerFullName = order.value.customer.name;
      customerName.value = customerFullName.split(',')[0];
      customerSurname.value = customerFullName.split(',')[1];
    }
  });

  onBeforeMount(async () => {
    await loadCountries();
  });
</script>

<style>
  /* Hide arrows on input type number*/
  /* Chrome, Safari, Edge, Opera */
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* Firefox */
  input[type='number'] {
    -moz-appearance: textfield;
    appearance: textfield;
  }
</style>

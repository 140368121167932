<template>
  <div>
    <div v-if="isLoading" class="absolute inset-x-1/2 inset-y-1/3 w-fit h-fit">
      <IcLoading />
    </div>
    <div v-else-if="currentCommunication" class="md:mx-[80px]">
      <div class="flex items-center space-x-4">
        <AgButton variant="ghost" @click="saveChanges">
          <template #icon>
            <IcSave />
          </template>
          {{ t('admin.save') }}
        </AgButton>
        <AgButton variant="ghost" @click="deleteElement(currentCommunication.id)">
          <template #icon>
            <IcDelete />
          </template>
          {{ t('admin.delete') }}
        </AgButton>
      </div>
      <div class="infoBox">
        <div class="infoboxTitle">{{ t('admin.title').toUpperCase() }}</div>
        <AgFormInput
          v-model="currentCommunication.title"
          :validation-state="titleValidation"
          :error-message="t('admin.titleRequired')"
          @input="resetValidation"
        />
      </div>
      <div class="infoBox">
        <div class="infoboxTitle">{{ t('admin.subtitle').toUpperCase() }}</div>
        <AgFormInput v-model="currentCommunication.subtitle" />
      </div>
      <div class="infoBox">
        <div class="infoboxTitle">{{ t('admin.text').toUpperCase() }}</div>
        <AgFormTextarea
          v-model="currentCommunication.text"
          rows="7"
          :validation-state="textValidation"
          :error-message="t('admin.textRequired')"
          @update:modelValue="resetValidation"
        />
      </div>
      <div class="infoBox">
        <div class="infoboxTitle">{{ t('admin.language').toUpperCase() }}</div>
        <AgFormSelect :options="langOptions" v-model="currentCommunication.language" />
      </div>
      <div v-if="currentCommunication.download" class="infoBox w-full">
        <div class="infoboxTitle">{{ t('admin.download').toUpperCase() }}</div>
        <div class="w-1/2">
          <AgSearchSelect :options="downloadOptions" v-model="currentCommunication.download.id" />
        </div>
      </div>
      <div v-if="currentCommunication.permissionGroup" class="infoBox w-full">
        <div class="infoboxTitle">{{ t('admin.permission').toUpperCase() }}</div>
        <div class="w-1/2">
          <AgSearchSelect :options="permissionGroupOptions" v-model="currentCommunication.permissionGroup.id" />
        </div>
      </div>
    </div>
    <div v-else class="py-32 px-64">
      <AgAlert variant="info">{{ t('admin.notFound') }}</AgAlert>
    </div>
  </div>
</template>

<script setup lang="ts">
  import type { CommunicationDto } from '@/admin/domain/CommunicationDto';
  import type { AgFormSelectOption } from '@/components/library/form-select/AgFormSelectOption';
  import type { AgSearchSelectOption } from '@/components/library/search-select/AgSearchSelectOption';

  import IcDelete from '@/components/icons/IcDelete.vue';
  import IcLoading from '@/components/icons/IcLoading.vue';
  import IcSave from '@/components/icons/IcSave.vue';
  import AgAlert from '@/components/library/alert/AgAlert.vue';
  import AgButton from '@/components/library/button/AgButton.vue';
  import AgFormInput from '@/components/library/form-input/AgFormInput.vue';
  import AgFormSelect from '@/components/library/form-select/AgFormSelect.vue';
  import AgFormTextarea from '@/components/library/form-textarea/AgFormTextarea.vue';
  import AgSearchSelect from '@/components/library/search-select/AgSearchSelect.vue';

  import { useCommunication } from '@/admin/modules/communication/composables/useCommunication';
  import { useTheHeader } from '@/composables/framework/useTheHeader';
  import { config } from '@/config/config';
  import { i18n } from '@/plugins/i18n';
  import { onBeforeMount, ref } from 'vue';
  import { useRoute, useRouter } from 'vue-router';

  const { t, locale } = i18n.global;
  const { loadData, getDownloads, getPermissionGroups, updateData, deleteData } = useCommunication();
  const route = useRoute();
  const router = useRouter();
  const theUsedHeader = useTheHeader();

  theUsedHeader.enabled(true);
  theUsedHeader.visible(true);
  theUsedHeader.title('admin.communicationDetails');

  const currentCommunication = ref<CommunicationDto>();
  const communications = ref<Array<CommunicationDto>>([]);
  const isLoading = ref<boolean>(true);
  const textValidation = ref<boolean>(true);
  const titleValidation = ref<boolean>(true);

  const langOptions = ref<Array<AgFormSelectOption>>(
    Object.values(config.company.availableLanguages).map((lang) => ({
      key: lang,
      label: `downloads.language.${lang}`,
    }))
  );

  const downloadOptions = ref<Array<AgSearchSelectOption>>([]);
  const permissionGroupOptions = ref<Array<AgSearchSelectOption>>([]);

  const setCurrentItem = () => {
    currentCommunication.value = communications.value.find((c) => c.id.toString() === route.params.communicationId);
    if (currentCommunication.value && !currentCommunication.value.download) {
      currentCommunication.value.download = {
        id: -1,
        fileName: '',
        language: locale.value,
        title: '',
        category: {
          id: -1,
          code: '',
          downloadsNumber: 0,
          title: {},
        },
        articleGroupId: null,
        articleId: null,
        permissionGroupId: null,
      };
    }
    if (currentCommunication.value && !currentCommunication.value.permissionGroup) {
      currentCommunication.value.permissionGroup = {
        id: -1,
        code: '',
        agents: [],
      };
    }
  };

  const resetValidation = () => {
    textValidation.value = true;
    titleValidation.value = true;
  };

  const saveChanges = async function () {
    if (
      currentCommunication.value &&
      (!currentCommunication.value.title || currentCommunication.value.title.length === 0)
    ) {
      titleValidation.value = false;
    }
    if (
      currentCommunication.value &&
      (!currentCommunication.value.text || currentCommunication.value.text.length === 0)
    ) {
      textValidation.value = false;
    }
    if (!titleValidation.value || !textValidation.value) {
      return;
    }
    isLoading.value = true;
    if (currentCommunication.value) {
      await updateData(currentCommunication.value);
      try {
        await router.push({
          path: '/admin/communication',
        });
      } catch (error) {
        console.error('Failed to navigate to delivery method:', error);
      }
      isLoading.value = false;
    }
  };

  const deleteElement = function (id: number) {
    deleteData(id).then(() => {
      router.push({ path: '/admin/communication' });
    });
  };

  const update = async function () {
    loadData()
      .then((methods) => {
        communications.value = methods ?? [];
        if (methods) {
          setCurrentItem();
        }
        isLoading.value = false;
      })
      .catch((error) => {
        console.log('Error loading communication: ', error);
        isLoading.value = false;
      });
  };

  onBeforeMount(async () => {
    await update();

    const downloads = await getDownloads();
    downloadOptions.value = downloads.map((download) => ({
      label: download.fileName,
      value: download.id,
      searchableString: download.fileName,
    }));
    downloadOptions.value.unshift({ label: 'admin.noChoice', value: -1, searchableString: 'admin.noChoice' });

    const permissionGroupsData = await getPermissionGroups();

    permissionGroupOptions.value = permissionGroupsData.map((group) => ({
      label: group.code,
      value: group.id,
      searchableString: group.code,
    }));
    permissionGroupOptions.value.unshift({ label: 'admin.noChoice', value: -1, searchableString: 'admin.noChoice' });
  });
</script>

<style scoped lang="scss">
  .infoboxTitle {
    @apply text-primary-light;
  }

  .name {
    @apply text-xl font-bold flex items-center;
  }

  .infoBox {
    @apply w-full justify-center my-12;
  }

  .table {
    @apply grid grid-cols-1 py-16 ml-32 space-x-0;
  }

  .tableColumn {
    @apply flex items-center min-w-0 ml-48 w-1/2 py-4 space-x-12;
  }
</style>

<template>
  <AgModal v-if="internalValue" fit-width>
    <template #header>
      <div class="text-2xl font-bold">{{ t('orders.overwriteDiscount') }}</div>
    </template>
    <template #content>
      <div class="overflow-y-auto md:min-w-[333px]">
        <!-- ITEM DISCOUNT -->
        <div class="flex flex-col" v-if="discounts">
          <div class="items-center gap-24 py-12" v-for="row in discounts.length" :key="row">
            <AgFormGroup :label="t('orders.discount') + '&nbsp' + row" class="w-full">
              <div class="flex">
                <div class="basis-11/12">
                  <AgFormInput
                    type="number"
                    v-model="discounts[row - 1]"
                    :validation-state="(validationDisocunts as any)[`discount${row}`].state"
                    :error-message="(validationDisocunts as any)[`discount${row}`].msg"
                    @update:model-value="getNetPriceForArticles(row)"
                    :disabled="discounts.some((discount, index) => row - 1 > index && discount === 100)"
                  />
                </div>
                <div class="basis-1/12 flex justify-end">
                  <AgButton variant="ghost" @click="removeDiscount(row - 1)">
                    <template #icon><IcClose /></template>
                  </AgButton>
                </div>
              </div>
            </AgFormGroup>
          </div>
        </div>
      </div>
    </template>
    <template #footer>
      <!-- BUTTON -->
      <AgButton variant="secondary" class="basis-1/2" @click="closeModal"> {{ t('orders.cancel') }} </AgButton>
      <AgButton class="basis-1/2" @click="applyDiscountAll" :disabled="isDisabled">{{ t('orders.apply') }}</AgButton>
    </template>
  </AgModal>
</template>

<script setup lang="ts">
  import IcClose from '@/components/icons/IcClose.vue';
  import AgButton from '@/components/library/button/AgButton.vue';
  import AgFormGroup from '@/components/library/form-group/AgFormGroup.vue';
  import AgFormInput from '@/components/library/form-input/AgFormInput.vue';
  import AgModal from '@/components/library/modal/AgModal.vue';

  import { useCommon } from '@/composables/useCommon';
  import { config } from '@/config/config';
  import type { OrderItemDto } from '@/domain/orderData/OrderItemDto';
  import {
    areModifiedDiscountsValid,
    validateAllDiscounts,
  } from '@/modules/orders/components/order-wizard/steps/shopping-cart/ShoppingCartDiscountsValidation';
  import { useOrderWizardStore } from '@/modules/orders/stores/useOrderWizardStore';
  import { i18n } from '@/plugins/i18n';
  import { storeToRefs } from 'pinia';
  import { computed, ref, watch } from 'vue';

  const { t } = i18n.global;

  const store = useOrderWizardStore();
  const { order } = storeToRefs(store);

  const { getMinPriceByArticle } = useCommon();

  interface Props {
    modelValue?: boolean;
  }
  const props = withDefaults(defineProps<Props>(), {
    modelValue: false,
  });

  const emit = defineEmits(['update:modelValue', 'update:orderItem']);

  const internalValue = computed({
    get: () => props.modelValue,
    set: (newValue) => emit('update:modelValue', newValue),
  });

  const netPrices = ref<{ [key: number]: Array<number> }>({});
  const minPrices = ref<Array<number>>([]);
  const maxItemDiscounts = ref<number>(config.company.maxItemDiscounts);

  const validationDisocunts = computed(() => {
    order.value?.items
      .filter((item) => !item.tradeAgreement.isPromotional && item.quantity && item.quantity > 0)
      .forEach(async (item, index) => {
        minPrices.value[index] = (await getMinPriceByArticle(item.article.id)) ?? 0;
      });

    emit('update:orderItem', {
      id: 1,
      error: areModifiedDiscountsValid(netPrices.value, discounts.value, minPrices.value),
    });
    return validateAllDiscounts(netPrices.value, discounts.value, minPrices.value);
  });

  const getUnitPrice = function (price: number, discounts: number[]) {
    let discountedPrice = price;
    if (discounts.length > 0) {
      for (let i = 0; i < discounts.length; i++) {
        const discount = discounts[i];
        if (discount > 0) {
          discountedPrice = discountedPrice * (1 - discount / 100);
        }
      }
    }
    return discountedPrice;
  };

  const getFinalPrice = function (price: number, discounts: number[], packagingSize: number, quantity: number) {
    const unitPrice = getUnitPrice(price, discounts);
    const finalPrice = unitPrice * packagingSize * quantity;

    return finalPrice;
  };

  const getNetPriceForArticles = function (row: number) {
    order.value?.items
      .filter((item) => !item.tradeAgreement.isPromotional && item.quantity && item.quantity > 0)
      .forEach((item, i) => {
        const discount = discounts.value[row - 1];
        let price = tradeAgreementPrice(item);

        if (
          netPrices.value[row - 1] &&
          netPrices.value[row - 1][i] !== undefined &&
          netPrices.value[row - 1][i] !== null
        ) {
          price = netPrices.value[row - 1][i];
        }

        if (discount > 0) {
          price = price * (1 - discount / 100);
        }

        if (!netPrices.value[row]) {
          netPrices.value[row] = [];
        }
        netPrices.value[row][i] = price;
      });
  };

  const tradeAgreementPrice = function (item: OrderItemDto) {
    if (item && item.quantity && item.quantity > 0) {
      if (item.isTradeAgreementPriceEdited) {
        return item.tradeAgreement.editedPrice ?? 0;
      }
      return item.tradeAgreement.price;
    }
    return 0;
  };

  const isDisabled = computed((): boolean => {
    if (order.value?.items.length === 0) {
      return true;
    }
    for (let i = 1; i < config.company.maxItemDiscounts + 1; i++) {
      type ObjectKey = keyof typeof validationDisocunts.value;
      const key = `discount${i}` as ObjectKey;
      if (validationDisocunts.value[key].state === false) {
        return true;
      }
    }
    return false;
  });

  const closeModal = function () {
    internalValue.value = false;
    clearDiscounts();
  };

  const clearDiscounts = function () {
    discounts.value = [];
    netPrices.value = {};
    minPrices.value = [];
  };

  const discounts = ref<Array<number>>([]);

  const applyDiscountAll = function () {
    // Move empty discounts to the end
    const discountArr = discounts.value.filter((i) => i);

    const fixedDiscounts: Record<string, number> = {};
    for (let i = 0; i < discounts.value.length; i++) {
      const key = `discount${i + 1}`;
      fixedDiscounts[key] = discountArr[i] || 0;
    }

    // Override discounts in order object!!!
    if (order.value) {
      order.value.items
        .filter((item) => !item.tradeAgreement.isPromotional && item.quantity && item.quantity > 0)
        .forEach((item) => {
          const price = tradeAgreementPrice(item);
          Object.assign(item, fixedDiscounts);
          const discounts: Array<number> = [];
          type ObjectKey = keyof typeof item;
          for (let i = 1; i <= maxItemDiscounts.value; i++) {
            const key = `discount${i}` as ObjectKey;
            if (item[key] && typeof item[key] === 'number') {
              discounts.push(item[key]);
            }
          }
          item.unitPrice = getUnitPrice(price, discounts);
          item.finalPrice = getFinalPrice(price, discounts, item.packaging.size, item.quantity ?? 0);
        });
      closeModal();
    }
  };

  const removeDiscount = function (row: number) {
    discounts.value[row] = 0;
    for (let i = row; i < discounts.value.length; i++) {
      getNetPriceForArticles(row + 1);
    }
  };

  watch(
    internalValue,
    (newValue) => {
      if (newValue) {
        discounts.value = new Array<number>(config.company.maxItemDiscounts).fill(0);
      }
    },
    { immediate: true }
  );
</script>

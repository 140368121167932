<template>
  <div class="py-12 md:mx-[80px] max-md:mx-16 flex items-center justify-between gap-12">
    <AgFormGroup :label="t('articles.type')" class="w-1/3">
      <AgSearchSelect v-model="typeId" :options="articleTypesOptions" :placeholder="t('articles.search')" />
    </AgFormGroup>
    <AgFormGroup :label="t('search')" class="w-2/3">
      <AgSearchInput v-model="internalValue" />
    </AgFormGroup>
  </div>
</template>

<script setup lang="ts">
  import AgFormGroup from '@/components/library/form-group/AgFormGroup.vue';
  import AgSearchInput from '@/components/library/search-input/AgSearchInput.vue';
  import AgSearchSelect from '@/components/library/search-select/AgSearchSelect.vue';
  import type { AgSearchSelectOption } from '@/components/library/search-select/AgSearchSelectOption';
  import { useTranslatedText } from '@/composables/useTransalteText';
  import type { ShoppingCartArticleDto } from '@/domain/masterData/ShoppingCartArticleDto';

  import { i18n } from '@/plugins/i18n';
  import { computed, onMounted, ref } from 'vue';
  import { useArticleFactory } from '../composables/useArticleFactory';

  const { t } = i18n.global;
  const { getTranslatedText } = useTranslatedText();
  const { getShoppingCartArticles } = useArticleFactory();

  interface Props {
    searchText: string;
    articleTypeId: number;
  }
  const props = defineProps<Props>();

  const emit = defineEmits(['update:searchText', 'update:articleTypeId']);

  const articleTypesOptions = ref<Array<AgSearchSelectOption>>([]);

  const internalValue = computed({
    get: () => props.searchText,
    set: (value) => emit('update:searchText', value),
  });

  const typeId = computed({
    get: () => props.articleTypeId,
    set: (value) => emit('update:articleTypeId', value),
  });

  onMounted(async () => {
    let articles: ShoppingCartArticleDto[] = [];

    const shoppingCartArticles = await getShoppingCartArticles();

    if (shoppingCartArticles.isPresent()) {
      articles = shoppingCartArticles.get();
    }

    articles.map((a) => {
      if (!articleTypesOptions.value.some((o) => o.value === a.articleType?.id) && a.articleType) {
        articleTypesOptions.value.push({
          value: a.articleType.id,
          label: getTranslatedText(a.articleType.title),
          searchableString: a.articleType.code + getTranslatedText(a.articleType?.title),
        });
      }
    });

    articleTypesOptions.value.sort((a, b) => a.label.localeCompare(b.label));

    articleTypesOptions.value.push({
      value: -1,
      label: t('articles.all'),
      searchableString: t('articles.all'),
    });
  });
</script>

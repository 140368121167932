<template>
  <div v-if="isLoading" class="absolute inset-x-1/2 inset-y-1/3 w-fit h-fit">
    <IcLoading />
  </div>
  <div v-else-if="category" class="md:mx-[80px]">
    <div class="flex items-center space-x-4">
      <AgButton variant="ghost" @click="saveCategory">
        <template #icon>
          <IcSave />
        </template>
        {{ t('admin.save') }}
      </AgButton>
      <AgButton variant="ghost" @click="deleteCurrentCategory">
        <template #icon>
          <IcDelete />
        </template>
        {{ t('admin.delete') }}
      </AgButton>
    </div>

    <div class="infoBox">
      <div class="infoboxTitle">{{ t('admin.code').toUpperCase() }}</div>
      <div class="content text-xl mt-4">
        {{ category?.code }}
      </div>
      <div class="infoboxTitle mt-8">{{ t('admin.numberDownloads').toUpperCase() }}</div>
      <div class="contentDownloads text-xl mt-4">
        {{ category?.downloadsNumber }}
      </div>
    </div>

    <div class="table mt-16">
      <span class="text-primary-light">{{ t('admin.languageDescription').toUpperCase() }}</span>
      <div v-for="selectedLang in Object.values(config.company.availableLanguages)" :key="selectedLang">
        <div class="tableColumn">
          <AgFormInput :placeholder="selectedLang" disabled />
          <AgFormInput v-model="category.title[selectedLang]" />
        </div>
      </div>
    </div>
  </div>
  <div v-else>
    <AgAlert variant="info"> {{ t('admin.downloadCategoryNotFound') }} </AgAlert>
  </div>
</template>

<script setup lang="ts">
  import IcDelete from '@/components/icons/IcDelete.vue';
  import IcLoading from '@/components/icons/IcLoading.vue';
  import IcSave from '@/components/icons/IcSave.vue';
  import AgAlert from '@/components/library/alert/AgAlert.vue';
  import AgButton from '@/components/library/button/AgButton.vue';
  import AgFormInput from '@/components/library/form-input/AgFormInput.vue';
  import type { DownloadCategoryDto } from '@/domain/DownloadCategoryDto';

  import { useDownloadCategories } from '@/admin/modules/downloadCategories/composables/useDownloadCategories';
  import { useTheHeader } from '@/composables/framework/useTheHeader';
  import { config } from '@/config/config';
  import { i18n } from '@/plugins/i18n';
  import { computed, onMounted, ref } from 'vue';
  import { useRoute, useRouter } from 'vue-router';

  const { t } = i18n.global;
  const route = useRoute();
  const router = useRouter();
  const { getDownloadCategoryList, deleteDownloadCategory, editDownloadCategory } = useDownloadCategories();
  const category = ref<DownloadCategoryDto | null>(null);
  const isLoading = ref(true);

  const theHeader = useTheHeader();
  theHeader.enabled(true);
  theHeader.visible(true);

  const categoryId = computed(() => {
    const catId = route.params.categoryId;
    if (Array.isArray(catId)) {
      return parseInt(catId[0]);
    }
    return parseInt(catId);
  });

  const saveCategory = async () => {
    if (category.value) {
      await editDownloadCategory(category.value);
      try {
        await router.push({
          path: '/admin/download-categories',
        });
      } catch (error) {
        console.error('Failed to navigate to download categories:', error);
      }
    }
  };

  const deleteCurrentCategory = async () => {
    if (category.value) {
      await deleteDownloadCategory(category.value.id);
      try {
        await router.push({
          path: '/admin/download-categories',
        });
      } catch (error) {
        console.error('Failed to navigate to download categories:', error);
      }
    }
  };

  const update = async function () {
    getDownloadCategoryList()
      .then((categories) => {
        category.value = categories.find((cat) => cat.id === categoryId.value) || null;
        isLoading.value = false;
      })
      .catch((error) => {
        console.log('Error loading delivery methods: ', error);
        isLoading.value = false;
      });
  };

  onMounted(async () => update());
</script>

<style scoped lang="scss">
  .infoboxTitle {
    @apply text-primary-light;
  }
  .infoBox {
    @apply my-12;
  }

  .table {
    @apply grid grid-cols-1 py-16 space-x-0;
  }

  .tableColumn {
    @apply flex items-center min-w-0 py-4 gap-4;
  }

  .content {
    margin-top: 16px;
  }

  .contentDownloads {
    margin-top: 16px;
  }
</style>

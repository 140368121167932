import type { RouteRecordRaw } from 'vue-router';

import { routes as activitiesRoutes } from '@/admin/modules/activities/routes';
import { routes as businessCategoriesRoutes } from '@/admin/modules/businessCategories/routes';
import { routes as campaignsRoutes } from '@/admin/modules/campaigns/routes';
import { routes as collaboratorRoutes } from '@/admin/modules/collaborators/routes';
import { routes as communicationRoutes } from '@/admin/modules/communication/routes';
import { routes as deliveryMethodsRoutes } from '@/admin/modules/deliveryMethods/routes';
import { routes as deliveryTermsRoutes } from '@/admin/modules/deliveryTerms/routes';
import { routes as AdminDownloadCategoriesRoutes } from '@/admin/modules/downloadCategories/routes';
import { routes as downloadRoutes } from '@/admin/modules/downloads/routes';
import { routes as orderOfferPhotosRoutes } from '@/admin/modules/orderOfferPhotos/routes';
import { routes as paymentMethodRoutes } from '@/admin/modules/paymentMethods/routes';
import { routes as paymentTermRoutes } from '@/admin/modules/paymentTerms/routes';
import { routes as permisssionGroupsRoutes } from '@/admin/modules/permissionGroups/routes';
import { routes as adminUserRoutes } from '@/admin/modules/users/routes';
import { routes as adminRoutes } from '@/admin/routes';
import { config } from '@/config/config';
import { routes as articlesModuleRoutes } from '@/modules/articles/routes';
import { routes as checklistsModuleRoutes } from '@/modules/checklists/routes';
import { routes as contingentsModuleRoutes } from '@/modules/contingents/routes';
import { routes as customersModuleRoutes } from '@/modules/customers/routes';
import { routes as downloadsModuleRoutes } from '@/modules/downloads/routes';
import { routes as messagesModuleRoutes } from '@/modules/messages/routes';
import { routes as offersModuleRoutes } from '@/modules/offers/routes';
import { routes as openItemsModuleRoutes } from '@/modules/open-items/routes';
import { routes as ordersModuleRoutes } from '@/modules/orders/routes';
import { routes as processedDataModuleRoutes } from '@/modules/processed-data/routes';
import { routes as statisticsModuleRoutes } from '@/modules/statistics/routes';
import { routes } from '@/routes';
import { createRouter, createWebHashHistory } from 'vue-router';

function addRoutes(newRoutes: Array<RouteRecordRaw>, path: string) {
  newRoutes.forEach((r) => {
    r.path = path + r.path;
    routes.push(r);
  });
}

if (config.company.modules.ARTICLES_OVERVIEW) {
  addRoutes(articlesModuleRoutes, '/articles');
}
if (config.company.modules.CUSTOMER_OVERVIEW) {
  addRoutes(customersModuleRoutes, '/customers');
}
if (config.company.modules.DOWNLOADS) {
  addRoutes(downloadsModuleRoutes, '/downloads');
}
if (config.company.modules.MESSAGES) {
  addRoutes(messagesModuleRoutes, '/messages');
}
if (config.company.modules.OFFER) {
  addRoutes(offersModuleRoutes, '/offers');
}
if (config.company.modules.ORDER) {
  addRoutes(ordersModuleRoutes, '/orders');
}
if (config.company.modules.OPEN_ITEMS) {
  addRoutes(openItemsModuleRoutes, '/open-items');
}
if (config.company.modules.PROCESSED_DATA) {
  addRoutes(processedDataModuleRoutes, '/processed-data');
}
if (config.company.modules.STATISTICS) {
  addRoutes(statisticsModuleRoutes, '/statistics');
}
if (config.company.modules.CONTINGENTS) {
  addRoutes(contingentsModuleRoutes, '/contingents');
}
if (config.company.modules.CHECKLISTS) {
  addRoutes(checklistsModuleRoutes, '/checklists');
}
addRoutes(adminRoutes, '/admin');

addRoutes(deliveryMethodsRoutes, '/admin/delivery-method');

addRoutes(deliveryTermsRoutes, '/admin/delivery-term');

addRoutes(paymentTermRoutes, '/admin/payment-term');

addRoutes(paymentMethodRoutes, '/admin/payment-methods');

addRoutes(adminUserRoutes, '/admin/users');

addRoutes(AdminDownloadCategoriesRoutes, '/admin/download-category');

addRoutes(communicationRoutes, '/admin/communication');

addRoutes(businessCategoriesRoutes, '/admin/business-categories');

addRoutes(collaboratorRoutes, '/admin/collaborator');

addRoutes(downloadRoutes, '/admin/download');

addRoutes(permisssionGroupsRoutes, '/admin/permissionGroups');

addRoutes(campaignsRoutes, '/admin/campaigns');

addRoutes(activitiesRoutes, '/admin/activities');

addRoutes(orderOfferPhotosRoutes, '/admin/photos');

export const router = createRouter({
  history: createWebHashHistory(),
  routes,
});

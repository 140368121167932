import type { BusinessCategoryDto } from '@/admin/domain/BusinessCategoryDto';
import type { ContainerDto } from '@/admin/domain/data/ContainerDto';
import { useApi } from '@/composables/api/useApi';
import { useError } from '@/util/useError';

export function useBusinnessCategories() {
  const api = useApi();
  const { handleCatchServerError, handleCatchLocalError } = useError();

  const loadData = async function () {
    let categories: Array<BusinessCategoryDto> = [];
    try {
      const requestUri = '/adminapi/businessCategories';

      const container = await api.fetch<ContainerDto>(requestUri, 'GET', undefined, true);
      if (container.errors && container.errors.length > 0) {
        handleCatchServerError(container.errors);
        return [];
      }

      if (container.data.businessCategories) {
        categories = container.data.businessCategories;
      }
      return categories;
    } catch (error) {
      handleCatchLocalError(error);
      return [];
    }
  };

  const createData = async function (category: BusinessCategoryDto | undefined) {
    if (!category) {
      return;
    }
    try {
      const requestUri = '/adminapi/businessCategories';

      const container = await api.fetch<ContainerDto>(requestUri, 'POST', category, true);

      if (container.errors && container.errors.length > 0) {
        handleCatchServerError(container.errors);
        return;
      }
    } catch (error) {
      handleCatchLocalError(error);
    }
  };

  const deleteData = async function (categoryId: number) {
    try {
      const requestUri = `/adminapi/businessCategories/${categoryId}`;

      const container = await api.fetch<ContainerDto>(requestUri, 'DELETE', undefined, true);

      if (container.errors && container.errors.length > 0) {
        handleCatchServerError(container.errors);
        return;
      }
    } catch (error) {
      handleCatchLocalError(error);
    }
  };

  const updateData = async function (category: BusinessCategoryDto | undefined) {
    if (!category) {
      return;
    }
    try {
      const requestUri = '/adminapi/businessCategories';

      const container = await api.fetch<ContainerDto>(requestUri, 'PATCH', category, true);
      if (container.errors && container.errors.length > 0) {
        handleCatchServerError(container.errors);
        return;
      }
    } catch (error) {
      handleCatchLocalError(error);
    }
  };

  return {
    createData,
    loadData,
    deleteData,
    updateData,
  };
}

<template>
  <div class="md:mx-[80px]">
    <div class="flex items-center space-x-4">
      <AgButton variant="ghost" @click="createNewDeliveryTerm">
        <template #icon>
          <IcSave />
        </template>
        {{ t('admin.save') }}
      </AgButton>
    </div>

    <div class="flex gap-32">
      <div class="infoBox">
        <div class="infoboxTitle">{{ t('admin.code').toUpperCase() }}</div>
        <div>
          <AgFormInput
            v-model="deliveryTerm.code"
            class="w-256"
            @input="resetValidation"
            :validation-state="!codeError"
            :error-message="codeErrorText"
          />
        </div>
      </div>

      <div class="infoBox">
        <span class="infoboxTitle">{{ t('admin.predefined').toUpperCase() }}</span>
        <div>
          <AgButton
            variant="ghost"
            class="h-24 w-24 flex items-center border-2"
            :class="{ 'border-success': !defaultError, 'border-infoRed': defaultError }"
            @click="toggleDefault"
          >
            <template #icon>
              <IcCheck v-if="deliveryTerm.default == true" class="pr-4" />
            </template>
          </AgButton>
          <AgAlert v-if="defaultError" variant="danger" class="w-256">
            {{ t('admin.deliveryTermStandardAlreadyChecked') }}
          </AgAlert>
        </div>
      </div>
    </div>

    <div class="table">
      <span class="text-primary-light">{{ t('admin.languageDescription').toUpperCase() }}</span>
      <div v-for="selectedLang in Object.values(config.company.availableLanguages)" :key="selectedLang">
        <div class="tableColumn">
          <AgFormInput :placeholder="selectedLang" disabled />
          <AgFormInput v-model="deliveryTerm.title[selectedLang]" />
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
  import type { DeliveryTermDto } from '@/admin/domain/DeliveryTermDto';

  import IcCheck from '@/components/icons/IcCheck.vue';
  import IcSave from '@/components/icons/IcSave.vue';
  import AgAlert from '@/components/library/alert/AgAlert.vue';
  import AgButton from '@/components/library/button/AgButton.vue';
  import AgFormInput from '@/components/library/form-input/AgFormInput.vue';

  import { useDeliveryTerms } from '@/admin/modules/deliveryTerms/composables/useDeliveryTerms';
  import { useTheHeader } from '@/composables/framework/useTheHeader';
  import { config } from '@/config/config';
  import { i18n } from '@/plugins/i18n';
  import { onMounted, ref } from 'vue';
  import { useRouter } from 'vue-router';

  const { t } = i18n.global;
  const theUsedHeader = useTheHeader();
  const { createData, loadData } = useDeliveryTerms();
  const router = useRouter();

  theUsedHeader.enabled(true);
  theUsedHeader.visible(true);
  theUsedHeader.saveVisible(true);
  theUsedHeader.title('admin.newDeliveryTerm');

  const defaultError = ref<boolean>(false);
  const codeError = ref<boolean>(false);
  const codeErrorText = ref<string>('');

  const deliveryTerm = ref<DeliveryTermDto>({
    code: '',
    title: {},
    default: false,
    id: -1,
  });
  const deliveryTerms = ref<Array<DeliveryTermDto>>([]);

  const toggleDefault = function () {
    resetValidation();

    if (deliveryTerm.value) {
      deliveryTerm.value.default = !deliveryTerm.value.default;
    }
  };

  const resetValidation = function () {
    codeError.value = false;
    codeErrorText.value = '';
    defaultError.value = false;
  };

  const createNewDeliveryTerm = async function () {
    resetValidation();

    if (deliveryTerm.value?.default) {
      if (deliveryTerms.value.some((term) => term.default && term.id !== deliveryTerm.value?.id)) {
        defaultError.value = true;
      }
    }

    if (deliveryTerm.value?.code.length == 0) {
      codeError.value = true;
      codeErrorText.value = t('admin.codeRequired');
    }

    if (
      deliveryTerms.value.some(
        (term) =>
          term.code.toLowerCase() === deliveryTerm.value?.code.toLowerCase() && term.id !== deliveryTerm.value?.id
      )
    ) {
      codeError.value = true;
      codeErrorText.value = t('admin.codeAlreadyExists');
    }
    if (!defaultError.value && !codeError.value) {
      sendRequestForCreate();
    }
  };

  const sendRequestForCreate = async function () {
    await createData(deliveryTerm.value);

    try {
      await router.push({
        path: '/admin/delivery-terms',
      });
    } catch (error) {
      console.error('Failed to navigate to delivery terms:', error);
    }
  };

  onMounted(async () => {
    deliveryTerms.value = await loadData();
  });
</script>

<style scoped lang="scss">
  .infoboxTitle {
    @apply text-primary-light;
  }
  .infoBox {
    @apply w-1/2 justify-center my-12 max-w-768;
  }

  .table {
    @apply grid grid-cols-1 py-16 space-x-0;
  }

  .tableColumn {
    @apply flex items-center min-w-0 py-4 gap-4;
  }
</style>

<template>
  <AgBox variant="accent">
    <div class="lg:flex">
      <div :class="checklistClasses">
        <div class="flex justify-between">
          <div>
            <div>
              <span class="font-bold">{{ t('checklists.dueDate') }}:&nbsp;</span>
              <span :class="{ 'font-bold text-infoRed': isExpired }">{{ formatDate(props.activity.dueDate) }}</span>
            </div>
            <div>
              <span class="font-bold">{{ t('checklists.category') }}:&nbsp;</span>
              <span>{{ props.activity.category }}</span>
            </div>
            <div>
              <span class="font-bold">{{ t('checklists.agent') }}:&nbsp;</span>
              <span>{{ props.activity.agentName }}</span>
            </div>
          </div>

          <div class="flex px-12 cursor-pointer" @click.stop="changeCompletation()">
            <span class="font-bold">{{ t('checklists.status') }}:&nbsp;</span>
            <IcCheck v-if="isCompleted" class="fill-success h-24 w-24 cursor-pointer" />
            <IcClose v-else class="text-danger h-24 w-24 cursor-pointer" />
          </div>
        </div>

        <div class="my-24">
          <span class="font-bold">{{ t('checklists.description') }}:&nbsp;</span>
          <div class="mr-24">
            <AgFormTextarea v-model="props.activity.description" disabled rows="8" />
          </div>
        </div>

        <div v-if="!hasCustomerActivities" class="my-24">
          <span class="font-bold">{{ t('checklists.comment') }}:&nbsp;</span>
          <div class="mr-24">
            <AgFormTextarea v-model="props.activity.comment" :disabled="!isEditable" />
          </div>
        </div>
        <div>
          <span class="font-bold">{{ t('checklists.lastEditDate') }}:&nbsp;</span>
          <span>{{ formatDateTime(props.activity.lastEditDate) }}</span>
        </div>
        <div v-if="!isEditable">
          <span class="text-infoRed">{{ t('checklists.notEditable') }}</span>
        </div>
      </div>

      <div
        v-if="props.activity.customers && props.activity.customers.length > 0"
        class="max-lg:border-t max-lg:py-8 lg:border-l lg:px-8 border-neutral-550 w-full lg:w-1/2"
      >
        <div class="flex items-center w-full gap-32">
          <AgSearchInput class="w-full" v-model="search" :placeholder="t('checklists.searchCustomer')" />
          <div class="cursor-pointer sorting-link" :class="{ active: isSorting }" @click="updateSorting()">
            <IcUpRed v-if="sorting.order === Order.ASC" class="h-24 w-24" />
            <IcDownRed v-else class="h-24 w-24" />
          </div>
        </div>
        <div class="max-h-[calc(30vh)] overflow-y-auto">
          <CustomerActivity
            v-for="(customerActivity, index) in displayedItems"
            :key="customerActivity.agentCustomerActivityId"
            :customerActivity="customerActivity"
            :isEditable="isEditable"
            class="py-4"
            :class="{ 'border-b border-neutral-550': index !== displayedItems.length - 1 }"
          />
        </div>
      </div>
    </div>
  </AgBox>
</template>

<script setup lang="ts">
  import type { ChecklistCustomerDto } from '@/domain/ChecklistCustomerDto';
  import type { ChecklistDto } from '@/domain/ChecklistDto';

  import IcCheck from '@/components/icons/IcCheck.vue';
  import IcClose from '@/components/icons/IcClose.vue';
  import AgBox from '@/components/library/box/AgBox.vue';
  import AgFormTextarea from '@/components/library/form-textarea/AgFormTextarea.vue';
  import AgSearchInput from '@/components/library/search-input/AgSearchInput.vue';
  import CustomerActivity from '@/modules/checklists/components/CustomerActivity.vue';

  import IcDownRed from '@/components/icons/IcDownRed.vue';
  import IcUpRed from '@/components/icons/IcUpRed.vue';
  import { useDateFormatting } from '@/composables/useDateFormatting';
  import { useHierarchy } from '@/composables/useHierarchy';
  import { i18n } from '@/plugins/i18n';
  import { Order } from '@/util/Order';
  import { Sorting } from '@/util/Sorting';
  import { computed, onMounted, ref, watch } from 'vue';

  const { t } = i18n.global;
  const { formatDate, formatDateTime } = useDateFormatting();
  const { getAllHierarchy } = useHierarchy();

  interface Props {
    activity: ChecklistDto;
  }
  const props = defineProps<Props>();

  const search = ref<string>('');
  const sorting = ref<Sorting>(new Sorting('default', Order.DESC));
  const displayedItems = ref<Array<ChecklistCustomerDto>>([]);
  const isSorting = ref<boolean>(false);
  const userId = ref<number>();
  const isEmployee = ref<boolean>(false);

  const checklistClasses = computed(() => ({
    'w-full': !props.activity.customers || props.activity.customers.length === 0,
    'basis-2/3': props.activity.customers && props.activity.customers.length > 0,
  }));

  const isCompleted = computed(() => {
    if (!props.activity.customers || props.activity.customers.length === 0) {
      return props.activity.completed;
    }
    return props.activity.customers?.every((customerActivity) => customerActivity.completed);
  });

  const hasCustomerActivities = computed(() => {
    return props.activity.customers && props.activity.customers.length > 0;
  });

  const isEditable = computed(() => {
    if (isEmployee.value) {
      return false;
    }
    return userId.value === props.activity.agentId;
  });

  const filterCustomerActivities = function () {
    if (!props.activity.customers || props.activity.customers.length === 0) {
      return;
    }
    displayedItems.value = props.activity.customers.filter((customerActivity) => {
      return (
        customerActivity.customerName.toLowerCase().includes(search.value.toLowerCase()) ||
        customerActivity.customerCode.toLowerCase().includes(search.value.toLowerCase())
      );
    });
  };

  const updateSorting = function () {
    if (sorting.value.order === Order.ASC) {
      sorting.value.order = Order.DESC;
    } else {
      sorting.value.order = Order.ASC;
    }

    displayedItems.value = displayedItems.value.sort((a, b) => {
      if (sorting.value.order === Order.ASC) {
        return a.customerName.localeCompare(b.customerName);
      } else {
        return b.customerName.localeCompare(a.customerName);
      }
    });
    isSorting.value = true;
    setTimeout(() => {
      isSorting.value = false;
    }, 300);
  };

  const isExpired = computed(() => {
    return props.activity.dueDate * 1000 < Date.now();
  });

  const changeCompletation = function () {
    if (!isEditable.value) {
      return;
    }
    if (!props.activity.customers || props.activity.customers.length === 0) {
      props.activity.completed = !props.activity.completed;
    } else {
      props.activity.customers?.forEach((customerActivity) => {
        customerActivity.completed = !props.activity.completed;
      });
    }
  };

  watch(
    () => props.activity.customers,
    () => {
      props.activity.completed = isCompleted.value;
    },
    { immediate: true, deep: true }
  );

  watch(search, () => filterCustomerActivities());

  onMounted(async () => {
    displayedItems.value = props.activity.customers ?? [];
    const hierarchyList = await getAllHierarchy();
    if (hierarchyList[0].workerAuthUser) {
      userId.value = hierarchyList[0].workerAuthUser.id;
      isEmployee.value = true;
    } else {
      userId.value = hierarchyList[0].authUser.id;
    }
  });
</script>

<style scoped lang="scss">
  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }

    100% {
      transform: rotate(360deg);
    }
  }

  .sorting-link.active {
    svg {
      animation: spin 1s linear infinite;
    }
  }
</style>

import { useAuthentication } from '@/composables/auth/useAuthentication';
import type { NavigationGuardNext, RouteLocationNormalized } from 'vue-router';

export default function authAdminRequired(
  _to: RouteLocationNormalized,
  _from: RouteLocationNormalized,
  next: NavigationGuardNext
) {
  const { isAuthenticated, role } = useAuthentication();

  if (!isAuthenticated.value) {
    // Not authenticated
    return next('/login');
  } else if (role.value !== 'Admin') {
    // Authenticated, unauthorised
    return next('/');
  }
  return next();
}

import type { AuthUserDto } from '@/admin/domain/AuthUserDto';
import type { ContainerDto } from '@/admin/domain/data/ContainerDto';
import { useApi } from '@/composables/api/useApi';
import { useError } from '@/util/useError';

export function useCollaborators() {
  const api = useApi();
  const { handleCatchServerError, handleCatchLocalError } = useError();

  const loadData = async function (): Promise<Array<AuthUserDto>> {
    let collaborators: Array<AuthUserDto> = [];
    try {
      const requestUri = '/adminapi/collaborator';

      const container = await api.fetch<ContainerDto>(requestUri, 'GET', undefined, true);
      if (container.errors && container.errors.length > 0) {
        handleCatchServerError(container.errors);
        return [];
      }
      if (container.data.authUsers) {
        collaborators = container.data.authUsers;
      }
      return collaborators;
    } catch (error) {
      handleCatchLocalError(error);
      return [];
    }
  };

  const loadUsers = async function (): Promise<Array<AuthUserDto>> {
    let users: Array<AuthUserDto> = [];
    try {
      const requestUri = '/adminapi/collaborator/users';

      const container = await api.fetch<ContainerDto>(requestUri, 'GET', undefined, true);
      if (container.errors && container.errors.length > 0) {
        handleCatchServerError(container.errors);
        return [];
      }
      if (container.data.authUsers) {
        users = container.data.authUsers as Array<AuthUserDto>;
      }
      return users;
    } catch (error) {
      handleCatchLocalError(error);
    }
    return users;
  };

  const createData = async function (collaborator: AuthUserDto | undefined) {
    if (!collaborator) {
      return;
    }
    try {
      const requestUri = '/adminapi/collaborator';
      const container = await api.fetch<ContainerDto>(requestUri, 'POST', collaborator, true);
      if (container.errors && container.errors.length > 0) {
        handleCatchServerError(container.errors);
        return;
      }
    } catch (error) {
      handleCatchLocalError(error);
    }
  };

  const deleteData = async function (collaboratorId: number) {
    try {
      const requestUri = `/adminapi/collaborator/${collaboratorId}`;

      const container = await api.fetch<ContainerDto>(requestUri, 'DELETE', undefined, true);
      if (container.errors && container.errors.length > 0) {
        handleCatchServerError(container.errors);
        return;
      }
    } catch (error) {
      handleCatchLocalError(error);
    }
  };

  const updateData = async function (collaborator: AuthUserDto | undefined) {
    if (!collaborator) {
      return;
    }
    try {
      const requestUri = '/adminapi/collaborator';

      const container = await api.fetch<ContainerDto>(requestUri, 'PATCH', collaborator, true);
      if (container.errors && container.errors.length > 0) {
        handleCatchServerError(container.errors);
        return;
      }
    } catch (error) {
      handleCatchLocalError(error);
    }
  };

  return {
    loadData,
    createData,
    deleteData,
    updateData,
    loadUsers,
  };
}

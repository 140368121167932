<template>
  <div class="overflow-x-auto">
    <div class="mx-auto w-fit py-12">
      <div class="flex items-center">
        <div v-for="statisticYear in keys" :key="statisticYear">
          <!-- PERIODS HEADER -->
          <div
            class="periodsHeader"
            :class="{
              'border-r': statisticYear !== lastYearStatistic,
            }"
          >
            <div v-if="statisticYear === firstYear" class="flex">
              <div class="w-128 border-r border-neutral-500 min-h-32">&nbsp;</div>
              <div class="periodsColumn">
                <span class="px-24">{{ statisticYear }}</span>
              </div>
            </div>
            <div v-else class="periodsColumn">
              <span class="px-24">{{ statisticYear }}</span>
            </div>
          </div>

          <!-- TABLE HEADER -->
          <div class="flex">
            <div v-if="statisticYear === firstYear" class="monthCol" @click.stop="updateSorting('month')">
              <span class="px-4">{{ t('statistics.month') }}</span>
              <span v-if="sorting.col === 'month'">
                <IcChevronDown v-if="sorting.order === Order.ASC" class="h-12 w-12" />
                <IcChevronUp v-if="sorting.order === Order.DESC" class="h-12 w-12" />
              </span>
              <span v-else class="h-12 w-12">&nbsp;</span>
            </div>

            <div
              :class="{
                'border-r': statisticYear !== lastYearStatistic,
                tableHeaderFirstColumn: statisticYear === firstYear,
                tableHeader: statisticYear !== firstYear,
              }"
            >
              <div class="right" @click.stop="updateSorting(`quantity_${statisticYear}`)">
                <span class="px-4">{{ t('statistics.quantity') }}</span>
                <span v-if="sorting.col === `quantity_${statisticYear}`">
                  <IcChevronDown v-if="sorting.order === Order.ASC" class="h-12 w-12" />
                  <IcChevronUp v-if="sorting.order === Order.DESC" class="h-12 w-12" />
                </span>
                <span v-else class="h-12 w-12">&nbsp;</span>
              </div>

              <div class="right" @click.stop="updateSorting(`amount_${statisticYear}`)">
                <span class="px-4">{{ t('statistics.amount') }}</span>
                <span v-if="sorting.col === `amount_${statisticYear}`">
                  <IcChevronDown v-if="sorting.order === Order.ASC" class="h-12 w-12" />
                  <IcChevronUp v-if="sorting.order === Order.DESC" class="h-12 w-12" />
                </span>
                <span v-else class="h-12 w-12">&nbsp;</span>
              </div>

              <div class="right cursor-default">
                <span class="px-4">{{ t('statistics.customers') }}</span>
              </div>

              <div class="right" @click.stop="updateSorting(`revenue_${statisticYear}`)">
                <span class="px-4">{{ t('statistics.revenue') }}&nbsp;%</span>
                <span v-if="sorting.col === `revenue_${statisticYear}`">
                  <IcChevronDown v-if="sorting.order === Order.ASC" class="h-12 w-12" />
                  <IcChevronUp v-if="sorting.order === Order.DESC" class="h-12 w-12" />
                </span>
                <span v-else class="h-12 w-12">&nbsp;</span>
              </div>
            </div>
          </div>

          <!-- TABLE ROWS -->
          <div v-for="index in sortedMonths" :key="index">
            <div class="tableRow">
              <div v-if="statisticYear === firstYear" class="monthTitleEntry">
                {{ t(`statistics.month${index}`) }}
              </div>

              <div class="tableColumnEntry" :class="{ 'border-r': statisticYear !== lastYearStatistic }">
                <div class="right">
                  <span v-if="Number.isNaN(getTotalQuantity(statisticYear, index))">--</span>
                  <span v-else :class="numberClass(getTotalQuantity(statisticYear, index))">
                    {{ getTotalQuantity(statisticYear, index) }}
                  </span>
                </div>
                <div class="right">
                  <span v-if="Number.isNaN(getTotalAmount(statisticYear, index))">--</span>
                  <span v-else :class="numberClass(props.globalStatistics[statisticYear][index].totalAmount)">
                    {{ getTotalAmount(statisticYear, index) }}
                  </span>
                </div>
                <div class="right">
                  <span v-if="Number.isNaN(getTotalCustomers(statisticYear, index))">--</span>
                  <span v-else :class="numberClass(getTotalCustomers(statisticYear, index))">
                    {{ getTotalCustomers(statisticYear, index) }}
                  </span>
                </div>
                <div class="right">
                  <span v-if="Number.isNaN(getPercentage(statisticYear, index))">--</span>
                  <span v-else :class="numberClass(getPercentage(statisticYear, index))">
                    {{ formatNumberToPercentage(getPercentage(statisticYear, index)) }}
                  </span>
                </div>
              </div>
            </div>
          </div>

          <!-- SUMMARY ROW -->
          <div v-if="sumStatistic[statisticYear]" class="tableRow bg-neutral-100 font-bold">
            <div v-if="statisticYear === firstYear" class="monthTitleEntry">{{ t('statistics.sum') }}</div>

            <div class="tableColumnEntry" :class="{ 'border-r': statisticYear !== lastYearStatistic }">
              <div class="right" :class="numberClass(sumStatistic[statisticYear].totalQuantity)">
                {{ sumStatistic[statisticYear].totalQuantity }}
              </div>
              <div class="right" :class="numberClass(sumStatistic[statisticYear].totalAmount)">
                {{ formatNumberToEuro(sumStatistic[statisticYear].totalAmount) }}
              </div>
              <div class="right">{{ sumStatistic[statisticYear].customerCodes.size }}*</div>
              <div class="right" :class="numberClass(getSummaryPercentage(statisticYear))">
                {{ formatNumberToPercentage(getSummaryPercentage(statisticYear)) }}
              </div>
            </div>
          </div>
        </div>
      </div>
      <span>*{{ t('statistics.distinctCustomers') }}</span>
    </div>
  </div>
</template>
<script setup lang="ts">
  import type { StatisticEntry } from '@/domain/internal/StatisticEntry';

  import IcChevronDown from '@/components/icons/IcChevronDown.vue';
  import IcChevronUp from '@/components/icons/IcChevronUp.vue';

  import { useNumberFormatting } from '@/composables/useNumberFormatting';
  import { i18n } from '@/plugins/i18n';
  import { Order } from '@/util/Order';
  import { Sorting } from '@/util/Sorting';
  import { computed, ref } from 'vue';

  const { t } = i18n.global;

  interface Props {
    globalStatistics: { [key: string]: { [key: number]: StatisticEntry } };
  }
  const props = defineProps<Props>();

  const { formatNumberToPercentage, formatNumberToEuro } = useNumberFormatting();

  const sortedMonths = ref<number[]>([12, 11, 10, 9, 8, 7, 6, 5, 4, 3, 2, 1]);

  const sorting = ref<Sorting>(new Sorting('month', Order.ASC));

  const lastYearStatistic = computed(() => Object.keys(props.globalStatistics).pop());
  const firstYear = computed(() => Object.keys(props.globalStatistics)[0]);
  const keys = computed(() => Object.keys(props.globalStatistics));

  const numberClass = function (number: number): string {
    return number < 0 ? 'text-infoRed' : '';
  };

  const sumStatistic = computed(() => {
    const years = Object.keys(props.globalStatistics);
    const sumStatistics: { [key: string]: StatisticEntry } = {};

    for (const year of years) {
      if (!sumStatistics[year]) {
        sumStatistics[year] = {
          totalAmount: 0,
          totalQuantity: 0,
          totalCustomers: 0,
          customerCodes: new Set<string>(),
        };
      }
      for (const month in props.globalStatistics[year]) {
        sumStatistics[year].totalAmount += props.globalStatistics[year][month].totalAmount;
        sumStatistics[year].totalQuantity += props.globalStatistics[year][month].totalQuantity;
        sumStatistics[year].totalCustomers += props.globalStatistics[year][month].totalCustomers;
        props.globalStatistics[year][month].customerCodes.forEach((code) => {
          sumStatistics[year].customerCodes.add(code);
        });
      }
    }
    return sumStatistics;
  });

  const getTotalQuantity = function (year: string, index: number) {
    if (year && index && props.globalStatistics[year][index]) {
      return props.globalStatistics[year][index].totalQuantity;
    }
    return NaN;
  };

  const getTotalAmount = function (year: string, index: number) {
    if (year && index && props.globalStatistics[year][index]) {
      return formatNumberToEuro(props.globalStatistics[year][index].totalAmount);
    }
    return NaN;
  };

  const getTotalCustomers = function (year: string, index: number) {
    if (year && index && props.globalStatistics[year][index]) {
      return props.globalStatistics[year][index].totalCustomers;
    }
    return NaN;
  };

  const getPercentage = function (year: string, index: number) {
    if (year && index && props.globalStatistics[year][index]) {
      const keys = Object.keys(props.globalStatistics);
      const previousYear = keys[keys.indexOf(year) - 1];

      const currentYearStatistic = props.globalStatistics[year][index];

      let revenue = 100;
      if (previousYear && props.globalStatistics[previousYear][index]) {
        const previousYearStatistic = props.globalStatistics[previousYear][index];

        const currentTotalAmount = Math.abs(currentYearStatistic.totalAmount);
        const previousTotalAmount = Math.abs(previousYearStatistic.totalAmount);

        if (previousTotalAmount > 0) {
          revenue = ((currentTotalAmount - previousTotalAmount) * 100) / previousTotalAmount;
        }
      }
      return revenue;
    }
    return NaN;
  };

  const getSummaryPercentage = function (year: string) {
    const keys = Object.keys(props.globalStatistics);
    const previousYear = keys[keys.indexOf(year) - 1];

    let revenue = 100;
    if (previousYear && sumStatistic.value[previousYear]) {
      const previousYearStatistic = sumStatistic.value[previousYear];

      const currentTotalAmount = Math.abs(sumStatistic.value[year].totalAmount);
      const previousTotalAmount = Math.abs(previousYearStatistic.totalAmount);

      if (previousTotalAmount > 0) {
        revenue = ((currentTotalAmount - previousTotalAmount) * 100) / previousTotalAmount;
      }
    }
    return revenue;
  };

  const updateSorting = function (col: string) {
    const column = col.split('_')[0];
    const year = col.split('_')[1];

    if (column === 'month') {
      sorting.value.col = col;
      if (sorting.value.order === Order.ASC) {
        sortedMonths.value.sort((a, b) => a - b);
      } else if (sorting.value.order === Order.DESC) {
        sortedMonths.value.sort((a, b) => b - a);
      }
      sorting.value.order = sorting.value.order === Order.ASC ? Order.DESC : Order.ASC;
    }
    if (year && props.globalStatistics[year]) {
      if (column === 'quantity') {
        sorting.value.col = col;
        if (sorting.value.order === Order.ASC) {
          sortedMonths.value.sort((monthA, monthB) => {
            if (!props.globalStatistics[year][monthA]) {
              return 1;
            }
            if (!props.globalStatistics[year][monthB]) {
              return -1;
            }
            return (
              props.globalStatistics[year][monthA].totalQuantity - props.globalStatistics[year][monthB].totalQuantity
            );
          });
        } else if (sorting.value.order === Order.DESC) {
          sortedMonths.value.sort((monthA, monthB) => {
            if (!props.globalStatistics[year][monthA]) {
              return -1;
            }
            if (!props.globalStatistics[year][monthB]) {
              return 1;
            }
            return (
              props.globalStatistics[year][monthB].totalQuantity - props.globalStatistics[year][monthA].totalQuantity
            );
          });
        }
        sorting.value.order = sorting.value.order === Order.ASC ? Order.DESC : Order.ASC;
      }
      if (column === 'amount') {
        sorting.value.col = col;
        if (sorting.value.order === Order.ASC) {
          sortedMonths.value.sort((monthA, monthB) => {
            if (!props.globalStatistics[year][monthA]) {
              return 1;
            }
            if (!props.globalStatistics[year][monthB]) {
              return -1;
            }
            return props.globalStatistics[year][monthA].totalAmount - props.globalStatistics[year][monthB].totalAmount;
          });
        } else if (sorting.value.order === Order.DESC) {
          sortedMonths.value.sort((monthA, monthB) => {
            if (!props.globalStatistics[year][monthA]) {
              return -1;
            }
            if (!props.globalStatistics[year][monthB]) {
              return 1;
            }
            return props.globalStatistics[year][monthB].totalAmount - props.globalStatistics[year][monthA].totalAmount;
          });
        }
        sorting.value.order = sorting.value.order === Order.ASC ? Order.DESC : Order.ASC;
      } else if (column === 'revenue') {
        sorting.value.col = col;
        if (sorting.value.order === Order.ASC) {
          sortedMonths.value.sort((monthA, monthB) => {
            if (!props.globalStatistics[year][monthA]) {
              return 1;
            }
            if (!props.globalStatistics[year][monthB]) {
              return -1;
            }
            let previousYear: number = 0;
            try {
              const current = parseInt(year);
              previousYear = current - 1;
            } catch (e) {
              console.log('exception parsing year, ', e);
            }
            let revenueA = 0;
            if (!props.globalStatistics[previousYear]) {
              return 1;
            }
            if (!props.globalStatistics[previousYear][monthA]) {
              return 1;
            }
            if (!props.globalStatistics[previousYear][monthB]) {
              return -1;
            }
            const prevYearOfA = props.globalStatistics[previousYear];
            if (!prevYearOfA) {
              revenueA = 100;
            } else {
              revenueA =
                ((props.globalStatistics[year][monthA].totalAmount - prevYearOfA[monthA].totalAmount) * 100) /
                prevYearOfA[monthA].totalAmount;
            }

            let revenueB = 0;
            const prevYearOfB = props.globalStatistics[previousYear];
            if (!prevYearOfB) {
              revenueB = 100;
            } else {
              revenueB =
                ((props.globalStatistics[year][monthB].totalAmount - prevYearOfA[monthB].totalAmount) * 100) /
                prevYearOfA[monthB].totalAmount;
            }
            return revenueA - revenueB;
          });
        } else if (sorting.value.order === Order.DESC) {
          sortedMonths.value.sort((monthA, monthB) => {
            if (!props.globalStatistics[year][monthA]) {
              return -1;
            }
            if (!props.globalStatistics[year][monthB]) {
              return 1;
            }
            let previousYear: number = 0;
            try {
              const current = parseInt(year);
              previousYear = current - 1;
            } catch (e) {
              console.log('exception parsing year, ', e);
            }
            if (!props.globalStatistics[previousYear]) {
              return 1;
            }
            let revenueA = 0;
            if (!props.globalStatistics[previousYear][monthA]) {
              return -1;
            }
            if (!props.globalStatistics[previousYear][monthB]) {
              return 1;
            }
            const prevYearOfA = props.globalStatistics[previousYear];
            if (!prevYearOfA) {
              revenueA = 100;
            } else {
              revenueA =
                ((props.globalStatistics[year][monthA].totalAmount - prevYearOfA[monthA].totalAmount) * 100) /
                prevYearOfA[monthA].totalAmount;
            }

            let revenueB = 0;
            const prevYearOfB = props.globalStatistics[previousYear];
            if (!prevYearOfB) {
              revenueB = 100;
            } else {
              revenueB =
                ((props.globalStatistics[year][monthB].totalAmount - prevYearOfA[monthB].totalAmount) * 100) /
                prevYearOfA[monthB].totalAmount;
            }
            return revenueB - revenueA;
          });
        }
        sorting.value.order = sorting.value.order === Order.ASC ? Order.DESC : Order.ASC;
      }
    }
  };
</script>

<style scoped lang="scss">
  .monthCol {
    @apply flex items-center text-neutral-550 border-r border-y border-neutral-500 cursor-pointer w-128 min-h-48;
  }

  .tableHeaderFirstColumn {
    @apply grid grid-cols-[103px_minmax(103px,153px)_minmax(103px,153px)_minmax(103px,153px)];
    @apply text-neutral-550 border-y border-neutral-500 cursor-pointer min-h-48;
  }

  .tableHeader {
    @apply grid grid-cols-[103px_minmax(103px,153px)_minmax(103px,153px)_minmax(103px,153px)];
    @apply text-neutral-550 border-y border-neutral-500 cursor-pointer min-h-48;
  }

  .tableColumnEntry {
    @apply grid grid-cols-[103px_minmax(103px,153px)_minmax(103px,153px)_minmax(103px,153px)];
    @apply border-b border-neutral-500 min-h-48;
  }

  .tableRow {
    @apply flex items-center;
  }

  .monthTitleEntry {
    @apply flex items-center border-b border-r border-neutral-500 w-128 min-h-48;
  }

  .periodsHeader {
    @apply flex items-center border-neutral-500 min-h-32;
  }

  .periodsColumn {
    @apply grid grid-cols-[103px_minmax(103px,153px)_minmax(103px,153px)_minmax(103px,153px)] col-auto min-h-32;
    @apply text-black text-l font-bold items-center;
  }

  .right {
    @apply flex justify-end items-center px-8;
  }
</style>

<template>
  <div>
    <AgCollapsible showIcon disabled-auto-collapse :collapse="!props.customerActivity.completed">
      <template #header>
        <input
          type="checkbox"
          class="cursor-pointer"
          v-model="props.customerActivity.completed"
          @click.stop="changeStatus()"
          :disabled="!isEditable"
        />
        <span class="font-bold ml-8">
          {{ props.customerActivity.customerName }}&nbsp;({{ props.customerActivity.customerCode }})
        </span>
      </template>
      <template #default>
        <div class="ml-8">
          <span class="font-bold">{{ t('checklists.comment') }}:&nbsp;</span>
          <AgFormTextarea v-model="props.customerActivity.comment" :disabled="!isEditable" />
        </div>
      </template>
    </AgCollapsible>
  </div>
</template>

<script setup lang="ts">
  import AgCollapsible from '@/components/library/collapsible/AgCollapsible.vue';
  import AgFormTextarea from '@/components/library/form-textarea/AgFormTextarea.vue';
  import type { ChecklistCustomerDto } from '@/domain/ChecklistCustomerDto';
  import { i18n } from '@/plugins/i18n';

  const { t } = i18n.global;

  interface Props {
    customerActivity: ChecklistCustomerDto;
    isEditable: boolean;
  }
  const props = defineProps<Props>();

  const changeStatus = function () {
    if (!props.isEditable) {
      return;
    }
    props.customerActivity.completed = !props.customerActivity.completed;
  };
</script>

import authAdminRequired from '@/middleware/authAdminRequired';
import type { RouteRecordRaw } from 'vue-router';
import ActivityDetailPage from './pages/ActivityDetailPage.vue';
import CustomerSelectionsPage from './pages/CustomerSelectionsPage.vue';
import NewActivityPage from './pages/NewActivityPage.vue';

export const routes: Array<RouteRecordRaw> = [
  {
    path: '/:activityId/',
    name: 'activity',
    component: ActivityDetailPage,
    meta: { layout: 'AdminLayout' },
    beforeEnter: authAdminRequired,
  },
  {
    path: '/:activityId/customers',
    name: 'activity/customers',
    component: CustomerSelectionsPage,
    meta: { layout: 'AdminLayout' },
    beforeEnter: authAdminRequired,
  },
  {
    path: '/new-activity',
    name: '/new-activity',
    component: NewActivityPage,
    meta: { layout: 'AdminLayout' },
    beforeEnter: authAdminRequired,
  },
];

export default routes;

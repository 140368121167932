<template>
  <div class="tableHeader">
    <div class="headerColumn">{{ t('admin.code') }}</div>
    <div class="headerColumn">{{ t('admin.description') }}</div>
    <div class="headerColumn">{{ t('admin.predefined') }}</div>
    <div class="headerColumn">{{ t('admin.recieveDateObligatory') }}</div>
    <div class="headerColumn"></div>
  </div>
  <div v-for="delivery in props.deliveryMethods" :key="delivery.id">
    <div class="table justify-start items-center" @click="viewDeliveryMethod(delivery.id.toString())">
      <div class="tableColumn">{{ delivery.code }}</div>
      <div class="tableColumn">{{ delivery.title[locale] }}</div>
      <div class="tableColumn">
        <div v-if="delivery.default" class="icon">
          <IcCheck class="fill-black border-success border-2" />
        </div>
        <div class="fill-transparent" v-else>
          <IcCheck class="border-success border-2" />
        </div>
      </div>
      <div class="tableColumn">
        <div v-if="delivery.requestedReceiptDateRequired" class="icon">
          <IcCheck class="fill-black border-success border-2" />
        </div>
        <div class="fill-transparent" v-else>
          <IcCheck class="border-success border-2" />
        </div>
      </div>
      <div class="tableColumn justify-end mr-32"><IcChevronForward /></div>
    </div>
  </div>
</template>

<script setup lang="ts">
  import type { DeliveryMethodDto } from '@/admin/domain/DeliveryMethodDto';

  import IcCheck from '@/components/icons/IcCheck.vue';
  import IcChevronForward from '@/components/icons/IcChevronForward.vue';

  import { i18n } from '@/plugins/i18n';
  import { useRouter } from 'vue-router';

  const { t } = i18n.global;

  interface Props {
    deliveryMethods: Array<DeliveryMethodDto>;
  }
  const props = defineProps<Props>();
  const router = useRouter();
  const { locale } = i18n.global;

  const viewDeliveryMethod = async (deliveryCode: string) => {
    try {
      await router.push({
        name: '/delivery-method',
        params: { codeId: deliveryCode },
      });
    } catch (error) {
      console.error('Failed to navigate to delivery method:', error);
    }
  };
</script>

<style scoped lang="scss">
  .tableHeader {
    @apply grid auto-cols-fr grid-flow-col-dense gap-12 py-8 font-light;
  }
  .headerColumn {
    @apply flex items-center hover:cursor-pointer font-bold text-xl text-success;
  }
  .table:hover {
    @apply bg-neutral-100;
  }
  .tableColumn {
    @apply flex items-center;
  }
  .table {
    @apply grid auto-cols-fr grid-flow-col-dense gap-12 border-t border-neutral-300 py-8 cursor-pointer;
  }
</style>

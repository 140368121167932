<template>
  <AgModal v-if="internalValue">
    <template #header>
      <div v-if="offerItem" class="gap-4 flex flex-col overflow-auto">
        <div class="text-2xl font-bold">{{ t('offers.article') }}</div>
        <div
          v-if="offerItem.article.articleGroup || offerItem.article.articleType"
          class="text-s-13 font-light text-neutral-550 italic flex mt-12"
        >
          <div v-if="offerItem.article.articleGroup">{{ getTranslatedText(offerItem.article.articleGroup) }}</div>
          <div v-if="offerItem.article.articleType">{{ getTranslatedText(offerItem.article.articleType) }}</div>
        </div>
        <div class="text-l font-bold">{{ getTranslatedText(offerItem.article.title) }}</div>
        <div>
          <span class="text-s-13 text-neutral-500 font-light">{{ t('offers.articleCode') }}:&nbsp;</span>
          <span class="text-s-13">{{ offerItem.article.code }}</span>
        </div>
        <!-- CHECK STOCK AVAILABILITY IF ENABLED  -->
        <div
          v-if="hasStockAvailability(offerItem.article.articleTypeCode)"
          class="text-s-13 text-neutral-500 flex flex-row pb-24"
        >
          <span class="text-s text-neutral-550">{{ t('offers.stockInfo') }}:&nbsp;</span>
          <span class="text-link text-s hover:cursor-pointer" @click.stop="showStocks = !showStocks">
            {{ t('offers.showStockInfo') }}
          </span>
          <OfferWizardPopupStocks v-model="showStocks" :articleCode="offerItem.article.code" />
        </div>
      </div>
    </template>
    <template #content>
      <div v-if="offerItem && offerItem != null">
        <div class="overflow-auto px-16">
          <span class="italic text-infoBlue">{{ getTranslatedText(articleInfo) }}</span>
          <div v-if="offerItem.packaging" class="text-s pt-16">
            <!-- PACKAGE -->
            <div class="flex text-base py-12">
              <div class="font-light italic text-s w-full flex flex-row items-center">
                <span class="font-bold">{{ t('offers.packaging') }} ({{ t('offers.size') }}):&nbsp;</span>
                <span class="font-bold">
                  {{ getTranslatedText(offerItem.packaging.unit?.title) }} ({{ offerItem.packaging.size }})
                </span>
              </div>
            </div>
          </div>
          <div>
            <div class="flex gap-8 pt-8">
              <!-- QUANTITY -->
              <AgFormGroup :label="t('offers.quantity')" class="basis-1/6">
                <AgFormInput
                  type="number"
                  variant="shoppingCart"
                  v-model="offerItem.quantity"
                  :placeholder="offerItem.quantity || offerItem.quantity === 0 ? '-' : ''"
                  :validation-state="quantityValidationState(offerItem.quantity)"
                  @input="updatePrice(offerItem)"
                />
              </AgFormGroup>
              <!-- FREE QUANTITY -->
              <AgFormGroup :label="t('offers.freeQta')" class="basis-1/6" v-if="config.company.enableFreeQuantity">
                <AgFormInput
                  type="number"
                  variant="shoppingCart"
                  v-model="offerItem.freeQuantity"
                  :placeholder="offerItem.freeQuantity || offerItem.freeQuantity === 0 ? '-' : ''"
                  :validation-state="freeQuantityValidationState(offerItem.freeQuantity)"
                />
              </AgFormGroup>
              <div class="flex flex-col w-full">
                <!-- DIMENSIONS -->
                <div class="flex flex-wrap gap-16 basis-2/3" v-if="Object.keys(dimensions).length > 0">
                  <AgFormGroup
                    v-for="key in Object.keys(dimensions)"
                    :key="key"
                    :label="getDimensionLabel(key)"
                    :class="{ 'w-full': Object.keys(dimensions).length === 1 }"
                  >
                    <AgFormSelect
                      variant="shoppingCart"
                      :options="dimensions[key]"
                      :modelValue="getDimensionValue(key)"
                      @update:modelValue="setDimensions($event, key, offerItem)"
                    />
                  </AgFormGroup>
                </div>
                <!-- PRODUCTION ARTILCLE -->
                <div v-if="isProductionArticle(offerItem.article.articleTypeCode)" class="basis-2/3">
                  <AgFormGroup :label="t('offers.productDescription')">
                    <AgFormTextarea v-model="internalDescription" rows="2" variant="shoppingCart" />
                  </AgFormGroup>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- ITEM TRADE AGREEMENT PRICE -->
        <div class="px-16 pt-16">
          <div class="flex flex-grow flex-col border-neutral-300 p-24 py-24 bg-neutral-100 mt-8">
            <div class="flex justify-between items-center font-bold">
              <span>{{ t('offers.unitPrice') }}</span>
              <div class="flex items-center">
                <div v-if="config.company.enablePriceEditable && isEditingPrice" class="flex items-center max-w-128">
                  <AgFormInput
                    type="number"
                    v-model="editedTradeAgreementPrice"
                    right
                    @input="getNetPrice(offerItem)"
                    @focusout="isEditingPrice = false"
                  />
                  &nbsp;&euro;
                </div>
                <span v-else class="flex justify-end">{{ formatNumberToEuro(tradeAgreementPrice) }}</span>
                <AgButton
                  v-if="config.company.enablePriceEditable"
                  variant="ghost"
                  @click="() => (isEditingPrice = !isEditingPrice)"
                >
                  <template #icon>
                    <IcEdit class="ml-8 h-24 w-24 cursor-pointer" :class="{ 'text-primary': isEditingPrice }" />
                  </template>
                </AgButton>
              </div>
            </div>
            <div class="flex flex-row justify-between">
              <span class="justify-start">
                {{ t('offers.unitPrice') }} * {{ t('offers.size') }} * {{ t('offers.quantity') }}
              </span>
              <span>
                {{ formatNumberToEuro(getPricePerSizeAndQuantity(offerItem)) }}
              </span>
            </div>
            <div
              v-if="config.company.minPrice"
              class="flex flex-row justify-between"
              :class="{ 'text-infoRed font-bold': isPriceLowerThanMinPrice }"
            >
              <span class="justify-start">{{ t('offers.minPrice') }}</span>
              <span>{{ formatNumberToEuro(minPrice) }} </span>
            </div>
            <div v-if="config.company.minPrice" class="flex flex-row justify-between">
              <span class="justify-start">
                {{ t('orders.minPrice') }} * {{ t('orders.size') }} * {{ t('orders.quantity') }}
              </span>
              <span>
                {{ formatNumberToEuro(minPricePerSizeAndQuantity) }}
              </span>
            </div>
          </div>
        </div>
        <div class="flex flex-col px-16 pt-16">
          <div class="text-danger flex justify-end" v-if="offerItem.tradeAgreement.isPromotional">
            {{ t('offers.isPromotional') }}
          </div>
          <div v-else>
            <div class="flex justify-between">
              <span class="font-bold">{{ t('offers.positionDiscounts') }}</span>
              <span class="text-neutral-500 font-light">
                {{ maxItemDiscounts }}&nbsp;{{ t('offers.applicableDiscounts') }}
              </span>
            </div>
            <div class="flex justify-end">
              <AgButton
                variant="secondary"
                @click="addDiscount"
                class="p-8 w-fit text-link border-0"
                :disabled="disabledDiscountButton(offerItem)"
              >
                <template #default>{{ t('offers.discount') }}</template>
                <template #icon><IcAdd /></template>
              </AgButton>
            </div>
          </div>
        </div>
        <!-- ITEM DISCOUNT -->
        <div v-if="showDiscounts" class="px-16">
          <div v-for="row in discountRowsCount" :key="row" class="flex flex-row justify-between mb-24">
            <div class="gap-12 flex max-md:flex-col max-md:items-start items-center">
              <div class="font-bold">{{ t('offers.discount') }} {{ row }}</div>
              <div
                class="gap-4 flex flex-row max-w-128 min-w-[40px]"
                :class="{ 'items-center': (validationDisocunts as any)[`discount${row}`].state }"
              >
                <AgFormInput
                  right
                  type="number"
                  v-model="(offerItem as any)[`discount${row}`]"
                  :validation-state="(validationDisocunts as any)[`discount${row}`].state"
                  :error-message="(validationDisocunts as any)[`discount${row}`].msg"
                  @input="getNetPrice(offerItem)"
                  @change="setSomeDiscountChanged"
                />
                &percnt;
              </div>
            </div>
            <div class="flex items-center max-md:items-end gap-x-24">
              {{ formatNumberToEuro(discountAmount[row - 1]) }}
              <div class="flex items-center max-md:items-end">
                {{ formatNumberToEuro(netPrice[row - 1]) }}
                <AgButton variant="ghost" @click="removeDiscount(row)">
                  <template #icon><IcClose /></template>
                </AgButton>
              </div>
            </div>
          </div>
        </div>
        <!-- ITEM NOTE -->
        <div class="flex flex-grow flex-col px-16 pt-16 mb-24">
          <div class="font-bold">{{ t('offers.articleNote') }}</div>
          <AgFormTextarea v-model="offerItem.note" rows="1" />
        </div>
      </div>
    </template>
    <!-- BUTTON -->
    <template #footer>
      <AgButton variant="secondary" @click="closeModal">{{ t('offers.cancel') }}</AgButton>
      <AgButton @click="save" :disabled="isDisabled">{{ t('offers.edit') }} </AgButton>
    </template>
  </AgModal>
</template>

<script setup lang="ts">
  import type { AgFormSelectOption } from '@/components/library/form-select/AgFormSelectOption';
  import type { DimensionItemDto } from '@/domain/DimensionItemDto';
  import type { OfferItemDto } from '@/domain/offerData/OfferItemDto';

  import IcAdd from '@/components/icons/IcAdd.vue';
  import IcClose from '@/components/icons/IcClose.vue';
  import AgButton from '@/components/library/button/AgButton.vue';
  import AgFormGroup from '@/components/library/form-group/AgFormGroup.vue';
  import AgFormInput from '@/components/library/form-input/AgFormInput.vue';
  import AgFormSelect from '@/components/library/form-select/AgFormSelect.vue';
  import AgFormTextarea from '@/components/library/form-textarea/AgFormTextarea.vue';
  import AgModal from '@/components/library/modal/AgModal.vue';
  import OfferWizardPopupStocks from '@/modules/offers/components/offer-wizard/steps/shopping-cart/article-table-row/OfferWizardPopupStocks.vue';

  import IcEdit from '@/components/icons/IcEdit.vue';
  import { useCommon } from '@/composables/useCommon';
  import { useNumberFormatting } from '@/composables/useNumberFormatting';
  import { useTranslatedText } from '@/composables/useTransalteText';
  import { config } from '@/config/config';
  import { CustomerType } from '@/domain/enumeration/CustomerType';
  import {
    areModifiedDiscountsValid,
    validateAllDiscounts,
  } from '@/modules/offers/components/offer-wizard/steps/shopping-cart/ArticleDiscountsValidation';
  import { useOfferFactory } from '@/modules/offers/composables/useOfferFactory';
  import { useOfferWizardStore } from '@/modules/offers/stores/useOfferWizardStore';
  import { i18n } from '@/plugins/i18n';
  import { cloneDeep } from 'lodash';
  import { storeToRefs } from 'pinia';
  import { computed, ref, watch } from 'vue';

  const { locale, t } = i18n.global;

  const { getTranslatedText } = useTranslatedText();
  const { formatNumberToEuro } = useNumberFormatting();

  const { getMinPriceByArticle, getArticleInfo } = useCommon();

  const { getDimOptionsByArticle, getTradeAgreementPriceAndDisconts } = useOfferFactory();

  const store = useOfferWizardStore();
  const { offer } = storeToRefs(store);

  const offerItem = ref<OfferItemDto | null>(null);
  const articleInfo = ref<{ [key: string]: string }>({});

  const maxItemDiscounts = ref<number>(config.company.maxItemDiscounts);
  const discountAmount = ref<Array<number>>([]);
  const netPrice = ref<Array<number>>([]);
  const minPrice = ref<number>();
  const isEditingPrice = ref<boolean>(false);
  const discountRowsCount = ref<number>(0);

  const newProductionDescription = ref<string>();
  const showStocks = ref<boolean>(false);

  const dimensions = ref<{ [key: string]: Array<AgFormSelectOption> }>({});
  const dimOptions = ref<{ [key: string]: Array<DimensionItemDto> }>({});
  const dimensionArrayWithValues = ref<Array<DimensionItemDto>>([]);

  interface Props {
    modelValue: boolean;
    item?: OfferItemDto;
  }
  const props = withDefaults(defineProps<Props>(), {
    modelValue: false,
  });

  const emit = defineEmits(['update:modelValue', 'update:offerItem']);

  const editedTradeAgreementPrice = computed({
    get: () => {
      if (offerItem.value && offerItem.value.quantity && offerItem.value.quantity > 0) {
        if (offerItem.value.isTradeAgreementPriceEdited) {
          return offerItem.value.tradeAgreement.editedPrice ?? 0;
        }
        return offerItem.value.tradeAgreement.price;
      }
      return 0;
    },
    set: (newValue) => {
      if (offerItem.value && newValue !== offerItem.value.tradeAgreement.price) {
        offerItem.value.tradeAgreement.editedPrice = newValue ?? 0;
        offerItem.value.isTradeAgreementPriceEdited = true;
      } else if (offerItem.value) {
        offerItem.value.isTradeAgreementPriceEdited = false;
        offerItem.value.tradeAgreement.editedPrice = undefined;
      }
    },
  });

  const internalDescription = computed({
    get: () => props.item?.article.productionDescription,
    set: (newValue) => (newProductionDescription.value = newValue),
  });

  const internalValue = computed({
    get: () => props.modelValue,
    set: (newValue) => emit('update:modelValue', newValue),
  });

  const tradeAgreementPrice = computed(() => {
    if (offerItem.value && offerItem.value.quantity && offerItem.value.quantity > 0) {
      if (offerItem.value.isTradeAgreementPriceEdited) {
        return offerItem.value.tradeAgreement.editedPrice ?? 0;
      }
      return offerItem.value.tradeAgreement.price;
    }
    return 0;
  });

  const freeQuantityValidationState = function (freeQuantity: number | null) {
    if (!config.company.enableFreeQuantity) {
      return false;
    }
    if (freeQuantity != null && freeQuantity < 0) {
      return false;
    }
    return true;
  };

  const quantityValidationState = function (quantity: number | null) {
    if (quantity != null && quantity < 0) {
      return false;
    }
    return true;
  };

  const showDiscounts = computed(() => {
    return !offerItem.value?.tradeAgreement.isPromotional && offerItem.value?.quantity && offerItem.value?.quantity > 0;
  });

  const validationDisocunts = computed(() => {
    const discounts: Array<number> = [];
    type ObjectKey = keyof typeof offerItem.value;
    for (let i = 1; i <= maxItemDiscounts.value; i++) {
      const key = `discount${i}` as ObjectKey;
      if (offerItem.value && typeof offerItem.value[key] === 'number') {
        discounts.push(offerItem.value[key]);
      }
    }
    emit('update:offerItem', {
      id: 1,
      error: areModifiedDiscountsValid(netPrice.value, discounts, minPricePerSizeAndQuantity.value),
    });
    return validateAllDiscounts(netPrice.value, discounts, minPricePerSizeAndQuantity.value);
  });

  const isPriceLowerThanMinPrice = computed(() => {
    if (!offerItem.value) {
      return true;
    }
    if (config.company.minPrice) {
      if (tradeAgreementPrice.value < (minPrice.value ?? 0)) {
        return true;
      }
    }
    return false;
  });

  const isDisabled = computed((): boolean => {
    if (!offerItem.value) {
      return true;
    }
    if (isPriceLowerThanMinPrice.value && offerItem.value.tradeAgreement.editedPrice !== undefined) {
      return true;
    }
    for (let i = 1; i < maxItemDiscounts.value + 1; i++) {
      type ObjectKey = keyof typeof validationDisocunts.value;
      const key = `discount${i}` as ObjectKey;
      if (validationDisocunts.value[key].state === false) {
        return true;
      }
    }
    if (
      (offerItem.value.quantity && offerItem.value.quantity > 0) ||
      (offerItem.value.freeQuantity && offerItem.value.freeQuantity > 0)
    ) {
      return false;
    }
    return true;
  });

  const hasStockAvailability = function (articleTypeCode: string = '') {
    return (
      config.company.enableStockAvailability &&
      config.company.partialDeliveryArticleTypeCode &&
      config.company.partialDeliveryArticleTypeCode.includes(articleTypeCode)
    );
  };

  const isProductionArticle = function (articleTypeCode: string = '') {
    return config.company.productionArticle && config.company.productionArticleTypeCode === articleTypeCode;
  };

  const getPricePerSizeAndQuantity = function (item: OfferItemDto) {
    if (!item) {
      return 0;
    }
    const size = item.packaging.size > 0 ? item.packaging.size : 1;
    return tradeAgreementPrice.value * size * (item.quantity ?? 1);
  };

  const minPricePerSizeAndQuantity = computed(() => {
    if (!offerItem.value) {
      return 0;
    }
    const size = offerItem.value.packaging.size > 0 ? offerItem.value.packaging.size : 1;
    return minPrice.value ? minPrice.value * size * (offerItem.value.quantity ?? 1) : 0;
  });

  const updatePrice = async function (item: OfferItemDto | null) {
    if (offer.value && item) {
      const qt = item.quantity ?? 0;
      const articleId = item.article.id;
      const priceGroupIds = item.article.priceGroupIds;

      // Add first dimension value config id if present
      let dimValConfId = undefined;
      if (dimensionArrayWithValues.value.length > 0) {
        dimValConfId = dimensionArrayWithValues.value[0].value.id;
      }
      // GET Trade Agreement and discounts
      const taDiscountLists = await getTradeAgreementPriceAndDisconts(
        offer.value,
        articleId,
        priceGroupIds,
        qt,
        dimValConfId
      );

      if (taDiscountLists.tradeAgreement) {
        const tradeAgreement = taDiscountLists.tradeAgreement;
        const isPromotional = tradeAgreement && tradeAgreement.customerType === CustomerType.PROMOTION;

        item.tradeAgreement = {
          id: tradeAgreement.id,
          price: taDiscountLists.price,
          isPromotional: isPromotional,
          editedPrice: item.tradeAgreement.editedPrice,
        };
        // CHECK DISCOUNTS IF PRESENT
        if (discountRowsCount.value > 0) {
          resetDiscount(item);
        }
        const discounts = taDiscountLists.discounts;
        if (discounts.length > 0) {
          type objectKey = keyof typeof item;
          for (let i = 0; i < discounts.length; i++) {
            const key = `discount${i + 1}` as objectKey;
            setObjProperty(item, key, discounts[i]);
          }
          discountRowsCount.value = discounts.length;
          getNetPrice(item);
        }
      }
    }
  };

  const resetDiscount = (item: OfferItemDto) => {
    if (item) {
      type objectKey = keyof typeof item;
      for (let i = 0; i < discountRowsCount.value; i++) {
        const key = `discount${i + 1}` as objectKey;
        delete item[key];
      }
      discountRowsCount.value = 0;
    }
  };

  const closeModal = function () {
    dimensions.value = {};
    isEditingPrice.value = false;
    internalValue.value = false;
  };

  const addDiscount = function () {
    if (discountRowsCount.value < maxItemDiscounts.value) {
      discountRowsCount.value++;
    }
  };

  const disabledDiscountButton = function (offerItem: OfferItemDto) {
    if (discountRowsCount.value >= maxItemDiscounts.value) {
      return true;
    }
    return !offerItem.quantity || offerItem.quantity === 0 || offerItem.tradeAgreement.isPromotional;
  };

  const removeDiscount = function (row: number) {
    if (!offerItem.value) {
      return;
    }
    for (let i = row; i <= maxItemDiscounts.value; i++) {
      type objectKey = keyof typeof offerItem.value;

      const key = `discount${i}` as objectKey;
      const key1 = `discount${i + 1}` as objectKey;
      const value = i === maxItemDiscounts.value ? null : offerItem.value[key1];
      setObjProperty(offerItem.value, key, value);
    }
    discountRowsCount.value--;
    getNetPrice(offerItem.value);
  };

  const getNetPrice = function (item: OfferItemDto | null) {
    if (!item) {
      return;
    }
    const size = item.packaging.size > 0 ? item.packaging.size : 1;
    let price = tradeAgreementPrice.value * size * (item.quantity ?? 1);
    let lastPrice = 0;

    type objKey = keyof typeof offerItem.value;

    [...new Array(maxItemDiscounts.value)].map((_, i) => {
      const key = `discount${i + 1}` as objKey;
      const discount = (getObjProperty(item, key) as number) ?? 0;
      if (discount > 0) {
        lastPrice = price;
        price = price * (1 - discount / 100);
      } else {
        lastPrice = 0;
      }
      netPrice.value[i] = price;
      discountAmount.value[i] = lastPrice > 0 ? lastPrice - price : 0;
    });
  };

  const setSomeDiscountChanged = function () {
    if (offerItem.value) {
      offerItem.value.isSomeDiscountEdited = true;
    }
  };

  const save = function () {
    if (!offerItem.value || !offer.value) {
      return;
    }
    const articleId = offerItem.value.article.id;
    const packagingId = offerItem.value.packaging.id;
    const productionDescription = offerItem.value.article.productionDescription;
    const articleTypeCode = offerItem.value.article.articleTypeCode;
    const dimensions = offerItem.value.article.dimensions;
    const items = offer.value.items;

    let qt = 0;
    let frqt = 0;

    if (
      (newProductionDescription.value && productionDescription !== newProductionDescription.value) ||
      (dimensionArrayWithValues.value.length > 0 && !compareArrayDimensions(dimensions, dimensionArrayWithValues.value))
    ) {
      const findIndex = items.findIndex((item) =>
        articleCompare(
          item,
          articleId,
          packagingId,
          newProductionDescription.value,
          articleTypeCode,
          dimensionArrayWithValues.value
        )
      );
      if (findIndex > -1) {
        qt = offer.value.items[findIndex].quantity ?? 0;
        frqt = offer.value.items[findIndex].freeQuantity ?? 0;
        // Remove copy item from offer
        offer.value.items.splice(findIndex, 1);
      }
    }
    const index = items.findIndex((item) =>
      articleCompare(item, articleId, packagingId, productionDescription, articleTypeCode, dimensions)
    );
    if (index > -1) {
      qt += offerItem.value.quantity ?? 0;
      frqt += offerItem.value.freeQuantity ?? 0;

      offerItem.value.quantity = qt;
      offerItem.value.freeQuantity = frqt;

      if (newProductionDescription.value !== undefined) {
        offerItem.value.article.productionDescription = newProductionDescription.value;
        newProductionDescription.value = undefined;
      }
      if (dimensionArrayWithValues.value.length > 0) {
        offerItem.value.article.dimensions = dimensionArrayWithValues.value;
      }
      const discounts: Array<number> = [];
      type ObjectKey = keyof typeof offerItem.value;
      for (let i = 1; i <= maxItemDiscounts.value; i++) {
        const key = `discount${i}` as ObjectKey;
        if (offerItem.value[key] && typeof offerItem.value[key] === 'number') {
          discounts.push(offerItem.value[key]);
        }
      }
      offerItem.value.unitPrice = getUnitPrice(tradeAgreementPrice.value, discounts);
      offerItem.value.finalPrice = getFinalPrice(
        tradeAgreementPrice.value,
        discounts,
        offerItem.value.packaging.size,
        qt
      );
      offer.value.items.splice(index, 1, offerItem.value);
    }
    closeModal();
  };

  const getUnitPrice = function (price: number, discounts: number[]) {
    let discountedPrice = price;
    if (discounts.length > 0) {
      for (let i = 0; i < discounts.length; i++) {
        const discount = discounts[i];
        if (discount > 0) {
          discountedPrice = discountedPrice * (1 - discount / 100);
        }
      }
    }
    return discountedPrice;
  };

  const getFinalPrice = function (price: number, discounts: number[], packagingSize: number, quantity: number) {
    const unitPrice = getUnitPrice(price, discounts);
    const finalPrice = unitPrice * packagingSize * quantity;

    return finalPrice;
  };

  const articleCompare = (
    item: OfferItemDto,
    artId: number,
    pkgId: number,
    prdDesc?: string,
    artType?: string,
    dims?: Array<DimensionItemDto>
  ) => {
    if (config.company.productionArticle && config.company.productionArticleTypeCode === artType) {
      return (
        item.article.id === artId &&
        item.packaging.id === pkgId &&
        item.article.productionDescription === prdDesc &&
        compareArrayDimensions(item.article.dimensions, dims)
      );
    } else {
      return (
        item.article.id === artId &&
        item.packaging.id === pkgId &&
        compareArrayDimensions(item.article.dimensions, dims)
      );
    }
  };

  const setObjProperty = function <T, K extends keyof T>(obj: T, key: K, value: T[K]) {
    obj[key] = value;
  };

  const getObjProperty = function <T, K extends keyof T>(obj: T, key: K): T[K] {
    return obj[key] as T[K];
  };

  const setDimensions = function (id: number, key: string, item: OfferItemDto | null) {
    const indexToRemove = dimensionArrayWithValues.value.findIndex((item) => item.id === parseInt(key));
    if (indexToRemove != -1) {
      dimensionArrayWithValues.value.splice(indexToRemove, 1);
    }
    const dimensionItem = dimOptions.value[key].find((item) => item.value.id === id);
    if (dimensionItem) {
      dimensionArrayWithValues.value.push(dimensionItem);
    }
    dimensionArrayWithValues.value.sort((a, b) => a.id - b.id);
    // IF NECESSARY SET CONTINGENT
    if (item) {
      updatePrice(item);
    }
  };

  const loadDimensions = function () {
    if (!props.item || !offer.value) {
      return;
    }
    const articleId = props.item.article.id;
    const priceGroupIds = props.item.article.priceGroupIds;

    getDimOptionsByArticle(offer.value, articleId, priceGroupIds).then((opts) => {
      if (opts) {
        dimOptions.value = opts;
        for (const [key, items] of Object.entries(opts)) {
          items.forEach((item) => {
            if (dimensions.value[key]) {
              dimensions.value[key].push({
                key: item.value.id,
                label: item.value.title[locale.value],
              });
            } else {
              dimensions.value[key] = [
                {
                  key: item.value.id,
                  label: item.value.title[locale.value],
                },
              ];
            }
          });
        }
      }
    });
  };

  const getDimensionLabel = (key: string) => dimOptions.value[key][0].title[locale.value] ?? '--';

  const getDimensionValue = function (key: string) {
    if (offerItem.value && offerItem.value.article.dimensions) {
      const dimension = offerItem.value.article.dimensions.find((d) => d.id === parseInt(key));
      if (dimension) {
        return dimension.value.id;
      }
    }
    return -1;
  };

  const compareArrayDimensions = (a?: Array<DimensionItemDto>, b?: Array<DimensionItemDto>) => {
    return a && b && a.length === b.length && a.every((adim) => b.some((bdim) => adim.value.id === bdim.value.id));
  };

  watch(
    internalValue,
    async (value) => {
      if (value && props.item) {
        articleInfo.value = await getArticleInfo(props.item.article.id);
        const hasDimesnions = props.item.article.dimensions && props.item.article.dimensions.length > 0;
        if (hasDimesnions && config.company.enabledProductionDimensions) {
          loadDimensions();

          dimensionArrayWithValues.value = props.item.article.dimensions ? [...props.item.article.dimensions] : [];
        }
        offerItem.value = cloneDeep(props.item);

        discountAmount.value = [];
        netPrice.value = [];
        minPrice.value = await getMinPriceByArticle(props.item.article.id);

        if (offerItem.value) {
          const item = offerItem.value;
          type objectKey = keyof typeof item;

          discountRowsCount.value = [...new Array(maxItemDiscounts.value)]
            .map((_, i) => {
              const key = `discount${i + 1}` as objectKey;
              const value = (getObjProperty(item, key) as number) ?? 0;
              if (value > 0) {
                getNetPrice(item);
              }
              return value;
            })
            .filter((d) => d > 0).length;
        }
      } else {
        offerItem.value = null;
      }
    },
    { immediate: true }
  );
</script>

<template>
  <div v-if="offer" class="md:px-[80px] max-md:px-16 mt-12 mb-32 space-y-8">
    <OfferInfo />

    <div class="font-bold">
      <span>{{ t('offers.dueDate') }}:&nbsp;</span>
      <span v-if="offer.offerDueDate">{{ formatDate(offer.offerDueDate) }}</span>
      <span v-else>{{ t('offers.noneDueDate') }}</span>
    </div>

    <div class="flex justify-end py-16" v-if="config.company.sendEmailOffer">
      <!-- OFFER MAIL NOT YET READY-->
      <AgButton
        hidden
        variant="ghost"
        class="text-link"
        @click="openPopupSendMail"
        :disabled="!offer.gid || isCustomerBlocked"
      >
        {{ t('offers.sendByMail') }}
        <template #icon><IcMailClose /></template>
      </AgButton>
    </div>

    <!-- IF OFFER IS COMPLETED -->
    <div class="py-16" v-if="offer.status === OfferStatus.COMPLETED">
      <AgBox variant="alternative">
        <div class="pb-12 font-bold" v-if="offer.synced === 1">{{ t('offers.offerCompletedAndTransmit') }}</div>
        <div class="pb-12 font-bold" v-else>{{ t('offers.offerCompletedAndNotTransmit') }}</div>
        <div class="pb-12">
          <span class="text-lg font-bold">{{ t('offers.offerNumber') }}:&nbsp;</span>
          <span v-if="offer.offerNumber">{{ offer.offerNumber }}</span>
          <span v-else>{{ t('offers.offerNumberNotAvailable') }}</span>
        </div>
        <div>
          <span class="font-bold">{{ t('offers.offerDate') }}:&nbsp;</span>
          <span>{{ formatDate(offer.offerDate) }}</span>
        </div>
      </AgBox>
    </div>

    <!-- OFFER ARTICLE INFO -->
    <div class="gap-12 pb-16">
      <AgBox v-if="items.length > 0" variant="accent" class="max-h-[333px] overflow-scroll">
        <div v-for="(item, index) in items" :key="item.article.id">
          <div class="flex-col border-b-2 border-y-neutral-200 py-12 w-full" :class="{ 'border-t-2': index === 0 }">
            <div
              class="flex gap-12 text-s-13 font-light w-fit text-neutral-550"
              v-if="item.article.articleGroup || item.article.articleType"
            >
              <div v-if="item.article.articleGroup">
                {{ getTranslatedText(item.article.articleGroup) }}
              </div>
              <div v-if="item.article.articleType">
                {{ getTranslatedText(item.article.articleType) }}
              </div>
            </div>
            <div class="flex flex-row items-center gap-4">
              <span class="font-bold text-l">{{ getTranslatedText(item.article.title) }}</span>
              <div v-if="item.note">
                <Popper class="agPopper" arrow @click.stop>
                  <AgButton variant="ghost">
                    <template #icon>
                      <IcInfo class="text-link" />
                    </template>
                  </AgButton>
                  <template #content>
                    <div class="p-8 text-s-20 whitespace-pre-wrap w-[350px] h-[230px] overflow-scroll">
                      {{ item.note }}
                    </div>
                  </template>
                </Popper>
              </div>
            </div>
            <div>
              <span class="text-neutral-550 font-light">{{ t('offers.articleCode') }}:&nbsp;</span>
              <span>{{ item.article.code }}</span>
            </div>
            <div class="font-bold mt-12">
              <span class="font-bold">{{ t('offers.packaging') }}:&nbsp;</span>
              {{ item.packaging.unit.title[locale] }}
            </div>

            <div class="flex items-start">
              <div class="flex flex-col basis-6/12">
                <div v-if="item.quantity && item.quantity > 0">
                  <span class="justify-end font-light text-neutral-550">{{ t('offers.quantity') }}:&nbsp;</span>
                  <span class="font-bold">{{ item.quantity }}&nbsp;</span>
                  <span> (x {{ item.packaging.size }} = {{ (item.packaging.size * item.quantity).toFixed(2) }}) </span>
                </div>
                <div v-if="config.company.enableFreeQuantity && item.freeQuantity && item.freeQuantity > 0">
                  <span class="justify-end font-light text-neutral-550">{{ t('offers.freeQta') }}:&nbsp;</span>
                  <span class="font-bold">{{ item.freeQuantity }}</span>
                  <span>
                    (x {{ item.packaging.size }} = {{ (item.packaging.size * item.freeQuantity).toFixed(2) }})
                  </span>
                </div>
                <div v-if="item.article.dimensions && item.article.dimensions.length > 0" class="flex flex-row">
                  <span class="font-light text-neutral-550">{{ t('offers.optionals') }}:&nbsp;</span>
                  <span>{{ getSortDimensions(item, locale) }}</span>
                </div>
                <div class="break-all" v-if="item.article.productionDescription">
                  <span class="font-light text-neutral-550">{{ t('offers.productDescription') }}: &nbsp;</span>
                  <span>{{ item.article.productionDescription }}</span>
                </div>
              </div>

              <div class="flex flex-col basis-6/12 items-end">
                <div>
                  <span class="font-light text-neutral-550">{{ t('offers.unitPrice') }}:&nbsp;</span>
                  <span>{{ formatNumberToEuro(tradeAgreementPrice(item)) }}</span>
                </div>
                <div v-if="item.quantity && item.quantity > 0">
                  <span>
                    ({{ tradeAgreementPrice(item) }}*{{ item.packaging.size }}*{{ item.quantity }})&nbsp;=&nbsp;
                  </span>
                  <span>{{ formatNumberToEuro(getFinalPrice(item)) }}</span>
                </div>
                <div v-if="getDiscountsString(item)" class="pl-4 text-neutral-550 font-light">
                  <span v-if="getDiscountedAmount(item) > 0">
                    - {{ formatNumberToEuro(getDiscountedAmount(item)) }}&nbsp;
                  </span>
                  <span>({{ getDiscountsString(item) }})</span>
                </div>
                <div class="font-bold">
                  {{ formatNumberToEuro(getFinalDiscountedPrice(item)) }}
                </div>
                <div
                  v-if="item.quantity && item.quantity > 0 && item.tradeAgreement.isPromotional"
                  class="flex flex-row w-full justify-end"
                >
                  <span class="text-infoRed font-bold">{{ t('offers.promotion') }}</span>
                </div>
                <span
                  v-if="item.article.articleTypeCode === config.company.productionArticleTypeCode"
                  class="text-link font-bold"
                >
                  {{ t('offers.production') }}
                </span>
              </div>
            </div>
          </div>
        </div>
        <!-- TOTAL AMOUNT -->
        <OfferWizardShoppingCartSummary />
      </AgBox>
      <AgAlert v-else variant="danger">
        {{ t('offers.cartEmpty') }}
        <template #icon><IcAlert /></template>
      </AgAlert>
    </div>

    <!-- CUSTOMER  -->
    <!-- CUSTOMER INFO -->
    <div class="basis-1 gap-12 pb-16">
      <div class="text-sm font-bold">{{ t('offers.customer') }}</div>
      <AgBox class="flex flex-row">
        <IcClientDetails class="mr-32" />
        <div class="sm:h-5/6 row w-full">
          <div>
            <div>
              <span class="text-sm font-light text-neutral-550">{{ t('offers.customerCode') }}:&nbsp;</span>
              <span>{{ offer.customer.code ?? '--' }}</span>
            </div>
            <div>
              <span class="text-sm font-light text-neutral-550">{{ t('offers.customer') }}:&nbsp;</span>
              <span>{{ offer.customer.name ?? '--' }}</span>
            </div>
            <div>
              <span class="text-sm font-light text-neutral-550">{{ t('offers.alternativeName') }}:&nbsp;</span>
              <span>{{ offer.customer.alternativeName ?? '--' }}</span>
            </div>
            <div>
              <span class="text-sm font-light text-neutral-550">{{ t('offers.website') }}:&nbsp;</span>
              <span>{{ offer.customer.webSiteUrl ?? '--' }}</span>
            </div>
            <div>
              <span class="text-sm font-light text-neutral-550">{{ t('offers.addressWizard') }}:&nbsp;</span>
              <span>{{ getCompleteInvoiceAddress() ?? '--' }}</span>
            </div>
            <div>
              <span class="text-sm font-light text-neutral-550">{{ t('offers.mail') }}:&nbsp;</span>
              {{ offer.invoiceAddress.email ?? '--' }}
            </div>
            <div>
              <span class="text-sm font-light text-neutral-550">{{ t('offers.pecMail') }}:&nbsp;</span>
              {{ offer.invoiceAddress.pec ?? '--' }}
            </div>
          </div>
          <div>
            <div>
              <span class="text-sm font-light text-neutral-550">{{ t('offers.phone') }}:&nbsp;</span>
              {{ offer.invoiceAddress.phoneNumber ?? '--' }}
            </div>
            <div>
              <span class="text-sm font-light text-neutral-550">{{ t('offers.fiscalCode') }}:&nbsp;</span>
              {{ offer.customer.fiscalCode ?? '--' }}
            </div>
            <div>
              <span class="text-sm font-light text-neutral-550">{{ t('offers.vatCode') }}:&nbsp;</span>
              {{ offer.customer.vatCode ?? '--' }}
            </div>
            <div>
              <span class="text-sm font-light text-neutral-550">{{ t('offers.languageCustomer') }}:&nbsp;</span>
              {{ offer.customer.language ?? '--' }}
            </div>
            <div>
              <span class="text-sm font-light text-neutral-550">{{ t('offers.section') }}:&nbsp;</span>
              {{ getTranslatedText(offer.invoiceAddress.businessCategory?.title) ?? '--' }}
            </div>
            <div>
              <span class="text-sm font-light text-neutral-550">{{ t('offers.sdiCode') }}:&nbsp;</span>
              {{ offer.customer.sdiCode ?? '--' }}
            </div>
            <div>
              <span class="text-sm font-light text-neutral-550"> IBAN:&nbsp;</span>
              {{ offer.customer.iban ?? '--' }}
            </div>
          </div>
        </div>
      </AgBox>
    </div>
    <!-- DELIVERY INFO -->
    <div class="basis-1 pb-16">
      <div class="text-sm font-bold">{{ t('offers.deliveryAddress') }}</div>
      <AgBox class="sm:h-5/6 flex flex-row">
        <IcDeliveryAddress class="mr-32" />
        <div>
          <div v-if="offer.deliveryAddressEqualToBilling" class="font-bold">
            {{ t('offers.deliveryAddressEqualToBilling') }}
          </div>
          <div>
            <span class="text-sm font-light text-neutral-550"> {{ t('offers.deliveryAddress') }}:&nbsp; </span>
            <span>{{ calculatedDeliveryAddress?.code }}</span>
          </div>
          <div>
            <span class="text-sm font-light text-neutral-550">{{ t('offers.addressWizard') }}:&nbsp;</span>
            {{ getCompleteDeliveryAddress() ?? '--' }}
          </div>
          <div>
            <span class="text-sm font-light text-neutral-550">{{ t('offers.mail') }}:&nbsp;</span>
            {{ calculatedDeliveryAddress?.email ?? '--' }}
          </div>
          <div>
            <span class="text-sm font-light text-neutral-550">{{ t('offers.pecMail') }}:&nbsp;</span>
            {{ calculatedDeliveryAddress?.pec ?? '--' }}
          </div>
          <div>
            <span class="text-sm font-light text-neutral-550">{{ t('offers.phone') }}:&nbsp;</span>
            {{ calculatedDeliveryAddress?.phoneNumber ?? '--' }}
          </div>
          <div v-if="calculatedDeliveryAddress?.deliveryPhoneNotification">
            <span class="text-sm font-light text-neutral-550">{{ t('offers.deliveryPhoneNumber') }}:&nbsp;</span>
            {{ calculatedDeliveryAddress?.deliveryPhoneNumber ?? '--' }}
          </div>
          <div>
            <span class="text-sm font-light text-neutral-550">{{ t('offers.latitude') }}:&nbsp;</span>
            {{ calculatedDeliveryAddress?.latitude ?? '--' }}
          </div>
          <div>
            <span class="text-sm font-light text-neutral-550">{{ t('offers.longitude') }}:&nbsp;</span>
            {{ calculatedDeliveryAddress?.longitude ?? '--' }}
          </div>
          <div>
            <span class="text-sm font-light text-neutral-550">{{ t('offers.section') }}:&nbsp;</span>
            {{ getTranslatedText(calculatedDeliveryAddress?.businessCategory?.title) ?? '--' }}
          </div>
          <div>
            <span class="text-sm font-light text-neutral-550">{{ t('offers.unloadingTimes') }}:&nbsp;</span>
            <span v-if="calculatedDeliveryAddress">
              <span v-for="(unloadingTime, index) in calculatedDeliveryAddress.unloadingTimes" :key="index" class="p-4">
                <span>
                  {{ timeToDate(unloadingTime.timeStart, locale) }} - {{ timeToDate(unloadingTime.timeEnd, locale) }}
                </span>
                <span v-if="index < calculatedDeliveryAddress?.unloadingTimes.length - 1"> ; </span>
              </span>
            </span>
          </div>
          <div>
            <span class="text-sm font-light text-neutral-550">{{ t('offers.daysOfRest') }}:&nbsp;</span>
            <span v-if="calculatedDeliveryAddress">
              <span v-for="(dayOfRest, index) in calculatedDeliveryAddress.daysOfRest" :key="index" class="p-4">
                <span class="text-sm">{{ t(`offers.day${dayOfRest.day}`) }}:&nbsp;</span>
                <span>
                  {{ timeToDate(dayOfRest.timeStart, locale) }} - {{ timeToDate(dayOfRest.timeEnd, locale) }}
                </span>
                <span v-if="index > calculatedDeliveryAddress.daysOfRest.length - 1">;</span>
              </span>
            </span>
          </div>
        </div>
      </AgBox>
    </div>

    <!-- DELIVERY PHOTOS -->
    <div class="pb-16" v-if="calculatedDeliveryAddress?.photos && calculatedDeliveryAddress?.photos.length > 0">
      <div class="text-sm font-bold">{{ t('offers.deliveryAddressPictures') }}</div>
      <AgBox class="sm:h-5/6 max-h-[350px]">
        <!-- PHOTOS -->
        <PhotoVisualizer :offer="offer" :editable="false" />
      </AgBox>
    </div>

    <!-- DELIVERY METHOD -->
    <div class="flex justify-between gap-12 pb-16 max-md:flex-col">
      <div class="basis-1/2">
        <div class="text-sm font-bold">{{ t('offers.deliveryDetails') }}</div>
        <AgBox class="sm:h-5/6 flex flex-row items-center">
          <IcDeliveryDetails class="mr-32" />
          <div>
            <div>
              <span class="text-sm font-light text-neutral-550">{{ t('offers.deliveryTerm') }}:&nbsp;</span>
              <span> {{ getTranslatedText(offer.deliveryTerm?.title) ?? '--' }}</span>
            </div>
            <div>
              <span class="text-sm font-light text-neutral-550">{{ t('offers.deliveryMethod') ?? '--' }}:&nbsp;</span>
              <span>{{ getTranslatedText(offer.deliveryMethod?.title) }}</span>
            </div>
            <div>
              <span class="text-sm font-light text-neutral-550">{{ t('offers.dueDate') ?? '--' }}:&nbsp;</span>
              <span>{{ formatDate(offer?.offerDueDate) }}</span>
            </div>
          </div>
        </AgBox>
      </div>

      <!-- PAYMENT METHOD -->
      <div class="basis-1/2">
        <div class="text-sm font-bold">{{ t('offers.paymentDetails') }}</div>
        <AgBox class="sm:h-5/6 flex flex-row items-center">
          <IcPaymentInfo class="mr-32" />
          <div>
            <div>
              <span class="text-sm font-light text-neutral-550">{{ t('offers.paymentTerm') }}:&nbsp;</span>
              <span>{{ getTranslatedText(offer.paymentTerm?.title) ?? '--' }}</span>
            </div>
            <div>
              <span class="text-sm font-light text-neutral-550">{{ t('offers.paymenthMethod') }}:&nbsp;</span>
              <span>{{ getTranslatedText(offer.paymentMethod?.title) ?? '--' }}</span>
            </div>
            <div v-if="offer.paymentMethod && offer.paymentMethod.ibanRequired">
              <div>
                <span class="text-sm font-light text-neutral-550">IBAN:&nbsp;</span>
                <span>{{ offer.customer.iban ?? '--' }}</span>
              </div>
            </div>
          </div>
        </AgBox>
      </div>
    </div>

    <div class="flex flex-col gap-12 pb-16">
      <div class="basis-1">
        <div class="text-sm font-bold table-header-group">{{ t('offers.note') }}</div>
        <AgBox class="whitespace-pre-wrap max-h-[333px] overflow-y-scroll">{{ getNote(offer.note) }}</AgBox>
      </div>
    </div>

    <!-- ALERT INFO -->
    <div v-if="isCustomerBlocked" class="py-12">
      <AgAlert variant="danger">
        {{ t('offers.userBlocked') }}
        <template #icon><IcAlert /></template>
      </AgAlert>
    </div>

    <div v-if="offer.status === OfferStatus.DRAFT" class="pb-16">
      <AgAlert v-if="isError" variant="danger">
        {{ t('offers.notAllDataCorrect') }}
        <template #icon><IcAlert /></template>
      </AgAlert>
      <AgAlert v-else variant="success">
        {{ t('offers.allDataCorrect') }}
        <template #icon><IcAlert /></template>
      </AgAlert>
    </div>

    <div class="flex justify-end pt-16" v-if="offer.status === OfferStatus.DRAFT">
      <AgButton
        variant="primary"
        class="sm:min-w-[359px]"
        :disabled="isError || isCustomerBlocked"
        @click="openPopupCompleteOffer"
      >
        {{ t('offers.completeOffer') }}
      </AgButton>
    </div>
    <div class="flex justify-end pb-16" v-if="offer.status === OfferStatus.COMPLETED">
      <AgButton
        variant="primary"
        class="sm:min-w-[359px]"
        @click.stop="onTransform(offer)"
        :disabled="isCustomerBlocked || isExpired"
      >
        {{ t('offers.transformInOrder') }}
      </AgButton>
    </div>

    <OfferWizardSendMail v-model="showPopupEmail" :customerLanguage="offer.customer.language" />
    <OfferWizardComplete v-model="showPopupComplete" />
  </div>
</template>

<script setup lang="ts">
  import type { OfferDto } from '@/domain/offerData/OfferDto';
  import type { OfferItemDto } from '@/domain/offerData/OfferItemDto';

  import IcAlert from '@/components/icons/IcAlert.vue';
  import IcClientDetails from '@/components/icons/IcClientDetails.vue';
  import IcDeliveryAddress from '@/components/icons/IcDeliveryAddress.vue';
  import IcDeliveryDetails from '@/components/icons/IcDeliveryDetails.vue';
  import IcInfo from '@/components/icons/IcInfo.vue';
  import IcMailClose from '@/components/icons/IcMailClose.vue';
  import IcPaymentInfo from '@/components/icons/IcPaymentInfo.vue';
  import AgAlert from '@/components/library/alert/AgAlert.vue';
  import AgBox from '@/components/library/box/AgBox.vue';
  import AgButton from '@/components/library/button/AgButton.vue';
  import OfferInfo from '@/modules/offers/components/offer-wizard/steps/OfferInfo.vue';
  import PhotoVisualizer from '@/modules/offers/components/offer-wizard/steps/PhotoVisualizer.vue';
  import OfferWizardComplete from '@/modules/offers/components/offer-wizard/steps/review/OfferWizardComplete.vue';
  import OfferWizardSendMail from '@/modules/offers/components/offer-wizard/steps/review/OfferWizardSendMail.vue';
  import OfferWizardShoppingCartSummary from '@/modules/offers/components/offer-wizard/steps/shopping-cart/shopping-cart-summary/OfferWizardShoppingCartSummary.vue';
  import Popper from 'vue3-popper';

  import { useOfferData } from '@/composables/data/useOfferData';
  import { useTheFooter } from '@/composables/framework/useTheFooter';
  import { useTheHeader } from '@/composables/framework/useTheHeader';
  import { useDateFormatting } from '@/composables/useDateFormatting';
  import { useNumberFormatting } from '@/composables/useNumberFormatting';
  import { useTranslatedText } from '@/composables/useTransalteText';
  import { config } from '@/config/config';
  import { OfferStatus } from '@/domain/enumeration/OfferStatus';
  import { useOfferWizardStore } from '@/modules/offers/stores/useOfferWizardStore';
  import { useOrderFactory } from '@/modules/orders/composables/useOrderFactory';
  import { useOrders } from '@/modules/orders/composables/useOrders';
  import { i18n } from '@/plugins/i18n';
  import { router } from '@/plugins/router';
  import { Utilities } from '@/util/Utilities';
  import { storeToRefs } from 'pinia';
  import { computed, ref } from 'vue';

  const { locale, t } = i18n.global;

  const { timeToDate } = Utilities();
  const { formatDate } = useDateFormatting();
  const { formatNumberToEuro } = useNumberFormatting();
  const { formatNumberToPercentage } = useNumberFormatting();
  const { getTranslatedText } = useTranslatedText();

  const { syncOffers } = useOfferData();
  const { createOrderFromOffer } = useOrderFactory();
  const { saveOrder } = useOrders();

  const offerStore = useOfferWizardStore();
  const { offer, isCustomerBlocked, validationStates } = storeToRefs(offerStore);

  const theHeader = useTheHeader();
  theHeader.enabled(true);
  theHeader.visible(true);
  theHeader.title('offers.offer', 'offers.reviewWizard');

  const theFooter = useTheFooter();
  theFooter.enabled(offer.value?.status === 1);
  theFooter.visible(offer.value?.status === 1);

  const showPopupEmail = ref<boolean>(false);
  const showPopupComplete = ref<boolean>(false);

  const items = computed((): Array<OfferItemDto> => offer.value?.items || []);
  const isError = computed((): boolean => validationStates.value?.includes(false) ?? true);
  const isExpired = computed((): boolean => {
    if (offer.value && offer.value.offerDueDate) {
      const today = new Date();
      today.setUTCHours(0, 0, 0, 0);

      const ddDay = new Date(offer.value.offerDueDate * 1000);
      ddDay.setUTCHours(0, 0, 0, 0);

      return ddDay < today;
    }
    return true;
  });

  const tradeAgreementPrice = function (item: OfferItemDto) {
    if (item && item.quantity && item.quantity > 0) {
      if (item.isTradeAgreementPriceEdited) {
        return item.tradeAgreement.editedPrice ?? 0;
      }
      return item.tradeAgreement.price;
    }
    return 0;
  };

  const getSortDimensions = function (item: OfferItemDto, locale: string) {
    const dimensions: Array<string> = [];

    if (item.article.dimensions && item.article.dimensions.length > 0) {
      const sortDim = item.article.dimensions.sort((a, b) => {
        if (!a.title[locale]) {
          console.warn('Warning: founded a dimension without title in locale: ' + locale);
          return 1;
        }
        if (!b.title[locale]) {
          console.warn('Warning: founded a dimension without title in locale: ' + locale);
          return -1;
        }
        return a.title[locale].localeCompare(b.title[locale]);
      });
      dimensions.push(...sortDim.map((d) => d.value.title[locale]));
    }
    return dimensions.join(', ');
  };

  const getCompleteInvoiceAddress = function () {
    if (offer.value && offer.value.invoiceAddress) {
      const adr = offer.value.invoiceAddress;
      return [adr.street, adr.city + ` (${adr.district})`, adr.region, adr.zip, adr.nation].join(', ');
    }
    return '';
  };

  const getCompleteDeliveryAddress = function () {
    if (offer.value && calculatedDeliveryAddress.value) {
      const adr = calculatedDeliveryAddress.value;
      return [adr.street, adr.city + ` (${adr.district})`, adr.region, adr.zip, adr.nation].join(', ');
    }
    return '';
  };

  const calculatedDeliveryAddress = computed(() => {
    if (offer.value?.deliveryAddressEqualToBilling) {
      return offer.value.invoiceAddress;
    } else if (offer.value?.deliveryAddress) {
      return offer.value.deliveryAddress;
    }
  });

  const getFinalPrice = function (item: OfferItemDto) {
    if (item.quantity && item.quantity > 0) {
      return tradeAgreementPrice(item) * item.quantity * item.packaging.size;
    }
    return 0;
  };

  const getFinalDiscountedPrice = function (item: OfferItemDto) {
    let price = tradeAgreementPrice(item);
    for (let i = 1; i <= config.company.maxItemDiscounts; i++) {
      if ((item as never)[`discount${i}`]) {
        price = price * (1 - (item as never)[`discount${i}`] / 100);
      }
    }
    if (item.quantity && item.quantity > 0) {
      return price * item.quantity * item.packaging.size;
    }
    return 0;
  };

  const getDiscountedAmount = function (item: OfferItemDto) {
    return getFinalPrice(item) - getFinalDiscountedPrice(item);
  };

  const getDiscountsString = function (item: OfferItemDto) {
    if (item.quantity && item.quantity > 0) {
      type ObjectKey = keyof typeof item & number;
      let discounts: Array<string> = [];
      for (let i = 1; i <= config.company.maxItemDiscounts; i++) {
        const key = `discount${i}` as ObjectKey;
        if (!item[key]) {
          continue;
        }
        discounts.push(formatNumberToPercentage(item[key]));
      }
      return discounts.join(', ');
    }
    return '';
  };

  const openPopupCompleteOffer = function () {
    if (!offer.value) {
      return;
    }
    showPopupComplete.value = true;
    showPopupEmail.value = false;
  };

  const openPopupSendMail = function () {
    if (!offer.value) {
      return;
    }
    showPopupComplete.value = false;
    showPopupEmail.value = true;
  };

  const getNote = function (note: string) {
    if (note && note.length > 0) {
      return note;
    } else {
      return i18n.global.t('offers.noNote');
    }
  };

  const onTransform = async function (offer: OfferDto) {
    const newOrder = await createOrderFromOffer(offer);

    setTimeout(() => syncOffers(), 3 * 1000);

    await saveOrder(newOrder).then(
      async () =>
        await router.push({
          name: 'order-wizard',
          params: { gid: newOrder.gid, step: 'review' },
        })
    );
  };
</script>

<style scoped lang="scss">
  .row {
    @apply flex flex-wrap justify-between;
  }
  .agPopper {
    --popper-theme-background-color: #006fff;
    --popper-theme-background-color-hover: #006fff;
    --popper-theme-text-color: #ffffff;
    --popper-theme-border-width: 0px;
    --popper-theme-border-style: solid;
    --popper-theme-border-radius: 6px;
    --popper-theme-box-shadow: 0 6px 30px -6px rgba(0, 0, 0, 0.25);
  }
</style>

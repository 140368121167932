<template>
  <div>
    <div class="tableHeader">
      <div class="headerColumn">{{ t('admin.agent') }}</div>
      <div class="headerColumn">{{ t('admin.orderNumber') }}</div>
      <div class="headerColumn">{{ t('admin.orderDate') }}</div>
      <div class="headerColumn">{{ t('admin.customerName') }}</div>
      <div class="headerColumn">{{ t('admin.status') }}</div>
      <div class="headerColumn"></div>
    </div>

    <div v-for="order in props.orders" :key="order.gid">
      <div class="table justify-start items-center" @click="viewOrder(order.gid)">
        <div class="tableColumn">{{ order.agentName }}</div>
        <div class="tableColumn">{{ orderNumber(order) }}</div>
        <div class="tableColumn">{{ orderDate(order) }}</div>
        <div class="tableColumn">{{ order.customer.name }}</div>
        <div class="tableColumn">{{ orderStatus(order.status) }}</div>
        <div class="tableColumn justify-end mr-32"><IcChevronForward /></div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
  import IcChevronForward from '@/components/icons/IcChevronForward.vue';
  import { useDateFormatting } from '@/composables/useDateFormatting';
  import { OrderStatus } from '@/domain/enumeration/OrderStatus';
  import type { OrderDto } from '@/domain/orderData/OrderDto';

  import { i18n } from '@/plugins/i18n';
  import { useRouter } from 'vue-router';

  interface Props {
    orders: Array<OrderDto>;
  }

  const props = defineProps<Props>();

  const { formatDate } = useDateFormatting();

  const { t } = i18n.global;

  const router = useRouter();

  const orderNumber = function (order: OrderDto) {
    if (order.status === OrderStatus.COMPLETED) {
      return order.orderNumber;
    }
    return order.gid;
  };

  const orderDate = function (order: OrderDto) {
    if (order.status === OrderStatus.COMPLETED) {
      return formatDate(order.orderDate);
    }
    return formatDate(order.createdAt);
  };

  const orderStatus = function (status: number) {
    if (status === OrderStatus.COMPLETED) {
      return t('admin.completed');
    }
    return t('admin.draft');
  };

  const viewOrder = async (gid: string) => {
    try {
      await router.push({
        name: '/order',
        params: { gid: gid },
      });
    } catch (error) {
      console.error('Failed to navigate to order:', error);
    }
  };
</script>

<style scoped>
  .head {
    @apply bg-neutral-100 w-full h-[80px] px-4 flex justify-between items-center;
  }

  .tableHeader {
    @apply grid auto-cols-fr grid-flow-col-dense gap-12 py-8 font-light;
  }

  .headerColumn {
    @apply flex items-center  font-bold text-xl text-success;
  }

  .table:hover {
    @apply bg-neutral-100;
  }

  .tableColumn {
    @apply flex items-center;
  }
  .table {
    @apply grid auto-cols-fr grid-flow-col-dense gap-12 border-t border-neutral-300 py-8 cursor-pointer;
  }
</style>

import type { ContainerDto } from '@/admin/domain/data/ContainerDto';
import type { DownloadCategoryDto } from '@/admin/domain/DownloadCategoryDto';
import { useApi } from '@/composables/api/useApi';
import { useError } from '@/util/useError';

export function useDownloadCategories() {
  const api = useApi();
  const { handleCatchServerError, handleCatchLocalError } = useError();

  const getDownloadCategoryList = async function () {
    let downloadCategories: Array<DownloadCategoryDto> = [];
    try {
      const requestUri = '/adminapi/downloadCategories';

      const container = await api.fetch<ContainerDto>(requestUri, 'GET', undefined, true);
      if (container.errors && container.errors.length > 0) {
        handleCatchServerError(container.errors);
        return [];
      }
      if (container.data.downloadCategories) {
        downloadCategories = container.data.downloadCategories;
      }
      return downloadCategories;
    } catch (error) {
      handleCatchLocalError(error);
      return [];
    }
  };

  const createDownloadCategory = async function (method: DownloadCategoryDto | undefined) {
    if (!method) {
      return;
    }
    try {
      const requestUri = '/adminapi/downloadCategories';

      const container = await api.fetch<ContainerDto>(requestUri, 'POST', method, true);
      if (container.errors && container.errors.length > 0) {
        handleCatchServerError(container.errors);
        return;
      }
    } catch (error) {
      handleCatchLocalError(error);
    }
  };

  const deleteDownloadCategory = async function (categoryId: number) {
    try {
      const requestUri = `/adminapi/downloadCategories/${categoryId}`;

      const container = await api.fetch<ContainerDto>(requestUri, 'DELETE', undefined, true);
      if (container.errors && container.errors.length > 0) {
        handleCatchServerError(container.errors);
        return;
      }
    } catch (error) {
      handleCatchLocalError(error);
    }
  };

  const editDownloadCategory = async function (category: DownloadCategoryDto | undefined) {
    if (!category) {
      return;
    }
    try {
      const requestUri = '/adminapi/downloadCategories';

      const container = await api.fetch<ContainerDto>(requestUri, 'PATCH', category, true);
      if (container.errors && container.errors.length > 0) {
        handleCatchServerError(container.errors);
        return;
      }
    } catch (error) {
      handleCatchLocalError(error);
    }
  };

  return {
    getDownloadCategoryList,
    createDownloadCategory,
    deleteDownloadCategory,
    editDownloadCategory,
  };
}

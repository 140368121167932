import authAdminRequired from '@/middleware/authAdminRequired';
import type { RouteRecordRaw } from 'vue-router';
import CommunicationDetailsPage from './pages/CommunicationDetailsPage.vue';
import NewCommunicationPage from './pages/NewCommunicationPage.vue';

export const routes: Array<RouteRecordRaw> = [
  {
    path: ':communicationId/',
    name: '/communication',
    component: CommunicationDetailsPage,
    meta: { layout: 'AdminLayout' },
    beforeEnter: authAdminRequired,
  },
  {
    path: '/new-communication',
    name: '/new-communication',
    component: NewCommunicationPage,
    meta: { layout: 'AdminLayout' },
    beforeEnter: authAdminRequired,
  },
];

export default routes;

import type { ContainerDto } from '@/admin/domain/data/ContainerDto';
import { useApi } from '@/composables/api/useApi';
import { ref } from 'vue';
import type { AuthUserDto } from '../domain/AuthUserDto';

export function useDashboard() {
  const api = useApi();

  const getUsers = async function (): Promise<Array<AuthUserDto>> {
    const users = ref<Array<AuthUserDto>>([]);
    try {
      const requestUri = '/adminapi/users';

      const container = await api.fetch<ContainerDto>(requestUri, 'GET', undefined, true);
      if (container.data.authUsers) {
        users.value = container.data.authUsers as Array<AuthUserDto>;
      }
    } catch (error) {
      console.log('Error', error);
    }
    return users.value;
  };

  return {
    getUsers,
  };
}
